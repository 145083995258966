<template>
    <div
        :class="$style.HomeNewsCard"
        @click="openModal('MediaModal', {card})"
    >
        <div
            :class="$style.newsTitle"
            v-html="getTitle"
        >
        </div>
        <div
            :class="$style.newsDescription"
            v-html="getDescription"
        >
        </div>
        <div :class="$style.secondLine">
            <div :class="$style.newsDate">
                {{ publicationDate(card.publicationDate) }}
            </div>
            <IconNewsButton
                v-if="$device.isDesktop"
                :class="$style.icon"
            />
        </div>
    </div>
</template>

<script>
import { clearHTMLTags, monthByNumber } from '~/assets/js/utils';
import IconNewsButton from '@/assets/icons/icon-news-button.svg?inline';

export default {
    name: 'HomeNewsCard',

    components: {
        IconNewsButton,
    },

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        getTitle() {
            return this.card.title;
        },

        getClearedDescription() {
            return this.card?.intro ? clearHTMLTags(this.card.intro) : '';
        },

        getDescription() {
            return this.getClearedDescription.length > 160? this.getClearedDescription.slice(0, 160) + '...' : this.card.intro;
        },
    },

    methods: {
        publicationDate(publicationDate) {
            const date = new Date(publicationDate);
            return `${date.getDate()} ${monthByNumber(date.getMonth(), 'case')} ${date.getFullYear()}`;
        },

        openModal(name, data) {
            this.$modal.open(name, data);
        },
    },
};
</script>

<style lang="scss" module>
    .HomeNewsCard {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        padding: 2.8rem 2.7rem 2.8rem 2.8rem;
        background-color: #fff;
        cursor: pointer;

        &:hover {
            .icon {
                opacity: 1;
            }

            .newsDescription {
                color: $home-base-1;
            }
        }
    }

    .newsTitle {
        margin-bottom: 2.4rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1.4;
        color: $base-900;

        @include respond-to(xxs) {
            font-size: 1.6rem;
        }
    }

    .newsDescription {
        margin-bottom: 2.9rem;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.32;
        color: $base-500;
        transition: $homeTransition;

        p {
            display: inline-block;
        }
    }

    .secondLine {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: auto;
    }

    .newsDate {
        padding: 9px 12px 7px;
        background: $base-50;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        box-shadow: inset .01px .01px 68px rgba(255, 255, 255, .05);
        backdrop-filter: blur(86px);
    }

    .icon {
        width: 40px;
        height: 40px;
        opacity: 0;
        transition: $homeTransition;
    }
</style>
