import {mapGetters} from "vuex";

const HostMixin = {
    computed: {
        ...mapGetters({
            fullPath: 'domain/getFullPath',
        })
    },
}

export default HostMixin;
