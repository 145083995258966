// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiMobileSelect_nrQxr{width:100%}.selectBtn_ko6-z{position:relative;width:100%;height:100%;padding:1.9rem 4.6rem 1.9rem 2rem;background-color:#f3f3f3;text-align:left;color:#a0a0a0;-webkit-transition:color .3s ease-in-out;transition:color .3s ease-in-out}.selectBtn_ko6-z:hover{color:#717171}.label_8gtZv{text-transform:uppercase;font-size:12px;font-weight:700;line-height:1}.text_9Gd2t{color:currentColor}.icon_7lirV{position:absolute;top:50%;right:2rem;width:1.6rem;height:1.6rem;color:#2f2f2f;-webkit-transform:translateY(-50%);transform:translateY(-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiMobileSelect": "UiMobileSelect_nrQxr",
	"selectBtn": "selectBtn_ko6-z",
	"label": "label_8gtZv",
	"text": "text_9Gd2t",
	"icon": "icon_7lirV"
};
module.exports = ___CSS_LOADER_EXPORT___;
