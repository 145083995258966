// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YMap_DWty-{position:relative}.map_DdmQC,.YMap_DWty-{width:100%;height:100%}.map_DdmQC{position:absolute;top:0;left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"YMap": "YMap_DWty-",
	"map": "map_DdmQC"
};
module.exports = ___CSS_LOADER_EXPORT___;
