// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsReadyFilter_8JEMW{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;margin-top:-.8rem;margin-left:-.8rem}.FlatsReadyFilter_8JEMW .tag_o0\\+6L{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:.8rem;margin-left:.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsReadyFilter": "FlatsReadyFilter_8JEMW",
	"tag": "tag_o0+6L"
};
module.exports = ___CSS_LOADER_EXPORT___;
