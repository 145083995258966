<script>
import { validateCoordinates } from '~/components/common/ymap/utils';
import { ymapClientKey } from '~/components/common/ymap/config';
import FirstPointIcon from '~/assets/icons/home/location-pin-white.svg';

export default {
    name: 'YMapRoute',
    inject: {
        getYmapClient: {
            from: ymapClientKey,
            default: null,
        },
    },

    props: {
        endCoords: {
            type: Array,
            required: true,
            validate: val => validateCoordinates(val),
        },
    },

    data() {
        return {
            ymaps: null,
            geoObject: null,
            routeInfo: null,
            errorMessage: null,
        };
    },

    created() {
        this.ymaps = this.getYmapClient();
    },

    methods: {
        async buildRoute(address, mode) {
            if (!this.ymaps) {
                return false;
            }

            if (!validateCoordinates(this.endCoords)) {
                this.errorMessage = 'Некорректный формат конченой точки';
                return false;
            }

            try {
                const response = await new this.ymaps.geocode(address);

                this.resetRoute();

                const startCoords = response.geoObjects.get(0).geometry.getCoordinates();
                const route = this.getRoute(startCoords, mode);
                const placemark = this.getPlacemark(startCoords);

                const collection = new this.ymaps.GeoObjectCollection();
                collection.add(route);
                collection.add(placemark);
                this.geoObject = collection;
                this.$parent.addGeoObject(this.geoObject);
            } catch (e) {
                console.warn('[YMapRoute / buildRoute]', e);
                this.errorMessage = 'Введите корректный адрес';
            }
        },

        resetRoute() {
            if (this.geoObject) {
                this.$parent.removeGeoObject(this.geoObject);
                this.routeInfo = null;
                this.errorMessage = null;
                this.geoObject = null;
            }
        },

        getRoute(startCoords, mode) {
            const route = new this.ymaps.multiRouter.MultiRoute({
                referencePoints: [
                    startCoords,
                    this.endCoords,
                ],

                params: {
                    results: 1,
                    routingMode: mode,
                },
            }, {
                // Внешний вид линии маршрута.
                routeActiveStrokeWidth: 3,
                routeActiveStrokeColor: '#2f2f2f',
                wayPointStartIconFillColor: '#2f2f2f',

                // Позволяет скрыть иконки путевых точек маршрута.
                wayPointVisible: false,
                viaPointVisible: false,
                routeActiveMarkerVisible: false,
                routeOpenBalloonOnClick: false,

                // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
                boundsAutoApply: true,
            });

            route.model.events.add('requestsuccess', e => {
                const activeRoutes = e.get('target').getRoutes();
                this.errorMessage = '';

                this.routeInfo = {
                    type: activeRoutes[0].properties.get('type'),
                    distance: `${(activeRoutes[0].properties.get('distance').value / 1000).toFixed(1)} км`,
                    time: activeRoutes[0].properties.get('duration').text,
                };
            });

            return route;
        },

        getPlacemark(startCoords) {
            return new this.ymaps.Placemark(startCoords, {}, {
                iconLayout: this.ymaps.templateLayoutFactory.createClass(`
                        <div class="ymap-first-point-placemark">
                            <div
                                class="ymap-first-point-placemark__image"
                                style="background-image: url(${FirstPointIcon});"
                            >
                            </div>
                        </div>
                    `),

                iconOffset: [-20, -20],
                zIndex: 680,
            });
        },
    },

    render() {
        return this.$scopedSlots.default({
            buildRoute: this.buildRoute,
            resetRoute: this.resetRoute,
            routeInfo: this.routeInfo,
            errorMessage: this.errorMessage,
        });
    },
};
</script>

<style lang="scss">
    .ymap-first-point-placemark {
        overflow: hidden;
        width: 40px;
        height: 40px;
        padding: 12px;
        border-radius: 50%;
        background-color: $home-base-1;
        transition: $homeTransition;

        &__image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
</style>
