<template>
    <component
        :is="tagComponent"
        :class="[$style.UiTag, classList]"
        v-bind="elementAttributes"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
        @click="$emit('click')"
    >
        <div
            v-if="$slots.iconBefore"
            :class="$style.iconBefore"
        >
            <slot name="iconBefore" />
        </div>

        <slot />

        <div
            v-if="$slots.iconAfter"
            :class="$style.iconAfter"
        >
            <slot name="iconAfter" />
        </div>
    </component>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
export default {
    name: 'UiTag',

    props: {
        link: {
            type: String,
            default: '',
        },

        external: {
            type: Boolean,
            default: false,
        },

        blank: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            default: 'button',
        },

        color: {
            type: String,
            default: 'light',
            validator: value => ['light', 'opacity', 'black', 'transparent', 'white', 'white-border', 'yellow', 'yellowInversed'].includes(value),
        },

        size: {
            type: String,
            default: 'normal',
            validator: value => ['normal', 'medium', 'big', 'small', 'extra-small', 'fitContent'].includes(value),
        },

        hover: {
            type: Boolean,
            default: false,
        },

        active: {
            type: Boolean,
            default: false,
        },

        bordered: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        blurred: {
            type: Boolean,
            default: false,
        },

        notInteractive: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        tagComponent() {
            return this.link ? this.external || this.blank ? 'a' : 'nuxt-link' : 'button';
        },

        elementAttributes() {
            return {
                type: this.link ? null : this.type,
                to: this.external || this.blank ? null : this.link,
                href: this.external || this.blank ? this.link : null,
                target: this.blank ? '_blank' : null,
                rel: this.blank ? 'noreferrer noopener' : null,
            };
        },

        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._disabled]: this.disabled,
                [this.$style._mobile]: !this.$device.isDesktop,
                [this.$style._hover]: this.hover,
                [this.$style._active]: this.active,
                [this.$style._bordered]: this.bordered,
                [this.$style._blurred]: this.blurred,
                [this.$style._notInteractive]: this.notInteractive,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .UiTag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 1.2rem;
        outline: none;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        transition: $transition;
        cursor: pointer;
        user-select: unset;
        pointer-events: unset;

        &._notInteractive {
            cursor: default;
            user-select: none;
            pointer-events: none;
        }

        .iconBefore,
        .iconAfter {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
        }

        .iconBefore {
            margin-right: .6rem;
        }

        .iconAfter {
            margin-left: .6rem;
        }

        &._disabled {
            pointer-events: none;
        }

        &._bordered {
            @include p4;

            border-radius: 2.4rem;
        }

        &._blurred {
            backdrop-filter: blur(.8rem);
        }
    }

    /* sizes */
    .UiTag._normal {
        height: 3.2rem;
        min-height: 3.2rem;
    }

    .UiTag._medium {
        height: 3.6rem;
        min-height: 3.6rem;
    }

    .UiTag._big {
        height: 4rem;
        min-height: 4rem;
    }

    .UiTag._small {
        @include l5;

        height: 3rem;
        min-height: 3rem;
    }

    .UiTag._extra-small {
        height: 2.4rem;
        min-height: 2.4rem;
        padding: 0 .8rem;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1;
    }

    .UiTag._fitContent {
        height: fit-content;
        min-height: fit-content;
    }

    /* colors */
    .UiTag._light {
        border: 1px solid $base-50;
        background-color: $base-50;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hover {
                color: $base-900;
            }

            &:active,
            &._active {
                color: $base-600;
            }
        }

        &:hover,
        &._hover {
            color: $base-600;
        }

        &:active,
        &._active {
            color: $base-900;
        }

        &._disabled {
            border: 1px solid $base-50;
            background-color: $base-50;
            color: $base-300;

            &._mobile {
                &:hover,
                &._hover {
                    color: $base-300;
                }

                &:active,
                &._active {
                    color: $base-300;
                }
            }

            &:hover,
            &._hover {
                color: $base-300;
            }

            &:active,
            &._active {
                color: $base-300;
            }
        }
    }

    .UiTag._opacity {
        border: none;
        background-color: rgba($base-900, .32);
        color: $base-0;

        &._mobile {
            &:hover,
            &._hover {
                background-color: rgba($base-900, .32);
            }

            &:active,
            &._active {
                background-color: rgba($base-900, .24);
            }
        }

        &:hover,
        &._hover {
            background-color: rgba($base-900, .24);
        }

        &:active,
        &._active {
            background-color: rgba($base-900, .32);
        }

        &._disabled {
            border: 1px solid $base-50;
            color: $base-300;

            &._mobile {
                &:hover,
                &._hover {
                    background-color: $base-50;
                }

                &:active,
                &._active {
                    background-color: $base-50;
                }
            }

            &:hover,
            &._hover {
                background-color: $base-50;
            }

            &:active,
            &._active {
                background-color: $base-50;
            }
        }
    }

    .UiTag._black {
        border: 1px solid $base-900;
        background-color: $base-900;
        color: $base-0;

        &._mobile {
            &:hover,
            &._hover {
                border: 1px solid $base-900;
                background-color: $base-900;
            }

            &:active,
            &._active {
                border: 1px solid $base-700;
                background-color: $base-700;
            }
        }

        &:hover,
        &._hover {
            border: 1px solid $base-700;
            background-color: $base-700;
        }

        &:active,
        &._active {
            border: 1px solid $base-900;
            background-color: $base-900;
        }

        &._disabled {
            border: 1px solid $base-700;
            background-color: $base-700;
            color: $base-600;

            &._mobile {
                &:hover,
                &._hover {
                    border: 1px solid $base-700;
                    background-color: $base-700;
                }

                &:active,
                &._active {
                    border: 1px solid $base-700;
                    background-color: $base-700;
                }
            }

            &:hover,
            &._hover {
                border: 1px solid $base-700;
                background-color: $base-700;
            }

            &:active,
            &._active {
                border: 1px solid $base-700;
                background-color: $base-700;
            }
        }
    }

    .UiTag._transparent {
        border: 1px solid rgba($base-0, .24);
        background-color: rgba($base-0, .24);
        color: $base-0;

        &._mobile {
            &:hover,
            &._hover {
                border: 1px solid rgba($base-0, .24);
                background-color: rgba($base-0, .24);
            }

            &:active,
            &._active {
                border: 1px solid rgba($base-0, .32);
                background-color: rgba($base-0, .32);
            }
        }

        &:hover,
        &._hover {
            border: 1px solid rgba($base-0, .32);
            background-color: rgba($base-0, .32);
        }

        &:active,
        &._active {
            border: 1px solid rgba($base-0, .24);
            background-color: rgba($base-0, .24);
        }

        &._disabled {
            border: 1px solid $base-50;
            background-color: $base-50;
            color: $base-300;

            &._mobile {
                &:hover,
                &._hover {
                    border: 1px solid $base-50;
                    background-color: $base-50;
                }

                &:active,
                &._active {
                    border: 1px solid $base-50;
                    background-color: $base-50;
                }
            }

            &:hover,
            &._hover {
                border: 1px solid $base-50;
                background-color: $base-50;
            }

            &:active,
            &._active {
                border: 1px solid $base-50;
                background-color: $base-50;
            }
        }
    }

    .UiTag._white {
        border: 1px solid $base-0;
        background-color: $base-0;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hover {
                color: $base-900;
            }

            &:active,
            &._active {
                color: $base-600;
            }
        }

        &:hover,
        &._hover {
            color: $base-600;
        }

        &:active,
        &._active {
            color: $base-900;
        }

        &._disabled {
            border: 1px solid $base-0;
            background-color: $base-0;
            color: $base-300;

            &._mobile {
                &:hover,
                &._hover {
                    color: $base-300;
                }

                &:active,
                &._active {
                    color: $base-300;
                }
            }

            &:hover,
            &._hover {
                color: $base-300;
            }

            &:active,
            &._active {
                color: $base-300;
            }
        }
    }

    .UiTag._white-border {
        border: 1px solid $base-200;
        background-color: $base-0;
        color: $base-900;
    }

    .UiTag._yellow {
        border: 1px solid $base-0;
        background-color: $base-0;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hover {
                border: 1px solid $base-0;
                background-color: $base-0;
                color: $base-900;
            }

            &:active,
            &._active {
                border: 1px solid #f1e56d;
                background-color: #f1e56d;
                color: $base-900;
            }
        }

        &:hover,
        &._hover {
            border: 1px solid #faee7c;
            background-color: #faee7c;
            color: $base-900;
        }

        &:active,
        &._active {
            border: 1px solid #f1e56d;
            background-color: #f1e56d;
            color: $base-900;
        }
    }

    .UiTag._yellowInversed {
        border: 1px solid $yellow-light;
        background-color: $yellow-light;
        color: $base-900;

        &._mobile {
            &:hover,
            &._hover {
                border: 1px solid #f1e56d;
                background-color: $base-0;
                color: $base-900;
            }

            &:active,
            &._active {
                border: 1px solid #f1e56d;
                background-color: $base-0;
                color: $base-900;
            }
        }

        &:hover,
        &._hover {
            border: 1px solid #f1e56d;
            background-color: $base-0;
            color: $base-900;
        }

        &:active,
        &._active {
            border: 1px solid #f1e56d;
            background-color: $base-0;
            color: $base-900;
        }
    }
</style>
