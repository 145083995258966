<template>
    <div :class="$style.FlatsUrbanBlockDropdown">
        <UiScrollbox
            resizable
            skip-offset
            :class="$style.scrollbox"
            color="black"
        >
            <UiSelectAccordion
                :name="name"
                :value="value"
                :specs="specs"
                :facets="facets"
                reset-label="Любой"
                :multiple="multiple"
                :disabled="disabled"
                @change="$emit('change', $event)"
                @mouseover="showInfo"
                @mouseleave="hideInfo"
            />
        </UiScrollbox>

        <div :class="[$style.info, {[$style._shown]: isInfoShown}]">
            <ImageLazy
                v-if="display"
                :preview="preview"
                :origin="display"
                :absolute="false"
                :class="$style.image"
            />
        </div>
    </div>
</template>

<script>
import UiScrollbox from '~/components/ui/scrollbox/UiScrollbox.vue';
import UiSelectAccordion from '~/components/ui/select-accordion/UiSelectAccordion.vue';
import ImageLazy from '~/components/common/ImageLazy.vue';

export default {
    name: 'FlatsUrbanBlockDropdown',

    components: {
        UiScrollbox,
        UiSelectAccordion,
        ImageLazy,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        value: {
            type: [String, Number, Array],
            default: '',
        },

        /**
         * Диапазон всех доступных значений селектора
         */
        specs: {
            type: Array,
            required: true,
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            required: true,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isInfoShown: false,
            preview: null,
            display: null,
            timer: null,
        };
    },

    methods: {
        showInfo(value) {
            if (value && value.imageFlatFilterDisplay) {
                clearTimeout(this.timer);

                this.preview = value.imageFlatFilterPreview;
                this.display = value.imageFlatFilterDisplay;
                this.isInfoShown = true;
            }
        },

        hideInfo() {
            this.isInfoShown = false;

            this.timer = setTimeout(() => {
                this.preview = null;
                this.display = null;
            }, 300);
        },
    },
};
</script>

<style lang="scss" module>
    $height-items: 4.8rem;

    .FlatsUrbanBlockDropdown {
        position: absolute;
        top: calc(100% + 1.2rem);
        left: 0;
        z-index: 5;
        display: flex;
        flex-direction: column;
        width: 40rem;
        border: 1.5px solid $base-50;
        background-color: $base-0;
        box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
        transition: $transition;

        .scrollbox {
            max-height: calc($height-items * 4.5);
        }

        .info {
            position: absolute;
            top: 0;
            left: calc(100% + .8rem);
            visibility: hidden;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            min-height: 20rem;
            background-color: $base-0;
            opacity: 0;
            box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
            transition: $transition;

            &._shown {
                visibility: visible;
                opacity: 1;
            }
        }

        .image {
            width: 100%;
            height: 100%;
        }
    }
</style>
