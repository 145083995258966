<template>
    <div :class="$style.HomeSaleFormTitle">
        <div :class="$style.title" v-html="title"></div>
        <div :class="$style.subtitle" v-html="description"></div>
    </div>
</template>

<script>
export default {
    name: 'HomeSaleFormTitle',

    props: {
        title: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },
    },
};

</script>

<style lang="scss" module>
    .HomeSaleFormTitle {
        text-align: center;
    }

    .title {
        @include old-h1;

        text-transform: uppercase;
        line-height: 1.16;
        color: $base-900;

        @include respond-to(sm) {
            font-size: 2.4rem;
        }
    }

    .subtitle {
        max-width: 66rem;
        margin: 2.4rem auto 0;
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.24;
        color: $base-500;

        @include respond-to(sm) {
            margin-top: 2.4rem;
            font-size: 1.8rem;
        }
    }
</style>
