<template>
    <div :class="$style.FlatsParametersDropdown">
        <UiScrollbox
            resizable
            skip-offset
            :class="$style.scrollbox"
        >
            <div :class="$style.container">
                <UiInputWrapper label="Тип лота">
                    <FlatsTagsArray
                        name="newTypeFlat"
                        data-gtm-html-id="new_type_flat"
                        :value="filters.newTypeFlat"
                        :specs="specs.new_type_flat"
                        :facets="facets.new_type_flat"
                        multiple
                        :disabled="isReloading"
                        @change="changeFilters"
                    />
                </UiInputWrapper>

                <UiInputWrapper label="Площадь, м²">
                    <UiRangeInput
                        data-gtm-html-id="area"
                        name="area"
                        :value-min="filters.areaMin"
                        :value-max="filters.areaMax"
                        :specs="specs.area"
                        :facets="facets.area"
                        show-label
                        :decimal-count="1"
                        :disabled="isReloading"
                        @change="changeFilters"
                    />
                </UiInputWrapper>

                <UiInputWrapper label="Количество спален">
                    <FlatsFilterLayout
                        name="layout"
                        :value="filters.layout"
                        :specs="specs.layout"
                        :facets="facets.layout"
                        :disabled="isReloading"
                        data-gtm-html-id="layout"
                        multiple
                        @change="changeFilters"
                    />
                </UiInputWrapper>
            </div>
        </UiScrollbox>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UiScrollbox from '~/components/ui/scrollbox/UiScrollbox.vue';
import UiInputWrapper from '~/components/ui/UiInputWrapper.vue';
import FlatsTagsArray from '~/components/flats/common/FlatsTagsArray.vue';
import UiRangeInput from '~/components/ui/inputs/range-input/UiRangeInput.vue';
import FlatsFilterLayout from '~/components/flats/filters/FlatsFilterLayout.vue';

export default {
    name: 'FlatsParametersDropdown',

    components: {
        FlatsFilterLayout,
        UiInputWrapper,
        UiScrollbox,
        FlatsTagsArray,
        UiRangeInput,
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'specs',
            'facets',
            'isReloading',
        ]),
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),
    },
};
</script>

<style lang="scss" module>
    .FlatsParametersDropdown {
        position: absolute;
        top: calc(100% + 1.2rem);
        left: 0;
        z-index: 5;
        width: 49.6rem;
        height: 33rem;
        background-color: $base-0;
        box-shadow: 0 1.2rem 2.4rem 0 rgba($base, .04);
        transition: $transition;

        .scrollbox {
            max-height: 100%;
        }

        .container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 3.2rem;
            width: 100%;
            height: fit-content;
            padding: 2rem;
        }
    }
</style>
