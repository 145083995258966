<template>
    <div
        :class="classes"
    >
        <TitleHeader
            v-if="isModal && !isRosteh"
            :class="$style.header"
            @close="onCloseModal"
        />

        <YMap
            ref="map"
            :scroll-zoom="scrollZoom"
            :zoom="17"
            :opened-coords="isRosteh ? [55.822336, 37.426626] : centerCoords"
            @loaded="mapLoaded = true"
            @geo-objects-updated="onGeoObjectsUpdated"
            @click.native="hideOverlay"
            @touchstart.native="hideOverlay"
        >
            <template v-if="isRosteh">
                <YMapProjectCirclePlacemark
                    v-for="pin in rostehPoints"
                    :key="pin.id"
                    :coords="pin.coords"
                    :icon="pin.icon"
                    :has-walking-distance="pin.hasWalkingDistance"
                />
            </template>
            <template v-else>
                <YMapProjectCirclePlacemark
                    v-for="pin in projectPoints"
                    :key="pin.id"
                    :coords="pin.coords"
                    :label="pin.label"
                    :icon="pin.icon"
                    :background="pin.background"
                    :has-walking-distance="pin.hasWalkingDistance"
                />
            </template>
            <YMapInfrastructureClusterer :key="activeCategory">
                <YMapInfrastructurePlacemark
                    v-for="point in infrastructurePoints"
                    :key="point.id"
                    :coords="point.coords"
                    :icon="point.icon"
                    :title="point.hint.title"
                    :display-image="point.hint.displayImage"
                    :preview-image="point.hint.previewImage"
                    :estimate="point.hint.estimate"
                    :is-digital="isDigital"
                />
            </YMapInfrastructureClusterer>

            <YMapRoute
                ref="route"
                v-slot="{ buildRoute, resetRoute, routeInfo, errorMessage }"
                :end-coords="routeEndCoords"
            >
                <div
                    v-if="!isModal && !isDigital"
                    :class="$style.route"
                >
                    <transition name="fade">
                        <UiButton
                            v-if="!isRouteShown && mapLoaded"
                            color="black"
                            size="big"
                            :class="$style.routeButton"
                            @click="isRouteShown = true"
                        >
                            <template #iconBefore>
                                <LocationArrowIcon/>
                            </template>
                            Маршрут
                        </UiButton>
                        <MapBuildRoute
                            v-else
                            :route-info="routeInfo"
                            :error-message="errorMessage"
                            :class="$style.routeModal"
                            @buildRoute="buildRoute"
                            @resetRoute="resetRoute"
                            @close="isRouteShown = false"
                        />
                    </transition>
                </div>
            </YMapRoute>

            <template #control="{ handleZoom }">
                <div
                    v-show="$device.isDesktop && !isIpadPro"
                    :class="$style.zoomControls"
                >
                    <UiCircleButton
                        color="white"
                        :class="$style.button"
                        @click="handleZoom(1)"
                    >
                        <PlusIcon/>
                    </UiCircleButton>

                    <UiCircleButton
                        color="white"
                        :class="$style.button"
                        @click="handleZoom(-1)"
                    >
                        <MinusIcon/>
                    </UiCircleButton>
                </div>

                <transition name="fade">
                    <IconFilter
                        v-if="categories && categories.length && mapLoaded && (!isModal || isDigital)"
                        v-model="activeCategory"
                        key-value="id"
                        :items="categories"
                        :class="$style.iconFilter"
                        footer-label="Все самое необходимое в пешей доступности"
                        :header-link="ymapLink"
                        blank
                    >
                        <template
                            v-if="!hideYandexLink"
                            #headerIcon="{ hover }"
                        >
                            <div :class="[$style.filterHeaderIcon, {[$style._hover]: hover}]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 28 28"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M17.573 17.348H15.35l-4.242 9.53H8l4.663-10.2c-2.191-1.111-3.66-3.13-3.66-6.853C9.004 4.601 12.307 2 16.247 2h4.004v24.878h-2.677v-9.53Zm0-2.223V4.266H16.15c-2.159 0-4.242 1.424-4.242 5.569 0 4.004 1.91 5.288 4.242 5.288h1.424Z"
                                    />
                                </svg>
                            </div>
                        </template>
                        <template
                            v-if="!hideYandexLink"
                            #headerLabel
                        >
                            Открыть Я.Карты
                        </template>
                    </IconFilter>
                </transition>
            </template>
        </YMap>

        <div
            v-if="isModal && !isDigital"
            :class="['container-home', $style.footer]"
        >
            <UiButtonGroup
                v-show="mapLoaded && categories && categories.length"
                v-model="activeCategory"
                data-scroll-lock-scrollable
                :class="$style.iconFilterModal"
            >
                <template v-if="categories && categories.length">
                    <UiButton
                        v-for="item in categories"
                        :key="item.id"
                        :value="item.id"
                        color="white"
                        :class="$style.tag"
                    >
                        <template #iconBefore="{ active }">
                            <img
                                v-lazy="item.icon"
                                :class="[$style.icon, {[$style._inverse]: !active}]"
                                alt="icon"
                            />
                        </template>
                        {{ item.label }}
                    </UiButton>
                </template>
            </UiButtonGroup>

            <template v-if="!isRosteh">
                <UiButton
                    color="black"
                    size="big"
                    full-width
                    :class="$style.link"
                    @click="openBuildRoute"
                >
                    <template #iconBefore>
                        <LocationArrowIcon/>
                    </template>
                    Маршрут
                </UiButton>

                <UiButton
                    color="gray"
                    size="big"
                    :link="ymapLink"
                    blank
                    :class="$style.link"
                >
                    Открыть Я.Карты
                </UiButton>
            </template>
        </div>

        <transition name="fade">
            <div
                v-if="isOverlayShown && isModal && !isDigital"
                :class="$style.overlay"
            >
                <div :class="$style.overlayContent">
                    <FingerIcon :class="$style.overlayIcon"/>
                    <p>Для перемещения по карте<br>используйте жесты</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

// queries
import mainPageInfrastructureMap from '~/queries/new-home/mainPageInfrastructureMap.graphql';

// icons
import RostehLogo from '~/assets/icons/home/logos/logo-rosteh.svg';
import AliaSlimLogo from '~/assets/icons/home/logos/logo-slim.svg';
import LocationArrowIcon from '~/assets/icons/home/location-arrow.svg?inline';
import FingerIcon from '~/assets/icons/genplan-finger.svg?inline';
import PlusIcon from '~/assets/icons/home/plus.svg?inline';
import MinusIcon from '~/assets/icons/home/minus.svg?inline';

// utils
import { mapGetters } from 'vuex';
import { getNodeArray, lockBody } from '~/assets/js/utils';
import { centerCoords } from '~/components/common/ymap/config';

// components
import YMap from '~/components/common/ymap/YMap.vue';
import YMapRoute from '~/components/common/ymap/YMapRoute.vue';
import YMapProjectCirclePlacemark from '~/components/common/ymap/placemarks/YMapProjectCirclePlacemark.vue';
import YMapInfrastructureClusterer from '~/components/common/ymap/clusterers/YMapInfrastructureClusterer.vue';
import YMapInfrastructurePlacemark from '~/components/common/ymap/placemarks/YMapInfrastructurePlacemark.vue';
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';
import UiButton from '~/components/ui/buttons/UiButton.vue';
import UiButtonGroup from '~/components/ui/buttons/UiButtonGroup.vue';
import IconFilter from '~/components/common/filter/IconFilter.vue';
import MapBuildRoute from '~/components/home/genplan/map/MapBuildRoute.vue';
import TitleHeader from '~/components/common/modals/universal-modal/headers/TitleHeader.vue';
import MapBuildRouteMobile from '~/components/home/genplan/map/MapBuildRouteMobile.vue';

export default {
    name: 'InfrastructureMap',
    components: {
        YMap,
        YMapRoute,
        YMapProjectCirclePlacemark,
        YMapInfrastructureClusterer,
        YMapInfrastructurePlacemark,
        UiCircleButton,
        UiButton,
        UiButtonGroup,
        IconFilter,
        MapBuildRoute,
        TitleHeader,
        PlusIcon,
        MinusIcon,
        LocationArrowIcon,
        FingerIcon,
    },

    props: {
        isModal: {
            type: Boolean,
            default: false,
        },

        onCloseModal: {
            type: Function,
            default: () => null,
        },

        // отключение/включение масштабирование карты по скроллу
        scrollZoom: {
            type: Boolean,
            default: false,
        },

        isRosteh: {
            type: Boolean,
            default: false,
        },

        // скрывает ссылку на яндекс карту
        hideYandexLink: {
            type: Boolean,
            default: false,
        },

        isDigital: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            rostehPoints: [{
                id: 'rosteh',
                coords: [55.822486, 37.426626],
                icon: RostehLogo,
                hasWalkingDistance: false,
            }],

            projectPoints: [{
                id: 'project',
                coords: [55.819900, 37.422932],
                icon: AliaSlimLogo,
                hasWalkingDistance: true,
            }],

            centerCoords: centerCoords,

            mapLoaded: false,
            activeCategory: null, // активная категория инфраструктуры
            categories: [], // категории инфраструктуры

            address: '', // адрес для построения маршрута
            isRouteShown: false, // флаг отображения модального окна маршрута
            routeType: null, // тип маршрута
            routeEndCoords: [55.819900, 37.422932], // конечная точка маршрута

            isOverlayShown: true,
        };
    },

    computed: {
        infrastructurePoints() {
            if (this.activeCategory === null) {
                return this.categories.reduce((pins, category) => pins.concat(category.pins), []);
            }

            const category = this.categories.find(category => category.id === this.activeCategory);
            return category && category?.pins ? category.pins : [];
        },

        ymapLink() {
            if (this.projectPoints && this.projectPoints.length) {
                return `https://yandex.ru/maps/213/moscow/?ll=${this.projectPoints[0].coords[1]}%2C${this.projectPoints[0].coords[0]}&origin=jsapi21&z=15`;
            }

            return '';
        },

        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        classes() {
            return {
                [this.$style.InfrastructureMap]: true,
                [this.$style._isModal]: this.isModal,
                [this.$style._isRosteh]: this.isRosteh,
            };
        },
    },

    async mounted() {
        await this.handleLoadData();
    },

    methods: {
        async handleLoadData() {
            try {
                const { data } = await this.$axios.$post('/graphql/', {
                    query: mainPageInfrastructureMap.loc.source.body,
                });

                const response = data?.mainPageNew;

                const categories = getNodeArray(response, 'allMapCategories')
                    .filter(category => category.pins.length)
                    .map(category => ({
                        id: category.id,
                        label: category.title,
                        icon: category.icon,
                        pins: category.pins.map(pin => ({
                            id: pin.id,
                            coords: [pin.latitude, pin.longitude],
                            icon: category.icon,
                            hint: {
                                title: pin.title,
                                text: pin.text,
                                displayImage: pin.imageDisplay,
                                previewImage: pin.imagePreview,
                                estimate: pin.estimate,
                            },
                        })),
                    }));

                if (categories.length) {
                    categories.unshift({
                        id: null,
                        label: 'Все объекты',
                        icon: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"%3E%3Cpath fill="%23fff" d="M8 12.173 4.559 14.1a.667.667 0 0 1-.98-.711l.769-3.868-2.896-2.677a.667.667 0 0 1 .374-1.152l3.916-.464 1.652-3.581a.667.667 0 0 1 1.211 0l1.652 3.58 3.916.465a.667.667 0 0 1 .374 1.152L11.652 9.52l.768 3.868a.667.667 0 0 1-.98.711L8 12.173Z"/%3E%3C/svg%3E%0A',
                        pins: [],
                    });
                }
                this.categories = categories;
            } catch (e) {
                console.warn('[InfrastructureMap / handleLoadData]', e);
            }
        },

        onGeoObjectsUpdated(action) {
            if (action === 'add' && this.$refs.map && this.mapLoaded && this.activeCategory !== null) {
                this.$refs.map.setBounds();
            }
        },

        hideOverlay() {
            if (this.isOverlayShown) {
                this.isOverlayShown = false;
            }
        },

        openBuildRoute() {
            this.$modalMobileSlideUp.open({
                component: MapBuildRouteMobile,
                options: {
                    title: 'Постройте маршрут до ЖК',
                    address: this.address,
                    routeType: this.routeType,
                    buildRoute: (address, mode) => {
                        this.$refs.route.buildRoute(address, mode);
                        this.address = address;
                        this.routeType = mode;
                    },
                    resetRoute: () => {
                        this.$refs.route.resetRoute();
                        this.address = '';
                        this.routeType = null;
                    },
                    routeInfo: this.$refs.route.routeInfo,
                    beforeClose: () => {
                        lockBody();
                    },
                },
            });
        },
    },
};
</script>

<style
    lang="scss"
    module
>
    .InfrastructureMap {
        position: relative;
        width: 100%;
        height: 100%;

        &._isModal {
            display: block;
            background-color: $base-0;
        }

        &._isRosteh {
            .footer {
                padding: 0;
            }
        }

        .iconFilter {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 3;
            height: 70%;
            transform: translateY(-50%);
            box-shadow: 1px 1px 4px rgba(88, 90, 95, .08);
        }

        .zoomControls {
            position: absolute;
            top: 50%;
            right: $homeDesktopPadding;
            z-index: 3;
            overflow: hidden;
            display: inline-flex;
            border: 1px solid $base-300;
            background-color: $base-0;
            transform: translateY(-50%) translateZ(0);
            flex-direction: column;
            user-select: none;

            @include respond-to(sm) {
                right: $homeTabletPadding;
            }

            @include respond-to(xxs) {
                right: $homeMobilePadding;
            }

            .button {
                margin: 0;

                &._disabled {
                    background-color: $base-0 !important; //stylelint-disable-line declaration-no-important
                    color: $base-500 !important; //stylelint-disable-line declaration-no-important
                }
            }
        }

        .routeButton,
        .routeModal {
            position: absolute;
            top: 0;
            right: 0;
        }

        .routeButton {
            width: 14rem;
        }

        .routeModal {
            z-index: 4;
            min-width: 40rem;
        }

        .header,
        .footer {
            position: absolute;
            left: 0;
            z-index: 3;
            width: 100%;
        }

        .header {
            top: 0;
            background-color: #fff;
        }

        .link {
            padding: 0 1.2rem;
        }
    }

    .filterHeaderIcon {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $base-50;
        color: $base-600;
        transition: all .2s;

        &._hover {
            background-color: #fc3f1d;
            color: #fff;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 50%;
            height: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .route {
        position: absolute;
        top: 3.2rem;
        right: $homeDesktopPadding;
        pointer-events: all;

        @include respond-to(sm) {
            right: $homeTabletPadding;
        }

        @include respond-to(xxs) {
            right: $homeMobilePadding;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 1.6rem;
        background-color: rgba($base-900, .85);
        user-select: none;
        pointer-events: none;

        .overlayContent {
            max-width: 33rem;
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.25;
            color: #fff;
        }

        .overlayIcon {
            display: inline-block;
            width: 4.8rem;
            height: auto;
            margin-bottom: 1.2rem;
        }
    }

    .iconFilterModal {
        position: absolute;
        bottom: calc(100% + 2.4rem);
        left: 0;
        z-index: 1;
        overflow-x: auto;
        display: flex;
        width: 100%;
        max-width: 100%;
        padding-right: 2.4rem;
        padding-left: 2.4rem;

        @include remove-scrollbar;

        .tag {
            width: auto;
            margin-right: .8rem;
            white-space: nowrap;
        }

        .icon {
            display: inline-block;
            width: 1.6rem;
            height: auto;

            &._inverse {
                filter: invert(1);
            }
        }
    }

    .footer {
        bottom: 0;
        display: grid;
        width: 100%;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
        background-color: $base-0;
        grid-template-columns: 1fr 1fr;
        gap: 1.6rem;

        @include respond-to(xxs) {
            gap: 1.2rem;
        }
    }
</style>
