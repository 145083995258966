<template>
    <div
        :class="[$style.UiSimpleDropdownOption, classList]"
        @click="$emit('click')"
    >
        <component
            :is="display"
            v-if="display"
            :value="value"
            :is-active="isActive"
        />

        <div
            v-else
            :class="[$style.title, 'UiSimpleDropdownOption__title']"
        >
            {{ value[keyLabel] || value[keyValue] }}

            <UiTooltip
                v-if="value?.description"
                tag="span"
                left
                :class="$style.tooltip"
                @touchstart.native.stop
                @click.native.stop
            >
                <template #activator>
                    <span :class="$style.icon">
                        <InfoIcon />
                    </span>
                </template>
                <template #default>
                    <div :class="$style.tooltipBox">{{ value?.description }}</div>
                </template>
            </UiTooltip>
        </div>
    </div>
</template>

<script>
import UiTooltip from '~/components/ui/home/UiTooltip.vue';

import InfoIcon from '~/assets/icons/home/info.svg?inline';

export default {
    name: 'UiSimpleDropdownOption',
    components: { UiTooltip, InfoIcon },

    props: {
        value: {
            type: Object,
            required: true,
        },

        display: {
            type: Object,
            default: null,
        },

        isActive: {
            type: Boolean,
            default: false,
        },

        keyLabel: {
            type: String,
            default: 'label',
        },

        keyValue: {
            type: String,
            default: 'value',
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style._active]: this.isActive,
                [this.$style._hasDescription]: this.value?.description,
            }];
        },
    },
};
</script>

<style lang="scss" module>
    .UiSimpleDropdownOption {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4.8rem;
        padding: 0 2rem;
        transition: $transition;
        cursor: pointer;

        &:hover {
            background-color: $base-900;

            .title {
                color: $base-0;
            }
        }

        &._active {
            background-color: $base-900;

            .title {
                color: $base-0;
            }
        }

        &._hasDescription {
            padding-right: 3.6rem;
        }

        .title {
            @include l4;

            transition: inherit;
        }

        .icon {
            display: inline-block;
            padding: .4rem;
            color: $base-300;

            svg {
                display: block;
                width: 14px;
                height: auto;
            }
        }

        .tooltip {
            position: absolute;
            top: 50%;
            right: 1.2rem;
            display: inline-block;
            transform: translateY(-50%);
        }
    }

    .tooltipBox {
        max-width: 28rem;
        padding: 1.6rem;
        background-color: #fff;
        font-size: 1.2rem;
        line-height: 1.6rem;
        box-shadow: 12px 12px 34px 0 rgba(0, 0, 0, .05);
    }
</style>
