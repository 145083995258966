// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeSaleFormTitle_UD2dq{text-align:center}.title_eFelr{font-size:4rem;font-weight:700;line-height:1.06;letter-spacing:normal;text-transform:uppercase;line-height:1.16;color:#2f2f2f}@media(max-width:991.98px){.title_eFelr{font-size:2.4rem}}.subtitle_\\+lkoj{max-width:66rem;margin:2.4rem auto 0;font-size:2rem;font-weight:300;line-height:1.24;color:#717171}@media(max-width:991.98px){.subtitle_\\+lkoj{margin-top:2.4rem;font-size:1.8rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeSaleFormTitle": "HomeSaleFormTitle_UD2dq",
	"title": "title_eFelr",
	"subtitle": "subtitle_+lkoj"
};
module.exports = ___CSS_LOADER_EXPORT___;
