<template>
    <div :class="$style.FlatsAdvancedFiltersDesktop">
        <div :class="$style.column">
            <UiInputWrapper label="Тип лота">
                <UiFilterDropdown
                    name="newTypeFlat"
                    data-gtm-html-id="new_type_flat"
                    :value="filters.newTypeFlat"
                    :specs="specs.new_type_flat"
                    :facets="facets.new_type_flat"
                    placeholder="Любой"
                    reset-label="Любой"
                    multiple
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Количество спален">
                <FlatsFilterLayout
                    name="layout"
                    data-gtm-html-id="layout"
                    :value="filters.layout"
                    :specs="specs.layout"
                    :facets="facets.layout"
                    :disabled="isReloading"
                    responsive
                    multiple
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Cтоимость, млн ₽">
                <UiRangeInput
                    name="price"
                    data-gtm-html-id="price"
                    :value-min="filters.priceMin"
                    :value-max="filters.priceMax"
                    :specs="specs.price"
                    :facets="facets.price"
                    show-label
                    :decimal-count="1"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Площадь, м²">
                <UiRangeInput
                    name="area"
                    data-gtm-html-id="area"
                    :value-min="filters.areaMin"
                    :value-max="filters.areaMax"
                    :specs="specs.area"
                    :facets="facets.area"
                    show-label
                    :decimal-count="1"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>
        </div>

        <div :class="$style.column">
            <div :class="$style.row">
                <UiInputWrapper label="Урбан-блок">
                    <UiUniversalDropdown
                        data-gtm-html-id="urban_block"
                        :value-component="UiFilterDropdownValue"
                        :value="createStringValues(filters.urbanBlock, specs.urban_block)"
                        placeholder="Любой"
                        :disabled="isReloading"
                        dropdown-offset-top="calc(100% + .6rem)"
                        :dropdown-width="`${columnWidth}px`"
                    >
                        <template #dropdown>
                            <FlatsUrbanBlockDropdown
                                name="urbanBlock"
                                :value="filters.urbanBlock"
                                :specs="specs.urban_block"
                                :facets="facets.urban_block"
                                :disabled="isReloading"
                                @change="changeFilters"
                            />
                        </template>
                    </UiUniversalDropdown>
                </UiInputWrapper>

                <UiInputWrapper label="Корпус">
                    <UiUniversalDropdown
                        data-gtm-html-id="building"
                        :value-component="UiFilterDropdownValue"
                        :value="createStringValues(filters.building, specs.building)"
                        placeholder="Любой"
                        :dropdown="FlatsBuildingDropdown"
                        :disabled="isReloading"
                        dropdown-offset-top="calc(100% + .6rem)"
                        dropdown-offset-left="initial"
                        dropdown-offset-right="0"
                        :dropdown-width="`${columnWidth}px`"
                    />
                </UiInputWrapper>
            </div>

            <div :class="$style.row">
                <UiInputWrapper label="Секция">
                    <UiFilterDropdown
                        name="section"
                        data-gtm-html-id="section"
                        :value="filters.section"
                        :specs="specs.section"
                        :facets="facets.section"
                        placeholder="Любая"
                        reset-label="Любая"
                        multiple
                        :disabled="isReloading"
                        @change="changeFilters"
                    />
                </UiInputWrapper>

                <UiInputWrapper label="Вид из окна">
                    <UiFilterDropdown
                        name="window_view"
                        data-gtm-html-id="window_view"
                        :value="filters.window_view"
                        :specs="specs.window_view"
                        :facets="facets.window_view"
                        placeholder="Не важно"
                        reset-label="Не важно"
                        multiple
                        :disabled="isReloading"
                        @change="changeFilters"
                    />
                </UiInputWrapper>
            </div>

            <UiInputWrapper label="Этаж">
                <UiRangeInput
                    name="floor"
                    data-gtm-html-id="floor"
                    :specs="specs.floor"
                    :facets="facets.floor"
                    :value-min="filters.floorMin"
                    :value-max="filters.floorMax"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Ключи в">
                <UiFilterDropdown
                    name="key_distribution"
                    data-gtm-html-id="key_distribution"
                    :value="filters.key_distribution"
                    :specs="specs.key_distribution"
                    :facets="facets.key_distribution"
                    placeholder="Не важно"
                    reset-label="Не важно"
                    multiple
                    :disabled="isReloading"
                    :max-items="3"
                    @change="changeFilters"
                />
            </UiInputWrapper>
        </div>

        <div :class="$style.column">
            <div :class="$style.row">
                <UiInputWrapper label="Отделка">
                    <UiFilterDropdown
                        name="decoration"
                        data-gtm-html-id="decoration"
                        :value="filters.decoration"
                        :specs="specs.decoration"
                        :facets="facets.decoration"
                        placeholder="Любая"
                        reset-label="Любая"
                        multiple
                        :disabled="isReloading"
                        @change="changeFilters"
                    />
                </UiInputWrapper>

                <UiInputWrapper label="Тип планировки">
                    <UiFilterDropdown
                        name="uspLayout"
                        data-gtm-html-id="usp_layout"
                        :value="filters.uspLayout"
                        :specs="specs.usp_layout"
                        :facets="facets.usp_layout"
                        placeholder="Любая"
                        reset-label="Любая"
                        multiple
                        :disabled="isReloading"
                        dropdown-offset-left="initial"
                        dropdown-offset-right="0"
                        :dropdown-width="`${columnWidth}px`"
                        @change="changeFilters"
                    />
                </UiInputWrapper>
            </div>

            <UiInputWrapper label="Лоджии">
                <FlatsTagsArray
                    name="uspBalcony"
                    data-gtm-html-id="usp_balcony"
                    :value="filters.uspBalcony"
                    :specs="specs.usp_balcony"
                    :facets="facets.usp_balcony"
                    multiple
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Особенности планировки">
                <FlatsTagsArray
                    name="uspLayoutFeatures"
                    data-gtm-html-id="usp_layout_features"
                    :value="filters.uspLayoutFeatures"
                    :specs="specs.usp_layout_features"
                    :facets="facets.usp_layout_features"
                    multiple
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import UiInputWrapper from '~/components/ui/UiInputWrapper.vue';
import FlatsFilterLayout from '~/components/flats/filters/FlatsFilterLayout.vue';
import UiFilterDropdown from '~/components/ui/dropdowns/filter-dropdown/UiFilterDropdown.vue';
import UiRangeInput from '~/components/ui/inputs/range-input/UiRangeInput.vue';
import UiUniversalDropdown from '~/components/ui/dropdowns/universal-dropdown/UiUniversalDropdown.vue';
import FlatsTagsArray from '~/components/flats/common/FlatsTagsArray.vue';
import UiFilterDropdownValue from '~/components/ui/dropdowns/filter-dropdown/UiFilterDropdownValue.vue';
import FlatsUrbanBlockDropdown from '~/components/flats/filters/dropdowns/FlatsUrbanBlockDropdown.vue';
import FlatsBuildingDropdown from '~/components/flats/filters/dropdowns/FlatsBuildingDropdown.vue';

export default {
    name: 'FlatsAdvancedFiltersDesktop',

    components: {
        FlatsUrbanBlockDropdown,
        UiInputWrapper,
        FlatsFilterLayout,
        UiFilterDropdown,
        UiRangeInput,
        UiUniversalDropdown,
        FlatsTagsArray,
    },

    data() {
        return {
            UiFilterDropdownValue,
            FlatsUrbanBlockDropdown,
            FlatsBuildingDropdown,
            columnWidth: 0,
        };
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'specs',
            'facets',
            'isReloading',
        ]),
    },

    mounted() {
        this.columnWidth = document.querySelector(`.${this.$style.column}`).clientWidth;
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),

        createStringValues(value, specs) {
            if (Array.isArray(value)) {
                return specs
                    .filter(item => value.includes(item.value))
                    .map(item => item.label)
                    .join(', ');
            } else {
                return specs.find(item => item.value === value)?.label;
            }
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsAdvancedFiltersDesktop {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3.2rem;
        width: 100%;

        .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            column-gap: 1.6rem;
        }

        .column {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2.4rem;
            width: 100%;
            height: fit-content;
        }
    }
</style>
