<template>
    <div :class="$style.ProjectFormatSlider">
        <div
            v-if="lazySlides && lazySlides.length"
            ref="slider"
            class="swiper-container"
            :class="[$style.slider, {[$style._isTouch]: (isTouch && $device.isDesktop)}]"
        >
            <div
                v-if="lazySlides && lazySlides.length"
                class="swiper-wrapper"
                :class="$style.wrapper"
            >
                <div
                    v-for="(slide, idx) in lazySlides"
                    :key="slide.id"
                    class="swiper-slide"
                    :class="$style.slide"
                >
                    <ProjectFormatSlide :is-active="activeIndex === idx" :slide="slide" />
                </div>
            </div>
            <UiCircleButton
                v-show="lazySlides.length > 1 && $device.isDesktop"
                ref="prev"
                :class="[$style.btn, $style._prev]"
                color="black"
                :disabled="isBeginning"
            >
                <ArrowLeft />
            </UiCircleButton>

            <UiCircleButton
                v-show="lazySlides.length > 1 && $device.isDesktop"
                ref="next"
                :class="[$style.btn, $style._next, $style._reverse]"
                color="black"
                :disabled="isEnd"
            >
                <ArrowLeft/>
            </UiCircleButton>
        </div>

        <ExpandBlock>
            <div
                v-if="currentSlide && currentSlide.title"
                :key="currentSlide.id"
            >
                <div :class="$style.textBlock">
                    <h4 :class="$style.textBlockTitle" v-html="currentSlide.title"></h4>
                    <p :class="$style.textBlockDesc" v-html="currentSlide.subtitle"></p>
                </div>
            </div>
        </ExpandBlock>

        <UiPagination
            v-if="lazySlides.length > 1"
            ref="pagination"
            :active-index="activeIndex"
            :tabs="lazySlides"
            color="black"
            without-animate
            small-line
            :class="$style.pagination"
        />
    </div>
</template>

<script>
import Swiper from 'swiper';
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';
import ArrowLeft from '~/assets/icons/arrow-left-solid.svg?inline';
import ProjectFormatSlide from '~/components/pages/project/fromat/ProjectFormatSlide.vue';
import ExpandBlock from '~/components/common/ExpandBlock.vue';
import UiPagination from '~/components/ui/paginations/UiPagination';
import { mapState } from 'vuex';
import { isTouchDevice } from '~/assets/js/utils';

export default {
    name: 'ProjectFormatSlider',
    components: { UiPagination, ExpandBlock, ProjectFormatSlide, UiCircleButton, ArrowLeft },

    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sliderApp: null,
            lazySlides: this.slides,
        };
    },

    computed: {
        ...mapState('device', ['isTouch']),

        activeIndex() {
            return this.sliderApp?.realIndex;
        },

        isEnd() {
            return this.sliderApp?.isEnd ?? false;
        },

        isBeginning() {
            return this.sliderApp?.isBeginning ?? false;
        },

        currentSlide() {
            return this.lazySlides[this.activeIndex] || null;
        },
    },

    watch: {
        slides(newVal) {
            if (this.sliderApp) {
                this.sliderApp.destroy();
            }
            this.lazySlides = [];
            this.$nextTick(() => {
                this.lazySlides = newVal;
                setTimeout(() => {
                    this.initSlider();
                }, 300);
            });
        },

        currentSlide: {
            handler(val) {
                this.$emit('update-button', {
                    text: val ? val.buttonText : '',
                    link: val ? val.link : '',
                });
            },

            immediate: true,
        },
    },

    mounted() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
        }
        this.$nextTick(() => {
            this.initSlider();
        });
    },


    beforeDestroy() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
        }
    },

    methods: {
        initSlider() {
            const isTouch = isTouchDevice();
            if (!this.lazySlides?.length) {
                return false;
            }
            const options = {
                slidesPerView: 3,
                allowTouchMove: !this.$device.isDesktop || isTouch,
                loop: false,
                centeredSlides: true,
                initialSlide: 0,
                speed: 500,
                navigation: {
                    prevEl: this.$refs.prev ? this.$refs.prev.$el : null,
                    nextEl: this.$refs.next ? this.$refs.next.$el : null,
                },

                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 1,
                },

                breakpoints: {
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },

                    521: {
                        slidesPerView: 3,
                    },
                },
            };

            this.sliderApp = new Swiper(this.$refs.slider, options);
        },
    },

};
</script>

<style lang="scss" module>
    .ProjectFormatSlider {
        position: relative;

        .pagination {
            position: static;
            margin-top: 2.6rem;

            @include respond-to(xxs) {
                margin-top: 2rem;
            }
        }
    }

    .slider {
        position: relative;
        height: 45.6rem;

        @include respond-to(sm) {
            height: 37rem;
            margin-right: -$homeTabletPadding - 3rem;
            margin-left: -$homeTabletPadding - 3rem;
        }

        @include respond-to(xxs) {
            margin-right: 0;
            margin-left: 0;
        }

        &._isTouch {
            .slide {
                touch-action: pan-y;
            }
        }

        :global(.swiper-slide-active) {
            opacity: 1;

            @include respond-to-min(xs) {
                transform: scale(1.1);
                transform-origin: 50% 50%;
            }
        }

        :global(.swiper-slide-active ~ .swiper-slide) {
            transform-origin: 90% 80%;
        }
    }

    .wrapper {
        padding-top: 3%;
        padding-bottom: 3%;
    }

    .slide {
        height: 100%;
        opacity: .4;
        transform-origin: 10% 80%;
        transition: all 500ms linear;

        @include respond-to-min(xs) {
            transform: scale(.4);
        }
    }

    .btn {
        position: absolute;
        top: 60%;
        transform: translateY(-50%);

        &._prev {
            left: .4rem;
        }

        &._next {
            right: .4rem;
        }

        &._locked {
            opacity: .8;
        }

        &._reverse {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .textBlock {
        width: 100%;
        max-width: 50rem;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5rem;
        text-align: center;

        @include respond-to(xs) {
            padding-top: 3.6rem;
        }

        @include respond-to(xxs) {
            padding-top: 1.6rem;
        }

        .textBlockTitle {
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1.4rem;

            @include respond-to(xxs) {
                font-size: 1.6rem;
            }
        }

        .textBlockDesc {
            margin-top: 1.2rem;
            margin-bottom: 0;
            font-weight: 300;
            line-height: 1.32;
            color: $base-500;
        }
    }
</style>
