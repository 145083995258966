/**
 * Валидация массива с координатами
 * @param coordinates
 * @returns {boolean}
 */
export const validateCoordinates = coordinates => {
    if (!Array.isArray(coordinates) || coordinates.length !== 2) {
        return false; // Некорректный формат координат
    }

    const [x, y] = coordinates;

    if (typeof x !== 'number' || typeof y !== 'number') {
        return false; // Координаты должны быть числами
    }

    return true;
};

/**
 * Хук предоставляющий функции для управления маркерами на карте.
 * setTimeout используется для пакетного обновления маркеров, чтобы избежать частого
 * обновления DOM, когда маркеры добавляются или удаляются.
 *
 * @param updateFunction
 * @returns {{deleteGeoObject: deleteGeoObject, addGeoObject: addGeoObject}}
 */
export const useGeoObjectActions = updateFunction => {
    let markersToAdd = [];
    let markersToDelete = [];
    let addTimeout;
    let removeTimeout;

    const addGeoObject = marker => {
        markersToAdd.push(marker);
        clearTimeout(addTimeout);
        addTimeout = window.setTimeout(() => {
            updateFunction(markersToAdd, 'add');
            markersToAdd = [];
        });
    };

    const removeGeoObject = marker => {
        markersToDelete.push(marker);
        clearTimeout(removeTimeout);
        removeTimeout = window.setTimeout(() => {
            updateFunction(markersToDelete, 'remove');
            markersToDelete = [];
        });
    };

    return { addGeoObject, removeGeoObject };
};
