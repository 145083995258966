// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlaceSpecifications_9QbLA{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.PlaceSpecifications_9QbLA,.PlaceSpecifications_9QbLA .row_vUkiq{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%}.PlaceSpecifications_9QbLA .row_vUkiq{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;font-size:1.4rem;font-weight:400;line-height:1}.PlaceSpecifications_9QbLA .row_vUkiq:not(:last-child){margin-bottom:1.2rem}.PlaceSpecifications_9QbLA .row_vUkiq .label_CxjMH{min-width:7rem;padding-right:2.4rem;color:#717171}.PlaceSpecifications_9QbLA .row_vUkiq .value_7h47v{min-width:9rem;text-align:right;color:#2f2f2f}.PlaceSpecifications_9QbLA .row_vUkiq .count_1foit{width:3.2rem;text-align:left;white-space:nowrap;color:#717171}.PlaceSpecifications_9QbLA .row_vUkiq .count_1foit+.value_7h47v{text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlaceSpecifications": "PlaceSpecifications_9QbLA",
	"row": "row_vUkiq",
	"label": "label_CxjMH",
	"value": "value_7h47v",
	"count": "count_1foit"
};
module.exports = ___CSS_LOADER_EXPORT___;
