<template>
    <label
        ref="label"
        :aria-checked="active"
        :aria-disabled="disabled"
        :class="[$style.UiCheckbox, classList]"
        role="checkbox"
    >
        <span :class="$style.input">
            <input
                v-model="computedValue"
                :class="$style.inputNative"
                :disabled="disabled"
                :false-value="falseValue"
                :name="name"
                :true-value="trueValue"
                :value="trueValue"
                type="checkbox"
                @blur="onBlur"
                @focus="onFocus"
                @keydown.enter.stop.prevent="$refs.label.click()"
            >

            <components
                :is="icon"
                :class="$style.icon"
            />
        </span>

        <!-- <span
            v-if="$slots.default"
            :class="$style.label"
        >
            <slot />
        </span> -->
    </label>
</template>

<script>
/**
 * Позволяет пользователю выбрать одно значение, для передачи в форму.
 *
 * @version 1.0.1
 * @displayName UiCheckbox
 */

import CheckIcon from '~/assets/icons/ui/check.svg?inline';
import RadioIcon from '~/assets/icons/ui/radio.svg?inline';

export default {
    name: 'UiCheckbox',

    components: {
        CheckIcon,
        RadioIcon,
    },

    props: {
        /**
         * Имя, которое используется при отправке формы
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Текущее значение чекбокса
         */
        value: {
            type: [Array, String, Boolean],
            required: true,
        },

        /**
         * True значение, которое используется при отправке формы, а также передается в value
         * при активации чекбокса
         */
        trueValue: {
            type: [String, Boolean],
            default: true,
        },

        /**
         * False значение, которое используется при отправке формы, а также передается в value
         * при активации чекбокса
         */
        falseValue: {
            type: [String, Boolean],
            default: false,
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium'].includes(value),
        },


        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: value => ['base', 'white'].includes(value),
        },

        /**
         * Модификатор компонента под radiobtn
         */
        radio: {
            type: Boolean,
            default: false,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        isHighlighted: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isFocused: false,
        };
    },

    computed: {
        icon() {
            return this.radio ? RadioIcon : CheckIcon;
        },

        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._radio]: this.radio,
                [this.$style._checkbox]: !this.radio,
                [this.$style._active]: this.active,
                [this.$style._disabled]: this.disabled,
                [this.$style._highlighted]: this.isHighlighted,
            };
        },

        computedValue: {
            get() {
                return this.value;
            },

            set(value) {
                /**
                 * Возвращает новое значение в родительский компонент.
                 @param {String|Boolean} value Новое значение
                 */
                this.$emit('input', value);
            },
        },

        active() {
            if (Array.isArray(this.value)) {
                return this.value.indexOf(this.trueValue) > -1;
            }

            return this.value === this.trueValue;
        },
    },

    methods: {
        /**
         * Метод, который обрабатывает событие focus на инпуте
         * @public
         */
        onFocus(e) {
            this.isFocused = true;

            /**
             * Передаёт родителю, что компонент находится в focus.
             * В большинстве реализаций - может и не пригодится
             * @event focus
             */
            this.$emit('focus', e);
        },

        /**
         * Метод, который обрабатывает событие blur на инпуте
         * @public
         */
        onBlur(e) {
            this.isFocused = false;

            /**
             * Передаёт родителю, что компонент больше не находится в focus.
             * В большинстве реализаций - может и не пригодится
             * @event blur
             */
            this.$emit('blur', e);
        },
    },
};
</script>

<style lang="scss" module>
    .UiCheckbox {
        display: flex;
        align-items: center;
        user-select: none;
        outline: none;
        cursor: pointer;

        /* Colors */
        &._base {
            .input {
                border: 1px solid $base-300;
                background-color: $base-0;
            }

            .icon {
                color: $base-0;
            }

            &:hover,
            &._highlighted {
                .input {
                    background-color: $base-50;
                }
            }

            &._active {
                .input {
                    border: 1px solid $base-800;
                    background-color: $base-800;
                }

                &:hover,
                &._highlighted {
                    .input {
                        border: 1px solid $base-700;
                        background-color: $base-700;
                    }
                }
            }

            &._disabled {
                .input {
                    border: 1px solid $base-50;
                    background-color: $base-0;
                }

                &:hover,
                &._highlighted {
                    .input {
                        border: 1px solid $base-50;
                        background-color: $base-0;
                    }
                }

                &._active {
                    .input {
                        border: 1px solid $base-200;
                        background-color: $base-200;
                    }

                    &:hover,
                    &._highlighted {
                        .input {
                            border: 1px solid $base-200;
                            background-color: $base-200;
                        }
                    }
                }
            }
        }

        &._white {
            .input {
                border: 1px solid $base-300;
                background-color: $base-0;
            }

            .icon {
                color: $base-900;
            }

            &:hover,
            &._highlighted {
                .input {
                    background-color: $base-50;
                }
            }

            &._active {
                .input {
                    border: 1px solid $base-0;
                    background-color: $base-0;
                }

                &:hover,
                &._highlighted {
                    .input {
                        border: 1px solid $base-50;
                        background-color: $base-50;
                    }
                }
            }

            &._disabled {
                .input {
                    border: 1px solid $base-50;
                    background-color: $base-0;
                }

                &:hover,
                &._highlighted {
                    .input {
                        border: 1px solid $base-50;
                        background-color: $base-0;
                    }
                }

                &._active {
                    .input {
                        border: 1px solid $base-200;
                        background-color: $base-200;
                    }

                    &:hover,
                    &._highlighted {
                        .input {
                            border: 1px solid $base-200;
                            background-color: $base-200;
                        }
                    }
                }
            }
        }

        /* Sizes */
        &._medium {
            .input {
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
                min-height: 2rem;
            }
        }

        /* Modificators */
        &._checkbox {
            .input {
                border-radius: .2rem;
            }
        }

        &._radio {
            .input {
                border-radius: 50%;
            }
        }

        &._active {
            .icon {
                visibility: visible;
                opacity: 1;
            }
        }

        &._disabled {
            cursor: not-allowed;

            .input {
                pointer-events: none;
            }
        }

        .input {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: $transition;
        }

        .inputNative {
            display: none;
        }

        .icon {
            visibility: hidden;
            width: calc(100% + 2px);
            min-width: calc(100% + 2px);
            height: calc(100% + 2px);
            min-height: calc(100% + 2px);
            opacity: 0;
            transition: $transition;
        }

        // .label {
        //     white-space: nowrap;
        //     transition: $transition;
        // }
    }
</style>
