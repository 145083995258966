<template>
    <button
        :class="[$style.FlatsResetButton, {[$style._disabled]: isDisabled}]"
        @click="resetFilters"
    >
        Сбросить параметры

        <CloseIcon :class="$style.icon" />
    </button>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { DEFAULT_FILTERS } from '~/assets/js/constants';

import CloseIcon from '~/assets/icons/ui/close.svg?inline';

export default {
    name: 'FlatsResetButton',

    components: {
        CloseIcon,
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'isReloading',
        ]),

        isDisabled() {
            return JSON.stringify(this.filters) === JSON.stringify(DEFAULT_FILTERS) || this.isReloading;
        },
    },

    methods: {
        ...mapActions({
            resetFilters: 'flats/resetFilters',
        }),
    },
};
</script>

<style lang="scss" module>
    .FlatsResetButton {
        @include reset-btn;

        display: flex;
        align-items: center;
        width: fit-content;
        height: fit-content;
        margin-left: 1.6rem;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        transition: $transition;

        &:not(._disabled):hover {
            color: $base-500;
        }

        .icon {
            display: flex;
            width: 1.4rem;
            min-width: 1.4rem;
            height: 1.4rem;
            min-height: 1.4rem;
            margin-left: .8rem;
            color: $base-400;
        }

        &._disabled {
            color: $base-300;
            pointer-events: none;

            .icon {
                color: $base-300;
            }
        }
    }
</style>
