// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdvantagesLinkCard_iqnK4{position:relative;overflow:hidden;width:100%;height:100%;padding:3.2rem;background:linear-gradient(352.82deg,hsla(0,0%,100%,.1596) 6.23%,hsla(0,0%,100%,.01) 6.24%,hsla(0,0%,100%,.1083) 67%),linear-gradient(0deg,rgba(1,1,1,.43),rgba(1,1,1,.43)),linear-gradient(0deg,rgba(1,1,1,.56),rgba(1,1,1,.56)),#0d1322;background-blend-mode:normal,color,normal,normal;cursor:pointer}.AdvantagesLinkCard_iqnK4:hover .image_nUq7p{-webkit-transform:scale(1.05);transform:scale(1.05)}@media(max-width:991.98px){.AdvantagesLinkCard_iqnK4{padding:2.8rem}}@media(max-width:519.98px){.AdvantagesLinkCard_iqnK4{padding:2rem}}.image_nUq7p{position:absolute;right:0;bottom:0;-webkit-transition:all .3s ease;transition:all .3s ease}.cardText_izTEg{position:relative;z-index:1;padding-right:3.2rem;font-size:4rem;font-weight:700;line-height:108%;color:#fff}@media(max-width:991.98px){.cardText_izTEg{font-size:2.4rem}}.arrowButton_oHJbo{position:absolute;right:3.2rem;bottom:3.2rem;font-size:0}@media(max-width:991.98px){.arrowButton_oHJbo{right:2.8rem;bottom:2.8rem;width:2rem;max-width:2rem;height:2rem;max-height:2rem}}@media(max-width:519.98px){.arrowButton_oHJbo{right:2rem;bottom:2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvantagesLinkCard": "AdvantagesLinkCard_iqnK4",
	"image": "image_nUq7p",
	"cardText": "cardText_izTEg",
	"arrowButton": "arrowButton_oHJbo"
};
module.exports = ___CSS_LOADER_EXPORT___;
