<template>
    <div
        :class="[$style.UiSimpleDropdownValue, classList]"
        @click="$emit('click')"
    >
        <div
            v-if="!value"
            :class="[$style.placeholder, 'UiSimpleDropdownValue__placeholder']"
        >
            {{ placeholder }}
        </div>

        <component
            :is="display"
            v-else-if="display"
            :value="value"
        />

        <div
            v-else
            :class="[$style.title, 'UiSimpleDropdownValue__title']"
        >
            {{ value[keyLabel] || value[keyValue] }}
        </div>

        <div :class="$style.arrow">
            <ArrowIcon />
        </div>
    </div>
</template>

<script>
import ArrowIcon from '~/assets/icons/ui/arrow-down.svg?inline';
import { kebabToCamelCase } from '~/components/ui/utils/helpers';

export default {
    name: 'UiSimpleDropdownValue',

    components: {
        ArrowIcon,
    },

    props: {
        value: {
            type: Object,
            default: null,
        },

        isOpened: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        display: {
            type: Object,
            default: null,
        },

        size: {
            type: String,
            default: 'big',
            validator: value => ['normal', 'big', 'medium'].includes(value),
        },

        color: {
            type: String,
            default: 'white',
            validator: value => ['gray', 'white'].includes(value),
        },

        keyLabel: {
            type: String,
            default: 'label',
        },

        keyValue: {
            type: String,
            default: 'value',
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style._opened]: this.isOpened,
                [this.$style._disabled]: this.disabled,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_${kebabToCamelCase(this.color)}`]]: this.color,
            }];
        },
    },
};
</script>

<style lang="scss" module>
    .UiSimpleDropdownValue {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 2rem;
        transition: $transition;
        cursor: pointer;

        &._big {
            height: 5.6rem;
        }

        &._normal {
            height: 4rem;
        }

        &._medium {
            height: 5.2rem;
        }

        &._white {
            background-color: $base-0;
        }

        &._gray {
            background-color: $base-50;

            .placeholder {
                color: $base-600;
            }
        }

        &:not(._opened):hover {
            .placeholder {
                color: $base-600;
            }

            .arrow {
                // color: $base-600; из-за цвета анимация поворота дергается, поэтому opacity
                opacity: .6;
            }
        }

        &._opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .placeholder {
                color: $base-600;
            }

            .arrow {
                color: $base-900;
                transform: rotate(180deg);
            }
        }

        .placeholder {
            @include l4;

            color: $base-400;
            transition: inherit;
        }

        .title {
            @include p2;

            white-space: nowrap;
            line-height: 132%;
            color: $base-900;
            transition: inherit;
        }

        .arrow {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
            margin-left: .4rem;
            color: $base-900;
            transition: inherit;
        }
    }
</style>
