<template>
    <div :class="[$style.FlatsListHeader, classList]">
        <transition name="fade">
            <UiButton
                v-if="showButtonBack"
                size="moderate"
                color="black"
                :class="$style.back"
                @click="$emit('click-back')"
            >
                <template #iconBefore>
                    <ArrowLeft />
                </template>

                Назад
            </UiButton>
        </transition>

        <UiButton
            size="moderate"
            color="black"
            :class="$style.filter"
            data-gtm-html-id="sort"
            @click="$root.$emit('openAdvancedFilter')"
        >
            {{ getFiltersLength ? `Фильтр | ${getFiltersLength}` : 'Фильтр' }}

            <template #iconAfter>
                <FilterIcon />
            </template>
        </UiButton>

        <FlatsSortFilter
            v-if="isHaveSort"
            :class="$style.sort"
            @open-modal="openSort"
        />

        <div :class="$style.count">
            {{ countLabel }}
        </div>

        <FlatsViewSwitcher v-if="isHaveViewSwitcher" :class="$style.switcher" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { arrayLabelsFromFilters } from '~/assets/js/utils/flatsUtils';
import { sortingData } from '~/assets/js/mock/filters';

import UiButton from '~/components/ui/buttons/UiButton.vue';
import FlatsSortFilter from '~/components/flats/filters/FlatsSortFilter.vue';
import FlatsViewSwitcher from '~/components/flats/common/FlatsViewSwitcher.vue';

import FilterIcon from '~/assets/icons/ui/filter.svg?inline';
import ArrowLeft from '~/assets/icons/ui/arrow-left.svg?inline';
import { plural } from '~/assets/js/utils';

export default {
    name: 'FlatsListHeader',

    components: {
        UiButton,
        FlatsSortFilter,
        FlatsViewSwitcher,
        FilterIcon,
        ArrowLeft,
    },

    props: {
        /**
         * Определяет отображение кнопки "Назад"
         */
        showButtonBack: {
            type: Boolean,
            default: false,
        },

        /**
         * Определяет отображение кнопки сортировки
         */

        isHaveSort: {
            type: Boolean,
            default: true,
        },

        /**
         * Определяет отображение кнопки изменения отображения квартир
         */
        isHaveViewSwitcher: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'isReloading',
            'specs',
        ]),

        classList() {
            return {
                [this.$style._disabled]: this.isReloading,
            };
        },

        ...mapGetters({
            totalCount: 'flats/totalCount',
        }),

        getFiltersLength() {
            return arrayLabelsFromFilters(this.filters, this.specs)?.length || 0;
        },

        newTypeFlat() {
            return this.filters.newTypeFlat || [];
        },

        countLabel() {
            const count = this.totalCount;
            if (this.newTypeFlat.length !== 1) {
                return `${count} ${plural(count, ['квартира', 'квартиры', 'квартир'])}`;
            }

            const type = this.newTypeFlat[0];
            switch (true) {
                case type === '210':
                    return `${count} ${plural(count, ['ситихаус', 'ситихауса', 'ситихаусов'])}`;
                case type === '211':
                    return `${count} ${plural(count, ['пентхаус', 'пентхауса', 'пентхаусов'])}`;
                case type === '280':
                    return `${count} ${plural(count, ['таунхаус', 'таунхауса', 'таунхаусов'])}`;
                case type === '212':
                    return `${count} ${plural(count, ['сити-юнит', 'сити-юнита', 'сити-юнитов'])}`;
                default:
                    return `${count} ${plural(count, ['квартира', 'квартиры', 'квартир'])}`;
            }
        },
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),

        openSort() {
            this.$universalModal.open({
                component: () => import('@/components/common/modals/universal-modal/bodies/ModalOptionsBody.vue'),
                componentOptions: {
                    resetLabel: 'По возрастанию цены',
                    specs: sortingData,
                    facets: sortingData.map(spec => spec.value),
                    value: this.filters.orderBy,
                    onChange: value => {
                        this.changeFilters({ orderBy: value });

                        this.$universalModal.changeData({
                            componentOptions: {
                                value: this.filters.orderBy,
                            },
                        });
                    },
                },
                header: () => import('@/components/common/modals/universal-modal/headers/TitleBackCloseHeader.vue'),
                headerOptions: {
                    title: 'Сортировать',
                    isBtnBack: false,
                    isBtnClose: true,
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsListHeader {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;

        &._disabled {
            pointer-events: none;
        }

        .back {
            width: fit-content;
            margin-right: 3.2rem;

            @include respond-to(sm) {
                display: none;
            }
        }

        .filter {
            display: none;
            width: 22rem;

            @include respond-to(sm) {
                display: flex;
                width: 100%;
            }
        }

        .count {
            @include l4;

            position: absolute;
            top: 50%;
            left: 50%;
            color: $base-500;
            transform: translate(-50%, -50%);
            pointer-events: none;

            @include respond-to(sm) {
                display: none;
            }
        }

        .switcher {
            margin-left: auto;

            @include respond-to(sm) {
                margin-left: .8rem;
            }
        }

        .sort {
            @include respond-to(sm) {
                margin-left: .8rem;
            }
        }
    }
</style>
