<template>
    <div
        :class="[$style.UiFilterDropdownOption, classList]"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @click="onClick"
    >
        <div :class="$style.text">
            <div :class="$style.title">
                {{ option[labelName] }}

                <FlatsTooltip
                    v-if="option.tooltip"
                    :text="option.tooltip"
                    :disabled-action-tooltip="disabledActionTooltip"
                    @click-tooltip="$emit('click-tooltip')"
                />
            </div>

            <div
                v-if="option.description"
                :class="$style.description"
                v-html="option.description"
            />
        </div>

        <transition name="component-fade">
            <UiCheckbox
                v-if="multiple"
                :value="option.selected"
                :disabled="isDisabled"
                :is-highlighted="isHighlighted"
                :class="$style.checkbox"
            />

            <UiCheckbox
                v-else-if="type === 'sorted' && (isHighlighted || !$device.isDesktop || option.selected)"
                :value="option.selected"
                radio
                :disabled="isDisabled"
                :class="$style.checkbox"
            />
        </transition>
    </div>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
import FlatsTooltip from '~/components/flats/common/FlatsTooltip.vue';
import UiCheckbox from '~/components/ui/checkboxes/UiCheckbox.vue';

export default {
    name: 'UiFilterDropdownOption',

    components: {
        FlatsTooltip,
        UiCheckbox,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            default: '',
        },

        option: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        value: {
            type: [Number, String, Array],
            required: true,
        },

        /**
         * Название поля для value
         */
        valueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label
         */
        labelName: {
            type: String,
            default: 'label',
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium', 'moderate', 'small'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: value => ['base', 'transparent', 'white-hover'].includes(value),
        },

        /**
         * Определяет тип выпадающего списка
         */
        type: {
            type: String,
            default: 'default',
            validator: value => ['default', 'sorted'].includes(value),
        },

        isHighlighted: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Состояние отключенного для взаимодействия элемента
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Отключает дефолтное поведение тултипа
         * (необходимо при кастомной логике на клик тултипа)
         */
        disabledActionTooltip: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style[`_${this.color}`]]: this.color,
                    [this.$style[`_type-${this.type}`]]: this.type,
                    [this.$style[`_${this.size}`]]: this.size,
                    [this.$style._highlighted]: this.isHighlighted,
                    [this.$style._selected]: this.option.selected,
                    [this.$style._disabled]: this.isDisabled,
                },
            ];
        },

        isDisabled() {
            return this.disabled || this.option.disabled;
        },
    },

    methods: {
        onClick() {
            if (this.isDisabled) {
                return;
            }

            this.$emit('click', this.option);
        },

        onMouseEnter() {
            if (this.isDisabled) {
                return;
            }

            this.$emit('mouseenter');
        },

        onMouseLeave() {
            if (this.isDisabled) {
                return;
            }

            this.$emit('mouseleave');
        },
    },
};
</script>

<style lang="scss" module>
    .UiFilterDropdownOption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: $transition;
        cursor: pointer;

        /* Sizes */
        &._medium,
        &._moderate,
        &._small {
            .title {
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: 700;
                line-height: 1.24;
                color: $base-900;
            }

            .description {
                @include p4;
            }

            height: 4.8rem;
            padding: 0 2rem;
        }

        &._transparent {
            background-color: $base-0;

            .title {
                color: $base-900;
            }

            &._selected {
                background-color: $base-50;
            }
        }

        /* Colors */
        &._base {
            background-color: $base-0;

            .title {
                color: $base-900;
            }

            .description {
                color: $base-600;
            }

            &._highlighted,
            &._selected {
                background-color: $base-50;
            }
        }

        &._white-hover {
            background-color: $base-0;

            .title {
                color: $base-900;
            }

            .description {
                color: $base-600;
            }

            &._highlighted,
            &._selected {
                background-color: $base-50;
            }
        }

        /* Modificators */
        &._disabled {
            cursor: not-allowed;

            &._highlighted {
                background-color: $base-0;
            }

            &._selected {
                background-color: $base-50;
            }

            .title,
            .description {
                color: $base-400;
            }
        }

        .checkbox {
            margin-left: 1.2rem;
            pointer-events: none;
        }

        .text {
            display: grid;
            grid-template-columns: 1fr;
            gap: .2rem;
        }

        .title,
        .description {
            display: flex;
            align-items: center;
            transition: $transition;
        }
    }
</style>
