<script>
import placemarkMixin from '~/components/common/ymap/mixins/placemarkMixin';

export default {
    name: 'YMapProjectCirclePlacemark',

    mixins: [placemarkMixin],

    props: {
        icon: {
            type: String,
            default: '',
        },

        // флаг наличие круга пешей доступности
        hasWalkingDistance: {
            type: Boolean,
            default: true,
        },

        // радиус круга пешей доступности
        walkingDistanceRadius: {
            type: Number,
            default: 450,
        },
    },

    methods: {
        getGeoObject() {
            const collection = new this.ymaps.GeoObjectCollection();
            const placemark = new this.ymaps.Placemark(this.coords, {
                label: this.label,
                icon: this.icon,

            }, {
                iconLayout: this.getLayoutPlacemark(),
                zIndex: 702,
            });

            collection.add(placemark);

            // добавляем зону пешей доступности если указал влаг
            if (this.hasWalkingDistance) {
                const circle = new this.ymaps.Circle([this.coords, this.walkingDistanceRadius], {}, {
                    geodesic: true,
                    fillColor: '#00000011',
                    strokeWidth: 0,
                    cursor: '',
                });
                collection.add(circle); // добавляем круг

                const startPoint = this.coords;
                const azimuth = Math.PI;
                const direction = [Math.cos(azimuth), Math.sin(azimuth)];
                const coords = this.ymaps.coordSystem.geo.solveDirectProblem(startPoint, direction, this.walkingDistanceRadius).endPoint;
                const squarePlacemark = new this.ymaps.Placemark(coords, {}, {
                    iconLayout: this.getLayoutCircleLabel(),
                    zIndex: 702,
                });
                collection.add(squarePlacemark); // добавляем label для круга
            }

            return collection;
        },

        getLayoutPlacemark() {
            return this.ymaps.templateLayoutFactory.createClass(`
                <div class="ymap-project-circle-placemark">
                    <div
                        class="ymap-project-circle-placemark__image"
                        style="background-image: url('$[properties.icon]');"
                    ></div>
                </div>
            `);
        },

        getLayoutCircleLabel() {
            return this.ymaps.templateLayoutFactory.createClass(`
                <div class="ymap-project-circle-circle-placemark">
                    <div class="ymap-project-circle-circle-placemark__text">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.06647 9.05768L10.8634 7.10229C11.1773 6.88113 11.5592 6.76758 11.9481 6.77975C12.4224 6.79113 12.8812 6.94447 13.2608 7.21844C13.6404 7.49241 13.9218 7.8734 14.066 8.30845C14.2283 8.79813 14.3767 9.12907 14.5111 9.30126C14.9172 9.8227 15.4439 10.2459 16.0495 10.5372C16.6551 10.8285 17.323 10.9799 18 10.9795V12.6594C17.0988 12.6603 16.2086 12.4689 15.3934 12.099C14.5783 11.7291 13.8585 11.1898 13.2859 10.52L12.6776 13.842L14.4762 15.2951L16.4161 20.4255L14.7755 21L12.9953 16.293L10.0369 13.9033C9.79417 13.7146 9.60839 13.4667 9.49896 13.1855C9.38953 12.9042 9.36044 12.5998 9.41473 12.3041L9.85892 9.88082L9.26813 10.2941L7.41197 12.7534L6 11.7657L8.05163 9.0476L8.06647 9.05768ZM13.2003 6.35978C12.7375 6.35978 12.2935 6.18279 11.9662 5.86775C11.6389 5.55271 11.455 5.12542 11.455 4.67989C11.455 4.23435 11.6389 3.80707 11.9662 3.49203C12.2935 3.17699 12.7375 3 13.2003 3C13.6632 3 14.1072 3.17699 14.4345 3.49203C14.7618 3.80707 14.9457 4.23435 14.9457 4.67989C14.9457 5.12542 14.7618 5.55271 14.4345 5.86775C14.1072 6.18279 13.6632 6.35978 13.2003 6.35978ZM10.6068 17.4311L7.80205 20.6481L6.46513 19.5687L9.06218 16.5903L9.71318 14.7592L11.2761 16.0191L10.6068 17.4311Z" fill="currentColor" />
                        </svg>
                        5 минут
                    </div>
                </div>
            `, {
                build() { // для скрытия элемента на маленьком зуме
                    this.constructor.superclass.build.call(this);
                    const map = this.getData().geoObject.getMap();
                    // Получим текущий уровень зума.
                    let zoom = map.getZoom();
                    if (!this.inited) {
                        // ставил флаг, что элемент проинициализирован
                        this.inited = true;
                        // Подпишемся на событие изменения области просмотра карты.
                        map.events.add('boundschange', function() {
                            // Запустим перестраивание макета при изменении уровня зума.
                            const currentZoom = map.getZoom();
                            if (currentZoom !== zoom) {
                                zoom = currentZoom;
                                this.rebuild();
                            }
                        }, this);
                    }
                    const element = this.getParentElement().querySelector('.ymap-project-circle-circle-placemark');
                    if (zoom <= 13) {
                        element.style.opacity = '0';
                    } else {
                        element.style.opacity = '1';
                    }
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .ymap-project-circle-placemark {
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        padding: .6rem;
        border-radius: 50%;
        background-color: $base-500;
        color: $base-300;
        transform: translate(-50%, -50%);
        transition: $homeTransition;

        &__image {
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .ymap-project-circle-circle-placemark {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: fit-content;
        height: 30px;
        padding: 0 12px;
        background-color: $inversed;
        transform: translate(-50%, -50%);
        transition: opacity .2s;

        &__text {
            white-space: nowrap;
            font-size: 14px;
            font-weight: 700;
            line-height: 1;
            color: $base;

            svg {
                display: inline-block;
                width: 18px;
                height: auto;
                vertical-align: middle;
            }
        }
    }
</style>
