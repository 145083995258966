<template>
    <div
        v-clickoutside="onClickOutside"
        :class="[$style.UiFilterDropdown, classList]"
        :style="{['--max-items']: maxItems}"
    >
        <div
            :class="$style.field"
            @click.stop="handleToggleMenu"
        >
            <input
                v-if="!spanPlaceholder"
                ref="input"
                :class="$style.nativeInput"
                type="text"
                :value="selectedLabel"
                :disabled="isDisabled"
                :readonly="true"
                @focus="onFocus"
                @keydown.down.stop.prevent="handleNavigateOptions('down')"
                @keydown.up.stop.prevent="handleNavigateOptions('up')"
                @keydown.enter.prevent="onEnterPress"
                @keydown.esc="isOpened = false"
                @keydown.tab="onBlur"
                @mouseenter="inputHovering = true"
                @mouseleave="inputHovering = false"
            />
            <span
                v-else
                ref="input"
                :class="$style.nativeInput"
                @focus="onFocus"
                @keydown.down.stop.prevent="handleNavigateOptions('down')"
                @keydown.up.stop.prevent="handleNavigateOptions('up')"
                @keydown.enter.prevent="onEnterPress"
                @keydown.esc="isOpened = false"
                @keydown.tab="onBlur"
                @mouseenter="inputHovering = true"
                @mouseleave="inputHovering = false"
            >
                {{ selectedLabel }}
            </span>

            <SortingIcon
                v-if="type === 'sorted'"
                :class="$style.sorting"
            />

            <ArrowDownIcon
                v-else
                :class="$style.arrow"
            />
        </div>

        <transition name="dropdown">
            <div
                v-if="isOpened"
                :class="$style.dropdown"
                :style="dropdownStyles"
            >
                <UiScrollbox
                    :class="$style.scrollbox"
                    resizable
                    skip-offset
                    color="black"
                >
                    <UiFilterDropdownOption
                        v-for="(option, index) in optionList"
                        :key="`${index}_${option[valueName]}`"
                        :name="name"
                        :option="option"
                        :value="value"
                        :value-name="valueName"
                        :label-name="labelName"
                        :size="size"
                        :color="color"
                        :type="type"
                        :data-gtm-html-id="option[valueName] === '' ? 'any' : option[valueName]"
                        :is-highlighted="highlightIndex === index"
                        :multiple="multiple"
                        :disabled="isDisabled"
                        @mouseenter="highlightIndex = index"
                        @mouseleave="highlightIndex = -1"
                        @click="onOptionSelect"
                    />
                </UiScrollbox>
            </div>
        </transition>
    </div>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
// Libs
import { mapGetters } from 'vuex';
// Components
import UiScrollbox from '~/components/ui/scrollbox/UiScrollbox.vue';
import UiFilterDropdownOption from '~/components/ui/dropdowns/filter-dropdown/UiFilterDropdownOption.vue';
// Icons
import ArrowDownIcon from '~/assets/icons/ui/arrow-down.svg?inline';
import SortingIcon from '~/assets/icons/ui/sorting.svg?inline';

/**
 * При работе с фильтрами и формами - позволяет выбирать одно или несколько значений.<br><br>
 *
 * У нас на проектах применяется принцип "фасетного фильтра", т.е.:<br>
 *
 * <strong>specs</strong> - диапазон всех доступных значений.<br>
 * <strong>facets</strong> - значения, которые доступны после передачи параметров из
 * <strong>value</strong>.<br><br>
 *
 * <a href="https://habr.com/ru/post/517074/" target="_blank">
 *     Подробннее про работу фасетных фильтров
 * </a>
 *
 * @version 1.0.4
 * @displayName UiFilterDropdown
 */
export default {
    name: 'UiFilterDropdown',

    components: {
        UiScrollbox,
        UiFilterDropdownOption,
        ArrowDownIcon,
        SortingIcon,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            default: '',
        },

        /**
         * Определяет тип выпадающего списка
         */
        type: {
            type: String,
            default: 'default',
            validator: value => ['default', 'sorted'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium', 'moderate', 'small'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: value => ['base', 'transparent', 'white-hover'].includes(value),
        },

        /**
         * Определяет кол-во элементов видимых без скролла
         */
        maxItems: {
            type: Number,
            default: 4.5,
        },

        /**
         * Текущее значение для определения активного элемента
         */
        value: {
            type: [Number, String, Array],
            required: true,
        },

        /**
         * Название поля для value
         */
        valueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label
         */
        labelName: {
            type: String,
            default: 'label',
        },

        /**
         * Диапазон всех доступных значений селектора
         */
        specs: {
            type: Array,
            required: true,
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            required: true,
        },

        /**
         * Значение, которое подставляется в селектор, визуально,
         * если в value является пустой переменной.
         */
        placeholder: {
            type: String,
            default: 'Все',
        },

        /**
         * Доп. элемент в селекторе, при выборе
         * которого - сбрасывается состояние value
         */
        resetLabel: {
            type: String,
            default: 'Все',
        },

        /**
         * Включает возможноть выбора более одного элемента.
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Включает возможность использовать не input, а span в качестве placeholder`a
         */
        spanPlaceholder: {
            type: Boolean,
            default: false,
        },

        /**
         * Скрывает выбранные варианты из селектора
         */
        hideSelected: {
            type: Boolean,
            default: false,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Запрещает сброс активного элемента
         */
        required: {
            type: Boolean,
            default: false,
        },

        /**
         * Устанавливает статичный placeholder, вместо динамического
         */
        staticPlaceholder: {
            type: String,
            default: '',
        },

        dropdownOffsetLeft: { // принудительное смещение компонента dropdown
            type: String,
            default: '',
        },

        dropdownOffsetRight: { // принудительное смещение компонента dropdown
            type: String,
            default: '',
        },

        dropdownWidth: { // принудительное ширина компонента dropdown
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isFocused: false,
            isOpened: false,
            highlightIndex: -1,
            inputHovering: false,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        classList() {
            return [
                {
                    [this.$style[`_${this.color}`]]: this.color,
                    [this.$style[`_${this.size}`]]: this.size,
                    [this.$style[`_type-${this.type}`]]: this.type,
                    [this.$style._selected]: this.selectedOption,
                    [this.$style._focused]: this.isFocused,
                    [this.$style._opened]: this.isOpened,
                    [this.$style._disabled]: this.isDisabled,
                },
            ];
        },

        dropdownStyles() {
            const styles = {};

            if (this.dropdownOffsetLeft) {
                styles.left = this.dropdownOffsetLeft;
            }

            if (this.dropdownOffsetRight) {
                styles.right = this.dropdownOffsetRight;
            }

            if (this.dropdownWidth) {
                styles.width = this.dropdownWidth;
            }

            return styles;
        },

        optionList() {
            const specs = [];

            this.specs.forEach((opt, index) => {
                if (opt[this.valueName] === this.value && this.hideSelected) {
                    return;
                }

                specs.push({
                    ...opt,
                    disabled: this.facets && !this.facets.includes(opt[this.valueName]) && opt[this.valueName] !== '' && !this.handleActiveNative(opt[this.valueName]),
                    selected: this.handleActiveNative(opt[this.valueName]),
                });
            });

            if (!this.required && this.resetLabel) {
                specs.unshift({
                    [this.labelName]: this.resetLabel,
                    [this.valueName]: '',
                    disabled: false,
                    selected: !this.multiple && this.value === '' || this.multiple && !this.value.length,
                });
            }

            return specs;
        },

        selectedOption() {
            if (this.multiple) {
                if (this.value.length) {
                    return this.specs.filter(a => this.value.includes(a[this.valueName]));
                } else {
                    return null;
                }
            } else {
                return this.specs.filter(a => a[this.valueName] === this.value)[0] || null;
            }
        },

        selectedLabel() {
            if (this.staticPlaceholder) {
                return this.staticPlaceholder;
            }

            if (this.multiple) {
                if (this.value.length) {
                    return this.selectedOption
                        .reduce((acc, cur) => `${acc + cur?.[this.labelName]}, `, '')
                        .slice(0, -2);
                } else {
                    return this.placeholder;
                }
            } else {
                return this.selectedOption
                    ? this.selectedOption?.[this.labelName]
                    : this.placeholder;
            }
        },

        isDisabled() {
            return this.disabled || this.specs.length === 0;
        },
    },

    methods: {
        /**
         * Отрабатывает во время выбора доступного элемента,
         * обрабатывает данные и отдаёт выбранный элемент родителю
         * @param {Object} option Объект селектора
         * @public
         */
        onOptionSelect(option) {
            let newValue;

            if (this.multiple) {
                if (!option[this.valueName]) {
                    newValue = [];
                } else {
                    newValue = this.value.slice();

                    if (newValue.includes(option[this.valueName])) {
                        newValue.splice(this.value.indexOf(option[this.valueName]), 1);
                    } else {
                        newValue.push(option[this.valueName]);
                    }
                }
            } else {
                newValue = this.value !== option[this.valueName] ? option[this.valueName] : '';
            }

            if (!this.multiple || this.multiple && !newValue.length) {
                this.onBlur();
            }

            if (this.name) {
                newValue = { [this.name]: newValue };
            }

            /**
             * Отдаёт выбранный вариант родителю
             * @event change
             * @param {Object|Array} option Объект или массив селектора
             */
            this.$emit('change', newValue);

            this.onFocus();
        },

        /**
         * Метод, который обрабатывает событие focus на инпуте
         * @public
         */
        onFocus() {
            this.isFocused = true;
            this.$refs.input.focus();

            /**
             * Передаёт родителю, что компонент находится в focus.
             * В большинстве реализаций - может и не пригодится
             * @event focus
             */
            this.$emit('focus');
        },

        /**
         * Метод, который обрабатывает событие blur на инпуте
         * @public
         */
        onBlur() {
            if (this.isOpened) {
                this.isOpened = false;
                this.highlightIndex = -1;
            }
            this.isFocused = false;
            this.$refs.input.blur();

            /**
             * Передаёт родителю, что компонент больше не находится в focus.
             * В большинстве реализаций - может и не пригодится
             * @event blur
             */
            this.$emit('blur');
        },

        /**
         * Вызывается в тот момент, когда пользовать
         * сделал клик за пределы вызываемого селектором окна
         * @public
         */
        onClickOutside() {
            this.onBlur();

            /**
             * Эмитит в тот момент, когда пользовать
             * сделал клик за пределы вызываемого селектором окна
             * @event click-outside
             */
            this.$emit('click-outside');
        },

        /**
         * Перехватывает событие, при нажатии клавиши enter,
         * Открывает закрытый селектор или выбирает текущий item
         * @public
         */
        onEnterPress() {
            if (!this.isOpened) {
                this.handleToggleMenu();
            } else if (this.optionList[this.highlightIndex]) {
                this.onOptionSelect(this.optionList[this.highlightIndex]);
            }
        },

        /**
         * Открывает или закрывает попап меню выбора
         * @public
         */
        handleToggleMenu() {
            if (this.isDisabled) {
                return;
            }

            this.isOpened = !this.isOpened;
        },

        /**
         * Для навигации с помощью клавиш up/down,
         * перехватывая нажатия этих клавиш
         * @param {String} direction Направление
         * @public
         */
        handleNavigateOptions(direction) {
            if (!this.isOpened) {
                this.isOpened = true;
                return;
            }

            if (direction === 'down') {
                this.highlightIndex++;
                if (this.highlightIndex === this.optionList.length) {
                    this.highlightIndex = 0;
                }
            } else if (direction === 'up') {
                this.highlightIndex--;
                if (this.highlightIndex < 0) {
                    this.highlightIndex = this.optionList.length - 1;
                }
            }

            const option = this.optionList[this.highlightIndex];

            if (option.disabled) {
                this.handleNavigateOptions(direction);
            }
        },

        /**
         * Вспомогательный метод для работы с мобильным устройствами.
         * В computed optionList возвращает индекс элемента для аттрибута selected
         * @param {String} value Option value
         * @returns {Boolean} selected Is value selected
         * @public
         */
        handleActiveNative(value) {
            if (this.multiple) {
                return this.value.includes(value);
            } else {
                return value === this.value;
            }
        },
    },
};
</script>

<style lang="scss" module>
    $height-items: 4.8rem;

    .UiFilterDropdown {
        position: relative;
        user-select: none;

        /* Sizes */
        &._medium {
            .field {
                height: 5.6rem;
                padding: 0 2rem;
            }

            .nativeInput {
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: 700;
                line-height: 1.24;
            }

            .arrow,
            .sorting {
                width: 1.6rem;
                min-width: 1.6rem;
                height: 1.6rem;
                min-height: 1.6rem;
                margin-left: 1.2rem;
            }
        }

        &._moderate {
            .field {
                height: 4.8rem;
                padding: 0;
            }

            .nativeInput {
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: 700;
                line-height: 1.24;
            }

            .arrow,
            .sorting {
                width: 2rem;
                min-width: 2rem;
                height: 2rem;
                min-height: 2rem;
                margin-left: .8rem;
            }

            .dropdown {
                width: 37.5rem;
            }
        }

        &._small {
            .field {
                height: 4.4rem;
                padding: 0 2.4rem;
            }

            .nativeInput {
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: 700;
                line-height: 1.24;
            }

            .arrow,
            .sorting {
                width: 1.6rem;
                min-width: 1.6rem;
                height: 1.6rem;
                min-height: 1.6rem;
                margin-left: 1.2rem;
            }
        }

        /* Colors */
        &._base {
            &._selected {
                .nativeInput {
                    color: $base-900;
                }

                .arrow {
                    color: $base-900;
                }
            }

            &:hover {
                .nativeInput {
                    color: $base-600;
                }

                .arrow {
                    opacity: .6;
                }
            }

            &._opened {
                .arrow {
                    color: $base-900;
                }

                .nativeInput {
                    color: $base-500;
                }
            }

            &._disabled {
                pointer-events: none;

                .nativeInput {
                    color: $base-400;
                }

                .arrow {
                    opacity: .4;
                }
            }

            .field {
                background-color: $base-50;
            }

            .nativeInput {
                color: $base-900;
            }

            .arrow {
                color: $base-900;
            }

            .dropdown {
                border: 1.5px solid $base-50;
                background-color: $base-0;
                box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
            }
        }

        &._white-hover {
            &._selected {
                .nativeInput {
                    color: $base-400;
                }

                .arrow {
                    color: $base-900;
                }
            }

            &:hover {
                .field {
                    background-color: $base-0;
                    box-shadow: 0 1.2rem 2.4rem 0 rgba($base, .04);
                }

                .nativeInput {
                    color: $base-400;
                }
            }

            &._opened {
                .field {
                    background-color: $base-0;
                    box-shadow: 0 1.2rem 2.4rem 0 rgba($base, .04);
                }

                .arrow {
                    color: $base-900;
                }

                .nativeInput {
                    color: $base-400;
                }
            }

            &._disabled {
                pointer-events: none;

                .nativeInput {
                    color: $base-400;
                }

                .arrow {
                    opacity: .4;
                }
            }

            .field {
                background-color: $base-50;
                transition: $transition;
            }

            .nativeInput {
                color: $base-400;
            }

            .arrow {
                color: $base-900;
            }

            .dropdown {
                border: 1.5px solid $base-50;
                background-color: $base-0;
                box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
            }
        }

        &._transparent {
            &:hover {
                .nativeInput {
                    color: $base-600;
                }

                .arrow,
                .sorting {
                    opacity: .6;
                }
            }

            &._opened {
                .arrow,
                .sorting,
                .nativeInput {
                    color: $base-600;
                }
            }

            &._disabled {
                pointer-events: none;

                .nativeInput {
                    color: $base-400;
                }

                .arrow,
                .sorting {
                    opacity: .4;
                }
            }

            .field {
                background-color: transparent;
            }

            .nativeInput {
                color: $base-900;
            }

            .arrow {
                color: $base-900;
            }

            .dropdown {
                background-color: $base-0;
                box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
            }
        }

        /* Modificators */
        &._opened {
            .arrow {
                transform: rotate(180deg);
            }
        }

        .field {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
        }

        .nativeInput {
            overflow: hidden;
            display: inline-block;
            width: 100%;
            padding: initial;
            background-color: transparent;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: $transition;
            cursor: pointer;

            &::selection {
                background-color: transparent;
            }
        }

        .arrow,
        .sorting {
            display: flex;
            transition: $transition;
        }

        .dropdown {
            position: absolute;
            top: calc(100% + .6rem);
            left: 0;
            z-index: 4;
            display: block;
            width: 100%;
            transition: $transition;
        }

        .scrollbox {
            max-height: calc($height-items * var(--max-items));
        }
    }
</style>
