<template>
    <div :class="$style.FilterFooter">
        <UiButton
            color="black"
            :size="$device.isMobile ? 'normal' : 'big'"
            @click="onClickApply"
        >
            {{ applyText }}
        </UiButton>

        <UiButton
            :size="$device.isMobile ? 'normal' : 'big'"
            :class="$style.reset"
            @click="resetOptionFn"
        >
            {{ resetText }}
        </UiButton>
    </div>
</template>

<script>
import UiButton from '~/components/ui/buttons/UiButton';

export default {
    name: 'FilterFooter',

    components: {
        UiButton,
    },

    props: {
        /**
         * Функция, которая будет срабатывать при нажатии на кнопку "Сбросить"
         */
        resetOptionFn: {
            type: Function,
            default: () => ({}),
        },

        /**
         * Функция, которая будет срабатывать при нажатии на кнопку "Применить"
         */
        applyOptionFn: {
            type: Function,
            default: null,
        },

        /**
         * Текст, который будет выведен в кнопку Применения фильтров
         */
        applyText: {
            type: String,
            default: 'Применить',
        },

        /**
         * Текст, который будет выведен в кнопку Сброса фильтров
         */
        resetText: {
            type: String,
            default: 'Сбросить',
        },
    },

    methods: {
        onClickApply() {
            this.applyOptionFn === null ? this.$emit('close') : this.applyOptionFn();
        },
    },
};
</script>

<style lang="scss" module>
    .FilterFooter {
        display: grid;
        grid-template-columns: 1fr min-content;
        gap: 1.2rem;
        padding: 1.6rem 2.4rem;

        .reset {
            white-space: nowrap;
        }
    }
</style>
