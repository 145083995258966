// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containerSale_KAl9s{position:relative;z-index:10;background:#fff}.HomeSaleForm_YTvN-{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding-right:6.4rem;padding-left:6.4rem}@media(max-width:991.98px){.HomeSaleForm_YTvN-{padding-right:2.4rem;padding-left:2.4rem}}.HomeSaleForm_YTvN- .HomeSaleFormTitle_WsflB{margin-bottom:6.4rem}@media(max-width:991.98px){.HomeSaleForm_YTvN- .HomeSaleFormTitle_WsflB{margin-bottom:3.6rem}}.HomeSaleForm_YTvN- .inputsContainer_ymRP4{display:-ms-grid;display:grid;-ms-grid-columns:1fr 1fr 1fr 1fr;grid-template-columns:1fr 1fr 1fr 1fr;grid-gap:12px;gap:12px}@media(max-width:991.98px){.HomeSaleForm_YTvN- .inputsContainer_ymRP4{-ms-grid-columns:1fr;grid-template-columns:1fr}}.HomeSaleForm_YTvN- .input_C3Wcm{z-index:1}.HomeSaleForm_YTvN- .button_CnTOE{z-index:1;width:100%;height:5.6rem}.HomeSaleForm_YTvN- .policy_WMl\\+O{font-size:1.2rem;line-height:1.5;letter-spacing:normal;margin:3.2rem auto 0;text-align:center;color:#bebec5}@media(max-width:991.98px){.HomeSaleForm_YTvN- .policy_WMl\\+O{margin:3.2rem auto 0}}@media(max-width:767.98px){.HomeSaleForm_YTvN- .policy_WMl\\+O{margin:2.4rem auto 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerSale": "containerSale_KAl9s",
	"HomeSaleForm": "HomeSaleForm_YTvN-",
	"HomeSaleFormTitle": "HomeSaleFormTitle_WsflB",
	"inputsContainer": "inputsContainer_ymRP4",
	"input": "input_C3Wcm",
	"button": "button_CnTOE",
	"policy": "policy_WMl+O"
};
module.exports = ___CSS_LOADER_EXPORT___;
