// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiSimpleDropdown_Ue8lL{position:relative;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.UiSimpleDropdown_Ue8lL._disabled_Ssy5g{pointer-events:none}.UiSimpleDropdown_Ue8lL._white_ouVY\\+ .wrap_6Gz3p{border-top:1px solid #dbdbdb;background-color:#fff}.UiSimpleDropdown_Ue8lL._gray_tvtWA .wrap_6Gz3p{border-top:1px solid #dbdbdb;background-color:#f3f3f3}.UiSimpleDropdown_Ue8lL .wrap_6Gz3p{position:absolute;top:100%;left:0;z-index:1;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:100%}.UiSimpleDropdown_Ue8lL .wrap_6Gz3p.dropdown-options-enter-active{-webkit-transition:opacity .3s ease;transition:opacity .3s ease}.UiSimpleDropdown_Ue8lL .wrap_6Gz3p.dropdown-options-leave-active{-webkit-transition:opacity .15s ease;transition:opacity .15s ease}.UiSimpleDropdown_Ue8lL .wrap_6Gz3p.dropdown-options-enter,.UiSimpleDropdown_Ue8lL .wrap_6Gz3p.dropdown-options-leave-to{opacity:0}.UiSimpleDropdown_Ue8lL .options_iznNm{overflow-x:hidden;overflow-y:auto;scrollbar-color:#bbb transparent;scrollbar-width:thin}.UiSimpleDropdown_Ue8lL .options_iznNm::-webkit-scrollbar{width:.4rem;height:.4rem;border-radius:.8rem;background:transparent}.UiSimpleDropdown_Ue8lL .options_iznNm::-webkit-scrollbar-thumb{border-radius:.8rem;background:#bbb}.UiSimpleDropdown_Ue8lL .scroll_98n-a{height:100%;max-height:14.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiSimpleDropdown": "UiSimpleDropdown_Ue8lL",
	"_disabled": "_disabled_Ssy5g",
	"_white": "_white_ouVY+",
	"wrap": "wrap_6Gz3p",
	"_gray": "_gray_tvtWA",
	"options": "options_iznNm",
	"scroll": "scroll_98n-a"
};
module.exports = ___CSS_LOADER_EXPORT___;
