<template>
    <div
        v-if="formats.length || scenarios.length"
        ref="filter"
        :class="$style.ProjectFormatFilter"
    >
        <UiButtonGroup
            v-if="formats.length && scenarios.length"
            v-model="model.type"
            :class="[$style.wrapper, $style.left, $style.group]"
        >
            <UiButton
                v-if="formats.length"
                color="gray-and-white"
                :class="$style.button"
                value="formats"
            >
                Форматы
            </UiButton>
            <UiButton
                v-if="scenarios.length"
                color="gray-and-white"
                :class="$style.button"
                value="scenarios"
            >
                Сценарии
            </UiButton>
        </UiButtonGroup>

        <UiButtonGroup
            v-show="tags && tags.length && tags.length !== select.length"
            v-model="model.currentTag"
            :class="[
                $style.wrapper,
                $style.center,
                $style.group,
                {[$style._hasDropdown]: select.length}
            ]"
        >
            <transition-group
                ref="container"
                :enter-active-class="$style.tagsEnterActive"
                :leave-active-class="$style.tagsLeaveActive"
                :enter-class="$style.tagsEnter"
                :leave-to-class="$style.tagsLeaveTo"
                tag="div"
                :class="[$style.tagContainer, $style.group]"
                @enter="enter"
                @leave="leave"
                @after-enter="afterEnter"
            >
                <div
                    v-for="tag in tags"
                    :key="tag.id"
                    ref="tags"
                    :data-id="tag.id"
                >
                    <UiTooltip
                        v-if="tag.description"
                        bottom
                        :nudge="12"
                        :class="$style.tooltip"
                    >
                        <template #activator>
                            <UiButton
                                :key="tag.id"
                                color="gray-and-white"
                                :class="$style.button"
                                :value="tag.id"
                            >
                                {{ tag.name }}
                            </UiButton>
                        </template>
                        <div :class="$style.tooltipCard" v-html="tag.description"></div>
                    </UiTooltip>
                    <UiButton
                        v-else
                        :key="tag.id"
                        color="gray-and-white"
                        :class="$style.button"
                        :value="tag.id"
                    >
                        {{ tag.name }}
                    </UiButton>
                </div>
            </transition-group>
        </UiButtonGroup>
        <div :class="[$style.group, $style.right]">
            <UiTooltip
                v-if="select.length"
                ref="dropdownTooltip"
                bottom
                :nudge="12"
            >
                <template #activator>
                    <UiSimpleDropdown
                        v-model="model.currentTag"
                        :class="$style.dropdown"
                        :options="selectSorted"
                        size="medium"
                        color="gray"
                        placeholder="Выберите еще"
                        key-value="id"
                        key-label="name"
                        @change-dropdown-opened="onChangeSelectVisible"
                    />
                </template>
                <div v-if="selectTagDescription && !selectOpened" :class="[$style.tooltipCard, {[$style._hide]: selectOpened}]">
                    {{ selectTagDescription }}
                </div>
            </UiTooltip>
            <transition
                :enter-active-class="$style.buttonShowEnterActive"
                :leave-active-class="$style.buttonShowLeaveActive"
                :enter-class="$style.buttonShowEnter"
                :leave-to-class="$style.buttonShowLeaveTo"
            >
                <UiButton
                    v-if="button && button.link"
                    color="black"
                    size="medium"
                    :link="typeof button.link === 'function' ? null : button.link"
                    :blank="typeof button.link !== 'function'"
                    :class="[$style.button, $style.buttonShow]"
                    @click="handleClick"
                >
                    {{ button.text }}
                </UiButton>
            </transition>
        </div>
        <div :class="$style.selectWrapper">
            <UiMobileSelect
                :value="model.currentTag"
                :options="tags"
                modal-title="Объекты"
                key-value="id"
                key-label="name"
                key-note="description"
                @change-value="onChangeSelect"
            />
            <div v-if="selectMessage" :class="$style.selectMessage">{{ selectMessage }}</div>
        </div>

    </div>
</template>

<script>
import { debounce } from '~/assets/js/utils';

import UiButtonGroup from '~/components/ui/buttons/UiButtonGroup.vue';
import UiButton from '~/components/ui/buttons/UiButton.vue';
import UiSimpleDropdown from '~/components/ui/dropdowns/simple-dropdown/UiSimpleDropdown.vue';
import UiTooltip from '~/components/ui/UiTooltip.vue';
import UiMobileSelect from '~/components/ui/select/UiMobileSelect.vue';
import ResizeObserver from 'resize-observer-polyfill';

export default {
    name: 'ProjectFormatFilter',
    components: { UiMobileSelect, UiTooltip, UiSimpleDropdown, UiButton, UiButtonGroup },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => null,

            validator(value) {
                const requiredKeys = ['type', 'currentTag'];
                const valueKeys = Object.keys(value);
                return requiredKeys.every(key => valueKeys.includes(key));
            },
        },

        formats: {
            type: Array,
            default: () => [],
        },

        scenarios: {
            type: Array,
            default: () => [],
        },

        button: {
            type: Object,
            default: () => ({
                text: '',
                link: '',
            }),

            validator(value) {
                const requiredKeys = ['text', 'link'];
                const valueKeys = Object.keys(value);
                return requiredKeys.every(key => valueKeys.includes(key));
            },
        },
    },

    data() {
        return {
            select: [],
            observer: null,
            resizeObserver: null,
            model: this.value || { type: 'formats', currentTag: null },
            selectOpened: false,
        };
    },

    computed: {
        tags() {
            return this[this.model.type] || [];
        },

        selectSorted() {
            const tags = [...this.select];
            tags.sort((a, b) => a?.order - b.order);
            return tags;
        },

        selectTagDescription() {
            const currentTag = this.select.find(el => el.id === this.model.currentTag);
            return currentTag?.description || '';
        },

        selectMessage() {
            if (this.tags.length <= 3) {
                return '';
            }
            const tags = this.tags.slice(0, 3);

            const text = tags.map(tag => tag.name).join(', ');

            return text[0].toUpperCase() + text.slice(1).toLowerCase() + ' и другие';
        },
    },

    watch: {
        tags(newVal) {
            this.select = [];
            this.model.currentTag = null;
            if (newVal && newVal.length) {
                this.model.currentTag = newVal[0].id;
            }
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
            // ждем анимации
            setTimeout(() => {
                this.debouncedInitObserver();
            }, 900);
        },

        value: {
            handler(newVal) {
                this.model = newVal;
            },

            deep: true,
        },


        model: {
            handler(newVal) {
                this.$emit('input', newVal);
            },

            deep: true,
        },
    },

    created() {
        this.debouncedShowTags = debounce(this.showTags, 400);
        this.debouncedInitObserver = debounce(this.initObserver, 200);
    },

    mounted() {
        this.$nextTick(() => {
            if (this.observer) {
                this.observer.disconnect();
                this.observer = null;
            }
            // ждем анимации
            setTimeout(() => {
                this.debouncedInitObserver();
                this.resizeObserver = new ResizeObserver(this.debouncedShowTags);
                this.resizeObserver.observe(this.$refs.filter);
            }, 800);
        });
    },

    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },

    methods: {
        initObserver() {
            if (!this.$refs.tags || !this.$refs.container || !this.tags.length) {
                return false;
            }

            const options = {
                root: this.$refs.container.$el,
                threshold: [0, 1],
            };

            this.observer = new IntersectionObserver(this.onTagObserved, options);

            this.$refs.tags.forEach(tag => {
                this.observer.observe(tag);
            });
        },

        onTagObserved(entries) {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0.98) {
                    entry.target.style.position = 'static';
                    entry.target.style.opacity = '1';
                    this.removeTagFromSelect(entry.target.dataset.id);
                } else {
                    entry.target.style.position = 'absolute';
                    entry.target.style.left = `${entry.boundingClientRect.left - entry.rootBounds.left}px`;
                    entry.target.style.opacity = '0';
                    this.addTagInSelect(entry.target.dataset.id);
                }
            });
        },

        showTags() {
            if (this.$refs.tags && this.observer) {
                this.$refs.tags.forEach(tag => {
                    this.observer.unobserve(tag);
                    tag.style.position = 'static';
                    tag.style.opacity = '1';
                    this.observer.observe(tag);
                });
            }
        },

        addTagInSelect(id) {
            const hasTagInSelect = this.select.some(tag => tag.id === id);
            if (!hasTagInSelect) {
                const tag = this.tags.find(tag => tag.id === id);
                this.select.push(tag);
            }
        },

        removeTagFromSelect(id) {
            this.select = this.select.filter(tag => tag.id !== id);
        },

        onChangeSelect(val) {
            this.model.currentTag = val;
        },

        onChangeSelectVisible(val) {
            if (!val) {
                this.$refs.dropdownTooltip.hide();
                setTimeout(() => {
                    this.selectOpened = val;
                }, 200);
            } else {
                this.selectOpened = val;
            }
        },

        enter(el) {
            const width = getComputedStyle(el).width;
            el.style.width = 0;
            setTimeout(() => {
                el.style.width = width;
            }, 0);
        },

        afterEnter(element) {
            element.style.width = '';
        },

        leave(el) {
            el.style.width = getComputedStyle(el).width;
            setTimeout(() => {
                el.style.width = 0;
            }, 0);
        },

        handleClick() {
            if (typeof this.button.link === 'function') {
                this.button.link();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectFormatFilter {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        white-space: nowrap;

        @include respond-to(sm) {
            flex-wrap: wrap;
            margin: -.8rem;
        }

        @include respond-to(xxs) {
            margin: -.6rem;
        }

        .buttonShow {
            width: 19rem;
            min-width: auto;
            max-width: none;
            margin-left: .8rem;
            padding-right: 1.2rem;
            padding-left: 1.2rem;
        }

        .buttonShowEnterActive {
            transition: width .3s ease, margin-left .3s ease, color .2s ease .3s, opacity .5s ease;
        }

        .buttonShowLeaveActive {
            transition: width .3s ease .2s, margin-left .3s ease .2s, color .2s ease, opacity .5s ease;
        }

        .buttonShowEnter,
        .buttonShowLeaveTo {
            width: 0;
            margin-left: 0;
            padding-right: 0;
            padding-left: 0;
            color: rgba(#000, 0);
            opacity: 0;
        }

        .tagsLeaveActive,
        .tagsEnterActive {
            margin: 0;
            padding: 0;
            transition: all .8s;
        }

        .tagsEnter,
        .tagsLeaveTo {
            margin-right: 0 !important; //stylelint-disable-line declaration-no-important
        }
    }

    .wrapper {
        display: flex;
        flex: 0 0 auto;
        width: auto;
        padding: .6rem;
        background-color: $base-50;
        white-space: nowrap;
    }

    .group {
        display: flex;

        & > * {
            &:not(:last-child) {
                margin-right: .8rem;
            }
        }
    }

    .center {
        overflow-x: hidden;
        flex-shrink: 1;

        &._hasDropdown {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        @include respond-to(sm) {
            display: none;
        }
    }

    .right {
        @include respond-to(sm) {
            display: none;
        }
    }

    .left {
        margin-right: 2.4rem;

        @include respond-to(sm) {
            flex-grow: 1;
            margin: .8rem;

            .button {
                width: 100%;
            }
        }

        @include respond-to(xxs) {
            margin: .6rem;
        }
    }

    .button {
        width: auto;
    }

    .tagContainer {
        position: relative;
        overflow-x: hidden;
        flex-shrink: 1;
    }

    .dropdown {
        min-width: 23rem;
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
        background-color: $base-50;

        :global(.UiSimpleDropdownValue__placeholder),
        :global(.UiSimpleDropdownValue__title),
        :global(.UiSimpleDropdownOption__title) {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            color: $base-600;
        }
    }

    .selectWrapper {
        display: none;

        @include respond-to(sm) {
            display: block;
            flex: 1 1 30rem;
            min-width: 30rem;
            margin: .8rem;
        }

        @include respond-to(xxs) {
            margin: .6rem;
        }
    }

    .selectMessage {
        margin-top: .8rem;
        padding-left: 2rem;
        white-space: normal;

        @include p4;
    }

    .dropdownMobile {
        width: 100%;
    }

    .tooltipCard {
        @include p4;

        max-width: 28rem;
        padding: 2rem;
        background-color: $base-50;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 132%;
        color: $base-500;
        box-shadow: 0 16px 24px rgba(0, 0, 0, .06), 0 2px 6px rgba(0, 0, 0, .04), 0 0 1px rgba(0, 0, 0, .04);
        transition: opacity .2s ease 2s;

        &._hide {
            opacity: 0;
            transition: opacity .1s;
        }
    }
</style>
