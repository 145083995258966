// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeGenplan_6d5Cs{width:100%;height:70rem;min-height:70rem}@media(max-width:991.98px){.HomeGenplan_6d5Cs{height:76rem;min-height:76rem}}@media(max-width:519.98px){.HomeGenplan_6d5Cs{height:56rem;min-height:56rem}}.HomeGenplan_6d5Cs .compass_SI1eI{position:absolute;right:6.4rem;bottom:3.2rem;width:7.2rem;height:7.2rem;padding:0;border-radius:50%;background-color:#fff}@media(max-width:767.98px){.HomeGenplan_6d5Cs .compass_SI1eI{right:2rem;bottom:2rem;width:5.8rem;height:5.8rem}}.HomeGenplan_6d5Cs .compassArrow_J75gY{position:absolute;top:50%;left:50%;margin-top:-3.2rem;margin-left:-3.2rem}@media(max-width:767.98px){.HomeGenplan_6d5Cs .compassArrow_J75gY{margin-top:-2.4rem;margin-left:-2.4rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeGenplan": "HomeGenplan_6d5Cs",
	"compass": "compass_SI1eI",
	"compassArrow": "compassArrow_J75gY"
};
module.exports = ___CSS_LOADER_EXPORT___;
