<template>
    <div :class="[$style.UiRangeInputValue, classList]">
        <div :class="$style.inner">
            <input
                ref="input"
                :class="$style.native"
                :style="`width: ${width}px;`"
                v-bind="inputAttrs"
                :value="splittedValue"
                type="text"
                @keydown.enter="$refs.input.blur()"
                @input="onInput"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
            >
        </div>
    </div>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
// Utils
import { splitThousands } from '~/assets/js/utils';

export default {
    name: 'UiRangeInputValue',

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: val => ['base', 'transparent'].includes(val),
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Boolean,
            default: false,
        },

        delimiter: {
            type: String,
            default: ' ',
        },

        decimalMark: {
            type: String,
            default: '.',
        },

        decimalCount: {
            type: Number,
            default: 0,
        },

        width: {
            type: Number,
            default: 0,
        },

        length: {
            type: Object,
            default: () => ({ maxlength: null, minlength: null }),
        },
    },

    data() {
        return {
            isFocused: false,
            splittedValue: this.splitValue(this.value),
        };
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._active]: this.isFocused,
                [this.$style._disabled]: this.disabled,
            };
        },

        inputAttrs() {
            let attrs = {
                ...this.$attrs,
            };

            if (this.disabled) {
                attrs.disabled = 'disabled';
            }

            if (Object.values(this.length).length) {
                attrs = {
                    ...attrs,
                    ...this.length,
                };
            }

            return attrs;
        },

        cleanValue() {
            return Number(this.splittedValue.split(this.delimiter)
                .join(''));
        },
    },

    watch: {
        value(newValue) {
            if (newValue !== this.cleanValue) {
                this.splittedValue = this.splitValue(newValue);
            }
        },
    },

    methods: {
        splitValue(value) {
            if (this.decimalCount) {
                return splitThousands(Number(value).toFixed(this.decimalCount), ' ', true);
            } else {
                return splitThousands(value);
            }
        },

        onChange() {
            this.$nextTick(() => {
                this.$emit('change', this.cleanValue);
            });
        },

        onFocus(e) {
            this.isFocused = true;
            this.$emit('focus', e);
        },

        onBlur(e) {
            this.isFocused = false;
            this.onInput(e);
            this.$emit('blur', e);
        },

        onInput(e) {
            let endPos = e.target.selectionEnd;
            const oldValue = e.target.value;
            const newValue = this.doSplitThousands(e.target.value);
            e.target.value = newValue;
            this.splittedValue = newValue;

            this.$nextTick(() => {
                endPos = this.getNextCursorPosition(endPos, oldValue, newValue, this.delimiter);
                this.setCursor(e.target, endPos);
                this.$emit('input', this.cleanValue);
            });
        },

        doSplitThousands(value) {
            if (typeof value !== 'number' && typeof value !== 'string') {
                console.warn('[UiRangeInputValue] Wrong prop value');
                return '';
            }

            let partDecimal = '';
            let parts;

            // strip alphabet letters
            value = value.toString();
            value = value
                .replace(/[A-Za-z]/g, '')
                // replace the first decimal mark with reserved placeholder
                .replace(this.decimalMark, 'M')

                // strip non numeric letters except minus and "M"
                // this is to ensure prefix has been stripped
                .replace(/[^\dM-]/g, '')

                // replace the leading minus with reserved placeholder
                .replace(/^-/, 'N')

                // strip the other minus sign (if present)
                .replace(/-/g, '')

                // replace the minus sign (if present)
                .replace('N', this.positiveOnly ? '' : '-')

                // replace decimal mark
                .replace('M', this.decimalMark);

            const partSign = value.slice(0, 1) === '-' ? '-' : '';
            const partSignAndPrefix = partSign;
            let partInteger = value;

            if (value.indexOf(this.decimalMark) >= 0) {
                parts = value.split(this.decimalMark);
                partInteger = parts[0];
                partDecimal = `${this.decimalMark}${parts[1].slice(0, this.decimalCount)}`;
            }

            if (partSign === '-') {
                partInteger = partInteger.slice(1);
            }

            partInteger = partInteger.replace(/(\d)(?=(\d{3})+$)/g, '$1' + this.delimiter);

            return (
                partSignAndPrefix +
                partInteger.toString() +
                (this.decimalCount > 0 ? partDecimal.toString() : '')
            );
        },

        getNextCursorPosition(prevPos, oldValue, newValue, delimiter) {
            return oldValue.length === prevPos
                ? newValue.length
                : prevPos + this.getPositionOffset(prevPos, oldValue, newValue, delimiter);
        },

        getPositionOffset(prevPos, oldValue, newValue, delimiter) {
            const oldRawValue = this.stripDelimiters(oldValue.slice(0, prevPos), delimiter);
            const newRawValue = this.stripDelimiters(newValue.slice(0, prevPos), delimiter);
            const lengthOffset = oldRawValue.length - newRawValue.length;
            return lengthOffset !== 0 ? lengthOffset / Math.abs(lengthOffset) : 0;
        },

        stripDelimiters(value, delimiter) {
            const delimiterRE = delimiter
                ? new RegExp(delimiter.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g')
                : '';
            return value.replace(delimiterRE, '');
        },

        setCursor(el, position) {
            // eslint-disable-next-line func-style
            const setSelectionRange = function() {
                el.setSelectionRange(position, position);
            };

            if (el === document.activeElement) {
                setSelectionRange();
                // Android Fix
                setTimeout(setSelectionRange, 1);
            }
        },
    },
};
</script>

<style lang="scss" module>
    .UiRangeInputValue {
        position: relative;
        max-width: fit-content;

        .inner {
            position: relative;
            z-index: 2;
            display: flex;
        }

        .native {
            padding: 0;
            background-color: transparent;
            transition: color $transition;
        }

        /* Sizes */
        &._medium {
            .native {
                @include l3;
            }
        }

        /* Colors */
        &._base {
            .native {
                color: $base-900;
            }
        }

        // уникальный цвет для фильтра на странице проекта
        &._transparent {
            .native {
                @include l4;

                color: $base-0;
            }
        }

        /* Modificators */
        &._active {
            .native {
                color: $base-600;
            }
        }

        &._disabled {
            pointer-events: none;

            .native {
                color: $base-400;
                pointer-events: none;
            }
        }
    }
</style>
