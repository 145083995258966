<template>
    <div :class="$style.FlatsAdvancedFiltersMobile">
        <FlatsReadyFilter
            :show-more-btn="false"
            size="normal"
            :class="$style.ready"
        />

        <div :class="$style.column">
            <h4 :class="$style.title">
                Параметры квартиры
            </h4>

            <UiInputWrapper label="Тип лота">
                <FlatsTagsArray
                    name="newTypeFlat"
                    data-gtm-html-id="new_type_flat"
                    :value="filters.newTypeFlat"
                    :specs="specs.new_type_flat"
                    :facets="facets.new_type_flat"
                    multiple
                    size="normal"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Количество спален">
                <FlatsFilterLayout
                    name="layout"
                    data-gtm-html-id="layout"
                    :value="filters.layout"
                    :specs="specs.layout"
                    :facets="facets.layout"
                    :disabled="isReloading"
                    responsive
                    multiple
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Cтоимость, млн ₽">
                <UiRangeInput
                    name="price"
                    data-gtm-html-id="price"
                    :value-min="filters.priceMin"
                    :value-max="filters.priceMax"
                    :specs="specs.price"
                    :facets="facets.price"
                    show-label
                    :decimal-count="1"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Площадь, м²">
                <UiRangeInput
                    name="area"
                    data-gtm-html-id="area"
                    :value-min="filters.areaMin"
                    :value-max="filters.areaMax"
                    :specs="specs.area"
                    :facets="facets.area"
                    show-label
                    :decimal-count="1"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>
        </div>

        <div :class="$style.column">
            <h4 :class="$style.title">
                Расположение
            </h4>

            <UiInputWrapper label="Урбан-блок">
                <UiFilterDropdownValue
                    data-gtm-html-id="urban_block"
                    :value="createStringValues(filters.urbanBlock, specs.urban_block)"
                    :is-selected="!!createStringValues(filters.urbanBlock, specs.urban_block)"
                    placeholder="Любой"
                    :disabled="isReloading"
                    @click="openModal('urban-block')"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Корпус">
                <UiFilterDropdownValue
                    data-gtm-html-id="building"
                    :value="createStringValues(filters.building, specs.building)"
                    :is-selected="!!createStringValues(filters.building, specs.building)"
                    placeholder="Любой"
                    :disabled="isReloading"
                    @click="openModal('building')"
                />
            </UiInputWrapper>

            <div :class="$style.row">
                <UiInputWrapper label="Секция">
                    <UiFilterDropdownValue
                        data-gtm-html-id="section"
                        :value="createStringValues(filters.section, specs.section)"
                        :is-selected="!!createStringValues(filters.section, specs.section)"
                        placeholder="Любая"
                        :disabled="isReloading"
                        @click="openModal('section')"
                    />
                </UiInputWrapper>

                <UiInputWrapper label="Ключи в">
                    <UiFilterDropdownValue
                        data-gtm-html-id="key-distribution"
                        :value="createStringValues(filters.key_distribution, specs.key_distribution)"
                        :is-selected="!!createStringValues(filters.key_distribution, specs.key_distribution)"
                        placeholder="Не важно"
                        :disabled="isReloading"
                        @click="openModal('key-distribution')"
                    />
                </UiInputWrapper>
            </div>

            <UiInputWrapper label="Этаж">
                <UiRangeInput
                    name="floor"
                    data-gtm-html-id="floor"
                    :specs="specs.floor"
                    :facets="facets.floor"
                    :value-min="filters.floorMin"
                    :value-max="filters.floorMax"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>
        </div>

        <div :class="$style.column">
            <h4 :class="$style.title">
                Преимущества
            </h4>

            <UiInputWrapper label="Вид из окна">
                <FlatsTagsArray
                    name="window_view"
                    data-gtm-html-id="window_view"
                    :value="filters.window_view"
                    :specs="specs.window_view"
                    :facets="facets.window_view"
                    multiple
                    size="normal"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Отделка">
                <FlatsTagsArray
                    name="decoration"
                    data-gtm-html-id="decoration"
                    :value="filters.decoration"
                    :specs="specs.decoration"
                    :facets="facets.decoration"
                    multiple
                    size="normal"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Тип планировки">
                <FlatsTagsArray
                    name="uspLayout"
                    data-gtm-html-id="usp_layout"
                    :value="filters.uspLayout"
                    :specs="specs.usp_layout"
                    :facets="facets.usp_layout"
                    multiple
                    size="normal"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Лоджии">
                <FlatsTagsArray
                    name="uspBalcony"
                    data-gtm-html-id="usp_balcony"
                    :value="filters.uspBalcony"
                    :specs="specs.usp_balcony"
                    :facets="facets.usp_balcony"
                    multiple
                    size="normal"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>

            <UiInputWrapper label="Особенности планировки">
                <FlatsTagsArray
                    name="uspLayoutFeatures"
                    data-gtm-html-id="usp_layout_features"
                    :value="filters.uspLayoutFeatures"
                    :specs="specs.usp_layout_features"
                    :facets="facets.usp_layout_features"
                    multiple
                    size="normal"
                    :disabled="isReloading"
                    @change="changeFilters"
                />
            </UiInputWrapper>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { plural } from '~/assets/js/utils';

import FlatsReadyFilter from '~/components/flats/filters/FlatsReadyFilter.vue';
import UiInputWrapper from '~/components/ui/UiInputWrapper.vue';
import UiFilterDropdownValue from '~/components/ui/dropdowns/filter-dropdown/UiFilterDropdownValue.vue';
import UiRangeInput from '~/components/ui/inputs/range-input/UiRangeInput.vue';
import FlatsTagsArray from '~/components/flats/common/FlatsTagsArray.vue';

import TitleBackCloseHeader from '~/components/common/modals/universal-modal/headers/TitleBackCloseHeader';
import ModalAccordionBody from '~/components/common/modals/universal-modal/bodies/ModalAccordionBody';
import FilterFooter from '~/components/common/modals/universal-modal/footers/FilterFooter';
import FlatsFilterLayout from '~/components/flats/filters/FlatsFilterLayout.vue';

export default {
    name: 'FlatsAdvancedFiltersMobile',

    components: {
        FlatsFilterLayout,
        FlatsReadyFilter,
        UiInputWrapper,
        UiFilterDropdownValue,
        UiRangeInput,
        FlatsTagsArray,
    },

    computed: {
        ...mapGetters({
            totalCount: 'flats/totalCount',
        }),

        ...mapState('flats', [
            'filters',
            'specs',
            'facets',
            'isReloading',
        ]),
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),

        createStringValues(value, specs) {
            if (Array.isArray(value)) {
                return specs
                    .filter(item => value.includes(item.value))
                    .map(item => item.label)
                    .join(', ');
            } else {
                return specs.find(item => item.value === value)?.label;
            }
        },

        createModalTipData(option, name) {
            return {
                componentOptions: {
                    imagePreview: option.imageFlatFilterPreview,
                    image: option.imageFlatFilterDisplay,
                },

                headerOptions: {
                    title: option.label,
                    btnCloseCallback: () => this.openModal(name),
                },
            };
        },

        handleMobileInfoModal(option, name) {
            const { componentOptions, headerOptions } = this.createModalTipData(option, name);

            this.$universalModal.changeData({
                component: () => import('@/components/common/modals/universal-modal/bodies/ImageDescriptionBody.vue'),
                componentOptions: {
                    ...componentOptions,
                },
                header: () => import('@/components/common/modals/universal-modal/headers/TitleBackCloseHeader.vue'),
                headerOptions: {
                    ...headerOptions,
                },
                footer: 'blank',
            });
        },

        openModal(modalType) {
            const reactiveData = () => ({
                section: {
                    componentOptions: {
                        value: this.filters.section,
                        specs: this.specs.section,
                        facets: this.facets.section,
                    },

                    footerOptions: {
                        applyText: this.getApplyTextModal(),
                    },
                },

                'key-distribution': {
                    componentOptions: {
                        value: this.filters.key_distribution,
                        specs: this.specs.key_distribution,
                        facets: this.facets.key_distribution,
                    },

                    footerOptions: {
                        applyText: this.getApplyTextModal(),
                    },
                },

                'urban-block': {
                    componentOptions: {
                        value: this.filters.urbanBlock,
                        specs: this.specs.urban_block,
                        facets: this.facets.urban_block,
                    },

                    footerOptions: {
                        applyText: this.getApplyTextModal(),
                    },
                },

                building: {
                    componentOptions: {
                        value: this.filters.building,
                        specs: this.specs.building,
                        facets: this.facets.building,
                    },

                    footerOptions: {
                        applyText: this.getApplyTextModal(),
                    },
                },
            });

            switch (modalType) {
                case 'section':
                    this.$universalModal.open({
                        component: ModalAccordionBody,
                        componentOptions: {
                            ...reactiveData().section.componentOptions,
                            name: 'section',
                            resetLabel: 'Любая',
                            multiple: true,
                            changeOptionFn: data => {
                                this.changeFilters(data)
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData().section);
                                    });

                                this.$universalModal.changeData(reactiveData().section);
                            },
                        },
                        header: TitleBackCloseHeader,
                        headerOptions: {
                            title: 'Секция',
                        },
                        footer: FilterFooter,
                        footerOptions: {
                            ...reactiveData().section.footerOptions,
                            resetOptionFn: () => {
                                this.changeFilters({ section: [] })
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData().section);
                                    });

                                this.$universalModal.changeData(reactiveData().section);
                            },
                        },
                    });

                    break;

                case 'key-distribution':
                    this.$universalModal.open({
                        component: ModalAccordionBody,
                        componentOptions: {
                            ...reactiveData()['key-distribution'].componentOptions,
                            name: 'key_distribution',
                            resetLabel: 'Не важно',
                            multiple: true,
                            changeOptionFn: data => {
                                this.changeFilters(data)
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData()['key-distribution']);
                                    });

                                this.$universalModal.changeData(reactiveData()['key-distribution']);
                            },
                        },
                        header: TitleBackCloseHeader,
                        headerOptions: {
                            title: 'Ключи в',
                        },
                        footer: FilterFooter,
                        footerOptions: {
                            ...reactiveData()['key-distribution'].footerOptions,
                            resetOptionFn: () => {
                                this.changeFilters({ key_distribution: [] })
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData()['key-distribution']);
                                    });

                                this.$universalModal.changeData(reactiveData()['key-distribution']);
                            },
                        },
                    });

                    break;

                case 'urban-block':
                    this.$universalModal.open({
                        component: ModalAccordionBody,
                        componentOptions: {
                            ...reactiveData()['urban-block'].componentOptions,
                            name: 'urbanBlock',
                            resetLabel: 'Любой',
                            multiple: true,
                            infoModalFn: option => this.handleMobileInfoModal(option, 'urban-block'),
                            changeOptionFn: data => {
                                this.changeFilters(data)
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData()['urban-block']);
                                    });

                                this.$universalModal.changeData(reactiveData()['urban-block']);
                            },
                        },
                        header: TitleBackCloseHeader,
                        headerOptions: {
                            title: 'Убран-блок',
                        },
                        footer: FilterFooter,
                        footerOptions: {
                            ...reactiveData()['urban-block'].footerOptions,
                            resetOptionFn: () => {
                                this.changeFilters({ urbanBlock: [] })
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData()['urban-block']);
                                    });

                                this.$universalModal.changeData(reactiveData()['urban-block']);
                            },
                        },
                    });

                    break;

                case 'building':
                    this.$universalModal.open({
                        component: ModalAccordionBody,
                        componentOptions: {
                            ...reactiveData().building.componentOptions,
                            name: 'building',
                            resetLabel: 'Любой',
                            multiple: true,
                            infoModalFn: option => this.handleMobileInfoModal(option, 'building'),
                            changeOptionFn: data => {
                                this.changeFilters(data)
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData().building);
                                    });

                                this.$universalModal.changeData(reactiveData().building);
                            },
                        },
                        header: TitleBackCloseHeader,
                        headerOptions: {
                            title: 'Корпус',
                        },
                        footer: FilterFooter,
                        footerOptions: {
                            ...reactiveData().building.footerOptions,
                            resetOptionFn: () => {
                                this.changeFilters({ building: [] })
                                    .then(() => {
                                        this.$universalModal.changeData(reactiveData().building);
                                    });

                                this.$universalModal.changeData(reactiveData().building);
                            },
                        },
                    });

                    break;
            }
        },

        getApplyTextModal() {
            const pl = plural(this.totalCount, ['квартира', 'квартиры', 'квартир']);
            return `Применить | ${this.totalCount} ${pl}`;
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsAdvancedFiltersMobile {
        .ready {
            margin-bottom: 2rem;
        }

        .column {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-top: 1px solid $base-200;

            &:last-child {
                padding-bottom: initial;
            }
        }

        .row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            width: 100%;
            column-gap: 1.2rem;
        }

        .title {
            @include l3;

            color: $base-900;
        }
    }
</style>
