// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdvantagesGallery_TRq-v{padding:6.4rem;grid-gap:2rem;gap:2rem}@media(max-width:991.98px){.AdvantagesGallery_TRq-v{padding:8rem 0 7.6rem}}@media(max-width:767.98px){.AdvantagesGallery_TRq-v{padding:3.2rem 0}}.AdvantagesGallery_TRq-v .galleryWrapper_MiNCA{display:-webkit-box;display:-ms-flexbox;display:flex;margin-top:2rem}@media(max-width:991.98px){.AdvantagesGallery_TRq-v .galleryWrapper_MiNCA{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin-top:0;padding:4rem 2.4rem}}@media(max-width:767.98px){.AdvantagesGallery_TRq-v .galleryWrapper_MiNCA{padding:3.2rem 1.6rem}}.AdvantagesGallery_TRq-v .block_dRq-L{width:100%}.AdvantagesGallery_TRq-v .block_dRq-L._video_ovT7W{height:58rem}.AdvantagesGallery_TRq-v .block_dRq-L._gallery_eUCuj{height:32rem}.AdvantagesGallery_TRq-v .block_dRq-L._gallery_eUCuj:first-child{-webkit-box-flex:2;-ms-flex:2 1 auto;flex:2 1 auto}.AdvantagesGallery_TRq-v .block_dRq-L._gallery_eUCuj:last-child{-webkit-box-flex:1;-ms-flex:1 2 auto;flex:1 2 auto;margin:0 0 0 1.6rem}@media(max-width:991.98px){.AdvantagesGallery_TRq-v .block_dRq-L._gallery_eUCuj:last-child{margin:1.6rem 0 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvantagesGallery": "AdvantagesGallery_TRq-v",
	"galleryWrapper": "galleryWrapper_MiNCA",
	"block": "block_dRq-L",
	"_video": "_video_ovT7W",
	"_gallery": "_gallery_eUCuj"
};
module.exports = ___CSS_LOADER_EXPORT___;
