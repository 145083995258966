// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenplanPinLocation_\\+gy-S{position:absolute;z-index:3;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:2.4rem;padding:.8rem;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-color:#f4f4f4;text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:.8;pointer-events:all}.GenplanPinLocation_\\+gy-S .direction_yCj4L,.GenplanPinLocation_\\+gy-S .icon_Evx\\+k{width:1.6rem;height:1.6rem;-o-object-fit:contain;object-fit:contain}.GenplanPinLocation_\\+gy-S .station_4egox{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:.4rem;color:#2f2f2f}.GenplanPinLocation_\\+gy-S .station_4egox .text_OK4d1{margin-left:.4rem}.GenplanPinLocation_\\+gy-S .distance_O7nHC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:.4rem;color:#2f2f2f}.GenplanPinLocation_\\+gy-S .distance_O7nHC .text_OK4d1{margin-left:.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GenplanPinLocation": "GenplanPinLocation_+gy-S",
	"direction": "direction_yCj4L",
	"icon": "icon_Evx+k",
	"station": "station_4egox",
	"text": "text_OK4d1",
	"distance": "distance_O7nHC"
};
module.exports = ___CSS_LOADER_EXPORT___;
