<template>
    <div
        ref="HomeAdvantages"
        :class="$style.Advantages"
    >
        <AdvantagesText
            :class="$style.text"
            class="container-home"
        />

        <AdvantagesGallery
            :gallery="gallery"
            :video="video"
            :video-image="videoImage"
        />

        <div :class="$style.bg" />

        <div ref="HomeAdvantagesListWrap">
            <AdvantagesList
                :accessibility="accessibility"
                :class="$style.list"
                class="container-home"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min.js';
import AdvantagesList from '~/components/home/advantages/AdvantagesList';
import AdvantagesText from '~/components/home/advantages/AdvantagesText.vue';
import AdvantagesGallery from '~/components/home/advantages/AdvantagesGallery.vue';

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'Advantages',

    components: {
        AdvantagesGallery,
        AdvantagesText,
        AdvantagesList,
    },

    props: {
        cards: {
            type: Array,
            default: () => [],
        },

        gallery: {
            type: Array,
            default: () => [],
        },

        video: {
            type: Array,
            default: () => [],
        },

        videoImage: {
            type: Object,
            default: () => ({}),
        },

        accessibility: {
            type: Array,
            default: () => [],

        },
    },

    data() {
        return {
            isBgShown: false,
        };
    },

    computed: {
        ...mapGetters({
            isWebpSup: 'device/getIsWebpSupported',
        }),

        videoImageLink() {
            const ex = this.isWebpSup ? 'webp' : 'png';

            return this.$image.gen(this.videoImage.imageDisplay, { q: 90, fit: false, ex: ex }) || '';
        },

    },
};
</script>

<style lang="scss" module>
    .Advantages {
        position: relative;
        z-index: 1;
        max-width: 1440px;
        margin: 0 auto;

        .gallery {
            z-index: 2;
        }

        .bg {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 3;
            visibility: hidden;
            width: 100vw;
            height: 100vh;
            background-color: $inversed;
            opacity: 0;
            transition: .3s ease;
        }

        .list {
            z-index: 4;
        }

        .text {
            margin-bottom: 6.4rem;
        }
    }
</style>
