<template>
    <div
        :class="$style.HomeHeroAnimation"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
    >
        <div
            ref="slider"
            :class="$style.container"
            class="swiper-container"
        >
            <div class="swiper-wrapper">
                <div
                    v-for="slide in filteredSlides"
                    :key="slide.id"
                    :class="slide.classes"
                    class="swiper-slide"
                >
                    <picture v-if="slide.sources">
                        <source media="(max-width: 480px)" :srcset="slide.sources.mobile">
                        <source media="(min-width: 481px) and (max-width: 992px)" :srcset="slide.sources.tablet">
                        <source media="(min-width: 993px) and (max-width: 1440px)" :srcset="slide.sources.desktopSmall">
                        <source media="(min-width: 1441px)" :srcset="slide.sources.desktop">

                        <!-- Загрузится, если ни одно из условий не выполнено -->
                        <img
                            v-if="slide.image"
                            :src="slide.sources.desktop"
                            :alt="slide.image.alt"
                            :class="slide.image.classes"
                        >
                    </picture>

                    <div v-if="slide.petal && slide.title" :class="$style.content">
                        <h2 :class="$style.title" v-html="slide.title" />

                        <UiButton
                            v-if="slide.contentButton && slide.contentButton.exists"
                            :color="slide.contentButton.color"
                            size="big"
                            :class="slide.contentButton.classes"
                        >
                            {{ slide.contentButton.text }}
                        </UiButton>

                        <div :class="$style.petal">
                            <PetalIcon :class="$style.petalIcon"/>
                        </div>
                    </div>

                    <UiButton
                        v-if="slide.button.exists"
                        :color="slide.button.color"
                        :class="slide.button.classes"
                    >
                        {{ slide.button.text }}
                    </UiButton>

                    <NuxtLink
                        v-if="slide.link.exists"
                        :to="slide.link.to"
                        :class="slide.link.classes"
                    >
                    </NuxtLink>
                </div>
            </div>

            <UiCircleButton
                v-show="$device.isDesktop && !isIpadPro"
                ref="prev"
                :class="[$style.btn, $style._prev, {[$style._locked]:isBeginning}]"
                color="white"
            >
                <ArrowLeftSolid/>
            </UiCircleButton>

            <UiCircleButton
                v-show="$device.isDesktop && !isIpadPro"
                ref="next"
                :class="[$style.btn, $style._next, {[$style._locked]:isEnd}]"
                color="white"
            >
                <ArrowLeftSolid :class="$style.reverse"/>
            </UiCircleButton>

            <UiPagination
                :tabs="[1, 2, 3]"
                :active-index="activeIndex"
                :expanded="hovered"
                :class="$style.tabs"
                :speed="1000"
                :duration="5000"
                :has-design-in-mobile="false"
                class="swiper-pagination"
                @click:tab="onClickTab"
            />
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

import { mapGetters } from 'vuex';
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';

// icons
import ArrowLeftSolid from '~/assets/icons/arrow-left-solid.svg?inline';
import PetalIcon from '~/assets/icons/petal_autumn.svg?inline';
import UiButton from '~/components/ui/buttons/UiButton.vue';
import UiPagination from '~/components/ui/paginations/UiPagination.vue';
import { BANNERS_TEXT_CONTENT, PURCHASE_PAGE_LINKS } from '~/assets/js/constants';

const TEMPLATE_DISCOUNT_TEXT = 'Скидка до 15%';
const TEMPLATE_DISCOUNT_ITEM_TEXT = 'на квартиры месяца';
const TEMPLATE_SALES_LINK_ADDRESS = '/flats/?sale=1';

export default {
    name: 'HomeHeroAnimation',

    components: {
        UiPagination,
        UiButton,
        UiCircleButton,
        ArrowLeftSolid,
        PetalIcon,
    },

    props: {
        slides: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            sliderApp: null,
            hovered: false,
            discountText: TEMPLATE_DISCOUNT_TEXT,
            discountItemText: TEMPLATE_DISCOUNT_ITEM_TEXT,
            salesLink: TEMPLATE_SALES_LINK_ADDRESS,

            homeHeroSlides: [
                {
                    id: 1,
                    visible: false,
                    classes: [this.$style.slide, this.$style._blackFriday],

                    sources: {
                        mobile: '/images/pages/home/hero/black-friday/slide-m.png',
                        tablet: '/images/pages/home/hero/black-friday/slide-t.png',
                        desktop: '/images/pages/home/hero/black-friday/slide-d.png',
                        desktopSmall: '/images/pages/home/hero/black-friday/slide-d.png',
                    },

                    petal: false,
                    title: null,

                    image: {
                        alt: BANNERS_TEXT_CONTENT.BLACK_FRIDAY_ALT,
                        classes: [this.$style.image],
                    },

                    button: {
                        exists: true,
                        color: 'white',
                        classes: [this.$style.button],
                        text: BANNERS_TEXT_CONTENT.INSTALLMENT_BUTTON,
                    },

                    link: {
                        exists: false,
                        to: '',
                        classes: [this.$style.link],
                    },
                },
                {
                    id: 2,
                    visible: true,
                    classes: [this.$style.slide],

                    sources: {
                        mobile: '/images/pages/home/hero/home-hero-5-mobile.png',
                        tablet: '/images/pages/home/hero/home-hero-5-tablet.png',
                        desktop: '/images/pages/home/hero/home-hero-5-desktop.png',
                        desktopSmall: '/images/pages/home/hero/home-hero-5-desktop.png',
                    },

                    petal: false,
                    title: null,

                    image: {
                        alt: BANNERS_TEXT_CONTENT.RIVER_PROJECT_ALT,
                        classes: [this.$style.image, this.$style._topPosition],
                    },

                    button: {
                        exists: false,
                        color: '',
                        classes: [this.$style.button],
                        text: '',
                    },

                    link: {
                        exists: true,
                        to: '/project/river-1/',
                        classes: [this.$style.link],
                    },
                },

                {
                    id: 3,
                    visible: true,
                    classes: [this.$style.slide, this.$style.minimalisticSlide],

                    sources: null,

                    petal: true,
                    title: `${BANNERS_TEXT_CONTENT.DISCOUNT_TEXT} <br> <span>${BANNERS_TEXT_CONTENT.DISCOUNT_ITEM_TEXT}</span>`,

                    image: {
                        alt: BANNERS_TEXT_CONTENT.RIVER_PROJECT_ALT,
                        classes: [this.$style.image, this.$style._topPosition],
                    },

                    button: {
                        exists: false,
                        color: '',
                        classes: [],
                        text: '',
                    },

                    contentButton: {
                        exists: true,
                        color: 'black',
                        classes: [this.$style.darkButton],
                        text: BANNERS_TEXT_CONTENT.FLATS_BUTTON,
                    },

                    link: {
                        exists: true,
                        to: PURCHASE_PAGE_LINKS.installment,
                        classes: [this.$style.link],
                    },
                },

                {
                    id: 4,
                    visible: true,
                    classes: [this.$style.slide],

                    sources: {
                        mobile: '/images/pages/home/hero/new/slide-m.jpg',
                        tablet: '/images/pages/home/hero/new/slide-t.jpg',
                        desktop: '/images/pages/home/hero/new/slide-d.jpg',
                        desktopSmall: '/images/pages/home/hero/new/slide-ds.jpg',
                    },

                    petal: false,
                    title: null,

                    image: {
                        alt: BANNERS_TEXT_CONTENT.INSTALLMENT_ALT,
                        classes: [this.$style.image],
                    },

                    button: {
                        exists: true,
                        color: 'white',
                        classes: [this.$style.button],
                        text: BANNERS_TEXT_CONTENT.INSTALLMENT_BUTTON,
                    },

                    link: {
                        exists: true,
                        to: PURCHASE_PAGE_LINKS.installment,
                        classes: [this.$style.link],
                    },
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        isBeginning() {
            return this.sliderApp?.isBeginning ?? false;
        },

        isEnd() {
            return this.sliderApp?.isEnd ?? false;
        },

        activeIndex() {
            return this.sliderApp?.realIndex;
        },

        filteredSlides() {
            return this.homeHeroSlides.filter(el => el.visible);
        },
    },

    mounted() {
        this.initSlider();
    },

    beforeDestroy() {
        if (this.sliderApp) {
            this.sliderApp.destroy();
            this.sliderApp = null;
        }
    },

    methods: {
        initSlider() {
            const options = {
                slidesPerView: 1,
                speed: 1000,
                allowTouchMove: !(this.$device.isDesktop && !this.isIpadPro),
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },

                navigation: {
                    prevEl: this.$refs.prev.$el,
                    nextEl: this.$refs.next.$el,
                },
            };

            this.sliderApp = new Swiper(this.$refs.slider, options);
        },

        mouseOver() {
            this.hovered = true;
        },

        mouseLeave() {
            this.hovered = false;
        },

        onClickTab(i) {
            this.sliderApp.slideTo(i);
        },

        onBlackFridayClick() {
            this.$router.replace({
                path: '/flats',
                query: {
                    isBlackFriday: true,
                    orderBy: '-is_black_friday',
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
    .HomeHeroAnimation {
        position: relative;
        display: block;
        width: 100%;
        height: 75vh;
        background-color: $base-50;
        cursor: pointer;

        @include respond-to(sm) {
            height: calc(100vh - $headerFullHeightMobile - $headerBannerHeight);
            min-height: 100%;
        }

        .container {
            width: 100%;
            height: 100%;
        }

        .slide {
            position: relative;
            width: 100%;
            height: auto;

            &._blackFriday {
                .image {
                    object-position: center center;
                }
            }
        }

        .slider {
            position: relative;
            overflow: hidden;
        }

        .minimalisticSlide {
            padding-right: 14rem;
            padding-left: 11.5rem;
            background-color: $yellow-light;

            @include respond-to(lg) {
                padding-right: 9.5rem;
                padding-left: 9.5rem;
            }

            @include respond-to(md) {
                padding-right: 5.6rem;
                padding-left: 5.6rem;
            }

            @include respond-to(sm) {
                padding-right: 2.4rem;
                padding-left: 2.4rem;
            }
        }

        .content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 75%;
        }

        .title {
            margin-bottom: 6rem;
            text-transform: uppercase;
            font-size: 8.1rem;
            font-weight: 700;
            line-height: 1;

            @include respond-to(md) {
                font-size: 7rem;
            }

            @include respond-to(sm) {
                margin-bottom: 8rem;
                font-size: 6rem;
            }

            @include respond-to(xxs) {
                font-size: 3.4rem;
            }

            span {
                font-size: 5.4rem;

                @include respond-to(lg) {
                    font-size: 4.6rem;
                }

                @include respond-to(md) {
                    font-size: 4rem;
                }

                @include respond-to(xxs) {
                    font-size: 2.2rem;
                }
            }
        }

        .petal {
            position: absolute;
            right: 4rem;
            bottom: 8.6rem;
            display: flex;
            align-items: center;
            width: 9.6rem;
            height: 18rem;

            @include respond-to(sm) {
                bottom: 14rem;
                width: 6.6rem;
                height: 12.3rem;
            }

            @include respond-to(xxs) {
                top: 3.2rem;
                right: 2rem;
                bottom: auto;
                width: 4.8rem;
                height: 9rem;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 94%;
                height: 100%;
                background-color: $base-0;
                transform: skewX(-20deg) translateX(34%);
            }
        }

        .petalIcon {
            position: relative;
            z-index: 1;
            display: block;
            width: 100%;
            height: auto;
        }

        .btn {
            position: absolute;
            top: 50%;
            z-index: 4;
            transform: translateY(-50%);

            @include respond-to(xs) {
                display: none;
            }

            &._prev {
                left: 3.2rem;
            }

            &._next {
                right: 3.2rem;
            }

            &._locked {
                opacity: .8;
            }
        }

        .link {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
        }

        .reverse {
            transform: rotate(180deg);
        }

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left center;

            &._topPosition {
                object-position: left top;
            }
        }

        .button {
            position: absolute;
            bottom: 3.4rem;
            left: 1.6rem;
            display: none;
            width: calc(100% - 3.2rem);
            min-width: calc(100% - 3.2rem);

            @include respond-to(sm) {
                display: flex;
            }
        }

        .darkButton {
            width: fit-content;

            @include respond-to(sm) {
                display: none;
            }
        }

        .tabs {
            position: absolute;
            bottom: 1.6rem;
            left: 50%;
            z-index: 4;
            padding-right: $homeDesktopPadding;
            padding-left: $homeDesktopPadding;
            transform: translateX(-50%);
        }

        .picture {
            position: relative;
        }
    }
</style>
