<template>
    <div
        ref="Genplan"
        :class="[$style.Genplan, classes]"
    >
        <!-- скролящийся контейнер -->
        <div
            ref="GenplanContainer"
            v-touch:swipe.left="onOverlayClick"
            v-touch:swipe.right="onOverlayClick"
            :class="$style.container"
            @scroll="onHorizontalScroll"
            @click="onOverlayClick"
        >
            <!-- обертка с картой, обводками и подписями -->
            <div
                :class="[$style.wrap, {[$style._loaded]: isLoaded}]"
                :style="position"
            >
                <!-- Наше основное изображение -->
                <img
                    ref="GenplanImage"
                    loading="lazy"
                    :class="[$style.image, {[$style._loaded]: isLoaded, [$style._highlighted]: highlighted}]"
                    :src="image"
                    alt="Генплан"
                    @load="onImageLoad"
                >

                <!-- Когда наводим на здание, сюда вставляется обводка -->
                <svg
                    v-if="$device.isDesktop"
                    :class="[$style.svg, $style.clipPath]"
                    :viewBox="`0 0 ${width} ${height}`"
                >
                    <defs>
                        <clipPath
                            id="SVG_BUILDING"
                            v-html="clipPath"
                        />
                    </defs>

                    <!-- А это нужно для выделения обводки на фоне нашего изображения -->
                    <image
                        :xlink:href="image"
                        x="0"
                        y="0"
                        width="100%"
                        height="100%"
                        style="clip-path: url('#SVG_BUILDING');"
                    />
                </svg>

                <!-- Все наши обводки зданий (прозрачные) -->
                <svg
                    :class="$style.svg"
                    :viewBox="`0 0 ${width} ${height}`"
                >

                    <!-- Обводки урбан-блоков -->
                    <g
                        v-for="object in objects"
                        :key="`outline-${object.id}`"
                        :class="[$style.outline, {
                            [$style._hover]: highlighted && highlighted === object.id,
                            [$style._outline]: object.isSalesStart,
                            [$style._hide]: highlighted && highlighted !== object.id
                        }]"
                        @mouseenter="onLabelMouseEnter(object)"
                        @mouseleave="onLabelMouseLeave"
                        @click.stop="onObjectClick(object)"
                        v-html="object.label.highlighting"
                    />

                    <!-- Обводки урбан-блоков предпродаж -->
                    <g
                        v-for="presale in presales"
                        :key="presale.id"
                        :class="[$style.outline, {
                            [$style._hover]: highlighted && highlighted === presale.id,
                            [$style._hide]: highlighted && highlighted !== presale.id
                        }]"
                        @mouseenter="onLabelMouseEnter(presale)"
                        @mouseleave="onLabelMouseLeave"
                        @click="onPresaleClick(presale)"
                        v-html="presale.label.highlighting"
                    />
                </svg>

                <div
                    v-show="isLoaded"
                    :class="$style.labels"
                >
                    <!--TODO сразу же удалить как только все необходимые данные придут по фиду и будут в админке https://collab.idaproject.com/my-work?modal=Task-133875-309-->
                    <template
                        v-for="object in riverObject"
                    >
                        <template v-if="object.label && object.label.name && $route.path === riverRoute">
                            <UiTag
                                v-if="!object?.isSalesStart"
                                :id="object.id"
                                :key="`tag-${object.id}`"
                                size="extra-small"
                                :style="getTagStyles(object.label)"
                                :class="[$style.label, {[$style._hide]: highlighted && highlighted !== object.id}]"
                            >
                                {{ object.label.name }}

                                <template
                                    v-if="object.label.icon"
                                    #iconAfter
                                >
                                    <component :is="object.label.icon" />
                                </template>
                            </UiTag>
                        </template>
                    </template>

                    <template
                        v-for="object in objects"
                    >
                        <template v-if="object.label && object.label.highlighting && object.label.name">
                            <UiTag
                                v-if="!object?.isSalesStart"
                                :id="object.id"
                                :key="`tag-${object.id}`"
                                size="extra-small"
                                :hover="highlighted === object.id"
                                :color="object.label.isInfrastructure ? 'black' : 'light'"
                                :style="getTagStyles(object.label)"
                                :class="[$style.label, {[$style._hide]: highlighted && highlighted !== object.id}]"
                                @mouseenter="onLabelMouseEnter(object)"
                                @mouseleave="onLabelMouseLeave"
                                @click="onObjectClick(object)"
                            >
                                {{ object.label.name }}

                                <template
                                    v-if="object.label.icon"
                                    #iconAfter
                                >
                                    <component :is="object.label.icon" />
                                </template>
                            </UiTag>
                            <GenplanBuildingLabel
                                v-else
                                :id="object.id"
                                :key="`tag-${object.id}`"
                                :icon="object.label.icon"
                                :hover="highlighted === object.id"
                                :style="getTagStyles(object.label)"
                                :class="[$style.label, {[$style._hide]: highlighted && highlighted !== object.id}]"
                                @mouseenter="onLabelMouseEnter(object)"
                                @mouseleave="onLabelMouseLeave"
                                @click="onObjectClick(object)"
                            >
                                <template v-if="object.isSalesStart" #header>Старт продаж</template>
                                <template v-if="object.isSalesStart" #default>{{ object.label.name }}</template>
                            </GenplanBuildingLabel>
                        </template>
                    </template>

                    <!-- лейблы к УБ предпродаже -->
                    <template v-for="presale in presales">
                        <UiTag
                            :id="presale.id"
                            :key="`tag-${presale.id}`"
                            size="extra-small"
                            :hover="highlighted === presale.id"
                            :style="getTagStyles(presale.label)"
                            :class="[$style.presale, {[$style._hide]: highlighted && highlighted !== presale.id}]"
                            @mouseenter="onLabelMouseEnter(presale)"
                            @mouseleave="onLabelMouseLeave"
                            @click="onPresaleClick(presale)"
                        >
                            {{ presale.label.name }}

                            <template
                                v-if="presale.label.icon"
                                #iconAfter
                            >
                                <component :is="presale.label.icon" />
                            </template>
                        </UiTag>
                    </template>

                    <!-- лейблы к инфраструктуре -->
                    <template v-if="infrastructures.length">
                        <GenplanPinInfrastructure
                            v-for="infrastructure in infrastructures"
                            :key="infrastructure.id"
                            :info="infrastructure.label"
                            :class="$style.infrastructure"
                            :styles-value="getTagStyles(infrastructure.label)"
                            @click="handleInfrastructureModal"
                        />
                    </template>

                    <!-- лейблы к указателям -->
                    <template v-if="locations.length">
                        <GenplanPinLocation
                            v-for="location in locations"
                            :key="location.id"
                            :info="location.label"
                            :class="$style.location"
                            :styles-value="getTagStyles(location.label)"
                        />
                    </template>

                    <slot
                        name="labels"
                        :scale="shieldScale"
                        :highlighted="Boolean(highlighted)"
                    />
                </div>
            </div>

            <!-- Тултип при наведении на лейбл -->
            <GenplanTooltipWrap
                v-if="highlighted && !isPresaleType && $device.isDesktop"
                :top="activeLabelOffsetTop"
                :left="activeLabelOffsetLeft"
                :width="activeLabelWidth"
                :class="$style.tooltip"
                @mouseenter="onTooltipMouseEnter"
                @mouseleave="onTooltipMouseLeave"
            >
                <!-- Пробрасываем компонент тултипа через родительский компонент -->
                <slot
                    name="tooltip"
                    :data="tooltipProps"
                />
            </GenplanTooltipWrap>
        </div>

        <!-- всё, что поверх ГП (кнопки, текста, табы и т.п.) -->
        <transition name="fade">
            <div v-show="isLoaded" :class="$style.attachments">
                <slot
                    name="attachments"
                    :disabled="isOverlayVisible"
                />
            </div>
        </transition>

        <!-- Оверлей для планшета и мобилки -->
        <transition name="fade">
            <GenplanOverlay
                v-if="isLoaded"
                :visible="isOverlayVisible"
                :class="$style.overlay"
            />
        </transition>
    </div>
</template>

<script>
import UiTag from '~/components/ui/tags/UiTag.vue';
import GenplanOverlay from '~/components/genplan/GenplanOverlay.vue';
import GenplanTooltipWrap from '~/components/genplan/GenplanTooltipWrap.vue';
import GenplanBuildingLabel from '~/components/old-genplan/GenplanBuildingLabel.vue';
import GenplanPinLocation from '~/components/genplan/GenplanPinLocation.vue';
import GenplanPinInfrastructure from '~/components/genplan/GenplanPinInfrastructure.vue';

import { mapGetters, mapState } from 'vuex';

// TODO сразу же удалить как только все необходимые данные придут по фиду и будут в админке https://collab.idaproject.com/my-work?modal=Task-133875-309
const RIVER_1_ROUTE = '/project/river-1/';
const RIVER_1_OBJECT = [{
    label: {
        left: '38',
        name: 'СТАРТ БРОНИРОВАНИЯ | LIGHTHOUSE 1',
        top: '72',
    },
}, {
    label: {
        left: '36',
        name: 'СТАРТ БРОНИРОВАНИЯ | LIGHTHOUSE 2',
        top: '78',
    },
}];

export default {
    name: 'Genplan',

    components: {
        UiTag,
        GenplanOverlay,
        GenplanTooltipWrap,
        GenplanBuildingLabel,
        GenplanPinLocation,
        GenplanPinInfrastructure,
    },

    props: {
        type: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            required: true,
        },

        preview: {
            type: String,
            required: true,
        },

        width: {
            type: Number,
            required: true,
        },

        height: {
            type: Number,
            required: true,
        },

        objects: {
            type: Array,
            default: () => [],
        },

        presales: {
            type: Array,
            default: () => [],
        },

        infrastructures: {
            type: Array,
            default: () => [],
        },

        locations: {
            type: Array,
            default: () => [],
        },

        // скрывает тег и делает белую обводку
        isHideTagStyle: {
            type: Boolean,
            default: false,
        },

        // Цвет обводок и лэйблов
        color: {
            typt: String,
            default: 'yellow',
            validator: value => ['default', 'yellow'].includes(value),
        },
    },

    data() {
        return {
            iw: 0,
            ih: 0,
            ww: 0,
            wh: 0,

            isLoaded: false,
            isOverlayVisible: false,

            isTooltipHover: false,
            isLabelHover: false,

            highlighted: null,
            tooltipProps: null,
            clipPath: '',
            activeLabelOffsetTop: 0,
            activeLabelOffsetLeft: 0,
            activeLabelWidth: 0,
            scrollLeft: 0,

            timer: null,
            objectType: '',
            riverObject: RIVER_1_OBJECT,
            riverRoute: RIVER_1_ROUTE,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        ...mapState('device', ['isTouch']),

        classes() {
            return {
                [this.$style._isHideTagStyle]: this.isHideTagStyle,
                [this.$style[`_${this.type}`]]: this.type,
                [this.$style[`_${this.color}`]]: this.color,
            };
        },

        imageRatio() {
            if (this.iw && this.ih) {
                return this.iw / this.ih;
            }

            return 0;
        },

        windowRatio() {
            if (this.ww && this.wh) {
                return this.ww / this.wh;
            }

            return 0;
        },

        ratio() {
            return this.windowRatio - this.imageRatio;
        },

        scale() {
            if (this.imageRatio && this.windowRatio) {
                return this.ratio > 0 ? this.ww / this.iw : this.wh / this.ih;
            }

            return 1;
        },

        position() {
            return {
                width: this.iw ? `${this.iw}px` : '100%',
                height: this.ih ? `${this.ih}px` : '100%',
                backgroundImage: `url(${this.preview})`,
                transform: `scale(${this.scale})`,
            };
        },

        shieldScale() {
            return 1 / this.scale;
        },

        isPresaleType() {
            return this.objectType === 'presale';
        },
    },

    mounted() {
        if (!this.$device.isDesktop) {
            this.isOverlayVisible = true;
        }

        // window.addEventListener('resize', this.initCalculateSizes);
    },

    activated() {
        this.$nextTick(() => {
            this.$refs.GenplanContainer.scrollLeft = this.scrollLeft;
        });
    },

    beforeDestroy() {
        // window.removeEventListener('resize', this.initCalculateSizes);
        this.$root.$off('refreshScrollTrigger', this.onRefreshScrollTrigger);
    },

    methods: {
        onRefreshScrollTrigger() {
            this.$refs.GenplanContainer.scrollLeft = this.scrollLeft;
        },

        onObjectClick(object) {
            if (!(this.$device.isDesktop && !this.isIpadPro && this.isTouch)) {
                this.$emit('onObjectClick', object.id);
            }
        },

        onLabelMouseEnter(object) {
            if (this.$device.isDesktop) {
                this.isLabelHover = true;

                if (this.timer) { // в зоне между обводкой и тултипом может быть другая обводка и onLabelMouseEnter срабатывает дважды, поэтому очищаем timer, чтобы createTooltip не выполнялся дважды и не было мерцания тултипа
                    clearTimeout(this.timer);
                }

                this.timer = setTimeout(() => { // 0.3s чтобы успеть навести на тултип
                    this.objectType = object?.type;

                    if (this.highlighted !== object.id && !this.isTooltipHover) {
                        this.createTooltip(object);
                    }
                }, 300);
            }
        },

        onLabelMouseLeave() {
            if (this.$device.isDesktop) {
                this.isLabelHover = false;

                setTimeout(() => { // если после 0.3s никуда не навели, то убираем тултип
                    if (!this.isLabelHover && !this.isTooltipHover) {
                        this.destroyTooltip();

                        if (this.timer) { // если случайно провели над другой обводкой, а потом убрали курсор, сбрасываем таймер, чтобы не было резкого появления и пропадание тултипа
                            clearTimeout(this.timer);
                        }
                    }
                }, 300);
            }
        },

        onTooltipMouseEnter() {
            this.isTooltipHover = true;
        },

        onTooltipMouseLeave() {
            this.isTooltipHover = false;

            setTimeout(() => { // если после 0.3s никуда не навели, то убираем тултип
                if (!this.isLabelHover && !this.isTooltipHover) {
                    this.destroyTooltip();
                }
            }, 300);
        },

        createTooltip(object) {
            const presale = object?.type === 'presale';

            this.highlighted = object.id;
            this.clipPath = object.label.highlighting;
            this.tooltipProps = !presale && object.tooltip;
            this.activeLabelWidth = !presale && document.getElementById(object.id).offsetWidth;
            this.activeLabelOffsetLeft = !presale && Number(object.label.left);
            this.activeLabelOffsetTop = !presale && Number(object.label.top);
        },

        destroyTooltip() {
            this.highlighted = null;
            this.tooltipProps = null;
            this.clipPath = '';
            this.activeLabelWidth = 0;
            this.activeLabelOffsetLeft = 0;
            this.activeLabelOffsetTop = 0;
        },

        onImageLoad() {
            this.initCalculateSizes();
        },

        // сначала перерасчитываем размеры, потом если картинка не влазит по высоте (ratio > 0),
        // то устанавливаем минимальную высоту и еще раз перерасчитываем размеры, т.к. scale изменился
        initCalculateSizes() {
            this.$parent.$el.style.height = getComputedStyle(this.$parent.$el).height;

            this.calculateSizes();

            if (this.ratio > 0) {
                this.$parent.$el.style.height = `${this.ih * this.scale}px`;
            } else {
                this.$parent.$el.style.height = getComputedStyle(this.$parent.$el).height;
            }

            this.$nextTick(() => {
                this.calculateSizes();

                if (!this.isLoaded) {
                    this.$emit('loaded');
                    this.isLoaded = true;
                }

                this.$nextTick(() => {
                    this.centerGenplan();
                });
            });
        },

        calculateSizes() {
            this.iw = this.$refs.GenplanImage.clientWidth;
            this.ih = this.$refs.GenplanImage.clientHeight;
            this.ww = this.$refs.GenplanContainer.clientWidth;
            this.wh = this.$refs.GenplanContainer.clientHeight;
        },

        centerGenplan() {
            this.scrollLeft = (this.$refs.GenplanContainer.scrollWidth - this.$refs.GenplanContainer.clientWidth) / 2;

            if (this.scrollLeft > 0) {
                this.$refs.GenplanContainer.scrollLeft = this.scrollLeft;

                this.$nextTick(() => {
                    this.$root.$on('refreshScrollTrigger', this.onRefreshScrollTrigger);
                });
            }
        },

        getTagStyles(label) {
            const styles = label?.styles || {};
            return {
                left: `${label.left}%`,
                top: `${label.top}%`,
                transform: `translate(-50%, -50%) scale(${this.shieldScale})`,
                ...styles,
            };
        },

        onHorizontalScroll() {
            this.scrollLeft = this.$refs.GenplanContainer.scrollLeft;
        },

        onOverlayClick() {
            if (this.isOverlayVisible) {
                this.isOverlayVisible = false;
            }
        },

        onPresaleClick(object) {
            if (object && object.slug) {
                window.open(object.slug, '_blank');
            }
        },

        handleInfrastructureModal(info) {
            if (this.$device.isDesktop) {
                return;
            }

            const modalData = {
                header: () => import('~/components/common/modals/universal-modal/headers/TitleHeader.vue'),
                headerOptions: {
                    title: info.name,
                    mobileTitle: info.name,
                },

                footer: () => import('~/components/common/modals/universal-modal/footers/GrayBlackButtonsFooter.vue'),
                footerOptions: {
                    leftBtnClick: () => this.$universalModal.close(),
                    leftBtnText: 'Закрыть',
                },
            };

            if (info.text) {
                modalData.component = () => import('~/components/common/modals/universal-modal/bodies/TitleTextBody.vue');
                modalData.componentOptions = {
                    text: info.text,
                };
            }

            this.$universalModal.open({
                ...modalData,
            });
        },
    },
};
</script>

<style lang="scss" module>
    .Genplan {
        position: relative;
        width: 100%;
        height: 100%;

        &._isHideTagStyle {
            .clipPath {
                display: none;
            }

            .outline {
                &._hover,
                &:hover {
                    fill: rgba($base-0, .32);
                    stroke: $base-0;
                    stroke-width: .1rem;
                }
            }

            .image._highlighted {
                opacity: 1;
            }

            .label {
                width: .5rem;
                min-width: auto;
                height: .5rem;
                min-height: auto;
                padding: 0;
                font-size: 0;
                opacity: 0;
            }
        }

        &._section {
            .outline {
                &._hover,
                &:hover {
                    fill: rgba(55, 55, 66, .32);
                    stroke-width: 1px;
                    stroke: $base-0;
                }
            }
        }

        &._floor {
            .outline {
                fill: rgba(255, 255, 255, .32);
                stroke-width: 1px;
                stroke: $base-0;
                transition: stroke .3s ease, fill .3s ease, stroke-width .3s ease;

                &._hover,
                &:hover {
                    fill: rgba(55, 55, 66, .32);
                    stroke-width: 1px;
                    stroke: $base-0;
                }
            }
        }

        &._yellow {
            .presale,
            .label {
                border-color: $yellow-light;
                background-color: $yellow-light;
                color: $base-900;
            }

            .outline,
            ._hidden,
            ._hover {
                transition: all $homeTransition;
            }

            .outline {
                fill: transparent;
                cursor: pointer;

                &._hover {
                    fill: rgba($yellow-light, .24);
                    stroke: $yellow-light;
                    stroke-width: .2rem;

                    @include respond-to(sm) {
                        stroke-width: .3rem;
                    }
                }

                &._hide {
                    stroke: transparent;
                }
            }

            &._section {
                .outline {
                    &._hover,
                    &:hover {
                        fill: rgba($yellow-light, .24);
                        stroke-width: .2rem;
                        stroke: $yellow-light;
                    }
                }
            }

            &._floor {
                .outline {
                    fill: rgba($yellow-light, .24);
                    stroke-width: .2rem;
                    stroke: $yellow-light;

                    &._hover,
                    &:hover {
                        fill: rgba(55, 55, 66, .32);
                        stroke-width: .2rem;
                        stroke: $yellow-light;
                    }
                }
            }
        }

        .container {
            @include remove-scrollbar;

            position: relative;
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            height: 100%;
        }

        .wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transform-origin: 0 0;
            transition: background-color $transition;

            &._loaded {
                background-color: $base;
                background-image: none !important; //stylelint-disable-line declaration-no-important
            }
        }

        .image {
            position: relative;
            z-index: -1;
            visibility: hidden;

            &._loaded {
                z-index: 1;
                visibility: visible;
            }

            &._highlighted {
                opacity: .5;
            }
        }

        .svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
            width: 100%;
            height: 100%;
            transition: transform $transition;
        }

        .labels {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .presale,
        .label {
            position: absolute;
            width: fit-content;
            min-width: 3.4rem;
            max-width: fit-content;
            white-space: nowrap;
            font-size: 1.2rem;
            line-height: .8rem;
            pointer-events: all;

            &._hide {
                visibility: hidden;
                opacity: 0;
            }
        }

        .presale {
            min-width: 11rem;
            min-height: 2.8rem;

            &:before {
                content: 'Скоро в продаже';
                position: absolute;
                top: 0;
                left: -1px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 2rem;
                border: 1px solid $base-0;
                background-color: $base-0;
                text-align: center;
                text-transform: uppercase;
                white-space: nowrap;
                font-size: 1rem;
                line-height: .8;
                color: $base-900;
                transform: translateY(-100%);
            }
        }

        .outline {
            cursor: pointer;
            fill: transparent;

            &._hover {
                stroke: $base-0;
                stroke-width: .2rem;
                transition: stroke $homeTransition;

                @include respond-to(sm) {
                    stroke-width: .3rem;
                }
            }

            &._hide {
                stroke: transparent;
            }
        }

        .tooltip {
            z-index: 5;
        }

        .attachments {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 6;
        }
    }
</style>
