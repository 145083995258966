<template>
    <div :class="$style.UiMobileSelect">
        <button
            type="button"
            class="btn-reset"
            :class="$style.selectBtn"
            @click="handleSelectModal"
        >
            <span :class="$style.label">{{ visibleLabel }}</span>

            <ArrowDownIcon :class="$style.icon"/>
        </button>
    </div>
</template>

<script>
// icons
import ArrowDownIcon from '~/assets/icons/arrow-down-solid.svg?inline';

export default {
    name: 'UiMobileSelect',

    components: {
        ArrowDownIcon,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            default: '',
        },

        value: {
            type: [String, Number],
            default: '',
        },

        label: {
            type: [String, Number],
            default: '',
        },

        modalTitle: {
            type: [String, Number],
            default: '',
        },

        options: {
            type: Array,
            default: () => [],
        },

        /**
         * Диапазон всех доступных значений селектора
         */
        specs: {
            type: Array,
            default: () => [],
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            default: () => [],
        },

        keyLabel: {
            type: String,
            default: 'label',
        },

        keyValue: {
            type: String,
            default: 'value',
        },

        keyNote: {
            type: String,
            default: 'keyNote',
        },
    },

    computed: {
        currentVisibleValue() {
            const option = this.options.find(option => option[this.keyValue] === this.value);
            return option ? option[this.keyLabel] || '' : '';
        },

        visibleLabel() {
            return this.label ? this.label + ' ' + this.currentVisibleValue : this.currentVisibleValue;
        },

        modalData() {
            return {
                componentOptions: {
                    options: this.options,
                    currentValue: this.value,
                    keyLabel: this.keyLabel,
                    keyValue: this.keyValue,
                    keyNote: this.keyNote,
                    handleSelect: option => {
                        this.handleChangeValue(option);
                        this.$universalModal.close();
                    },
                },

                headerOptions: {
                    mobileTitle: this.modalTitle,
                },
            };
        },
    },

    methods: {
        handleChangeValue(option) {
            this.$emit('change-value', option[this.keyValue]);
        },

        handleSelectModal() {
            const { componentOptions, headerOptions } = this.modalData;

            this.$universalModal.open({
                component: () => import('~/components/common/modals/universal-modal/bodies/SelectBody.vue'),
                componentOptions,
                header: () => import('~/components/common/modals/universal-modal/headers/TitleHeader.vue'),
                headerOptions: {
                    ...headerOptions,
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
    .UiMobileSelect {
        width: 100%;
    }

    .selectBtn {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 1.9rem 4.6rem 1.9rem 2rem;
        background-color: $base-50;
        text-align: left;
        color: $base-400;
        transition: color .3s ease-in-out;

        &:hover {
            color: $base-500;
        }
    }

    .label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
    }

    .text {
        color: currentColor;
    }

    .icon {
        position: absolute;
        top: 50%;
        right: 2rem;
        width: 1.6rem;
        height: 1.6rem;
        color: $base-900;
        transform: translateY(-50%);
    }
</style>
