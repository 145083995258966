<template>
    <div
        :class="[$style.UiSelectAccordionSubItem, classList]"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
        @click="onClick"
    >

        <div :class="$style.container">
            <div
                :class="$style.title"
                v-html="option[labelName]"
            />

            <button
                v-if="option.imageFlatFilterDisplay"
                type="button"
                :class="$style.mobileInfoBtn"
                @click.stop.self="onTipClick"
            >
                <InfoIcon :class="$style.icon" />
            </button>
        </div>

        <UiCheckbox
            :value="option.selected"
            :disabled="isDisabled"
            :class="$style.checkbox"
        />
    </div>
</template>

<script>
import UiCheckbox from '~/components/ui/checkboxes/UiCheckbox.vue';
import InfoIcon from '~/assets/icons/ui/info.svg?inline';

export default {
    name: 'UiSelectAccordionSubItem',

    components: {
        InfoIcon,
        UiCheckbox,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        option: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        /**
         * Название поля для value
         */
        valueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label
         */
        labelName: {
            type: String,
            default: 'label',
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hovered: false,
        };
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style._hovered]: this.hovered,
                    [this.$style._active]: this.option.selected,
                    [this.$style._disabled]: this.isDisabled,
                    [this.$style._multiple]: this.multiple,
                },
            ];
        },

        isDisabled() {
            return this.option.disabled || this.disabled;
        },
    },

    methods: {
        onClick() {
            if (!this.isDisabled) {
                this.$emit('click', this.option);
            }
        },

        onMouseover() {
            this.hovered = true;
            this.$emit('mouseover');
        },

        onMouseleave() {
            this.hovered = false;
            this.$emit('mouseleave');
        },

        onTipClick() {
            this.$emit('show-info-tip-sub', this.option);
        },
    },
};
</script>

<style lang="scss" module>
    .UiSelectAccordionSubItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1.4rem 2rem 1.4rem 3.2rem;
        background-color: $base-0;
        transition: $transition;
        cursor: pointer;

        .container {
            display: flex;
            align-items: center;
        }

        .title {
            @include l4;

            width: 100%;
            margin-right: 1.2rem;
            color: $base-600;
            transition: $transition;
        }

        .mobileInfoBtn {
            position: relative;
            display: none;
            padding: 0;
            border: none;
            background-color: transparent;
            outline: none;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 250%;
                height: 250%;
                transform: translate(-50%, -50%);
            }

            @include respond-to(sm) {
                display: block;
            }
        }

        .icon {
            display: flex;
            width: 1.4rem;
            height: 1.4rem;
            color: $base-300;
        }

        .checkbox {
            visibility: hidden;
            opacity: 0;
            transition: $transition;
            pointer-events: none;

            @include respond-to(sm) {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .UiSelectAccordionSubItem:not(._multiple):not(._disabled)._hovered {
        .title {
            color: $base-900;
        }
    }

    .UiSelectAccordionSubItem:not(._multiple):not(._disabled)._active {
        .title {
            color: $base-900;
        }
    }

    .UiSelectAccordionSubItem:not(._disabled)._multiple._hovered {
        .title {
            color: $base-900;
        }

        .checkbox {
            visibility: visible;
            opacity: 1;
        }
    }

    .UiSelectAccordionSubItem:not(._disabled)._multiple._active {
        .title {
            color: $base-900;
        }

        .checkbox {
            visibility: visible;
            opacity: 1;
        }
    }

    .UiSelectAccordionSubItem._multiple._active {
        .checkbox {
            visibility: visible;
            opacity: 1;
        }
    }

    .UiSelectAccordionSubItem._disabled {
        cursor: not-allowed;

        .title {
            color: $base-300;
        }
    }
</style>
