// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapBuildRouteMobile_6Kng5{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;padding:2.4rem}.MapBuildRouteMobile_6Kng5 .input_qOv04{margin-bottom:2rem}.MapBuildRouteMobile_6Kng5 .name_rTkxh{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:5.6rem;margin-bottom:2rem;padding:0 3.2rem;background-color:#f4f4f5;color:#2f2f2f}.MapBuildRouteMobile_6Kng5 .icon_Qi55B{width:2rem;height:2rem}.MapBuildRouteMobile_6Kng5 .btns_G6RaG{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);grid-gap:1.2rem;gap:1.2rem;margin-bottom:2rem}.MapBuildRouteMobile_6Kng5 .resetButton_jhwLF{margin-top:2rem}.MapBuildRouteMobile_6Kng5 .routeBtnText_tdk\\+N{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}[dir=ltr] .MapBuildRouteMobile_6Kng5 .routeBtnText_tdk\\+N span{text-align:left}[dir=rtl] .MapBuildRouteMobile_6Kng5 .routeBtnText_tdk\\+N span{text-align:right}.MapBuildRouteMobile_6Kng5 .routeBtnText_tdk\\+N span{white-space:nowrap}.MapBuildRouteMobile_6Kng5 .gray_uytkt{color:#bebec5}@media(max-width:767.98px){.MapBuildRouteMobile_6Kng5{padding:1.6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapBuildRouteMobile": "MapBuildRouteMobile_6Kng5",
	"input": "input_qOv04",
	"name": "name_rTkxh",
	"icon": "icon_Qi55B",
	"btns": "btns_G6RaG",
	"resetButton": "resetButton_jhwLF",
	"routeBtnText": "routeBtnText_tdk+N",
	"gray": "gray_uytkt"
};
module.exports = ___CSS_LOADER_EXPORT___;
