// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiRangeInputSlider_ie2yX{width:100%}.UiRangeInputSlider_ie2yX .rail_fGJTX{position:relative;width:calc(100% - 1.8rem);margin:0 .9rem;cursor:pointer}.UiRangeInputSlider_ie2yX .track_P5Ww1{position:relative;z-index:3;width:100%;height:.2rem}.UiRangeInputSlider_ie2yX .progress_jkJ3\\+{position:absolute;height:100%}.UiRangeInputSlider_ie2yX._base_QHRt9 .progress_jkJ3\\+{background-color:#2f2f2f}.UiRangeInputSlider_ie2yX._base_QHRt9 .dot{width:2rem;height:2rem}.UiRangeInputSlider_ie2yX._base_QHRt9 .handle{width:1rem;height:1rem;background-color:#2f2f2f}.UiRangeInputSlider_ie2yX._base_QHRt9 .handle:hover{background-color:#505050}.UiRangeInputSlider_ie2yX._transparent_it82Z .progress_jkJ3\\+{background-color:#fff}.UiRangeInputSlider_ie2yX._transparent_it82Z .dot{width:1.6rem;height:1.6rem}.UiRangeInputSlider_ie2yX._transparent_it82Z .handle{width:1.6rem;height:1.6rem;background-color:#fff}.UiRangeInputSlider_ie2yX._disabled_j5rnV .rail_fGJTX{cursor:default}.UiRangeInputSlider_ie2yX._disabled_j5rnV .progress_jkJ3\\+,.UiRangeInputSlider_ie2yX._disabled_j5rnV .handle{background-color:#a0a0a0}.UiRangeInputSlider_ie2yX._disabled_j5rnV .handle:hover{background-color:#a0a0a0;-webkit-transform:none;transform:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiRangeInputSlider": "UiRangeInputSlider_ie2yX",
	"rail": "rail_fGJTX",
	"track": "track_P5Ww1",
	"progress": "progress_jkJ3+",
	"_base": "_base_QHRt9",
	"_transparent": "_transparent_it82Z",
	"_disabled": "_disabled_j5rnV"
};
module.exports = ___CSS_LOADER_EXPORT___;
