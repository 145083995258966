// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeFormat_5R7dF{overflow:hidden;width:100%;height:107rem;max-height:107rem;padding-top:6.4rem;padding-bottom:6.4rem}@media(max-width:991.98px){.HomeFormat_5R7dF{height:107rem;max-height:107rem}}@media(max-width:519.98px){.HomeFormat_5R7dF{padding-top:0}}.HomeFormat_5R7dF .button_-e8bw{display:none;margin-top:2.4rem}@media(max-width:991.98px){.HomeFormat_5R7dF .button_-e8bw{display:-webkit-box;display:-ms-flexbox;display:flex}}.HomeFormat_5R7dF .title_E9KPC{max-width:64rem;margin-bottom:6.4rem}@media(max-width:991.98px){.HomeFormat_5R7dF .title_E9KPC{margin-bottom:4.8rem}}@media(max-width:519.98px){.HomeFormat_5R7dF .title_E9KPC{margin-bottom:4rem}}.fileterSkeleton_6ladv{max-width:80rem;margin-right:auto;margin-bottom:3.2rem;margin-left:auto}@media(max-width:519.98px){.fileterSkeleton_6ladv{margin-bottom:2rem}}.slider_T33q0{margin-top:3.2rem}@media(max-width:519.98px){.slider_T33q0{margin-top:2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeFormat": "HomeFormat_5R7dF",
	"button": "button_-e8bw",
	"title": "title_E9KPC",
	"fileterSkeleton": "fileterSkeleton_6ladv",
	"slider": "slider_T33q0"
};
module.exports = ___CSS_LOADER_EXPORT___;
