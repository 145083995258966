// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsAdvancedFilterHeader_yKigK{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%}.FlatsAdvancedFilterHeader_yKigK .title_CqIkh{font-size:2.4rem;font-weight:700;line-height:3.1rem;letter-spacing:normal;color:#2f2f2f}.FlatsAdvancedFilterHeader_yKigK .reset_kIWq\\+{font-size:1.4rem;font-weight:700;line-height:1.8rem;color:#2f2f2f;-webkit-transition:.3s ease;transition:.3s ease}.FlatsAdvancedFilterHeader_yKigK .reset_kIWq\\+._disabled_n2-o3{pointer-events:none}@media(max-width:1279.98px){.FlatsAdvancedFilterHeader_yKigK .title_CqIkh{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;color:#2f2f2f;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsAdvancedFilterHeader": "FlatsAdvancedFilterHeader_yKigK",
	"title": "title_CqIkh",
	"reset": "reset_kIWq+",
	"_disabled": "_disabled_n2-o3"
};
module.exports = ___CSS_LOADER_EXPORT___;
