// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalAccordionBody_nvfZa{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding:0 2.4rem}.ModalAccordionBody_nvfZa._padding_RRb2A{padding-bottom:2.4rem}@media(max-width:767.98px){.ModalAccordionBody_nvfZa._padding_RRb2A{padding-bottom:1.6rem}}@media(max-width:767.98px){.ModalAccordionBody_nvfZa{padding:0 1.6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalAccordionBody": "ModalAccordionBody_nvfZa",
	"_padding": "_padding_RRb2A"
};
module.exports = ___CSS_LOADER_EXPORT___;
