<template>
    <client-only>
        <div :class="$style.FlatsFilter">
            <div :class="$style.filters">
                <UiUniversalDropdown
                    placeholder="Корпус"
                    :dropdown="FlatsBuildingDropdown"
                    :class="$style.field"
                    :dropdown-offset-left="'0'"
                    data-gtm-html-id="building"
                />

                <div :class="$style.separator"></div>

                <UiUniversalDropdown
                    placeholder="Параметры квартиры"
                    :dropdown="FlatsParametersDropdown"
                    :class="$style.field"
                    data-gtm-html-id="flats_parameters"
                />

                <div :class="$style.separator"></div>

                <UiFilterDropdown
                    name="key_distribution"
                    :class="$style.field"
                    :value="filters.key_distribution"
                    :specs="specs.key_distribution"
                    :facets="facets.key_distribution"
                    placeholder="Ключи в"
                    static-placeholder="Ключи в"
                    reset-label="Не важно"
                    size="small"
                    color="white-hover"
                    multiple
                    :disabled="isReloading"
                    data-gtm-html-id="key_distribution"
                    @change="changeFilters"
                />
            </div>

            <UiRangeInput
                name="price"
                :value-min="filters.priceMin"
                :value-max="filters.priceMax"
                :specs="specs.price"
                :facets="facets.price"
                postfix="млн ₽"
                show-label
                :decimal-count="1"
                :disabled="isReloading"
                :class="$style.price"
                data-gtm-html-id="price"
                @change="changeFilters"
            />

            <UiButton
                v-if="!isHaveRedirectButton"
                size="big"
                color="black"
                :class="$style.button"
                data-gtm-html-id="open_advanced_filter"
                @click="$root.$emit('openAdvancedFilter')"
            >
                Расширенный фильтр

                <template #iconAfter>
                    <FilterIcon />
                </template>
            </UiButton>

            <UiButton
                v-else
                size="big"
                color="black"
                :class="[$style.button, $style.redirectButton]"
                :link="flatQuery"
            >
                {{ countLabel }}

            </UiButton>
        </div>
    </client-only>
</template>

<script>
// icons
import FilterIcon from '~/assets/icons/ui/filter.svg?inline';

import UiButton from '~/components/ui/buttons/UiButton.vue';
import UiUniversalDropdown from '~/components/ui/dropdowns/universal-dropdown/UiUniversalDropdown.vue';
import FlatsBuildingDropdown from '~/components/flats/filters/dropdowns/FlatsBuildingDropdown.vue';
import FlatsParametersDropdown from '~/components/flats/filters/dropdowns/FlatsParametersDropdown.vue';
import UiFilterDropdown from '~/components/ui/dropdowns/filter-dropdown/UiFilterDropdown.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import UiRangeInput from '~/components/ui/inputs/range-input/UiRangeInput.vue';

import { objectToQuery } from '~/assets/js/utils/queryUtils';
import { plural } from '~/assets/js/utils';

export default {
    name: 'FlatsFilter',

    components: {
        UiRangeInput,
        UiFilterDropdown,
        UiButton,
        UiUniversalDropdown,
        FilterIcon,
    },

    props: {
        isHaveRedirectButton: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            FlatsBuildingDropdown,
            FlatsParametersDropdown,
        };
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'specs',
            'facets',
            'isReloading',
        ]),

        ...mapGetters({
            totalCount: 'flats/totalCount',
        }),

        flatQuery() {
            const query = objectToQuery(this.filters);
            return query ? `/flats/?${query}` : '/flats/';
        },

        countLabel() {
            const count = this.totalCount;
            return `${count} ${plural(count, ['квартира', 'квартиры', 'квартир'])}`;
        },
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),
    },
};
</script>

<style lang="scss" module>
    .FlatsFilter {
        display: flex;
        align-items: center;
        width: 100%;

        @include respond-to(md) {
            flex-wrap: wrap;
        }

        .button {
            width: fit-content;
            margin-left: 1.2rem;
            white-space: nowrap;

            @include respond-to(md) {
                margin-top: 1.2rem;
            }
        }

        .redirectButton {
            min-width: 22rem;
        }
    }

    .filters {
        display: flex;
        align-items: center;
        width: 100%;
        height: 5.6rem;
        padding: .6rem;
        background-color: $base-50;
    }

    .field {
        min-width: 22rem;

        @include respond-to(md) {
            width: 100%;
        }
    }

    .price {
        flex-grow: 1;
        width: 100%;
        margin-left: 1.2rem;

        @include respond-to(md) {
            width: auto;
            margin-top: 1.2rem;
            margin-left: 0;
        }
    }

    .separator {
        width: 0;
        height: 1.6rem;
        border-right: .1rem solid $base-300;
    }
</style>
