<template>
    <div :class="$style.TitleBackCloseHeader">
        <div>
            <UiCircleButton
                v-show="isBtnBack"
                size="moderate"
                @click="onClose"
            >
                <ArrowLeftIcon />
            </UiCircleButton>
        </div>

        <h4
            v-if="title"
            :class="$style.title"
            v-html="title"
        />

        <div>
            <UiCircleButton
                v-show="isBtnClose"
                size="moderate"
                @click="onClose"
            >
                <CloseIcon />
            </UiCircleButton>
        </div>
    </div>
</template>

<script>
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';

import ArrowLeftIcon from '~/assets/icons/ui/arrow-left.svg?inline';
import CloseIcon from '~/assets/icons/ui/close.svg?inline';

export default {
    name: 'TitleBackCloseHeader',

    components: {
        ArrowLeftIcon,
        CloseIcon,
        UiCircleButton,
    },

    props: {
        /**
         * Содержит тайтл для хедера
         */
        title: {
            type: String,
            default: '',
        },

        /**
         * Определяет отображением кнопки "Назад"
         */
        isBtnBack: {
            type: Boolean,
            default: true,
        },

        /**
         * Определяет отображением кнопки "Закрыть"
         */
        isBtnClose: {
            type: Boolean,
            default: false,
        },

        /**
         * Коллбек функция при закрытии модалки, в случае
         * если предоставлена
         */
        btnCloseCallback: {
            type: Function,
            default: null,
        },
    },

    methods: {
        onClose() {
            if (this.btnCloseCallback) {
                this.btnCloseCallback();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" module>
    .TitleBackCloseHeader {
        display: grid;
        grid-template-columns: 3.6rem 1fr 3.6rem;
        gap: .8rem;
        align-items: center;
        padding: 2.4rem 2.4rem 1.6rem;

        .title {
            @include h4;

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        @include respond-to(xs) {
            padding: 2.4rem 1.6rem 1.6rem;

            .title {
                @include h5;
            }
        }
    }
</style>
