// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsAdvancedFiltersDesktop_fP3wg{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[3];grid-template-columns:repeat(3,1fr);grid-gap:3.2rem;gap:3.2rem;width:100%}.FlatsAdvancedFiltersDesktop_fP3wg .row_uKJTh{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);width:100%;grid-column-gap:1.6rem;-webkit-column-gap:1.6rem;-moz-column-gap:1.6rem;column-gap:1.6rem}.FlatsAdvancedFiltersDesktop_fP3wg .column_CeuFN{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:2.4rem;gap:2.4rem;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsAdvancedFiltersDesktop": "FlatsAdvancedFiltersDesktop_fP3wg",
	"row": "row_uKJTh",
	"column": "column_CeuFN"
};
module.exports = ___CSS_LOADER_EXPORT___;
