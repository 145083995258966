// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdvantagesText_gF4Ff{text-align:center}.title_w0e5C{text-transform:uppercase;font-size:4rem;font-weight:700;line-height:1.2;color:#2f2f2f}.title_w0e5C .color_AzTPU{color:#cfcfcf}.subtitle_5rDl2{max-width:64rem;margin:3.2rem auto 0;font-size:1.6rem;font-weight:300;line-height:1.32;color:#717171}.subtitle_5rDl2 b{font-weight:700;color:#2f2f2f}.buttons_7ckyt{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin-top:6.4rem;grid-column-gap:1.2rem;-webkit-column-gap:1.2rem;-moz-column-gap:1.2rem;column-gap:1.2rem}.btn_hsT5j{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media(max-width:991.98px){.title_w0e5C{font-size:3.2rem;font-weight:700;line-height:3.6rem;letter-spacing:-.13rem}.subtitle_5rDl2{max-width:670px;margin-top:40px}.buttons_7ckyt{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;max-width:332px;margin-right:auto;margin-left:auto}.btn_hsT5j{width:100%}}@media(max-width:767.98px){.title_w0e5C{font-size:2.4rem;font-weight:700;line-height:2.4rem;letter-spacing:-.08rem;text-transform:uppercase;line-height:3.1rem}.subtitle_5rDl2{font-size:1.4rem;line-height:1.4;letter-spacing:normal;max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvantagesText": "AdvantagesText_gF4Ff",
	"title": "title_w0e5C",
	"color": "color_AzTPU",
	"subtitle": "subtitle_5rDl2",
	"buttons": "buttons_7ckyt",
	"btn": "btn_hsT5j"
};
module.exports = ___CSS_LOADER_EXPORT___;
