<template>
    <div
        v-if="specifications && specifications.length"
        :class="$style.PlaceSpecifications"
    >
        <div
            v-for="spec in specifications"
            :key="spec.value"
            :class="$style.row"
        >
            <span :class="$style.label">
                {{ spec.label }}
            </span>

            <span v-if="spec.count" :class="$style.count">
                {{ spec.count }} {{ countPostfix }}
            </span>

            <span :class="$style.value">
                {{ spec.value }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlaceSpecifications',
    props: {
        /**
         * Массив с элементами типа
         * { label: string, value: string }[]
         */
        specifications: {
            type: Array,
            default: () => [],
        },

        countPostfix: {
            type: String,
            default: 'кв.',
        },
    },
};

</script>

<style lang="scss" module>
    .PlaceSpecifications {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .row {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1;

            &:not(:last-child) {
                margin-bottom: 1.2rem;
            }

            .label {
                min-width: 7rem;
                padding-right: 2.4rem;
                color: $base-500;
            }

            .value {
                min-width: 9rem;
                text-align: right;
                color: $base-900;
            }

            .count {
                width: 3.2rem;
                text-align: left;
                white-space: nowrap;
                color: $base-500;
            }

            .count + .value {
                text-align: right;
            }
        }
    }
</style>
