// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsFilter_k0Y5K{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%}@media(max-width:1279.98px){.FlatsFilter_k0Y5K{-ms-flex-wrap:wrap;flex-wrap:wrap}}.FlatsFilter_k0Y5K .button_xLyzH{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-left:1.2rem;white-space:nowrap}@media(max-width:1279.98px){.FlatsFilter_k0Y5K .button_xLyzH{margin-top:1.2rem}}.FlatsFilter_k0Y5K .redirectButton_BEYo8{min-width:22rem}.filters_-Yid4{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;height:5.6rem;padding:.6rem;background-color:#f3f3f3}.field_Na-Yb{min-width:22rem}@media(max-width:1279.98px){.field_Na-Yb{width:100%}}.price_oqXnw{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;width:100%;margin-left:1.2rem}@media(max-width:1279.98px){.price_oqXnw{width:auto;margin-top:1.2rem;margin-left:0}}.separator_2GEYk{width:0;height:1.6rem;border-right:.1rem solid #cfcfcf}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsFilter": "FlatsFilter_k0Y5K",
	"button": "button_xLyzH",
	"redirectButton": "redirectButton_BEYo8",
	"filters": "filters_-Yid4",
	"field": "field_Na-Yb",
	"price": "price_oqXnw",
	"separator": "separator_2GEYk"
};
module.exports = ___CSS_LOADER_EXPORT___;
