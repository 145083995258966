// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiInputWrapper_d314z{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.UiInputWrapper_d314z,.UiInputWrapper_d314z .label_UwB-I{display:-webkit-box;display:-ms-flexbox;display:flex}.UiInputWrapper_d314z .label_UwB-I{font-size:1.2rem;font-weight:700;line-height:1.6rem;color:#2f2f2f;position:relative;margin-bottom:.8rem;color:#616161}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiInputWrapper": "UiInputWrapper_d314z",
	"label": "label_UwB-I"
};
module.exports = ___CSS_LOADER_EXPORT___;
