<template>
    <div :class="$style.ProjectGenplanCorpusTooltip">
        <div :class="$style.info">
            <div :class="$style.header">
                <h3
                    :class="$style.title"
                    v-html="title"
                />

                <div
                    v-if="sale"
                    :class="$style.discount"
                >
                    <DiscountIcon :class="$style.icon" />

                    <div :class="$style.percent">
                        {{ sale }}%
                    </div>
                </div>
            </div>

            <ProjectGenplanAdvantages
                v-if="tags.length"
                :class="$style.tags"
                :items="tags"
            />
        </div>

        <div :class="$style.description">
            <span
                v-if="order"
                :class="$style.order"
                v-html="order"
            />

            <span
                v-if="flatsForSale"
                :class="$style.flatsForSale"
                v-html="flatsForSale"
            />
        </div>

        <ProjectGenplanFlatsPrices 
            v-if="!disabled"
            :flats-info="flatsInfo"
        />

        <div :class="$style.btns">
            <UiButton
                v-if="explore"
                @click="explore"
            >
                Изучить особенности
            </UiButton>

            <UiButton
                v-if="next && !disabled"
                color="black"
                @click="next"
            >
                {{ nextText }}
            </UiButton>
        </div>
    </div>
</template>

<script>
import DiscountIcon from '~/assets/icons/home/discount.svg?inline';
import UiButton from '~/components/ui/buttons/UiButton.vue';
import ProjectGenplanAdvantages from '~/components/pages/project/architecture/attachments/ProjectGenplanAdvantages.vue';
import ProjectGenplanFlatsPrices from './ProjectGenplanFlatsPrices.vue';

export default {
    name: 'ProjectGenplanCorpusTooltip',

    components: {
        UiButton,
        DiscountIcon,
        ProjectGenplanAdvantages,
        ProjectGenplanFlatsPrices,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        sale: {
            type: [String, Number],
            default: '',
        },

        finishing: {
            type: String,
            default: '',
        },

        isSalesStart: {
            type: Boolean,
            default: false,
        },

        order: {
            type: String,
            default: '',
        },

        flatsForSale: {
            type: String,
            default: '',
        },

        flatsInfo: {
            type: Array,
            default: () => [],
        },

        explore: {
            type: Function,
            default: null,
        },

        next: {
            type: Function,
            default: null,
        },

        nextText: {
            type: String,
            default: '',
        },

        // Добавил флаг для корпуса, где нет квартир.
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        tags() {
            const tags = [];

            if (this.isSalesStart) {
                tags.push({
                    label: 'Старт продаж',
                    color: 'black',
                });
            }

            if (this.finishing) {
                tags.push({
                    label: this.finishing,
                });
            }

            if (this.sale) {
                tags.push({
                    label: 'Скидка',
                });
            }

            return tags;
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanCorpusTooltip {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        width: 28rem;
        min-width: 28rem;
        max-width: 28rem;
        padding: 2rem;
        box-shadow: 0 1.6rem 2.4rem rgba($base, .06), 0 .2rem .6rem rgba($base, .04), 0 0 .1rem rgba($base, .04);
        background-color: $inversed;

        .info {
            display: flex;
            flex-direction: column;
        }

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .title {
            text-transform: uppercase;

            @include l2;
        }

        .discount {
            display: flex;
            align-items: center;
            margin-left: 3rem;
        }

        .icon {
            width: 2rem;
            min-width: 2rem;
            height: 2rem;
            color: $red;
        }

        .percent {
            @include l4;

            margin-left: .6rem;
        }

        .tags {
            margin-top: 1.6rem;
        }

        .description {
            display: grid;
            grid-template-columns: 1fr;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 124%;
        }

        .order {
            color: $base-900;
        }

        .flatsForSale {
            color: $base-500;
        }

        .btns {
            display: grid;
            grid-template-columns: 1fr;
            gap: .8rem;
        }
    }
</style>
