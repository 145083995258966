<template>
    <div v-if="priceRange" :class="[$style.PlacePriceInfo, classList]">
        <div :class="$style.priceRange">{{ priceRange }}</div>
        <div v-if="hasSale" :class="$style.hasSale">
            cо скидкой
        </div>
    </div>
</template>

<script>
export default {
    name: 'PlacePriceInfo',

    props: {
        size: {
            type: String,
            default: 'normal',
            validator: value => ['normal', 'large'].includes(value),
        },

        priceRange: {
            type: String,
            default: '',
        },

        hasSale: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.size}`]]: this.size,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .PlacePriceInfo {
        &._normal {
            .priceRange {
                font-size: 2rem;
                line-height: 1.4;
            }
        }

        &._large {
            .priceRange {
                font-size: 2.4rem;
                line-height: 1.32;
            }
        }

        .priceRange {
            text-transform: uppercase;
            font-weight: 700;
        }

        .hasSale {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin-top: .4rem;
            padding: .8rem;
            background-color: $base-900;
            text-align: center;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            line-height: 100%;
            color: $base-0;
        }
    }
</style>
