<template>
    <div :class="[$style.GenplanOverlay, {[$style._visible]: visible}]">
        <div :class="$style.container">
            <FingerIcon :class="$style.icon" />

            <p :class="$style.text">
                Для перемещения по изображению двигайте вправо-влево
            </p>
        </div>
    </div>
</template>

<script>
import FingerIcon from '~/assets/icons/genplan-finger.svg?inline';

export default {
    name: 'GenplanOverlay',

    components: {
        FingerIcon,
    },

    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
    .GenplanOverlay {
        visibility: hidden;
        display: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba($base-900, .85);
        pointer-events: none;
        transition: $transition;

        &._visible {
            visibility: visible;
            display: flex;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 30rem;
    }

    .text {
        @include p3;

        text-align: center;
        color: $base-0;
    }

    .icon {
        width: 4.8rem;
        height: 4.8rem;
        margin-bottom: 1.2rem;
        color: $base-0;
    }
</style>
