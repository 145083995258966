<template>
    <div
        :class="[$style.UiUniversalDropdownValue, classList]"
        @click="$emit('click')"
    >
        <div
            v-if="!value"
            :class="$style.placeholder"
        >
            {{ placeholder }}
        </div>

        <component
            :is="display"
            v-else-if="display"
            :value="value"
        />

        <div
            v-else
            :class="$style.title"
        >
            {{ value }}
        </div>

        <div :class="$style.arrow">
            <ArrowIcon />
        </div>
    </div>
</template>

<script>
import ArrowIcon from '~/assets/icons/ui/arrow-down.svg?inline';

export default {
    name: 'UiUniversalDropdownValue',

    components: {
        ArrowIcon,
    },

    props: {
        value: {
            type: String,
            default: '',
        },

        isOpened: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        display: {
            type: Object,
            default: null,
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style._opened]: this.isOpened,
                [this.$style._disabled]: this.disabled,
            }];
        },
    },
};
</script>

<style lang="scss" module>
    .UiUniversalDropdownValue {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4.4rem;
        padding: 0 2.4rem;
        background-color: $base-50;
        transition: $transition;
        cursor: pointer;

        &:not(._opened):hover {
            background-color: $base-0;
            box-shadow: 0 1.2rem 2.4rem 0 rgba($base, .04);
        }

        &._opened {
            background-color: $base-0;
            box-shadow: 0 1.2rem 2.4rem 0 rgba($base, .04);

            .arrow {
                transform: rotate(180deg);
            }
        }

        .placeholder,
        .title {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1;
        }

        .placeholder {
            color: $base-400;
        }

        .title {
            text-transform: uppercase;
            color: $base-900;
            transition: inherit;
        }

        .arrow {
            display: flex;
            width: 1.6rem;
            height: 1.6rem;
            margin-left: .4rem;
            color: $base-900;
            transition: inherit;
        }
    }
</style>
