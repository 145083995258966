// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeCards_ucGtF{overflow:hidden;display:-ms-grid;display:grid;-ms-grid-columns:1fr 2rem 1fr 2rem 1fr 2rem 1fr;grid-template-columns:repeat(4,1fr);grid-template-areas:\"card2 card3 card0 card1\";grid-gap:2rem;gap:2rem}@media(max-width:991.98px){.HomeCards_ucGtF{-ms-grid-columns:1fr;grid-template-columns:1fr;-ms-grid-rows:auto .8rem auto .8rem auto .8rem auto;grid-template-areas:\"card0\" \"card1\" \"card2\" \"card3\";grid-gap:.8rem;gap:.8rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeCards": "HomeCards_ucGtF"
};
module.exports = ___CSS_LOADER_EXPORT___;
