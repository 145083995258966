<template>
    <div
        :class="[$style.UiSelectAccordionItem, classList]"
        @mouseover="onMouseover"
        @mouseleave="onMouseleave"
        @click="onClick"
    >
        <div :class="$style.container">
            <div :class="$style.wrap">
                <div
                    :class="$style.title"
                    v-html="option[labelName]"
                />

                <div
                    v-if="option.description"
                    :class="$style.subtitle"
                    v-html="option.description"
                />
            </div>

            <button
                v-if="option.imageFlatFilterDisplay"
                type="button"
                :class="$style.mobileInfoBtn"
                @click.stop.self="onTipClick"
            >
                <InfoIcon :class="$style.icon" />
            </button>

            <FlatsTooltip
                v-if="option.tooltip"
                :text="option.tooltip"
            />
        </div>

        <UiCheckbox
            :value="option.selected"
            :disabled="isDisabled"
            :is-highlighted="isHighlighted"
            :class="$style.checkbox"
        />
    </div>
</template>

<script>
import FlatsTooltip from '~/components/flats/common/FlatsTooltip.vue';
import UiCheckbox from '~/components/ui/checkboxes/UiCheckbox.vue';
import InfoIcon from '~/assets/icons/ui/info.svg?inline';

export default {
    name: 'UiSelectAccordionItem',

    components: {
        FlatsTooltip,
        InfoIcon,
        UiCheckbox,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        option: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        /**
         * Название поля для value
         */
        valueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label
         */
        labelName: {
            type: String,
            default: 'label',
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        isHighlighted: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style._active]: this.option.selected,
                    [this.$style._disabled]: this.isDisabled,
                    [this.$style._multiple]: this.multiple,
                    [this.$style._highlighted]: this.isHighlighted,
                },
            ];
        },

        isDisabled() {
            return this.option.disabled || this.disabled;
        },
    },

    methods: {
        onClick() {
            if (!this.isDisabled) {
                this.$emit('click', this.option);
            }
        },

        onMouseover() {
            this.$emit('mouseover');
        },

        onMouseleave() {
            this.$emit('mouseleave');
        },

        onTipClick() {
            this.$emit('show-info-tip', this.option);
        },
    },
};
</script>

<style lang="scss" module>
    .UiSelectAccordionItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4.8rem;
        padding: 0 2rem;
        background-color: $base-0;
        transition: $transition;
        cursor: pointer;

        .container {
            display: flex;
            align-items: center;
        }

        .wrap {
            display: grid;
            grid-template-columns: 1fr;
            gap: .2rem;
        }

        .title {
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.24;
            color: $base-900;
            transition: $transition;
        }

        .subtitle {
            @include p4;

            color: $base-600;
            transition: $transition;
        }

        .checkbox {
            margin-left: 1.2rem;
            pointer-events: none;
        }

        .mobileInfoBtn {
            position: relative;
            display: none;
            margin-left: .8rem;
            padding: 0;
            border: none;
            background-color: transparent;
            outline: none;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 250%;
                height: 250%;
                transform: translate(-50%, -50%);
            }

            @include respond-to(sm) {
                display: block;
            }
        }

        .icon {
            display: flex;
            width: 1.4rem;
            height: 1.4rem;
            color: $base-300;
        }
    }

    .UiSelectAccordionItem:not(._multiple) {
        .checkbox {
            visibility: hidden;
            opacity: 0;
        }
    }

    .UiSelectAccordionItem:not(._disabled)._highlighted {
        background-color: $base-50;
    }

    .UiSelectAccordionItem:not(._disabled)._active {
        background-color: $base-50;
    }

    .UiSelectAccordionItem._disabled._highlighted {
        background-color: $base-0;
    }

    .UiSelectAccordionItem._disabled._active {
        background-color: $base-50;
    }

    .UiSelectAccordionItem._disabled {
        cursor: not-allowed;

        .title,
        .subtitle {
            color: $base-400;
        }
    }
</style>
