// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiSimpleDropdownValue_jVNd7{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;padding:0 2rem;-webkit-transition:.3s ease;transition:.3s ease;cursor:pointer}.UiSimpleDropdownValue_jVNd7._big_SDLmK{height:5.6rem}.UiSimpleDropdownValue_jVNd7._normal_14K\\+K{height:4rem}.UiSimpleDropdownValue_jVNd7._medium_YJZee{height:5.2rem}.UiSimpleDropdownValue_jVNd7._white_iDwmk{background-color:#fff}.UiSimpleDropdownValue_jVNd7._gray_E\\+gs9{background-color:#f3f3f3}.UiSimpleDropdownValue_jVNd7._gray_E\\+gs9 .placeholder_1sU0Q,.UiSimpleDropdownValue_jVNd7:not(._opened_Mb6K8):hover .placeholder_1sU0Q{color:#616161}.UiSimpleDropdownValue_jVNd7:not(._opened_Mb6K8):hover .arrow_zIgj3{opacity:.6}.UiSimpleDropdownValue_jVNd7._opened_Mb6K8{border-bottom-left-radius:0;border-bottom-right-radius:0}.UiSimpleDropdownValue_jVNd7._opened_Mb6K8 .placeholder_1sU0Q{color:#616161}.UiSimpleDropdownValue_jVNd7._opened_Mb6K8 .arrow_zIgj3{color:#2f2f2f;-webkit-transform:rotate(180deg);transform:rotate(180deg)}.UiSimpleDropdownValue_jVNd7 .placeholder_1sU0Q{font-size:1.4rem;font-weight:700;line-height:1.8rem;color:#2f2f2f;color:#a0a0a0;-webkit-transition:inherit;transition:inherit}.UiSimpleDropdownValue_jVNd7 .title_Ew5bx{font-size:1.6rem;line-height:2.4rem;letter-spacing:normal;color:#616161;white-space:nowrap;line-height:132%;color:#2f2f2f;-webkit-transition:inherit;transition:inherit}.UiSimpleDropdownValue_jVNd7 .arrow_zIgj3{display:-webkit-box;display:-ms-flexbox;display:flex;width:1.6rem;height:1.6rem;margin-left:.4rem;color:#2f2f2f;-webkit-transition:inherit;transition:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiSimpleDropdownValue": "UiSimpleDropdownValue_jVNd7",
	"_big": "_big_SDLmK",
	"_normal": "_normal_14K+K",
	"_medium": "_medium_YJZee",
	"_white": "_white_iDwmk",
	"_gray": "_gray_E+gs9",
	"placeholder": "placeholder_1sU0Q",
	"_opened": "_opened_Mb6K8",
	"arrow": "arrow_zIgj3",
	"title": "title_Ew5bx"
};
module.exports = ___CSS_LOADER_EXPORT___;
