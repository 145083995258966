<template>
    <ValidationObserver
        v-slot="{ handleSubmit }"
        tag="div"
        :class="$style.MapBuildRoute"
    >
        <div :class="$style.header">
            <div :class="$style.title">
                Постройте<br/> маршрут до ЖК
            </div>

            <UiCloseButton
                color="primary"
                @click="$emit('close')"
            />
        </div>

        <UiInput
            v-model="address"
            rules="map_route"
            field="адрес"
            name="address"
            placeholder="Ваше местоположение"
            :class="$style.input"
            :prop-error="errorMessage"
        />

        <div :class="$style.name">
            ÁLIA

            <LocationArrowIcon :class="$style.icon" />
        </div>

        <div :class="$style.btns">
            <UiButton
                :active="routeType === 'pedestrian'"
                @click="routeInfo? handleSubmit(() => createRoute('pedestrian')) : routeType ='pedestrian'"
            >
                <template slot="iconBefore">
                    <WalkIcon />
                </template>

                <template slot="default">
                    <span :class="$style.routeBtnText">
                        Пешком<br/>
                        <span :class="$style.gray">{{ pedestrianTime }}</span>
                    </span>
                </template>
            </UiButton>

            <UiButton
                :active="routeType ==='driving'"
                @click="routeInfo? handleSubmit(() => createRoute('driving')) : routeType ='driving'"
            >
                <template slot="iconBefore">
                    <CarIcon />
                </template>

                <template slot="default">
                    <span :class="$style.routeBtnText">
                        Авто<br/>
                        <span :class="$style.gray">{{ drivingTime }}</span>
                    </span>
                </template>
            </UiButton>
        </div>

        <UiButton
            v-if="routeInfo"
            color="primary"
            @click="resetRoute"
        >
            <template
                slot="iconBefore"
            >
                <CloseIcon />
            </template>

            <template slot="default">
                {{ 'Сбросить маршрут' }}
            </template>
        </UiButton>

        <UiButton
            v-if="!routeInfo"
            color="primary"
            @click="handleSubmit(() => createRoute(routeType))"
        >
            <template
                slot="iconBefore"
            >
                <MapRoute />
            </template>

            <template slot="default">
                {{ 'Построить маршрут' }}
            </template>
        </UiButton>
    </ValidationObserver>
</template>

<script>
import UiCloseButton from '~/components/ui/home/UiCloseButton';
import UiInput from '~/components/ui/home/UiInput';
import UiButton from '~/components/ui/home/UiButton';

import LocationArrowIcon from '~/assets/icons/home/location-arrow.svg?inline';
import WalkIcon from '~/assets/icons/home/walk.svg?inline';
import CarIcon from '~/assets/icons/home/car.svg?inline';
import CloseIcon from '~/assets/icons/home/close.svg?inline';
import MapRoute from '~/assets/icons/home/map-route.svg?inline';

export default {
    name: 'MapBuildRoute',

    components: {
        UiCloseButton,
        UiInput,
        UiButton,
        LocationArrowIcon,
        WalkIcon,
        CarIcon,
        CloseIcon,
        MapRoute,
    },

    props: {
        routeInfo: {
            type: Object,
            default: null,
        },

        errorMessage: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            address: '',
            routeType: '',
        };
    },

    computed: {
        drivingTime() {
            if (this.routeInfo && this.routeInfo.type === 'driving') {
                const time = this.routeInfo.time.split(' ').map(el => {
                    el = el[el.length - 1] === '.'? el : el + '.';
                    return el;
                });
                return time.join(' ');
            }

            return '';
        },

        pedestrianTime() {
            if (this.routeInfo && this.routeInfo.type === 'pedestrian') {
                const time = this.routeInfo.time.split(' ').map(el => {
                    el = el[el.length - 1] === '.'? el : el + '.';
                    return el;
                });
                return time.join(' ');
            }

            return '';
        },
    },

    methods: {
        createRoute(type) {
            this.$emit('buildRoute', this.address, type);
        },

        resetRoute() {
            this.$emit('resetRoute');
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" module>
    .MapBuildRoute {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 40rem;
        padding: 2.8rem;
        background-color: $inversed;

        .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4rem;
        }

        .title {
            @include old-h3;

            margin-right: 6.2rem;
            color: #141516;
        }

        .input {
            margin-bottom: 2rem;
        }

        .name {
            @include old-p4;

            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 5.6rem;
            margin-bottom: 2rem;
            padding: 0 3.2rem;
            background-color: $home-accent-6;
            color: $home-accent-2;
        }

        .icon {
            width: 2rem;
            height: 2rem;
        }

        .btns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.2rem;
            margin-bottom: 2rem;
        }

        .routeBtnText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            span {
                text-align: start;
                white-space: nowrap;
            }
        }

        .gray {
            color: $home-accent-5;
        }
    }
</style>
