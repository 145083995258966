// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenplanPinInfrastructure_kE6yc{position:absolute;z-index:3;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:3.2rem;height:3.2rem;border-radius:50%;background-color:#f4f4f4;pointer-events:all;cursor:pointer}.GenplanPinInfrastructure_kE6yc:after{content:\"\";position:absolute;bottom:-.6rem;left:50%;width:0;height:0;border-top:1.2rem solid #f4f4f4;border-right:.6rem solid transparent;border-left:.6rem solid transparent;-webkit-transform:translateX(-50%);transform:translateX(-50%)}.GenplanPinInfrastructure_kE6yc .icon_XLY7p{width:1.6rem;height:1.6rem;-o-object-fit:contain;object-fit:contain}.GenplanPinInfrastructure_kE6yc .tooltip_KUtMH{position:absolute;top:calc(100% + 8px);left:50%;visibility:hidden;width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:25rem;padding:.8rem;background-color:#fff;opacity:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;pointer-events:none;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:all .3s ease-in;transition:all .3s ease-in}.GenplanPinInfrastructure_kE6yc .title_R-19I{text-transform:uppercase;white-space:nowrap;font-size:1.2rem;font-weight:700;line-height:1.2;color:#2f2f2f}.GenplanPinInfrastructure_kE6yc .description_O5\\+2J{margin-top:.6rem;font-size:1.2rem;font-weight:400;line-height:1;color:#717171}.GenplanPinInfrastructure_kE6yc:hover .tooltip_KUtMH{visibility:visible;opacity:1;pointer-events:all}@media(max-width:991.98px){.GenplanPinInfrastructure_kE6yc .tooltip_KUtMH{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GenplanPinInfrastructure": "GenplanPinInfrastructure_kE6yc",
	"icon": "icon_XLY7p",
	"tooltip": "tooltip_KUtMH",
	"title": "title_R-19I",
	"description": "description_O5+2J"
};
module.exports = ___CSS_LOADER_EXPORT___;
