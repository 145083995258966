<template>
    <div
        v-clickoutside="closeDropdown"
        :class="[$style.UiUniversalDropdown, classList]"
    >
        <component
            :is="valueComponent || UiUniversalDropdownValue"
            :display="valueDisplay"
            :value="value"
            :placeholder="placeholder"
            :is-opened="isOpened"
            :is-selected="isSelected"
            :size="size"
            :color="color"
            :disabled="disabled"
            @click="toggleDropdown"
        />

        <transition name="dropdown">
            <slot v-if="isOpened" name="dropdown">
                <component
                    :is="dropdown"
                    v-if="dropdown"
                    :style="dropdownStyles"
                />
            </slot>
        </transition>
    </div>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
import { mapGetters } from 'vuex';

import UiUniversalDropdownValue from '~/components/ui/dropdowns/universal-dropdown/UiUniversalDropdownValue.vue';

export default {
    name: 'UiUniversalDropdown',

    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        valueComponent: { // для кастомного отображения компонента с value
            type: Object,
            default: null,
        },

        valueDisplay: { // для кастомного отображения значения value
            type: Object,
            default: null,
        },

        dropdown: { // для кастомного отображения выпадающего списка
            type: Object,
            default: null,
        },

        dropdownOffsetTop: { // принудительное смещение компонента dropdown
            type: String,
            default: '',
        },

        dropdownOffsetLeft: { // принудительное смещение компонента dropdown
            type: String,
            default: '',
        },

        dropdownOffsetRight: { // принудительное смещение компонента dropdown
            type: String,
            default: '',
        },

        dropdownWidth: { // принудительное ширина компонента dropdown
            type: String,
            default: '',
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: val => ['base'].includes(val),
        },
    },

    data() {
        return {
            isOpened: false,
            UiUniversalDropdownValue,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        classList() {
            return [{
                [this.$style._disabled]: this.disabled,
            }];
        },

        isSelected() {
            if (this.multiple) {
                return Boolean(this.value.length);
            }

            return Boolean(this.value);
        },

        dropdownStyles() {
            const styles = {};

            if (this.dropdownOffsetLeft) {
                styles.left = this.dropdownOffsetLeft;
            }

            if (this.dropdownOffsetRight) {
                styles.right = this.dropdownOffsetRight;
            }

            if (this.dropdownOffsetTop) {
                styles.top = this.dropdownOffsetTop;
            }

            if (this.dropdownWidth) {
                styles.width = this.dropdownWidth;
            }

            return styles;
        },
    },

    methods: {
        toggleDropdown() {
            if (this.disabled) {
                return;
            }

            this.isOpened = !this.isOpened;
        },

        closeDropdown() {
            this.isOpened = false;
        },
    },
};
</script>

<style lang="scss" module>
    .UiUniversalDropdown {
        position: relative;
        width: 100%;
        height: fit-content;

        &._disabled {
            pointer-events: none;
        }
    }
</style>
