<template>
    <div
        v-click-outside="closeDropdown"
        :class="[$style.UiSimpleDropdown, classList]"
    >
        <UiSimpleDropdownValue
            :display="valueDisplay"
            :value="selectedValue"
            :placeholder="placeholder"
            :is-opened="isOpened"
            :disabled="disabled"
            :size="size"
            :color="color"
            :key-label="keyLabel"
            :key-value="keyValue"
            @click="toggleDropdown"
        />

        <transition name="dropdown-options">
            <div
                v-if="isOpened"
                :class="$style.wrap"
            >
                <div :class="$style.options">
                    <div :class="$style.scroll">
                        <UiSimpleDropdownOption
                            v-for="option in filteredOptions"
                            :key="option[keyValue]"
                            :display="optionDisplay"
                            :value="option"
                            :is-active="!!(value && option[keyValue] === value)"
                            :key-label="keyLabel"
                            :key-value="keyValue"
                            @click="selectItem(option[keyValue])"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
// TODO пока только черновой вариант, только белый цвет, без эффекта disabled, без specs и facets
import ClickOutside from 'vue-click-outside';

import UiSimpleDropdownValue from '~/components/ui/dropdowns/simple-dropdown/UiSimpleDropdownValue.vue';
import UiSimpleDropdownOption from '~/components/ui/dropdowns/simple-dropdown/UiSimpleDropdownOption.vue';
import { kebabToCamelCase } from '~/components/ui/utils/helpers';

export default {
    name: 'UiSimpleDropdown',

    components: {
        UiSimpleDropdownValue,
        UiSimpleDropdownOption,
    },

    directives: {
        ClickOutside,
    },

    props: {
        value: {
            type: [String, Number],
            default: '',
        },

        options: {
            type: Array,
            required: true,
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        valueDisplay: { // для кастомного отображения value
            type: Object,
            default: null,
        },

        optionDisplay: { // для кастомного отображения option
            type: Object,
            default: null,
        },

        showSelected: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: 'big',
            validator: value => ['normal', 'big', 'medium'].includes(value),
        },

        color: {
            type: String,
            default: 'white',
            validator: value => ['gray', 'white'].includes(value),
        },

        keyLabel: {
            type: String,
            default: 'label',
        },

        keyValue: {
            type: String,
            default: 'value',
        },
    },

    data() {
        return {
            isOpened: false,
        };
    },

    computed: {
        classList() {
            return [{
                [this.$style._disabled]: this.disabled,
                [this.$style[`_${kebabToCamelCase(this.color)}`]]: this.color,
            }];
        },

        selectedValue() {
            return this.options.find(item => item[this.keyValue] === this.value);
        },

        filteredOptions() {
            if (this.showSelected) {
                return this.options;
            }

            return this.options.filter(item => item[this.keyValue] !== this.value);
        },
    },

    watch: {
        isOpened(newVal) {
            this.$emit('change-dropdown-opened', newVal);
        },
    },

    methods: {
        selectItem(value) {
            this.$emit('input', value);
            this.closeDropdown();
        },

        toggleDropdown() {
            this.isOpened = !this.isOpened;
        },

        closeDropdown() {
            this.isOpened = false;
        },
    },
};
</script>

<style lang="scss" module>
    .UiSimpleDropdown {
        position: relative;
        width: 100%;
        height: fit-content;

        &._disabled {
            pointer-events: none;
        }

        &._white {
            .wrap {
                border-top: 1px solid $base-200;
                background-color: $base-0;
            }
        }

        &._gray {
            .wrap {
                border-top: 1px solid $base-200;
                background-color: $base-50;
            }
        }

        .wrap {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            width: fit-content;
            min-width: 100%;

            &:global(.dropdown-options-enter-active) {
                transition: opacity $transition;
            }

            &:global(.dropdown-options-leave-active) {
                transition: opacity .15s ease;
            }

            &:global(.dropdown-options-enter),
            &:global(.dropdown-options-leave-to) {
                opacity: 0;
            }
        }

        .options {
            overflow-x: hidden;
            overflow-y: auto;

            @include scroll-style;
        }

        .scroll {
            height: 100%;
            max-height: 14.5rem;
        }
    }
</style>
