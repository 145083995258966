// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsViewSwitcher_Wdm5i{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);grid-gap:.6rem;gap:.6rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;padding:.3rem;background-color:#fff}.FlatsViewSwitcher_Wdm5i._mobile_gqixJ{-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:initial;gap:initial;padding:initial;border-radius:0;border-radius:initial;border:none;background-color:transparent;background-color:initial}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsViewSwitcher": "FlatsViewSwitcher_Wdm5i",
	"_mobile": "_mobile_gqixJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
