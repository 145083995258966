<template>
    <div :class="[$style.UiPagination, classList]" :style="cssVars">
        <div
            v-for="(tab, i) in tabs"
            :key="i"
            :class="$style.tab"
        >
            <div v-if="tab.name" :class="$style.label">
                {{ tab.name }}
            </div>

            <div
                v-if="!withoutAnimate"
                :class="$style.clickOverlay"
                @click="$emit('click:tab', i)"
            >
            </div>

            <div :class="$style.bar">
                <div :class="$style.bg" />

                <div
                    :class="[
                        $style.fill,
                        {
                            [$style._active]:
                                !withoutAnimate ? lazyIndex > i
                                    || stopped && lazyIndex >= i
                                    || !$device.isDesktop && lazyIndex >= i && hasDesignInMobile
                                : lazyIndex === i
                        },
                    ]"
                />
                <div
                    v-if="$device.isDesktop || !hasDesignInMobile"
                    :class="[
                        $style.progress,
                        {[$style._active]: lazyIndex === i}
                    ]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { convertToUnit } from '~/components/ui/utils/helpers';

export default {
    name: 'UiPagination',

    props: {
        tabs: {
            type: Array,
            default: () => [],
        },

        activeIndex: {
            type: Number,
            default: 1,
        },

        expanded: {
            type: Boolean,
            default: false,
        },

        stopped: {
            type: Boolean,
            default: false,
        },

        smallLine: {
            type: Boolean,
            default: false,
        },

        isPresale: {
            type: Boolean,
            default: false,
        },

        color: {
            type: String,
            default: 'white',
            validator: value => ['white', 'black'].includes(value),
        },

        withoutAnimate: {
            type: Boolean,
            default: false,
        },

        speed: {
            type: Number,
            default: 1000,
        },

        duration: {
            type: [Number, String],
            default: 3000,
        },

        hasDesignInMobile: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isFirst: true,
            lazyIndex: null,
            lazyDuration: this.duration,
        };
    },

    computed: {
        cssVars() {
            return {
                '--v-ui-pagination-duration': convertToUnit(this.calcDuration, 'ms'),
            };
        },

        calcDuration() {
            return this.isFirst ? this.duration : this.duration + this.speed;
        },

        classList() {
            return {
                [this.$style._small]: this.smallLine,
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style._expanded]: this.expanded && (this.$device.isDesktop || !this.hasDesignInMobile),
                [this.$style._tablet]: !this.$device.isDesktop && !this.isPresale && this.hasDesignInMobile,
                [this.$style._withoutAnimate]: this.withoutAnimate,
            };
        },
    },

    watch: {
        activeIndex(newVal) {
            this.lazyIndex = newVal;
            setTimeout(() => {
                this.isFirst = false;
            });
        },
    },

    mounted() {
        document.addEventListener('visibilitychange', this.handlerVisibilityChange);
    },

    beforeDestroy() {
        document.removeEventListener('visibilitychange', this.handlerVisibilityChange);
    },

    methods: {
        handlerVisibilityChange() {
            if (document.visibilityState === 'visible') {
                setTimeout(() => {
                    this.lazyIndex = this.activeIndex;
                });
            } else {
                this.lazyIndex = null;
                this.isFirst = true;
            }
        },
    },
};
</script>

<style lang="scss" module>
    .UiPagination {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;

        &._withoutAnimate {
            &._black,
            &._white {
                .progress,
                .fill {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    opacity: .4;

                    &._active {
                        opacity: 1;
                        transition: background-color .3s ease-in-out;
                    }
                }
            }

            &._white {
                .progress {
                    &._active {
                        background-color: $base-0;
                    }
                }
            }

            &._black {
                .progress {
                    &._active {
                        background-color: $base-900;
                    }
                }
            }
        }

        &._white {
            .label {
                color: $base-0;
            }

            .bg {
                background-color: $base-0;
            }

            .fill {
                background-color: $base-0;
            }

            .progress {
                background-color: $base-0;
            }
        }

        &._black {
            .label {
                color: $base-900;
            }

            .bg {
                background-color: $base-200;
                opacity: 1;
            }

            .fill {
                background-color: $base-900;
            }

            .progress {
                background-color: $base-900;
            }
        }

        .tab {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 6.7rem;
            padding-right: .4rem;
            padding-left: .4rem;
            transition: 1s ease;
        }

        .label {
            @include old-p4;

            visibility: hidden;
            margin-bottom: 1.6rem;
            opacity: 0;
            transition: 1s ease;
        }

        .bar {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: .4rem;
            transition: 1s ease;
        }

        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .4;
            transition: 1s ease;
        }

        .fill {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;

            &._active {
                width: 100%;
            }
        }

        .progress {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;

            &._active {
                width: 100%;
                transition: var(--v-ui-pagination-duration) linear;
            }
        }

        .clickOverlay {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: calc(100% + 25px);
            transform: translateY(-50%);
        }
    }

    .UiPagination._expanded {
        .tab {
            width: 100%;
        }

        .label {
            visibility: visible;
            opacity: 1;
        }
    }

    .UiPagination._tablet {
        .tab {
            width: 2.7rem;
            padding-right: .2rem;
            padding-left: .2rem;
        }

        .label {
            display: none;
        }

        .bg {
            opacity: 1;
        }

        &._white {
            .bg {
                background-color: $home-base-4;
            }

            .fill {
                background-color: $home-accent-2;
            }
        }

        &._black {
            .bg {
                background-color: $base-200;
            }

            .fill {
                background-color: $base-900;
            }
        }
    }

    .UiPagination._small {
        .tab {
            width: 2.4rem;
        }

        .bar {
            height: .2rem;
        }
    }
</style>
