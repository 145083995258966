<template>
    <div :class="$style.UiSelectAccordion">
        <template v-for="(option, i) in optionList">
            <UiSelectAccordionItem
                :key="`${i}_${option[valueName]}`"
                :data-gtm-html-id="option[valueName] === '' ? 'any' : option[valueName]"
                :name="name"
                :option="option"
                :value-name="valueName"
                :label-name="labelName"
                :multiple="multiple"
                :is-highlighted="highlightIndex === i"
                :disabled="isDisabled"
                @click="onOptionSelect($event, multiple, valueName, value, name)"
                @show-info-tip="$emit('show-info-modal', $event)"
                @mouseover="onItemMouseOver(i, option)"
                @mouseleave="onItemMouseLeave"
            />

            <!-- Эта часть кода больше не актуализируется, т.к. не используется -->
            <ExpandBlock :key="`${i}_${option[valueName]}_exp`">
                <div
                    v-if="option.items && option.items.length && option.selected"
                    :class="$style.subitems"
                >
                    <UiSelectAccordionSubItem
                        v-for="(item, j) in option.items"
                        :key="`${j}_${item.value}`"
                        :name="childName"
                        :option="item"
                        :value-name="childValueName"
                        :label-name="childLabelName"
                        :multiple="childMultiple"
                        :disabled="isDisabled"
                        @click="onOptionSelect($event, childMultiple, childValueName, childValue, childName)"
                        @show-info-tip-sub="$emit('show-info-modal', $event)"
                        @mouseover="$emit('mouseover', item)"
                        @mouseleave="$emit('mouseleave')"
                    />
                </div>
            </ExpandBlock>
        </template>
    </div>
</template>

<script>
import UiSelectAccordionItem
    from '~/components/ui/select-accordion/UiSelectAccordionItem.vue';
import ExpandBlock from '~/components/common/ExpandBlock.vue';
import UiSelectAccordionSubItem
    from '~/components/ui/select-accordion/UiSelectAccordionSubItem.vue';

export default {
    name: 'UiSelectAccordion',

    components: {
        UiSelectAccordionItem,
        ExpandBlock,
        UiSelectAccordionSubItem,
    },

    props: {
        /**
         * Будут ли включены подпункты. Если true, то поля childName childValue childSpecs childFacets обязательны
         */
        useChild: {
            type: Boolean,
            default: false,
        },

        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * Имя ключа для работы с формами или запросами подпункта
         */
        childName: {
            type: String,
            default: '',
        },

        /**
         * Текущее значение для определения активного элемента
         */
        value: {
            type: [Number, String, Array],
            required: true,
        },

        /**
         * Текущее значение для определения активного элемента подпункта
         */
        childValue: {
            type: [Number, String, Array],
            default: '',
        },

        /**
         * Название поля для value
         */
        valueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label
         */
        labelName: {
            type: String,
            default: 'label',
        },

        /**
         * Название поля для value подпункта
         */
        childValueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label подпункта
         */
        childLabelName: {
            type: String,
            default: 'label',
        },

        /**
         * Диапазон всех доступных значений селектора
         */
        specs: {
            type: Array,
            required: true,
        },

        /**
         * Диапазон всех доступных значений селектора подпункта + поле parentId обязательно для таких specs
         */
        childSpecs: {
            type: Array,
            default: () => [],
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            required: true,
        },

        /**
         * Фасеты подпункта
         */
        childFacets: {
            type: Array,
            default: () => [],
        },

        /**
         * Доп. элемент в селекторе, при выборе
         * которого - сбрасывается состояние value
         */
        resetLabel: {
            type: String,
            default: '',
        },

        /**
         * Доп. элемент в селекторе подпункта, при выборе
         * которого - сбрасывается состояние value
         */
        childResetLabel: {
            type: String,
            default: '',
        },

        /**
         * Включает возможноть выбора более одного элемента
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Включает возможноть выбора более одного элемента подпункта
         */
        childMultiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            highlightIndex: -1,
        };
    },

    computed: {
        optionList() {
            let options = this.createOptions(this.specs, this.facets, this.valueName, this.value, this.labelName, this.multiple, this.resetLabel);

            if (this.useChild) {
                options = options.map(option => {
                    const childSpecs = this.childSpecs.filter(spec => spec.parentId === option[this.valueName]);

                    return {
                        ...option,
                        items: this.createOptions(childSpecs, this.childFacets, this.childValueName, this.childValue, this.childLabelName, this.childMultiple, this.childResetLabel),
                    };
                });
            }

            return options;
        },

        isDisabled() {
            return this.disabled || this.specs.length === 0;
        },
    },

    methods: {
        onOptionSelect(option, multiple, valueName, value, name) {
            let newValue;

            if (multiple) {
                if (!option[valueName]) {
                    newValue = [];
                } else {
                    newValue = value.slice();

                    if (newValue.includes(option[valueName])) {
                        newValue.splice(value.indexOf(option[valueName]), 1);
                    } else {
                        newValue.push(option[valueName]);
                    }
                }
            } else {
                newValue = value !== option[valueName] ? option[valueName] : '';
            }

            if (name === this.name) {
                // если у родителя идет сброс значения, то в зависимости от multiple и childMultiple сбрасываем подпункты
                if (this.multiple && !newValue.length) {
                    if (this.childMultiple) {
                        newValue = {
                            [this.name]: [],
                            [this.childName]: [],
                        };
                    } else {
                        newValue = {
                            [this.name]: [],
                            [this.childName]: '',
                        };
                    }
                } else if (this.multiple && newValue.length > 1 && this.childValue.length) {
                    /**
                     * Если одно из родительских значений сброшено,
                     * при наличии других родительских значений - убирать детей
                     * сброшенного значения, но оставлять прочие
                     */
                    const childsToStayByFacets = [];
                    newValue.forEach(item => {
                        const option = this.optionList.find(option => option.value === item)?.items;

                        if (option && option.length) {
                            option.forEach(item => {
                                childsToStayByFacets.push(item.value);
                            });
                        }
                    });

                    let childsToStayInValue = [];

                    if (childsToStayByFacets.length) {
                        childsToStayInValue = this.childValue.filter(value => childsToStayByFacets.includes(value));
                    }

                    newValue = {
                        [this.name]: newValue,
                        [this.childName]: childsToStayInValue,
                    };
                } else if (newValue === '') {
                    if (this.childMultiple) {
                        newValue = {
                            [this.name]: '',
                            [this.childName]: [],
                        };
                    } else {
                        newValue = {
                            [this.name]: '',
                            [this.childName]: '',
                        };
                    }
                } else {
                    newValue = { [name]: newValue };
                }
            } else {
                newValue = { [name]: newValue };
            }

            this.$emit('change', newValue);
        },

        createOptions(specs, facets, valueName, value, labelName, multiple, resetLabel) {
            const result = [];

            specs.forEach(opt => {
                result.push({
                    ...opt,
                    disabled: this.checkIsDisabled(opt[valueName], facets) && !this.checkIsSelected(opt[valueName], value, multiple),
                    selected: this.checkIsSelected(opt[valueName], value, multiple),
                });
            });

            if (resetLabel) {
                result.unshift({
                    [labelName]: resetLabel,
                    [valueName]: '',
                    disabled: false,
                    selected: !multiple && value === '' || multiple && !value.length,
                });
            }

            return result;
        },

        checkIsSelected(value, currentValue, multiple) {
            if (multiple) {
                return currentValue.includes(value);
            } else {
                return value === currentValue;
            }
        },

        checkIsDisabled(value, facets) {
            return facets && !facets.includes(value) && value !== '';
        },

        onItemMouseOver(index, option) {
            this.highlightIndex = index;
            this.$emit('mouseover', option);
        },

        onItemMouseLeave() {
            this.highlightIndex = -1;
            this.$emit('mouseleave');
        },
    },
};
</script>

<style lang="scss" module>
    .UiSelectAccordion {
        display: flex;
        flex-direction: column;

        .subitems {
            display: flex;
            flex-direction: column;
        }
    }
</style>
