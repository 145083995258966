<template>
    <div :class="$style.FlatsReadyFilter">
        <UiTag
            v-if="hasBlackFriday"
            color="yellowInversed"
            :active="Boolean(filters.isBlackFriday)"
            :size="size"
            :disabled="isReloading"
            :class="[$style.tag, $style.blackFriday]"
            @click="changeBlackFriday"
        >
            <template #iconBefore>
                <FireIcon />
            </template>

            Чёрная пятница
        </UiTag>

        <UiTag
            :color="filters.isReady === true ? 'black' : 'light'"
            :size="size"
            :disabled="isReloading"
            :class="$style.tag"
            data-gtm-html-id="flats_with_keys"
            @click="changeIsReady(true)"
        >
            <template #iconAfter>
                <KeyIcon />
            </template>

            Квартиры с ключами
        </UiTag>

        <UiTag
            :color="filters.isReady === false ? 'black' : 'light'"
            :size="size"
            :disabled="isReloading"
            :class="$style.tag"
            data-gtm-html-id="flats_under_construction"
            @click="changeIsReady(false)"
        >
            Строящиеся квартиры
        </UiTag>

        <UiTag
            :color="Boolean(filters.sale) ? 'black' : 'light'"
            :size="size"
            :disabled="isReloading"
            :class="$style.tag"
            data-gtm-html-id="discounted_flats"
            @click="changeSale"
        >
            <template #iconAfter>
                <PercentIcon />
            </template>

            Со скидкой
        </UiTag>

        <UiTag
            v-if="hasSalesStart"
            :color="Boolean(filters.isSalesStart) ? 'black' : 'light'"
            :size="size"
            :disabled="isReloading"
            :class="$style.tag"
            data-gtm-html-id="start_of_sales"
            @click="changeSalesStart"
        >
            Старт продаж
        </UiTag>

        <UiTag
            v-if="showMoreBtn"
            :size="size"
            :disabled="isReloading"
            :class="$style.tag"
            data-gtm-html-id="more_10"
            @click="$root.$emit('openAdvancedFilter')"
        >
            <template #iconAfter>
                <ArrowRightIcon />
            </template>

            Ещё 10
        </UiTag>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UiTag from '~/components/ui/tags/UiTag.vue';

import FireIcon from '~/assets/icons/ui/fire.svg?inline';
import KeyIcon from '~/assets/icons/ui/key.svg?inline';
import PercentIcon from '~/assets/icons/ui/percent.svg?inline';
import ArrowRightIcon from '~/assets/icons/ui/arrow-right.svg?inline';

export default {
    name: 'FlatsReadyFilter',

    components: {
        UiTag,
        FireIcon,
        KeyIcon,
        PercentIcon,
        ArrowRightIcon,
    },

    props: {
        hasBlackFriday: {
            type: Boolean,
            default: false,
        },

        /**
         * Определяет состояние кнопки "Ещё 10"
         */
        showMoreBtn: {
            type: Boolean,
            default: true,
        },

        size: {
            type: String,
            default: 'big',
        },
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'facets',
            'isReloading',
        ]),

        hasSalesStart() {
            if (!Array.isArray(this.facets?.is_sales_start)) {
                return false;
            }

            return this.facets.is_sales_start.some(el => el === 'true');
        },
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),

        async changeIsReady(isReady) {
            if (this.filters.isReady === isReady) {
                await this.changeFilters({
                    isReady: '',
                    isBlackFriday: '',
                    orderBy: this.filters.orderBy === '-is_black_friday' ? '' : this.filters.orderBy,
                });
            } else {
                await this.changeFilters({
                    isReady,
                    isBlackFriday: '',
                    orderBy: this.filters.orderBy === '-is_black_friday' ? '' : this.filters.orderBy,
                    isSalesStart: '',
                });
            }
        },

        async changeSale() {
            if (this.filters.sale) {
                await this.changeFilters({ sale: '', orderBy: '' });
            } else {
                await this.changeFilters({
                    sale: '1',
                    orderBy: '-sale_percent',
                    isBlackFriday: '',
                });
            }
        },

        async changeBlackFriday() {
            if (this.filters.isBlackFriday) {
                await this.changeFilters({
                    isBlackFriday: '',
                    orderBy: this.filters.orderBy === '-is_black_friday' ? '' : this.filters.orderBy,
                });
            } else {
                await this.changeFilters({
                    isBlackFriday: true,
                    orderBy: '-is_black_friday',
                    sale: '',
                    isReady: '',
                    isSalesStart: '',
                });
            }
        },

        async changeSalesStart() {
            if (this.filters.isSalesStart) {
                await this.changeFilters({ isSalesStart: '' });
            } else {
                await this.changeFilters({
                    isSalesStart: true,
                    isBlackFriday: '',
                    orderBy: this.filters.orderBy === '-is_black_friday' ? '' : this.filters.orderBy,
                });
            }
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsReadyFilter {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-top: -.8rem;
        margin-left: -.8rem;

        .tag {
            width: fit-content;
            margin-top: .8rem;
            margin-left: .8rem;
        }
    }
</style>
