<template>
    <div :class="$style.AdvantagesText">
        <h1 :class="$style.title">
            Жилой район ÁLIA
            <br>
            <span :class="$style.color">WORK.LIVE.PLAY.LEARN.</span>
        </h1>

        <div :class="$style.subtitle">
            <p>
                Жилой район ÁLIA расположен на северо-западе столицы, на живописном берегу Москвы-реки. Здесь авторская архитектура от международного бюро SOM (Skidmore, Owings & Merrill) органично вписана в природный ландшафт, а урбанистическая концепция <b>WORK.LIVE.PLAY.LEARN.</b> позволяет создавать пространство, которое формируется вокруг потребностей жителей и их стиля жизни.
                <br>
                <br>
                Концепция <b>WORK.LIVE.PLAY.LEARN.</b> выражает основные преимущества и философию ÁLIA. На территории жилого района есть всё, чтобы продуктивно работать, наслаждаться комфортом «15-минутного города» и природой, расширить возможности для занятий спортом и учиться по программе лучших московских вузов.
            </p>
        </div>

        <div :class="$style.buttons">
            <UiButton
                :full-width="!$device.isDesktop"
                color="primary"
                link="/project/"
                :class="$style.btn"
            >
                Подробнее о проекте
            </UiButton>
        </div>
    </div>
</template>

<script>
import UiButton from '~/components/ui/home/UiButton';

export default {
    name: 'AdvantagesText',

    components: {
        UiButton,
    },
};
</script>

<style lang="scss" module>
    .AdvantagesText {
        text-align: center;
    }

    .title {
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 700;
        line-height: 1.2;
        color: $base-900;

        .color {
            color: $base-300;
        }
    }

    .subtitle {
        max-width: 64rem;
        margin: 3.2rem auto 0;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.32;
        color: $base-500;

        :global(b) {
            font-weight: 700;
            color: $base-900;
        }
    }

    .buttons {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 6.4rem;
        column-gap: 1.2rem;
    }

    .btn {
        width: fit-content;
    }

    @include respond-to(sm) {
        .title {
            @include old-h2;
        }

        .subtitle {
            max-width: 670px;
            margin-top: 40px;
        }

        .buttons {
            justify-content: space-between;
            max-width: 332px;
            margin-right: auto;
            margin-left: auto;
        }

        .btn {
            width: 100%;
        }
    }

    @include respond-to(xs) {
        .title {
            @include old-h3;

            text-transform: uppercase;
            line-height: 3.1rem;
        }

        .subtitle {
            @include old-p5;

            max-width: 100%;
        }
    }
</style>
