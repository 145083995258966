<template>
    <nuxt-link to="/flats/?newTypeFlat=209">
        <div :class="$style.AdvantagesLinkCard">
            <img
                src="/images/pages/home/advantages/card-line.svg"
                alt="image"
                :class="$style.image"
            >
            <div :class="$style.cardText" v-html="card.title"></div>
            <UiArrowButton
                solid-arrow
                :class="$style.arrowButton"
                :hovered="isArrowButtonHoverStateEnabled"
            />
        </div>
    </nuxt-link>
</template>

<script>
import UiArrowButton from '~/components/ui/home/UiArrowButton.vue';
export default {
    name: 'AdvantagesLinkCard',
    components: {
        UiArrowButton,
    },

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" module>
    .AdvantagesLinkCard {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 3.2rem;
        background: linear-gradient(352.82deg, rgba(255, 255, 255, .1596) 6.23%, rgba(255, 255, 255, .01) 6.24%, rgba(255, 255, 255, .1083) 67%), linear-gradient(0deg, rgba(1, 1, 1, .43), rgba(1, 1, 1, .43)), linear-gradient(0deg, rgba(1, 1, 1, .56), rgba(1, 1, 1, .56)), #0d1322;
        background-blend-mode: normal, color, normal, normal;
        cursor: pointer;

        &:hover {
            .image {
                transform: scale(1.05);
            }
        }

        @include respond-to(sm) {
            padding: 2.8rem;
        }

        @include respond-to(xxs) {
            padding: 2rem;
        }
    }

    .image {
        position: absolute;
        right: 0;
        bottom: 0;
        transition: all $homeTransition;
    }

    .cardText {
        position: relative;
        z-index: 1;
        padding-right: 3.2rem;
        font-size: 4rem;
        font-weight: 700;
        line-height: 108%;
        color: $inversed;

        @include respond-to(sm) {
            font-size: 2.4rem;
        }
    }

    .arrowButton {
        position: absolute;
        right: 3.2rem;
        bottom: 3.2rem;
        font-size: 0;

        @include respond-to(sm) {
            right: 2.8rem;
            bottom: 2.8rem;
            width: 2rem;
            max-width: 2rem;
            height: 2rem;
            max-height: 2rem;
        }

        @include respond-to(xxs) {
            right: 2rem;
            bottom: 2rem;
        }
    }
</style>
