// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsTagsArray_CrsoP{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-top:-.8rem;margin-left:-.8rem}.FlatsTagsArray_CrsoP .tag_6D8AK{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:.8rem;margin-left:.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsTagsArray": "FlatsTagsArray_CrsoP",
	"tag": "tag_6D8AK"
};
module.exports = ___CSS_LOADER_EXPORT___;
