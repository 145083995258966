// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectGenplanUrbanNavigation_XtR5N{position:relative;width:100%;height:100%;pointer-events:none}.ProjectGenplanUrbanNavigation_XtR5N:not(._disabled_oYogn)>*{pointer-events:all}.ProjectGenplanUrbanNavigation_XtR5N .left_7XGRk{position:absolute;top:3.2rem;left:6.4rem}@media(max-width:991.98px){.ProjectGenplanUrbanNavigation_XtR5N .left_7XGRk{display:none}}.ProjectGenplanUrbanNavigation_XtR5N .right_QOCdE{position:absolute;top:3.2rem;right:6.4rem}@media(max-width:991.98px){.ProjectGenplanUrbanNavigation_XtR5N .right_QOCdE{top:2.4rem;right:2.4rem}}@media(max-width:519.98px){.ProjectGenplanUrbanNavigation_XtR5N .right_QOCdE{top:auto;right:auto;bottom:2rem;left:2rem;width:calc(100% - 4rem)}}.ProjectGenplanUrbanNavigation_XtR5N .text_tXxo7{max-width:20.1rem;font-size:16px;font-weight:400;line-height:1.24;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectGenplanUrbanNavigation": "ProjectGenplanUrbanNavigation_XtR5N",
	"_disabled": "_disabled_oYogn",
	"left": "left_7XGRk",
	"right": "right_QOCdE",
	"text": "text_tXxo7"
};
module.exports = ___CSS_LOADER_EXPORT___;
