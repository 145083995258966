// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectGenplanAdvantages_JK\\+Pv{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;grid-gap:.6rem;gap:.6rem;width:100%}.ProjectGenplanAdvantages_JK\\+Pv .tag_x-mwC{position:relative;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;pointer-events:none}.ProjectGenplanAdvantages_JK\\+Pv .tag_x-mwC._extra_zej2t{pointer-events:all}.ProjectGenplanAdvantages_JK\\+Pv .popup_FYNpL{position:absolute;bottom:calc(100% + .85rem);left:100%;visibility:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:.6rem;gap:.6rem;width:-webkit-max-content;width:-moz-max-content;width:max-content;max-width:37rem;padding:1.6rem;-webkit-box-shadow:1.2rem 1.2rem 3.4rem rgba(0,0,0,.05);box-shadow:1.2rem 1.2rem 3.4rem rgba(0,0,0,.05);background-color:#fff;opacity:0;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:opacity .3s ease;transition:opacity .3s ease}.ProjectGenplanAdvantages_JK\\+Pv .popup_FYNpL._shown_izbcZ{visibility:visible;opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectGenplanAdvantages": "ProjectGenplanAdvantages_JK+Pv",
	"tag": "tag_x-mwC",
	"_extra": "_extra_zej2t",
	"popup": "popup_FYNpL",
	"_shown": "_shown_izbcZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
