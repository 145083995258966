export const centerCoords = [55.820733, 37.423932];

export const apiKey = '08fc89c7-604e-4ab7-9a24-44ac5580bc59';

export const ymapClientKey = Symbol('getYmapClient');

export const behaviorsDefault = [
    'drag', // перемещение карты при нажатой левой кнопке мыши либо одиночным касанием
    'scrollZoom', // изменение масштаба колесом мыши
    'dblClickZoom', // масштабирование карты двойным щелчком кнопки мыши
    'multiTouch', // масштабирование карты двойным касанием
];
