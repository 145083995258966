// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiRangeInputValue_R-BL4{position:relative;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content}.UiRangeInputValue_R-BL4 .inner_nl6Pc{position:relative;z-index:2;display:-webkit-box;display:-ms-flexbox;display:flex}.UiRangeInputValue_R-BL4 .native_i1Wpu{padding:0;background-color:transparent;-webkit-transition:color .3s ease;transition:color .3s ease}.UiRangeInputValue_R-BL4._medium_jJjdW .native_i1Wpu{font-size:1.6rem;font-weight:700;line-height:2rem;color:#2f2f2f}.UiRangeInputValue_R-BL4._base_I2bHZ .native_i1Wpu{color:#2f2f2f}.UiRangeInputValue_R-BL4._transparent_Zk-yC .native_i1Wpu{font-size:1.4rem;font-weight:700;line-height:1.8rem;color:#2f2f2f;color:#fff}.UiRangeInputValue_R-BL4._active_aA0OZ .native_i1Wpu{color:#616161}.UiRangeInputValue_R-BL4._disabled_iBFS8{pointer-events:none}.UiRangeInputValue_R-BL4._disabled_iBFS8 .native_i1Wpu{color:#a0a0a0;pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiRangeInputValue": "UiRangeInputValue_R-BL4",
	"inner": "inner_nl6Pc",
	"native": "native_i1Wpu",
	"_medium": "_medium_jJjdW",
	"_base": "_base_I2bHZ",
	"_transparent": "_transparent_Zk-yC",
	"_active": "_active_aA0OZ",
	"_disabled": "_disabled_iBFS8"
};
module.exports = ___CSS_LOADER_EXPORT___;
