// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeNewsCard_cw6Sv{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;width:100%;height:100%;padding:2.8rem 2.7rem 2.8rem 2.8rem;background-color:#fff;cursor:pointer}.HomeNewsCard_cw6Sv:hover .icon_4OJKm{opacity:1}.HomeNewsCard_cw6Sv:hover .newsDescription_sUAle{color:#18191c}.newsTitle_4ixH6{margin-bottom:2.4rem;text-transform:uppercase;font-size:2rem;font-weight:700;line-height:1.4;color:#2f2f2f}@media(max-width:519.98px){.newsTitle_4ixH6{font-size:1.6rem}}.newsDescription_sUAle{margin-bottom:2.9rem;font-size:1.4rem;font-weight:300;line-height:1.32;color:#717171;-webkit-transition:.3s ease;transition:.3s ease}.newsDescription_sUAle p{display:inline-block}.secondLine_blWcs{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;margin-top:auto}.newsDate_32rBz{padding:9px 12px 7px;background:#f3f3f3;text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:1;-webkit-box-shadow:inset .01px .01px 68px hsla(0,0%,100%,.05);box-shadow:inset .01px .01px 68px hsla(0,0%,100%,.05);-webkit-backdrop-filter:blur(86px);backdrop-filter:blur(86px)}.icon_4OJKm{width:40px;height:40px;opacity:0;-webkit-transition:.3s ease;transition:.3s ease}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeNewsCard": "HomeNewsCard_cw6Sv",
	"icon": "icon_4OJKm",
	"newsDescription": "newsDescription_sUAle",
	"newsTitle": "newsTitle_4ixH6",
	"secondLine": "secondLine_blWcs",
	"newsDate": "newsDate_32rBz"
};
module.exports = ___CSS_LOADER_EXPORT___;
