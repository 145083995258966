// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectGenplanFilterBtn_Yylox{position:relative}.ProjectGenplanFilterBtn_Yylox .btn_0s3ow{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.ProjectGenplanFilterBtn_Yylox .btn_0s3ow:hover+.tooltip_w9aRE{opacity:1}@media(max-width:991.98px){.ProjectGenplanFilterBtn_Yylox .btn_0s3ow:hover+.tooltip_w9aRE{opacity:0}}@media(max-width:519.98px){.ProjectGenplanFilterBtn_Yylox .btn_0s3ow{width:100%}}.ProjectGenplanFilterBtn_Yylox .tooltip_w9aRE{position:absolute;top:calc(100% + 1.2rem);left:50%;padding:1.2rem;background-color:#2f2f2f;white-space:nowrap;font-size:1.4rem;font-weight:300;line-height:1.24;color:#fff;opacity:0;-webkit-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:opacity .3s ease;transition:opacity .3s ease;-webkit-box-shadow:1.2rem 1.2rem 3.4rem rgba(0,0,0,.05);box-shadow:1.2rem 1.2rem 3.4rem rgba(0,0,0,.05)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectGenplanFilterBtn": "ProjectGenplanFilterBtn_Yylox",
	"btn": "btn_0s3ow",
	"tooltip": "tooltip_w9aRE"
};
module.exports = ___CSS_LOADER_EXPORT___;
