<template>
    <div ref="FlatsAdvancedFilter" :class="[$style.FlatsAdvancedFilter, classList]">
        <component
            :is="$device.isDesktop ? 'UiScrollbox' : 'div'"
            resizable
            skip-offset
            color="black"
            :class="$device.isDesktop ? $style.scrollbox : $style.adaptiveWrapper"
        >
            <div :class="[$style.header, 'container-home']">
                <FlatsAdvancedFilterHeader @close="$root.$emit('closeAdvancedFilter')" />
            </div>

            <div
                v-if="$device.isDesktop"
                :class="[$style.body, 'container-home']"
            >
                <FlatsAdvancedFiltersDesktop />
            </div>

            <UiScrollbox
                v-else
                resizable
                skip-offset
                :class="$style.scrollbox"
            >
                <div :class="[$style.body, 'container-home']">
                    <FlatsAdvancedFiltersTablet v-if="$device.isTablet || isIpadPro" />

                    <FlatsAdvancedFiltersMobile v-if="$device.isMobile" />
                </div>
            </UiScrollbox>

            <div :class="[$style.footer, 'container-home']">
                <FlatsAdvancedFilterFooter @close="$root.$emit('closeAdvancedFilter')" />
            </div>
        </component>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

import UiScrollbox from '~/components/ui/scrollbox/UiScrollbox.vue';
import FlatsAdvancedFilterHeader from '~/components/flats/filters/advanced-filter/FlatsAdvancedFilterHeader.vue';
import FlatsAdvancedFiltersDesktop from '~/components/flats/filters/advanced-filter/FlatsAdvancedFiltersDesktop.vue';
import FlatsAdvancedFiltersTablet from '~/components/flats/filters/advanced-filter/FlatsAdvancedFiltersTablet.vue';
import FlatsAdvancedFiltersMobile from '~/components/flats/filters/advanced-filter/FlatsAdvancedFiltersMobile.vue';
import FlatsAdvancedFilterFooter from '~/components/flats/filters/advanced-filter/FlatsAdvancedFilterFooter.vue';

export default {
    name: 'FlatsAdvancedFilter',

    components: {
        UiScrollbox,
        FlatsAdvancedFilterHeader,
        FlatsAdvancedFiltersDesktop,
        FlatsAdvancedFiltersTablet,
        FlatsAdvancedFiltersMobile,
        FlatsAdvancedFilterFooter,
    },

    data() {
        return {
            isOpened: false,
        };
    },

    computed: {
        ...mapState('flats', [
            'filters',
        ]),

        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        classList() {
            return {
                [this.$style._opened]: this.isOpened,
            };
        },
    },

    mounted() {
        this.$root.$on('openFlatsAdvancedFilter', this.openFilter);
        this.$root.$on('closeFlatsAdvancedFilter', this.closeFilter);

        let headerHeight = 0;

        const header = document.querySelector('.TheHeader');
        const bannerHeader = document.querySelector('.HeaderBanner:not(.HeaderBanner__hidden)');

        [header, bannerHeader].forEach(el => {
            if (!el) {
                return false;
            }
            const { height } = el.getBoundingClientRect();
            headerHeight += height;
        });

        this.$refs.FlatsAdvancedFilter.style.setProperty('--header-height', headerHeight + 'px');
    },

    beforeDestroy() {
        this.$root.$off('openFlatsAdvancedFilter', this.openFilter);
        this.$root.$off('closeFlatsAdvancedFilter', this.closeFilter);
    },

    methods: {
        ...mapActions({
            resetFilters: 'flats/resetFilters',
        }),

        openFilter() {
            disablePageScroll();
            this.isOpened = true;
        },

        closeFilter() {
            this.isOpened = false;
            setTimeout(() => {
                enablePageScroll();
            }, 800);
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsAdvancedFilter {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -2;
        display: flex;
        width: 100%;
        height: 70rem;
        max-height: calc(100vh - var(--header-height));
        background-color: $base-0;
        transform: translateY(-100%);
        transition: .8s ease;
        flex-direction: column;

        &._opened {
            transform: translateY(0);
        }

        .header {
            width: 100%;
            padding-top: 4.8rem;
            padding-bottom: 3.2rem;
        }

        .scrollbox {
            flex-grow: 1;
            max-height: 100%;

            @include respond-to(sm) {
                flex-grow: unset;
            }
        }

        .adaptiveWrapper {
            display: flex;
            height: 100%;
            flex-direction: column;
        }

        .body {
            width: 100%;
            height: 100%;
        }

        .footer {
            width: 100%;
            padding-top: 3.2rem;
            padding-bottom: 4.8rem;
        }

        @include respond-to(md) {
            position: fixed;
            top: -100%;
            z-index: 21;
            height: calc(100 * var(--vh, 1vh));
            max-height: none;

            &._opened {
                top: 0;
            }

            .header {
                padding-top: 2.4rem;
                padding-bottom: 2.4rem;
            }

            .footer {
                padding-top: 1.6rem;
                padding-bottom: 1.6rem;
            }
        }

        @include respond-to(xs) {
            .header {
                padding-bottom: 2rem;
            }

            .footer {
                padding-top: 1.2rem;
                padding-bottom: 1.2rem;
            }
        }
    }
</style>
