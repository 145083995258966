// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiSelectAccordion_lhY3x,.UiSelectAccordion_lhY3x .subitems_sWW\\+M{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiSelectAccordion": "UiSelectAccordion_lhY3x",
	"subitems": "subitems_sWW+M"
};
module.exports = ___CSS_LOADER_EXPORT___;
