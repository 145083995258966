// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenplanTooltipWrap_sf1Z5{position:absolute}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GenplanTooltipWrap": "GenplanTooltipWrap_sf1Z5"
};
module.exports = ___CSS_LOADER_EXPORT___;
