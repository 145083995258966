<template>
    <div :class="$style.NewHomePage">
        <HomeHeroAnimation
            :slides="allTopSlides"
            :class="$style.hero"
        />

        <HomeCards :class="$style.cards" />

        <MiniFilters />

        <Advantages
            :gallery="allGalleryCategories"
            :video="allGallerySlidesVideo"
            :video-image="videoImage"
            :accessibility="[accessibilityTitle, accessibilityNumbersFoot, accessibilityNumbersMetro, accessibilityNumbersCar]"
            :class="$style.advantages"
        />

        <HomeSaleForm
            :title="formData.title"
            :description="formData.description"
        />

        <div
            ref="HomeArchitecture"
            :class="$style.genplan"
        >
            <HomeArchitecture />
        </div>

        <HomeFormat
            :title="formatData.title"
            :text="formatData.text"
            :class="$style.homeFormat"
        />

        <HomeOfficeForm />

        <div :class="$style.background">
            <HomeNews v-if="allNews.length" :news="allNews" />
            <div class="container-home">
                <HomePartners />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min.js';

import HostMixin from '~/assets/js/mixins/HostMixin';
import getMicroMarkup from '~/assets/js/utils/microMarkup';

import mainPageNew from '~/queries/new-home/mainPageNew.graphql';
import firstPosts from '~/queries/center/firstPosts.graphql';

import HomeCards from '~/components/home/HomeCards.vue';
import Advantages from '~/components/home/advantages/Advantages.vue';
import HomeSaleForm from '~/components/home/saleForm/HomeSaleForm.vue';
import HomeArchitecture from '~/components/home/HomeArchitecture.vue';
import HomeFormat from '~/components/home/format/HomeFormat.vue';
import HomeOfficeForm from '~/components/home/HomeOfficeForm.vue';
import HomeNews from '~/components/home/HomeNews.vue';
import HomePartners from '~/components/home/HomePartners.vue';
import HomeHeroAnimation from '~/components/home/HomeHeroAnimation.vue';
import allHuuPages from '~/queries/flats/allHuuPages.graphql';
import { getNodeArray } from '~/assets/js/utils';
import { DEFAULT_FILTERS, ERROR_MESSAGES } from '~/assets/js/constants';
import { applyQuery, queryToObject } from '~/assets/js/utils/queryUtils';
import MiniFilters from '~/components/common/filter/MiniFilters.vue';
import FlatsAdvancedFilter from '~/components/flats/filters/advanced-filter/FlatsAdvancedFilter';

gsap.registerPlugin(ScrollTrigger);

const HEAD_FIELDS = {
    title: 'Современный и стильный жилой район ÁLIA в районе метро Тушино',
    description: 'Стильный и современный жилой район ÁLIA в Москве в районе метро Тушино. Продуманная градостроительная концепция комплекса WORK.LIVE.PLAY.LEARN сочетает в себе опыт лучших российских и мировых практик для комфортной жизни. Узнать подробнее о проекте можно на сайте или по телефону: +7 (495) 122-00-00',
};

function fetchGraphQLData(app) {
    return Promise.all([
        app.$axios.$post('/graphql/', { query: mainPageNew.loc.source.body }),
        app.$axios.$post('/graphql/', { query: firstPosts.loc.source.body }),
    ]);
}

/**
 * Функция для получения данных страницы с ЧЧ URL
 */
async function fetchHuuPageData(app, pathMatch) {
    const { data } = await app.$axios.$post('/graphql/', {
        query: allHuuPages.loc.source.body,
        variables: {
            slug: pathMatch,
            type: 'flat',
        },
    });

    const huuPages = getNodeArray(data, 'allHuuPages');
    const huuPage = huuPages.pop();
    const huuQuery = queryToObject(huuPage.param);

    return { huuPage, huuQuery };
}

const defaultHuuPageData = {
    huuPage: {},
    huuQuery: {},
};

function mapResponse(data) {
    return data?.edges?.length ? data.edges.map(item => item.node) : [];
}

export default {
    name: 'NewHomePage',

    components: {
        MiniFilters,
        HomeHeroAnimation,
        HomeCards,
        Advantages,
        HomeSaleForm,
        HomeArchitecture,
        HomeFormat,
        HomeOfficeForm,
        HomeNews,
        HomePartners,
    },

    mixins: [
        HostMixin,
    ],

    async asyncData({ query, store, params, app, error, $sentry }) {
        try {
            const [response1, response2] = await fetchGraphQLData(app);

            const huuPageData = params.pathMatch ? await fetchHuuPageData(app, params.pathMatch) : defaultHuuPageData;

            if (!huuPageData.huuPage) {
                return error({ statusCode: 404, message: ERROR_MESSAGES.TRANSLATED_PAGE_NOT_FOUND });
            }

            // подмешиваем query в объект фильтров, пушим в store
            const filterFromQuery = applyQuery(DEFAULT_FILTERS, { ...query, ...huuPageData.huuQuery });

            store.dispatch('flats/setFilters', filterFromQuery);
            store.dispatch('flats/setHuuQuery', huuPageData.huuQuery);

            if (store.state.flats.filters.scenario !== '') {
                store.dispatch('flats/setFilterView', 'scenarios');
            }

            await Promise.all([
                store.dispatch('flats/fetchSpecs'),
                store.dispatch('flats/fetchFacets'),
            ]);

            const allTopSlides = mapResponse(response1.data?.mainPageNew?.allTopSlides);
            const allGalleryCategories = mapResponse(response1.data?.mainPageNew?.allGalleryCategories);
            const allGallerySlidesVideo = mapResponse(response1.data?.mainPageNew?.allGallerySlidesVideo);
            const allFeatureCategories = mapResponse(response1.data?.mainPageNew?.allFeatureCategories);
            const accessibilityTitle = response1.data?.mainPageNew?.travelBlockTitle ? response1.data?.mainPageNew?.travelBlockTitle : '';
            const accessibilityNumbersFoot = mapResponse(response1.data?.mainPageNew?.allTravelTimesOnFoot);
            const accessibilityNumbersMetro = mapResponse(response1.data?.mainPageNew?.allTravelTimesByPublicTransport);
            const accessibilityNumbersCar = mapResponse(response1.data?.mainPageNew?.allTravelTimesByCar);
            const allNews = mapResponse(response2?.data?.firstPosts);

            store.dispatch('home/gallery/setAlbums', allGalleryCategories);
            if (allGalleryCategories.length) {
                store.dispatch('home/gallery/setActiveGalleryAlbum', allGalleryCategories[0]);
            }

            store.dispatch('home/gallery/setTabs', allFeatureCategories);

            const videoImage = {
                imageDisplay: response1.data?.mainPageNew?.imageAboutVideoDisplay || '',
                imagePreview: response1.data?.mainPageNew?.imageAboutVideoPreview || '',
            };

            const formatData = {
                title: response1.data?.mainPageNew?.flatFormatBlockTitle,
                text: response1.data?.mainPageNew?.flatFormatBlockSubtitle,
            };

            const formData = {
                title: response1.data?.mainPageNew?.consultationBlockTitle || '',
                description: response1.data?.mainPageNew?.consultationBlockSubtitle || '',
            };

            $sentry.captureException(new Error('test error in index page in landing on SERVER SIDE'));

            return {
                allTopSlides,
                allGalleryCategories,
                allGallerySlidesVideo,
                videoImage,
                allNews,
                accessibilityTitle,
                accessibilityNumbersFoot,
                accessibilityNumbersMetro,
                accessibilityNumbersCar,
                formatData,
                formData,
            };
        } catch (e) {
            console.warn('[NewHomePage] asyncData: ', e);
        }
    },

    data() {
        return {
            allTopSlides: null,
            allGalleryCategories: null,
            allGallerySlidesVideo: null,
            videoImage: null,
            allNews: null,
            formData: {},
        };
    },

    async fetch() {
        await this.loadObjects();
    },

    head() {
        const {
            title,
            description,
        } = HEAD_FIELDS;

        return {
            title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    name: 'yandex-verification',
                    content: 'e53793f47fa6bd73',
                },

                ...getMicroMarkup({
                    ...HEAD_FIELDS,
                    url: this.fullPath,
                }),
            ],
        };
    },

    computed: {
        ...mapState('PageTransition', [
            'changing',
        ]),

        ...mapState('flats', [
            'filters',
            'specs',
            'facets',
            'isReloading',
        ]),
    },

    mounted() {
        if (this.changing) {
            this.animate();
        }

        this.$root.$on('openAdvancedFilter', this.openAdvancedFilter);
        this.$root.$on('closeAdvancedFilter', this.closeAdvancedFilter);
        this.$root.$on('onLayoutOverlayClick', this.closeAdvancedFilter);

        if (this.$route?.query?.building) {
            this.$refs.HomeArchitecture.scrollIntoView();
        }

        this.$sentry.captureException(new Error('test error in main page CLIENT SIDE'));
    },

    beforeDestroy() {
        this.$root.$off('openAdvancedFilter', this.openAdvancedFilter);
        this.$root.$off('closeAdvancedFilter', this.closeAdvancedFilter);
        this.$root.$off('onLayoutOverlayClick', this.closeAdvancedFilter);
        this.closeAdvancedFilter();
    },

    methods: {
        ...mapActions({
            animate: 'PageTransition/animate',
            loadObjects: 'flats/loadObjects',
            setHuuQuery: 'flats/setHuuQuery',
            updateQueryParams: 'flats/updateQueryParams',
        }),

        openAdvancedFilter() {
            this.$root.$emit('createHeaderSlot', FlatsAdvancedFilter);
            setTimeout(() => {
                this.$root.$emit('showLayoutOverlay');
                this.$root.$emit('openFlatsAdvancedFilter');
            }, 0);
        },

        closeAdvancedFilter() {
            this.$root.$emit('hideLayoutOverlay');
            this.$root.$emit('closeFlatsAdvancedFilter');

            setTimeout(() => {
                this.$root.$emit('destroyHeaderSlot');
            }, 1000);
        },
    },
};
</script>

<style lang="scss" module>
.NewHomePage {
    .hero {
        margin-bottom: 3.6rem;
    }

    .cards {
        margin-bottom: 6.4rem;
    }

    .advantages {
        margin-bottom: -2px;
    }

    .genplan {
        position: relative;
        z-index: 1;
        margin-bottom: 3.6rem;
        padding-top: 10rem;

        @include respond-to(sm) {
            // margin-bottom: 10rem;
            margin-bottom: 2.4rem;
            padding-top: $headerHeightTablet;
        }

        @include respond-to(xs) {
            margin-bottom: 3.6rem;
            padding-top: $headerHeightMobile;
        }
    }

    .background {
        padding: 10rem 0 6.4rem;
        background-color: $base-50;

        @include respond-to(sm) {
            padding: 46px 0;
        }
    }

    .homeFormat {
        padding-bottom: 10rem;
    }
}
</style>
