<template>
    <div :class="$style.FlatsTagsArray">
        <UiTag
            v-for="(item, i) in specs"
            :key="`${name}_${i}`"
            :color="checkIsActive(item.value) ? 'black' : 'light'"
            :size="size"
            :disabled="checkIsDisabled(item.value)"
            :data-gtm-html-id="item.value"
            :class="$style.tag"
            @click="onClick(item.value)"
        >
            {{ item.label }}

            <FlatsTooltip
                v-if="item.tooltip"
                :text="item.tooltip"
            />
        </UiTag>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import UiTag from '~/components/ui/tags/UiTag.vue';
import FlatsTooltip from '~/components/flats/common/FlatsTooltip.vue';

export default {
    name: 'FlatsTagsArray',

    components: {
        UiTag,
        FlatsTooltip,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * Текущее значение для определения активного элемента
         */
        value: {
            type: [Array, String],
            required: true,
        },

        /**
         * Диапазон всех доступных значений селектора
         */
        specs: {
            type: Array,
            required: true,
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            required: true,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        size: {
            type: String,
            default: 'medium',
        },
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),
    },

    methods: {
        checkIsActive(value) {
            if (this.multiple) {
                return this.value.includes(value);
            }

            return this.value === value;
        },

        checkIsDisabled(value) {
            return !this.facets.includes(value) && !this.checkIsActive(value) || this.disabled;
        },

        onClick(value) {
            if (this.checkIsDisabled(value)) {
                return;
            }

            if (this.multiple) {
                let values = [...this.value];

                if (values.includes(value)) {
                    values = values.filter(el => el !== value);
                } else {
                    values.push(value);
                }

                this.$emit('change', { [this.name]: values });
            } else if (this.checkIsActive(value)) {
                this.$emit('change', { [this.name]: '' });
            } else {
                this.$emit('change', { [this.name]: value });
            }
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsTagsArray {
        display: flex;
        flex-wrap: wrap;
        margin-top: -.8rem;
        margin-left: -.8rem;

        .tag {
            width: fit-content;
            margin-top: .8rem;
            margin-left: .8rem;
        }
    }
</style>
