// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Advantages_lecEr{position:relative;z-index:1;max-width:1440px;margin:0 auto}.Advantages_lecEr .gallery_3\\+fCF{z-index:2}.Advantages_lecEr .bg_x1-84{position:fixed;top:0;left:0;z-index:3;visibility:hidden;width:100vw;height:100vh;background-color:#fff;opacity:0;-webkit-transition:.3s ease;transition:.3s ease}.Advantages_lecEr .list_5zirz{z-index:4}.Advantages_lecEr .text_UOdWQ{margin-bottom:6.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Advantages": "Advantages_lecEr",
	"gallery": "gallery_3+fCF",
	"bg": "bg_x1-84",
	"list": "list_5zirz",
	"text": "text_UOdWQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
