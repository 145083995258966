<template>
    <div :class="[$style.ProjectSectionTitle, classes]">
        <component :is="tag" :class="$style.title">
            <span
                v-if="firstLine"
                :class="[$style.line, {[$style._dark]: isReversed}]"
                v-html="firstLine"
            />

            <span
                v-if="secondLine"
                :class="[$style.line, {[$style._dark]: !isReversed}]"
                v-html="secondLine"
            />
        </component>

        <div
            v-if="description"
            :class="$style.description"
            v-html="description"
        />
    </div>
</template>

<script>
export default {
    name: 'ProjectSectionTitle',

    props: {
        firstLine: {
            type: String,
            default: '',
        },

        secondLine: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        isReversed: {
            type: Boolean,
            default: false,
        },

        isSmallDescription: {
            type: Boolean,
            default: false,
        },

        tag: {
            type: String,
            default: 'h2',
        },
    },

    computed: {
        classes() {
            return {
                [this.$style._smallDescription]: this.isSmallDescription,
            };
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectSectionTitle {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3.2rem;
        max-width: 72.8rem;
        margin-right: auto;
        margin-left: auto;

        @include respond-to(sm) {
            gap: 2.4rem;
            max-width: initial;
        }

        @include respond-to(xxs) {
            gap: 2rem;
        }

        &._smallDescription {
            .description {
                font-size: 1.6rem;

                @include respond-to(sm) {
                    font-size: 1.4rem;
                }
            }
        }
    }

    .title {
        display: grid;
        grid-template-columns: 1fr;
        gap: .4rem;
    }

    .line {
        @include hc4;

        text-align: center;
        color: $base-300;

        &._dark {
            color: $base-900;
        }

        @include respond-to(sm) {
            font-size: 3.2rem;
            line-height: 4rem;
            letter-spacing: -.04em;
            opacity: .9;
        }

        @include respond-to(xxs) {
            @include hc5;

            color: $base-300;
        }
    }

    .description {
        text-align: center;
        font-size: 2rem;
        font-weight: 300;
        line-height: 124%;
        color: $base-500;

        @include respond-to(xxs) {
            font-size: 1.8rem;
        }
    }
</style>
