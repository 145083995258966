// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MiniFilters_lWgdA{min-height:12.4rem;margin-bottom:10rem}@media(max-width:1440.98px){.MiniFilters_lWgdA{min-height:19.2rem}}@media(max-width:991.98px){.MiniFilters_lWgdA{min-height:11.6rem}}.MiniFilters_lWgdA .title_Pt6aJ{font-size:3.2rem;font-weight:700;line-height:3.6rem;color:#2f2f2f;margin-bottom:3.2rem;text-transform:uppercase}@media(max-width:519.98px){.MiniFilters_lWgdA .title_Pt6aJ{font-size:2.4rem;font-weight:700;line-height:3.1rem;letter-spacing:normal;color:#2f2f2f;font-size:2rem;line-height:2.6rem;margin-bottom:2rem}}.MiniFilters_lWgdA ._desktop_hG75w{display:-webkit-box;display:-ms-flexbox;display:flex}@media(max-width:767.98px){.MiniFilters_lWgdA ._desktop_hG75w{display:none}}.MiniFilters_lWgdA ._mobile_sxszA{display:none}@media(max-width:767.98px){.MiniFilters_lWgdA ._mobile_sxszA{display:-webkit-box;display:-ms-flexbox;display:flex}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MiniFilters": "MiniFilters_lWgdA",
	"title": "title_Pt6aJ",
	"_desktop": "_desktop_hG75w",
	"_mobile": "_mobile_sxszA"
};
module.exports = ___CSS_LOADER_EXPORT___;
