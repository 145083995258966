// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectLocationVisualCard_\\+4n2s{padding:.8rem;background-color:#fff}.ProjectLocationVisualCard_\\+4n2s._isModal_uPtL6{padding-top:2rem;padding-bottom:2rem;border-top-left-radius:1.2rem;border-top-right-radius:1.2rem}.ProjectLocationVisualCard_\\+4n2s._isModal_uPtL6 .content_nkt\\+7{padding-top:2.4rem}@media(max-width:519.98px){.ProjectLocationVisualCard_\\+4n2s._isModal_uPtL6 .content_nkt\\+7{padding-top:2rem}}.ProjectLocationVisualCard_\\+4n2s._isModal_uPtL6 .title_5YxlM{margin-bottom:1.6rem;font-size:2.4rem;line-height:3.1rem}@media(max-width:519.98px){.ProjectLocationVisualCard_\\+4n2s._isModal_uPtL6 .title_5YxlM{margin-bottom:.8rem}}.imageContainer_wKeG1{overflow:hidden;-webkit-transform:translateZ(0);transform:translateZ(0);position:relative}.imageContainer_wKeG1:before{content:\"\";display:block;width:100%;padding-top:62.1212121212%}.imageContainer_wKeG1>.aspect-content_ExLGD{position:absolute;top:0;left:0;width:100%;height:100%}.content_nkt\\+7{margin:1.2rem}.title_5YxlM{margin-bottom:1.6rem;font-size:1.6rem;font-weight:400;line-height:1.24}.text_VziMn{font-size:1.4rem;font-weight:300;line-height:1.32}@media(max-width:519.98px){.text_VziMn{font-size:1.2rem}}.estimate_ehyX1{position:relative;margin-top:2rem;padding-top:.2rem;padding-left:2.6rem;font-size:1.2rem;font-weight:700;line-height:1.6rem;letter-spacing:-.024rem;color:#717171}.estimate_ehyX1 span{color:#2f2f2f}.estimate_ehyX1 svg{position:absolute;top:50%;left:0;display:inline-block;width:2rem;height:2rem;-webkit-transform:translateY(-50%);transform:translateY(-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectLocationVisualCard": "ProjectLocationVisualCard_+4n2s",
	"_isModal": "_isModal_uPtL6",
	"content": "content_nkt+7",
	"title": "title_5YxlM",
	"imageContainer": "imageContainer_wKeG1",
	"aspect-content": "aspect-content_ExLGD",
	"text": "text_VziMn",
	"estimate": "estimate_ehyX1"
};
module.exports = ___CSS_LOADER_EXPORT___;
