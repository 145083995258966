<template>
    <div :class="$style.ProjectGenplanUrbanTooltip">
        <ImageLazy
            v-if="imageDisplay"
            :preview="imagePreview"
            :origin="imageDisplay"
            :absolute="false"
            :class="$style.image"
        />

        <div :class="$style.container">
            <div :class="$style.info">
                <div :class="$style.header">
                    <h3
                        :class="$style.title"
                        v-html="title"
                    />

                    <!-- <InfoIcon :class="$style.icon" /> -->
                </div>

                <p
                    v-if="subtitle"
                    :class="$style.subtitle"
                    v-html="subtitle"
                />

                <p
                    v-if="description"
                    :class="$style.description"
                    v-html="description"
                />
            </div>

            <PlaceSpecifications
                :specifications="specifications"
            />

            <PlacePriceInfo
                :price-range="priceRange"
                :has-sale="hasSale"
            />

            <ProjectGenplanFlatsPrices
                v-if="flatsInfo && flatsInfo.length"
                :flats-info="flatsInfo"
                :class="$style.flatsInfo"
            />

            <div :class="$style.btns">
                <UiButton
                    v-if="explore"
                    @click="explore"
                >
                    Изучить особенности
                </UiButton>

                <UiButton
                    v-if="next"
                    color="black"
                    @click="next"
                >
                    {{ nextText }}
                </UiButton>
            </div>
        </div>
    </div>
</template>

<script>
import UiButton from '~/components/ui/buttons/UiButton.vue';
import ImageLazy from '~/components/common/ImageLazy.vue';
import PlacePriceInfo from '~/components/place/PlacePriceInfo.vue';
import PlaceSpecifications from '~/components/place/PlaceSpecifications.vue';
import ProjectGenplanFlatsPrices
    from '~/components/pages/project/architecture/attachments/ProjectGenplanFlatsPrices.vue';

// import InfoIcon from '~/assets/icons/home/info.svg?inline'; // убрал, т.к. пока не ясна логика

export default {
    name: 'ProjectGenplanUrbanTooltip',

    components: {
        ProjectGenplanFlatsPrices,
        ImageLazy,
        UiButton,
        PlacePriceInfo,
        PlaceSpecifications,
    },

    props: {
        imagePreview: {
            type: String,
            default: '',
        },

        imageDisplay: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            required: true,
        },

        subtitle: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        specifications: {
            type: Array,
            default: () => [],
        },

        flatsInfo: {
            type: Array,
            default: () => [],
        },

        explore: {
            type: Function,
            default: null,
        },

        next: {
            type: Function,
            default: null,
        },

        nextText: {
            type: String,
            default: 'Выбрать корпус',
        },

        hasSale: {
            type: Boolean,
            default: false,
        },

        priceRange: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanUrbanTooltip {
        display: grid;
        grid-template-columns: 1fr;
        gap: .8rem;
        width: 34rem;
        min-width: 34rem;
        max-width: 34rem;
        padding: .8rem;
        box-shadow: 0 1.6rem 2.4rem rgba($base, .06), 0 .2rem .6rem rgba($base, .04), 0 0 .1rem rgba($base, .04);
        background-color: $inversed;

        .image {
            width: 100%;
            height: 16.4rem;
        }

        .container {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            padding: 1.2rem;
        }

        .info {
            display: flex;
            flex-direction: column;
        }

        .header {
            display: flex;
            align-items: center;
        }

        .title {
            @include l2;
        }

        // .icon {
        //     width: 1.4rem;
        //     min-width: 1.4rem;
        //     height: 1.4rem;
        //     margin-left: .4rem;
        //     color: $base-300;
        //     cursor: pointer;
        // }

        .subtitle {
            margin-top: .8rem;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.2;
            color: $base-900;
        }

        .description {
            @include p3;

            margin-top: .8rem;
            font-weight: 300;
            line-height: 132%;
            color: $base-500;
        }

        .btns {
            display: grid;
            grid-template-columns: 1fr;
            gap: .8rem;
        }

        .flatsInfo {
            padding-top: 2rem;
            border-top: 1px solid $base-100;
        }
    }
</style>
