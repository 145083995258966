/**
 * Данный миксин необходимо добавлять для всех создаваемых компонентов маркеров.
 * Компонент не должен иметь template и должен реализовывать свой метод getGeoObject
 */

import { validateCoordinates } from '~/components/common/ymap/utils';
import { ymapClientKey } from '~/components/common/ymap/config';

const placemarkMixin = {
    inject: {
        getYmapClient: {
            from: ymapClientKey,
            default: null,
        },
    },

    props: {
        coords: {
            type: Array,
            required: true,
            validate: val => validateCoordinates(val),
        },
    },

    data() {
        return {
            geoObject: null,
            ymaps: null,
        };
    },

    created() {
        this.ymaps = this.getYmapClient();
    },

    /**
     * добавление маркера на карту или в кластер
     * в зависимости от того кто "родитель"
     */
    mounted() {
        //
        if (this.ymaps && validateCoordinates(this.coords) && this.$parent?.addGeoObject) {
            this.geoObject = this.getGeoObject();
            this.$parent.addGeoObject(this.geoObject);
        }
    },

    beforeDestroy() {
        // добавление маркера на карту или в кластер
        if (this.$parent?.removeGeoObject && this.geoObject) {
            this.$parent.removeGeoObject(this.geoObject);
        }
    },

    methods: {
        /**
         * Данный метод необходимо переопределить в компоненте
         */
        getGeoObject() {
            return new this.ymaps.Placemark(this.coords);
        },
    },

    render() {
        return {};
    },
};

export default placemarkMixin;
