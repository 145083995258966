<template>
    <div ref="container" :class="[$style.ProjectFormatSlide, {[$style._loaded]: loaded}]">
        <div :class="$style.container" :style="imageOrientation">
            <img
                ref="image"
                :data-src="slide.plan"
                class="swiper-lazy"
                :class="$style.image"
                :style="imageOrientation"
                @load="handlerLoadImage"
            >
            <transition
                :enter-active-class="$style.overlayEnterActive"
                :leave-active-class="$style.overlayLeaveActive"
                :enter-class="$style.overlayEnter"
                :leave-to-class="$style.overlayLeaveTo"
            >
                <div
                    v-if="loaded && displayPins.length && isActive"
                    ref="overlay"
                    :class="$style.overlay"
                >
                    <template v-for="pin in displayPins">
                        <template v-if="pin.imageDisplay || pin.title || pin.text">
                            <UiTooltip
                                v-if="$device.isDesktop"
                                :key="pin.id"
                                :class="$style.position"
                                top
                                :style="{
                                    top: `${pin.point[1]}%`,
                                    left: `${pin.point[0]}%`,
                                }"
                            >
                                <template #activator>
                                    <UiButton
                                        :class="$style.button"
                                        color="black"
                                    >
                                        <PlusIcon :class="$style.buttonIcon" />
                                    </UiButton>
                                </template>
                                <template #default>
                                    <ProjectLocationVisualCard
                                        :title="pin.title"
                                        :text="pin.text"
                                        :display-image="pin.imageDisplay"
                                        :preview-image="pin.imagePreview"
                                        :class="$style.pinCard"
                                    />
                                </template>
                            </UiTooltip>

                            <UiButton
                                v-else
                                :key="pin.id"
                                :class="[$style.button, $style.position]"
                                color="black"
                                :style="{
                                    top: `${pin.point[1]}%`,
                                    left: `${pin.point[0]}%`,
                                }"
                                @click="handlePinModal(pin)"
                            >
                                <PlusIcon :class="$style.buttonIcon" />
                            </UiButton>
                        </template>
                    </template>
                </div>
            </transition>
        </div>
        <transition
            :enter-active-class="$style.skeletonEnterActive"
            :leave-active-class="$style.skeletonLeaveActive"
            :enter-class="$style.skeletonEnter"
            :leave-to-class="$style.skeletonLeaveTo"
        >
            <SkeletonLoader
                v-if="!loaded"
                absolute
                radius=".6rem"
                :color="isActive ? '#f3f3f3' : !$device.isMobile ? '#dddde7' : '#f3f3f3'"
            />
        </transition>
    </div>
</template>

<script>
import UiTooltip from '~/components/ui/UiTooltip.vue';
import UiButton from '~/components/ui/buttons/UiButton.vue';
import PlusIcon from '~/assets/icons/home/plus.svg?inline';
import ProjectLocationVisualCard from '~/components/pages/project/location/ProjectLocationVisualCard.vue';
import SkeletonLoader from '~/components/common/SkeletonLoader.vue';

export default {
    name: 'ProjectFormatSlide',
    components: { SkeletonLoader, ProjectLocationVisualCard, UiButton, UiTooltip, PlusIcon },

    props: {
        slide: {
            type: Object,
            default: () => ({
                buttonText: '',
                link: '',
                pins: [],
                plan: '',
                subtitle: '',
                title: '',
            }),
        },

        isActive: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            imageOrientation: {
                width: '100%',
                height: 'auto',
            },

            loaded: false,
            startDate: null,
        };
    },

    computed: {
        displayPins() {
            if (this.slide.pins && this.slide.pins.length) {
                return this.slide.pins.filter(pin => pin.point.length === 2);
            } else {
                return [];
            }
        },
    },

    mounted() {
        this.startDate = Date.now();
    },

    methods: {
        updateImageOrientation() {
            const containerWidth = this.$refs.container.clientWidth;
            const containerHeight = this.$refs.container.clientHeight;
            const imageWidth = this.$refs.image.offsetWidth;
            const imageHeight= this.$refs.image.offsetHeight;

            if (imageHeight > containerHeight) {
                this.imageOrientation = {
                    width: 'auto',
                    height: '100%',
                };
            }

            if (imageWidth > containerWidth) {
                this.imageOrientation = {
                    width: '100%',
                    height: 'auto',
                };
            }
        },

        handlerLoadImage() {
            this.updateImageOrientation();
            const elapsedTime = Date.now() - this.startDate;
            if (elapsedTime < 1000) {
                setTimeout(() => {
                    this.loaded = true;
                }, 1000 - elapsedTime);
            } else {
                this.loaded = true;
            }
        },

        handlePinModal(pin) {
            this.$universalModal.open({
                component: ProjectLocationVisualCard,
                componentOptions: {
                    title: pin.title,
                    text: pin.text,
                    displayImage: pin.imageDisplay,
                    previewImage: pin.imagePreview,
                    isModal: true,
                },
                footer: () => import('~/components/common/modals/universal-modal/footers/CloseFooter.vue'),
                footerOptions: {
                    closeBtnClick: () => {
                        this.$universalModal.close();
                    },
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectFormatSlide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &._loaded {
            .image {
                opacity: 1;
            }
        }

        .button {
            position: relative;
            width: 3.2rem;
            min-width: 3.2rem;
            height: 3.2rem;
            min-height: 3.2rem;
            padding: 0;

            @include respond-to-min(xs) {
                transform: scale(.9);
            }

            &:hover {
                .buttonIcon {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }

            .buttonIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 60%;
                height: auto;
                transform: translate(-50%, -50%);
                transition: transform .2s linear;
            }
        }
    }

    .container {
        position: relative;
        display: inline-flex;
        width: 100%;
        height: auto;
        user-select: none;
    }

    .image {
        display: inline-flex;
        width: 100%;
        height: auto;
        opacity: 0;
        transition: opacity .3s linear .5s;
        user-select: none;
    }

    .position {
        position: absolute;
        transform: translate(-50%, -50%);
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .overlayEnterActive {
        transition: opacity .4s ease .5s;
    }

    .overlayLeaveActive {
        transition: opacity .4s ease;
    }

    .overlayEnter,
    .overlayLeaveTo {
        opacity: 0;
    }

    .skeletonEnterActive,
    .skeletonLeaveActive {
        transition: opacity .3s ease;
    }

    .skeletonEnter,
    .skeletonLeaveTo {
        opacity: 0;
    }

    .pinCard {
        width: 100%;
        min-width: 28rem;
        max-width: 28rem;
        box-shadow: 0 16px 24px rgba(0, 0, 0, .06), 0 2px 6px rgba(0, 0, 0, .04), 0 0 1px rgba(0, 0, 0, .04);
    }
</style>
