// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlacePriceInfo_Muzco._normal_RzAkK .priceRange_iJOFl{font-size:2rem;line-height:1.4}.PlacePriceInfo_Muzco._large_o2bla .priceRange_iJOFl{font-size:2.4rem;line-height:1.32}.PlacePriceInfo_Muzco .hasSale_gQSvJ,.PlacePriceInfo_Muzco .priceRange_iJOFl{text-transform:uppercase;font-weight:700}.PlacePriceInfo_Muzco .hasSale_gQSvJ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:.4rem;padding:.8rem;background-color:#2f2f2f;text-align:center;font-size:10px;line-height:100%;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlacePriceInfo": "PlacePriceInfo_Muzco",
	"_normal": "_normal_RzAkK",
	"priceRange": "priceRange_iJOFl",
	"_large": "_large_o2bla",
	"hasSale": "hasSale_gQSvJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
