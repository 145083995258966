<template>
    <div :class="[$style.ProjectLocationVisualCard, {[$style._isModal]: isModal}]">
        <div v-if="displayImage" :class="$style.imageContainer">
            <ImageLazy
                :origin="displayImage"
                :preview="previewImage"
            />
        </div>

        <div v-if="title || text" :class="$style.content">
            <slot name="content">
                <div :class="$style.title" v-html="title"></div>
                <div
                    v-if="text"
                    :class="$style.text"
                    v-html="text"
                >
                </div>
                <slot name="after-content" />
                <div v-if="estimate" :class="$style.estimate">
                    <svg
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1.7002 9.99996C1.7002 5.39746 5.4302 1.66663 10.0335 1.66663C14.6352 1.66663 18.3669 5.39746 18.3669 9.99996C18.3669 14.6025 14.6352 18.3333 10.0335 18.3333C5.4302 18.3333 1.7002 14.6025 1.7002 9.99996Z" fill="#FC3F1D"/>
                        <path d="M11.0995 6.38838H10.3295C8.91788 6.38838 8.17538 7.10338 8.17538 8.15755C8.17538 9.34921 8.68871 9.90755 9.74288 10.6234L10.6137 11.21L8.11121 14.9492H6.24121L8.48704 11.6042C7.19538 10.6784 6.47038 9.77921 6.47038 8.25838C6.47038 6.35172 7.79954 5.05005 10.3204 5.05005H12.8229V14.94H11.0995V6.38838Z" fill="white"/>
                    </svg>
                    Оценка <span>{{ estimate }}</span>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import ImageLazy from '~/components/common/ImageLazy.vue';

export default {
    name: 'ProjectLocationVisualCard',
    components: { ImageLazy },

    props: {
        title: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        displayImage: {
            type: String,
            default: '',
        },

        previewImage: {
            type: String,
            default: '',
        },

        isModal: {
            type: Boolean,
            default: false,
        },

        estimate: {
            type: [String, Number],
            default: () => null,
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectLocationVisualCard {
        padding: .8rem;
        background-color: $base-0;

        &._isModal {
            padding-top: 2rem;
            padding-bottom: 2rem;
            border-top-left-radius: 1.2rem;
            border-top-right-radius: 1.2rem;

            .content {
                padding-top: 2.4rem;

                @include respond-to(xxs) {
                    padding-top: 2rem;
                }
            }

            .title {
                margin-bottom: 1.6rem;
                font-size: 2.4rem;
                line-height: 3.1rem;

                @include respond-to(xxs) {
                    margin-bottom: .8rem;
                }
            }
        }
    }

    .imageContainer {
        position: relative;
        overflow: hidden;
        transform: translateZ(0);

        @include aspect-ratio(264, 164);
    }

    .content {
        margin: 1.2rem;
    }

    .title {
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.24;
    }

    .text {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.32;

        @include respond-to(xxs) {
            font-size: 1.2rem;
        }
    }

    .estimate {
        position: relative;
        margin-top: 2rem;
        padding-top: .2rem;
        padding-left: 2.6rem;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.6rem;
        letter-spacing: -.024rem;
        color: $base-500;

        span {
            color: $base-900;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 0;
            display: inline-block;
            width: 2rem;
            height: 2rem;
            transform: translateY(-50%);
        }
    }
</style>
