// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeArchitecture_-byqr{width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.HomeArchitecture_-byqr .btns_WFgBX{display:-ms-grid;display:grid;-ms-grid-columns:1fr 1fr;grid-template-columns:1fr 1fr;grid-gap:1.2rem;gap:1.2rem;width:100%;padding:2.4rem 2.4rem 1.6rem}.HomeArchitecture_-byqr .container_aKi6P{position:relative}.HomeArchitecture_-byqr .tabs_RAB4Q{position:absolute;top:3.2rem;right:6.4rem;z-index:5}.HomeArchitecture_-byqr .tabs_RAB4Q>button{height:4.4rem}.HomeArchitecture_-byqr .panoramaBtn_0\\+5qk{position:absolute;bottom:3.2rem;left:6.4rem;z-index:5}.HomeArchitecture_-byqr .section_lqOX3{opacity:0;-webkit-transition:opacity .3s ease;transition:opacity .3s ease}.HomeArchitecture_-byqr .section_lqOX3._active_cmzKT{opacity:1}.HomeArchitecture_-byqr .map_fhuBN{position:absolute;top:0;left:0;z-index:1;width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HomeArchitecture": "HomeArchitecture_-byqr",
	"btns": "btns_WFgBX",
	"container": "container_aKi6P",
	"tabs": "tabs_RAB4Q",
	"panoramaBtn": "panoramaBtn_0+5qk",
	"section": "section_lqOX3",
	"_active": "_active_cmzKT",
	"map": "map_fhuBN"
};
module.exports = ___CSS_LOADER_EXPORT___;
