// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleHeader_Uw\\+8S{display:-ms-grid;display:grid;grid-gap:1.6rem;gap:1.6rem;padding:2.8rem}.TitleHeader_Uw\\+8S:not(._notBorder_YzJrl){border-bottom:1px solid #dbdbdb}.TitleHeader_Uw\\+8S .wrap_8r7DH{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.TitleHeader_Uw\\+8S .title_45wXY{padding-right:1.6rem;text-transform:uppercase;font-size:2rem;font-weight:700;line-height:140%;color:#2f2f2f}.TitleHeader_Uw\\+8S .subTitle_aPZPC{font-size:1.6rem;line-height:2rem;color:#616161}.TitleHeader_Uw\\+8S .tag_qhtQu{padding:.7rem 1.2rem;background-color:rgba(47,47,47,.32);font-size:1.4rem;line-height:1.8rem;letter-spacing:-.02em;color:#fff}.TitleHeader_Uw\\+8S .tag_qhtQu:not(:last-child){margin-right:.8rem}.TitleHeader_Uw\\+8S .close_pPCI9{border-radius:0}@media(max-width:991.98px){.TitleHeader_Uw\\+8S{padding:2.8rem 2.4rem 2.4rem}.TitleHeader_Uw\\+8S .wrap_8r7DH{-webkit-box-align:center;-ms-flex-align:center;align-items:center}.TitleHeader_Uw\\+8S .title_45wXY{font-size:1.6rem;font-weight:700;line-height:132%}}@media(max-width:767.98px){.TitleHeader_Uw\\+8S{padding:2rem 2rem 1.6rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleHeader": "TitleHeader_Uw+8S",
	"_notBorder": "_notBorder_YzJrl",
	"wrap": "wrap_8r7DH",
	"title": "title_45wXY",
	"subTitle": "subTitle_aPZPC",
	"tag": "tag_qhtQu",
	"close": "close_pPCI9"
};
module.exports = ___CSS_LOADER_EXPORT___;
