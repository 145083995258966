<template>
    <component
        :is="component"
        v-bind="attributes"
        :class="[$style.UiCard, classList]"
        @click="onClick"
    >
        <div :class="$style.header">
            <div
                :class="$style.title"
                :style="styleContent"
                v-html="title"
            >
            </div>

            <template v-if="icon">
                <img
                    v-if="typeof icon === 'string'"
                    :src="icon"
                    alt="Иконка"
                    :class="$style.icon"
                >

                <component
                    :is="icon"
                    v-else
                    :class="$style.icon"
                />
            </template>
        </div>

        <div
            :class="$style.text"
            :style="styleContent"
            v-html="text"
        />

        <img
            v-if="background"
            v-lazy="background"
            alt="Фон"
            :class="$style.bg"
        >
    </component>
</template>

<script>
import { convertToUnit } from '~/components/ui/utils/helpers';

export default {
    name: 'UiCard',

    props: {
        title: {
            type: String,
            default: '',
        },

        icon: {
            type: [Object, String],
            default: null,
        },

        background: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        color: {
            type: String,
            default: '',
            validator: value => ['', 'primary', 'inversed', 'dark', 'yellow'].includes(value),
        },

        link: {
            type: String,
            default: '',
        },

        external: {
            type: Boolean,
            default: false,
        },

        blank: {
            type: Boolean,
            default: false,
        },

        contentMaxWidth: {
            type: String,
            default: '100%',
        },

        callback: {
            type: Function,
            default: null,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style['_link']]: this.link || this.callback,
            };
        },

        attributes() {
            return {
                to: this.external || this.blank ? null : this.link ? this.link : null,
                href: this.external || this.blank ? this.link : null,
                target: this.blank ? '_blank' : null,
                rel: this.blank ? 'noreferrer noopener' : null,
            };
        },

        component() {
            return this.link ? this.external || this.blank ? 'a' : 'nuxt-link' : 'div';
        },

        styleContent() {
            return {
                maxWidth: convertToUnit(this.contentMaxWidth, 'rem'),
            };
        },
    },

    methods: {
        onClick() {
            if (this.callback) {
                this.callback();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .UiCard {
        position: relative;
        overflow: hidden;
        display: flex;
        padding: 2.8rem;
        background-color: $base-50;
        flex-direction: column;

        &._link {
            cursor: pointer;
        }

        @include respond-to(xxs) {
            padding: 2rem;
        }

        .header {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2.4rem;
        }

        .title {
            @include l3;

            margin-right: 1.6rem;
            text-transform: uppercase;
            line-height: 1.32;

            @include respond-to(xxs) {
                @include l4;

                line-height: 1.32;
            }
        }

        .icon {
            width: 2.4rem;
            min-width: 2.4rem;
            height: 2.4rem;
            color: $base-500;
        }

        .text {
            position: relative;
            z-index: 1;
            margin-top: auto;
            font-size: 1.6rem;
            font-weight: 300;
            line-height: 1.24;
            color: $base-500;
        }

        .bg {
            position: absolute;
            top: 50%;
            right: -5%;
            z-index: 0;
            width: auto;
            height: 110%;
            transform: translateY(-50%);

            @include respond-to(sm) {
                top: 40%;
                right: -5%;
                width: 20rem;
                height: auto;
            }

            @include respond-to(xxs) {
                top: -5%;
                right: -5%;
                width: 12rem;
                height: auto;
                transform: none;
            }
        }
    }

    .UiCard._primary {
        background: $base-900;

        .title {
            color: $base-0;
        }

        .icon {
            color: $base-0;
        }

        .text {
            color: $base-200;
        }
    }

    .UiCard._inversed {
        background-color: $base-0;

        .text {
            color: $base-500;
        }
    }

    .UiCard._dark {
        background-color: $base-900;

        .title {
            color: $base-0;
        }

        .icon {
            color: $base-0;
        }

        .text {
            color: $base-400;
        }
    }

    .UiCard._yellow {
        background-color: $new-accent;
    }
</style>
