// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsTooltip_uDb\\+q{margin-left:.8rem;pointer-events:all}.text_xaYqt{font-size:1.2rem;line-height:1.6rem;letter-spacing:normal;color:#616161;width:24.8rem;padding:.8rem 1.2rem;background-color:#2f2f2f;text-align:center;color:#fff}.icon_wTvBt{display:-webkit-box;display:-ms-flexbox;display:flex;width:1.4rem;height:1.4rem;color:#cfcfcf}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsTooltip": "FlatsTooltip_uDb+q",
	"text": "text_xaYqt",
	"icon": "icon_wTvBt"
};
module.exports = ___CSS_LOADER_EXPORT___;
