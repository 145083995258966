<template>
    <div :class="$style.UiButtonGroup">
        <slot />
    </div>
</template>

<script>
import { deepEqual } from '~/components/ui/utils/helpers';

export default {
    name: 'UiButtonGroup',

    provide() {
        return {
            // Провайдим методы для компонента UiButton
            ButtonGroup: {
                registerButton: this.registerButton,
                unregisterButton: this.unregisterButton,
            },
        };
    },

    props: {
        // Для двухстороннего связывания (v-model)
        value: {
            type: [String, Array, Number, Object],
            required: false,
            default: () => undefined,
        },

        // Множественный выбор
        multiple: {
            type: Boolean,
            default: false,
        },

        // Указывает что должно быть выбрано хотя бы одно значение
        mandatory: {
            type: Boolean,
            required: false,
            default: true,
        },

        // Применение пользовательской функции сравнения значений
        valueComparator: {
            type: Function,
            default: deepEqual,
        },

        // Устанавливает максимальное количество возможных вариантов выбора.
        max: {
            type: [Number, String],
            default: null,
        },
    },

    data() {
        return {
            buttons: [], // массив кнопок которые расположены внутри UiButtonGroup
            internalLazyValue: this.value !== undefined
                ? this.value
                : this.multiple ? [] : undefined,
        };
    },


    computed: {
        internalValue: {
            get() {
                return this.internalLazyValue;
            },

            set(val) {
                if (val === this.internalLazyValue) {
                    return;
                }

                this.internalLazyValue = val;

                this.$emit('input', val);
            },
        },

        selectedValues() {
            if (this.internalValue === null || this.internalValue === undefined) {
                return [];
            }

            return Array.isArray(this.internalValue)
                ? this.internalValue
                : [this.internalValue];
        },


        toggleMethod() {
            if (!this.multiple) {
                return v => this.valueComparator(this.internalValue, v);
            }

            const internalValue = this.internalValue;
            if (Array.isArray(internalValue)) {
                return v => internalValue.some(intern => this.valueComparator(intern, v));
            }

            return () => false;
        },

        selectedButtons() {
            return this.buttons.filter((item, index) => this.toggleMethod(this.getValue(item, index)));
        },
    },

    watch: {
        value(val) {
            this.internalLazyValue = val;
        },

        internalValue: 'updateItemsState',
        items: 'updateItemsState',
    },

    methods: {
        // метод срабатывает при created компонента UiButton
        registerButton(button) {
            const index = this.buttons.push(button) - 1;

            button.$on('click', () => this.onClick(button));

            // Если значение не указано и является обязательным,
            // назначаем первый зарегистрированный элемент
            if (this.mandatory && !this.selectedValues.length) {
                this.updateMandatory();
            }

            this.updateButton(button, index);
        },

        // метод срабатывает при beforeDestroy компонента UiButton
        unregisterButton(button) {
            if (this._isDestroyed) {
                return;
            }
            const index = this.buttons.indexOf(button);
            const value = this.getValue(button, index);

            this.buttons.splice(index, 1);

            const valueIndex = this.selectedValues.indexOf(value);

            // Элементы не выбраны, ничего не делать
            if (valueIndex < 0) {
                return;
            }

            if (!this.mandatory) {
                return this.updateInternalValue(value);
            }

            // удалить значение
            if (this.multiple && Array.isArray(this.internalValue)) {
                this.internalValue = this.internalValue.filter(v => v !== value);
            } else {
                this.internalValue = undefined;
            }

            if (!this.selectedButtons.length) {
                this.updateMandatory(true);
            }
        },

        updateInternalValue(value) {
            this.multiple ? this.updateMultiple(value) : this.updateSingle(value);
        },

        // метод обновления значение в случае если props multiple = false
        updateMultiple(value) {
            const defaultValue = Array.isArray(this.internalValue)
                ? this.internalValue
                : [];
            const internalValue = defaultValue.slice();
            const index = internalValue.findIndex(val => this.valueComparator(val, value));

            if (
                // если стоит флаг, что должно быть выбрано хотя бы одно значение
                this.mandatory &&
                // и кнопка уже помечена как выбрана (случай удаления)
                index > -1 &&
                // и проверяем если мы удалим кнопку то значение не может быть меньше 1
                internalValue.length - 1 < 1
            ) {
                return;
            }

            if (
                // если задано максимальное количество выбираемых элементов
                this.max !== null &&
                // и кнопка не помечена как выбрана (случай добавление)
                index < 0 &&
                // и длины массива с выбранными элементами превышает максимальное значение
                internalValue.length + 1 > this.max
            ) {
                return;
            }

            // если значение найдено, то удаляем, иначе добавляем
            index > -1
                ? internalValue.splice(index, 1)
                : internalValue.push(value);

            this.internalValue = internalValue;
        },

        updateSingle(value) {
            const isSame = this.valueComparator(this.internalValue, value);

            if (
                // если стоит флаг, что должно быть выбрано хотя бы одно значение
                this.mandatory &&
                // и значение совпадают
                isSame
            ) {
                // то не удаляем значение
                return;
            }

            this.internalValue = isSame ? undefined : value;
        },

        updateButton(button, index) {
            const value = this.getValue(button, index);

            button.isActive = this.toggleMethod(value);
        },

        getValue(button, idx) {
            return button.value === undefined ? idx : button.value;
        },

        onClick(button) {
            this.updateInternalValue(this.getValue(button, this.buttons.indexOf(button)));
        },

        updateMandatory(last = false) {
            if (!this.buttons.length) {
                return;
            }

            const buttons = this.buttons.slice();

            if (last) {
                buttons.reverse();
            }

            const button = buttons.find(item => !item.disabled);

            // Если нет доступных кнопок,
            // отменяет обязательное значение
            if (!button) {
                return;
            }

            const index = this.buttons.indexOf(button);

            this.updateInternalValue(this.getValue(button, index));
        },

        updateItemsState() {
            this.$nextTick(() => {
                if (this.mandatory &&
                    !this.selectedButtons.length
                ) {
                    return this.updateMandatory();
                }

                this.buttons.forEach(this.updateButton);
            });
        },
    },
};
</script>

<style lang="scss" module>
    .UiButtonGroup {
        //
    }
</style>
