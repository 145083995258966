<template>
    <div :class="[$style.ProjectGenplanCorpusNavigation, {[$style._disabled]: disabled}]">
        <div :class="$style.left">
            <UiButton
                color="black"
                @click="back"
            >
                Назад
            </UiButton>

            <div :class="$style.wrap">
                <p
                    v-if="subtitle"
                    :class="$style.subtitle"
                >
                    {{ subtitle }}
                </p>

                <p
                    v-if="title"
                    :class="$style.title"
                >
                    {{ title }}
                </p>
            </div>
        </div>

        <div v-if="link" :class="$style.right">
            <ProjectGenplanFilterBtn :link="link" />
        </div>
    </div>
</template>

<script>
import UiButton from '~/components/ui/buttons/UiButton.vue';
import ProjectGenplanFilterBtn from '~/components/pages/project/architecture/attachments/ProjectGenplanFilterBtn.vue';

export default {
    name: 'ProjectGenplanCorpusNavigation',

    components: {
        UiButton,
        ProjectGenplanFilterBtn,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: '',
        },

        subtitle: {
            type: String,
            default: '',
        },

        back: {
            type: Function,
            default: () => null,
        },

        link: {
            type: [String, Function],
            default: '/flats/',
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanCorpusNavigation {
        position: relative;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background: linear-gradient(0deg, rgba($base-900, 0) 0%, rgba($base-900, .2) 25%, rgba($base-900, .6) 60%, rgba($base-900, .8) 100%);
            pointer-events: none;
            opacity: .8;
        }

        &:not(._disabled) > * {
            pointer-events: all;
        }

        .left {
            position: absolute;
            top: 3.2rem;
            left: $homeDesktopPadding;
            display: grid;
            align-items: center;
            grid-template-columns: min-content 1fr;
            gap: 3.2rem;
        }

        .right {
            position: absolute;
            top: 3.2rem;
            right: $homeDesktopPadding;
        }

        .wrap {
            display: flex;
            flex-direction: column;
        }

        .subtitle {
            @include l5;

            margin-bottom: .4rem;
            color: $base-0;
            opacity: .72;
        }

        .title {
            @include l3;

            color: $base-0;
        }
    }
</style>
