<template>
    <div
        :class="$style.GenplanPinLocation"
        :style="stylesValue"
    >
        <div v-if="info.name" :class="$style.station">
            <ArrowSolid
                v-if="info.azimuth !== null"
                :class="$style.direction"
                :style="{ transform: `rotate(${info.azimuth}deg)`}"
            />

            <div
                :class="$style.text"
                v-html="info.name"
            />
        </div>

        <div
            v-if="info.distance"
            :class="$style.distance"
        >
            <img
                v-if="info.icon"
                :src="info.icon"
                :class="$style.icon"
                :alt="info.distance"
            >

            <div
                :class="$style.text"
                v-html="info.distance"
            />
        </div>
    </div>
</template>

<script>
import ArrowSolid from '~/assets/icons/arrow-up-solid.svg?inline';

export default {
    name: 'GenplanPinLocation',

    components: {
        ArrowSolid,
    },

    props: {
        info: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        stylesValue: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },
};
</script>

<style lang="scss" module>
    .GenplanPinLocation {
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        height: 2.4rem;
        padding: .8rem;
        user-select: none;
        background-color: #f4f4f4;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: .8;
        pointer-events: all;

        .icon,
        .direction {
            width: 1.6rem;
            height: 1.6rem;
            object-fit: contain;
        }

        .station {
            display: flex;
            align-items: center;
            margin-right: .4rem;
            color: $base-900;

            .text {
                margin-left: .4rem;
            }
        }

        .distance {
            display: flex;
            align-items: center;
            margin-right: .4rem;
            color: $base-900;

            .text {
                margin-left: .4rem;
            }
        }
    }
</style>
