<template>
    <div
        :class="[
            $style.GenplanBuildingLabel,
            { [$style._inversed]: color === 'inversed' },
            { [$style._hover]: hover },
            { [$style._disabled]: disabled },
        ]"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
        @click="$emit('click')"
    >
        <div v-if="$slots.header" :class="$style.header">
            <slot name="header"></slot>
        </div>

        <div :class="$style.body">
            <div :class="$style.text">
                <slot>
                    {{ text }}
                </slot>
            </div>

            <div
                v-if="icon"
                :class="$style.icon"
            >
                <component :is="icon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GenplanBuildingLabel',

    props: {
        text: {
            type: String,
            default: '',
        },

        icon: {
            type: Object,
            default: null,
        },

        color: {
            type: String,
            default: 'default',
            validator: value => ['default', 'inversed'].includes(value),
        },

        hover: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" module>
    .GenplanBuildingLabel {
        overflow: hidden;
        min-width: 3.4rem;
        text-align: center;
        transform: translateZ(0);
        transition: $homeTransition;
        cursor: pointer;

        &._disabled {
            pointer-events: none;
        }

        .text {
            position: relative;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 100%;
            color: $home-accent-2;
            transition: $homeTransition;
        }

        .icon {
            position: relative;
            width: 1.2rem;
            height: 1.2rem;
            margin-left: .8rem;
            color: $home-accent-2;
            transition: $homeTransition;

            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .header,
    .body {
        padding: 0 1rem;
    }

    .header {
        padding-top: .6rem;
        padding-bottom: .6rem;
        background: $inversed;
        text-align: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1;
        color: $base-900;
    }

    .body {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 2.8rem;
        padding-top: .8rem;
        padding-bottom: .8rem;
        background-color: $new-accent;
        line-height: 1;
    }

    .GenplanBuildingLabel._inversed {
        .body {
            background-color: $home-accent-2;
        }

        .text {
            color: $inversed;
        }

        .icon {
            color: $inversed;
        }
    }
</style>
