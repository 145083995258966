// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsListHeader_sc3Ge{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%}.FlatsListHeader_sc3Ge._disabled_SWwa7{pointer-events:none}.FlatsListHeader_sc3Ge .back_dyL9d{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-right:3.2rem}@media(max-width:991.98px){.FlatsListHeader_sc3Ge .back_dyL9d{display:none}}.FlatsListHeader_sc3Ge .filter_PhP1z{display:none;width:22rem}@media(max-width:991.98px){.FlatsListHeader_sc3Ge .filter_PhP1z{display:-webkit-box;display:-ms-flexbox;display:flex;width:100%}}.FlatsListHeader_sc3Ge .count_DcDQt{font-size:1.4rem;font-weight:700;line-height:1.8rem;color:#2f2f2f;position:absolute;top:50%;left:50%;color:#717171;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);pointer-events:none}@media(max-width:991.98px){.FlatsListHeader_sc3Ge .count_DcDQt{display:none}}.FlatsListHeader_sc3Ge .switcher_xifjH{margin-left:auto}@media(max-width:991.98px){.FlatsListHeader_sc3Ge .sort_TGrWX,.FlatsListHeader_sc3Ge .switcher_xifjH{margin-left:.8rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsListHeader": "FlatsListHeader_sc3Ge",
	"_disabled": "_disabled_SWwa7",
	"back": "back_dyL9d",
	"filter": "filter_PhP1z",
	"count": "count_DcDQt",
	"switcher": "switcher_xifjH",
	"sort": "sort_TGrWX"
};
module.exports = ___CSS_LOADER_EXPORT___;
