var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style.UiPagination, _vm.classList],style:(_vm.cssVars)},_vm._l((_vm.tabs),function(tab,i){return _c('div',{key:i,class:_vm.$style.tab},[(tab.name)?_c('div',{class:_vm.$style.label},[_vm._v("\n            "+_vm._s(tab.name)+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.withoutAnimate)?_c('div',{class:_vm.$style.clickOverlay,on:{"click":function($event){return _vm.$emit('click:tab', i)}}}):_vm._e(),_vm._v(" "),_c('div',{class:_vm.$style.bar},[_c('div',{class:_vm.$style.bg}),_vm._v(" "),_c('div',{class:[
                    _vm.$style.fill,
                    {
                        [_vm.$style._active]:
                            !_vm.withoutAnimate ? _vm.lazyIndex > i
                                || _vm.stopped && _vm.lazyIndex >= i
                                || !_vm.$device.isDesktop && _vm.lazyIndex >= i && _vm.hasDesignInMobile
                            : _vm.lazyIndex === i
                    },
                ]}),_vm._v(" "),(_vm.$device.isDesktop || !_vm.hasDesignInMobile)?_c('div',{class:[
                    _vm.$style.progress,
                    {[_vm.$style._active]: _vm.lazyIndex === i}
                ]}):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }