<template>
    <div :class="[$style.TitleHeader, classes]">
        <div :class="$style.wrap">
            <div
                :class="$style.title"
                v-html="$device.isDesktop ? title : mobileTitle"
            />

            <UiCircleButton :class="$style.close" @click="close">
                <CloseIcon />
            </UiCircleButton>
        </div>

        <div
            v-if="$device.isDesktop && subTitle"
            :class="$style.subTitle"
            v-html="subTitle"
        />

        <div
            v-if="tags && tags.length"
            :class="$style.tags"
        >
            <span
                v-for="(tag, i) in tags"
                :key="'tag_' + i"
                :class="$style.tag"
            >
                {{ tag }}
            </span>
        </div>
    </div>
</template>

<script>
import UiCircleButton from '~/components/ui/buttons/UiCircleButton';
import CloseIcon from '~/assets/icons/home/close.svg?inline';

export default {
    name: 'TitleHeader',

    components: {
        UiCircleButton,
        CloseIcon,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        subTitle: {
            type: String,
            default: '',
        },

        mobileTitle: {
            type: String,
            default: '',
        },

        tags: {
            type: Array,
            default: () => [],
        },

        notBorder: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                [this.$style._notBorder]: this.notBorder,
            };
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" module>
    .TitleHeader {
        display: grid;
        gap: 1.6rem;
        padding: 2.8rem;

        &:not(._notBorder) {
            border-bottom: 1px solid $base-200;
        }

        .wrap {
            display: flex;
            justify-content: space-between;
        }

        .title {
            padding-right: 1.6rem;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 700;
            line-height: 140%;
            color: $base-900;
        }

        .subTitle {
            font-size: 1.6rem;
            line-height: 2rem;
            color: $base-600;
        }

        .tag {
            padding: .7rem 1.2rem;
            background-color: rgba($base-900, .32);
            font-size: 1.4rem;
            line-height: 1.8rem;
            letter-spacing: -.02em;
            color: $inversed;

            &:not(:last-child) {
                margin-right: .8rem;
            }
        }

        .close {
            border-radius: 0;
        }
    }

    @include respond-to(sm) {
        .TitleHeader {
            padding: 2.8rem 2.4rem 2.4rem;

            .wrap {
                align-items: center;
            }

            .title {
                font-size: 1.6rem;
                font-weight: 700;
                line-height: 132%;
            }
        }
    }

    @include respond-to(xs) {
        .TitleHeader {
            padding: 2rem 2rem 1.6rem;
        }
    }
</style>
