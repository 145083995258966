// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsParametersDropdown_5dHAp{position:absolute;top:calc(100% + 1.2rem);left:0;z-index:5;width:49.6rem;height:33rem;background-color:#fff;-webkit-box-shadow:0 1.2rem 2.4rem 0 rgba(0,0,0,.04);box-shadow:0 1.2rem 2.4rem 0 rgba(0,0,0,.04);-webkit-transition:.3s ease;transition:.3s ease}.FlatsParametersDropdown_5dHAp .scrollbox_QZQJv{max-height:100%}.FlatsParametersDropdown_5dHAp .container_thKLJ{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:3.2rem;gap:3.2rem;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;padding:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsParametersDropdown": "FlatsParametersDropdown_5dHAp",
	"scrollbox": "scrollbox_QZQJv",
	"container": "container_thKLJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
