// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsAdvancedFiltersTablet_RdX9M .ready_XXMvo{margin-bottom:2.4rem}.FlatsAdvancedFiltersTablet_RdX9M .dropdowns_OxLfj{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);grid-gap:2.4rem 3.2rem;gap:2.4rem 3.2rem;margin-bottom:3.2rem}.FlatsAdvancedFiltersTablet_RdX9M .wrap_CrrS9{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);width:100%;grid-column-gap:1.2rem;-webkit-column-gap:1.2rem;-moz-column-gap:1.2rem;column-gap:1.2rem}.FlatsAdvancedFiltersTablet_RdX9M .tags_6WL\\+k{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:2.4rem;gap:2.4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsAdvancedFiltersTablet": "FlatsAdvancedFiltersTablet_RdX9M",
	"ready": "ready_XXMvo",
	"dropdowns": "dropdowns_OxLfj",
	"wrap": "wrap_CrrS9",
	"tags": "tags_6WL+k"
};
module.exports = ___CSS_LOADER_EXPORT___;
