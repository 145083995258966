<script>
import { mapGetters } from 'vuex';
import placemarkMixin from '~/components/common/ymap/mixins/placemarkMixin';
import ProjectLocationVisualCard from '~/components/pages/project/location/ProjectLocationVisualCard.vue';

export default {
    name: 'YMapInfrastructurePlacemark',
    mixins: [placemarkMixin],

    props: {
        icon: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        displayImage: {
            type: String,
            default: '',
        },

        previewImage: {
            type: String,
            default: '',
        },

        // оценка на Яндекс картах
        estimate: {
            type: String,
            default: '',
        },

        isDigital: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),
    },

    methods: {
        getGeoObject() {
            const placeMark = new this.ymaps.Placemark(this.coords, {
                icon: this.icon,
                hint: {
                    title: this.title,
                    text: this.text,
                    image: this.displayImage,
                    estimate: this.estimate,
                },
            }, {
                iconLayout: this.getLayoutPlacemark(),
                openHintOnHover: false,
                hintCloseTimeout: 50,
                hintOpenTimeout: 50,
                hintHoldByMouse: false, // Флаг, отменяющий закрытие хинта, находящегося под курсором.
                hasHint: this.checkHasHint() && this.$device.isDesktop && !this.isIpadPro,
                hintLayout: this.getLayoutHint(),
                hintPane: 'hint', // подсказка не будет вылезать за пределы карты
                iconShape: {
                    type: 'Rectangle',
                    coordinates: [[-16, -37], [16, 0]],
                },
            });

            if (this.$device.isMobileOrTablet || this.isIpadPro) {
                placeMark.events.add('click', () => {
                    this.handlePinModal();
                });
            }

            // добавляем свои события наведения для предотвращения
            // эффекта перемещение подсказки за курсором мыши
            if (this.$device.isDesktop && !this.isIpadPro && !this.isDigital) {
                placeMark.events.add('mouseenter', e => {
                    const target = e.get('target');
                    if (!target.hint.isOpen()) {
                        target.hint.open();
                    }
                });
                placeMark.events.add('mouseleave', e => {
                    const target = e.get('target');
                    if (target.hint.isOpen()) {
                        target.hint.close();
                    }
                });
            }

            if (this.isDigital) {
                placeMark.events.add('click', e => {
                    const target = e.get('target');

                    if (!target.hint.isOpen()) {
                        target.hint.open();
                    } else {
                        target.hint.close();
                    }
                });
            }

            return placeMark;
        },

        getLayoutPlacemark() {
            return this.ymaps.templateLayoutFactory.createClass(`
                <div class="ymap-infrastructure-placemark">
                    <svg
                        class="ymap-infrastructure-placemark__iconBg"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 32 37"
                    >
                        <path d="M19.067 33.09c.674-1.045 1.728-1.77 2.883-2.233C27.84 28.496 32 22.734 32 16c0-8.837-7.163-16-16-16S0 7.163 0 16c0 6.734 4.16 12.496 10.05 14.857 1.155.463 2.208 1.188 2.883 2.234l2.22 3.44a1 1 0 0 0 1.693 0l2.22-3.44Z"/>
                    </svg>

                    <div class="ymap-infrastructure-placemark__content">
                        {% if properties.icon %}
                            <span
                                class="ymap-infrastructure-placemark__icon"
                                style="background-image: url('$[properties.icon]');"
                            ></span>
                        {% endif %}
                    </div>
                </div>
            `);
        },

        getLayoutHint() {
            const ymaps = this.ymaps;
            return this.ymaps.templateLayoutFactory.createClass(`
                <div class="ymap-infrastructure-hint">
                    <div class="ymap-infrastructure-hint__wrapper">
                        {% if properties.hint.image %}
                            <div class="ymap-infrastructure-hint__imgContainer">
                                <div
                                    class="ymap-infrastructure-hint__img"
                                    style="background-image: url('$[properties.hint.image]');"
                                ></div>
                            </div>
                        {% endif %}
                        {% if properties.hint.title || properties.hint.text || properties.hint.estimate  %}
                            <div class="ymap-infrastructure-hint__content">
                                {% if properties.hint.title %}
                                    <div
                                        class="ymap-infrastructure-hint__title"
                                        {% if !properties.hint.text %}
                                            style="margin-bottom: 0;"
                                        {% endif %}
                                    >
                                        $[properties.hint.title]
                                    </div>
                                {% endif %}
                                {% if properties.hint.text %}
                                    <div class="ymap-infrastructure-hint__text">
                                        $[properties.hint.text]
                                    </div>
                                {% endif %}
                                {% if properties.hint.estimate %}
                                    <div class="ymap-infrastructure-hint__estimate">
                                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.7002 9.99996C1.7002 5.39746 5.4302 1.66663 10.0335 1.66663C14.6352 1.66663 18.3669 5.39746 18.3669 9.99996C18.3669 14.6025 14.6352 18.3333 10.0335 18.3333C5.4302 18.3333 1.7002 14.6025 1.7002 9.99996Z" fill="#FC3F1D"/>
                                            <path d="M11.0995 6.38838H10.3295C8.91788 6.38838 8.17538 7.10338 8.17538 8.15755C8.17538 9.34921 8.68871 9.90755 9.74288 10.6234L10.6137 11.21L8.11121 14.9492H6.24121L8.48704 11.6042C7.19538 10.6784 6.47038 9.77921 6.47038 8.25838C6.47038 6.35172 7.79954 5.05005 10.3204 5.05005H12.8229V14.94H11.0995V6.38838Z" fill="white"/>
                                        </svg>
                                        Оценка <span>$[properties.hint.estimate]</span>
                                    </div>
                                {% endif %}
                            </div>
                        {% endif %}
                    </div>
                </div>
            `, {
                // Анимация появления подсказки
                build() {
                    this.constructor.superclass.build.call(this);
                    const element = this.getParentElement()
                        .querySelector('.ymap-infrastructure-hint');

                    setTimeout(() => element.classList.add('ymap-infrastructure-hint_active'), 50);
                },

                // Анимация исчезновения подсказки
                clear: function() {
                    const parent = this.getParentElement().parentNode;
                    const element = this.getParentElement()
                        .cloneNode(true);
                    this.constructor.superclass.clear.call(this);

                    parent.insertAdjacentElement('afterbegin', element);
                    const hint = parent.querySelector('.ymap-infrastructure-hint');

                    setTimeout(() => hint.classList.remove('ymap-infrastructure-hint_active'), 50);
                    setTimeout(() => element.remove(), 350);
                },

                // Определяем метод getShape, который
                // будет возвращать размеры макета хинта.
                // Это необходимо для того, чтобы хинт автоматически
                // сдвигал позицию при выходе за пределы карты.
                getShape() {
                    const el = this.getElement();
                    let result = null;
                    if (el) {
                        const firstChild = el.querySelector('.ymap-infrastructure-hint');
                        result = new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                            [0, 0],
                            [firstChild.offsetWidth, firstChild.offsetHeight],
                        ]));
                    }
                    return result;
                },
            });
        },

        /**
         * Проверка наличие данных для подсказки
         * @returns {boolean}
         */
        checkHasHint() {
            return Boolean(this.title || this.text || this.displayImage);
        },

        handlePinModal() {
            if (!this.checkHasHint()) {
                return false;
            }
            this.$universalModal.open({
                component: ProjectLocationVisualCard,
                componentOptions: {
                    title: this.title,
                    text: this.text,
                    displayImage: this.displayImage,
                    previewImage: this.previewImage,
                    isModal: true,
                    estimate: this.estimate,
                },
                footer: () => import('~/components/common/modals/universal-modal/footers/CloseFooter.vue'),
                footerOptions: {
                    closeBtnClick: () => {
                        this.$universalModal.close();
                    },
                },
            });
        },
    },
};
</script>

<style lang="scss">
    .ymap-infrastructure-placemark {
        position: relative;
        width: 3.2rem;
        color: $base-900;
        transform: translate(-50%, -100%);

        &__iconBg {
            display: block;
            width: 100%;
            height: auto;
        }

        &__content {
            position: absolute;
            top: 0;
            left: 0;
            width: 3.2rem;
            height: 3.2rem;
        }

        &__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 50%;
            height: 50%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            transform: translate(-50%, -50%);
        }
    }

    .ymap-infrastructure-hint {
        display: block;
        width: 31rem;
        padding: 0 1.5rem 1.5rem 1rem;
        opacity: 0;
        transition: all .3s;
        pointer-events: none;

        &_active {
            opacity: 1;
        }

        &__wrapper {
            padding: 0 .8rem .8rem .8rem;
            background-color: $base-0;
            box-shadow: 0 0 .1rem 0 rgba(0, 0, 0, .04), 0 .2rem .6rem 0 rgba(0, 0, 0, .04), 0 1.6rem 2.4rem 0 rgba(0, 0, 0, .06);
        }

        &__imgContainer {
            padding-top: .8rem;
        }

        &__img {
            background-color: $base-50;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

            @include aspect-ratio(264, 164);
        }

        &__content {
            padding: 2rem 1.2rem 1.2rem 1.2rem;
        }

        &__title {
            margin-bottom: .4rem;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2rem;
            letter-spacing: -.032rem;
            color: $base-900;
        }

        &__text {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.6rem;
            color: $base-600;
        }

        &__estimate {
            position: relative;
            margin-top: 2rem;
            padding-top: .2rem;
            padding-left: 2.6rem;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.6rem;
            letter-spacing: -.024rem;
            color: $base-500;

            span {
                color: $base-900;
            }

            svg {
                position: absolute;
                top: 50%;
                left: 0;
                display: inline-block;
                width: 2rem;
                height: 2rem;
                transform: translateY(-50%);
            }
        }
    }
</style>
