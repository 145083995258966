// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsAdvancedFilterFooter_hh-7e{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%}.FlatsAdvancedFilterFooter_hh-7e .button_RZhd1,.FlatsAdvancedFilterFooter_hh-7e .tabletReset_0C2wJ{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.FlatsAdvancedFilterFooter_hh-7e .tabletReset_0C2wJ{display:-webkit-box;display:-ms-flexbox;display:flex;margin-left:1.2rem;white-space:nowrap}@media(max-width:1279.98px){.FlatsAdvancedFilterFooter_hh-7e .button_RZhd1{width:100%}.FlatsAdvancedFilterFooter_hh-7e .reset_\\+G4z0{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsAdvancedFilterFooter": "FlatsAdvancedFilterFooter_hh-7e",
	"button": "button_RZhd1",
	"tabletReset": "tabletReset_0C2wJ",
	"reset": "reset_+G4z0"
};
module.exports = ___CSS_LOADER_EXPORT___;
