// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleBackCloseHeader_KsJRC{display:-ms-grid;display:grid;-ms-grid-columns:3.6rem 1fr 3.6rem;grid-template-columns:3.6rem 1fr 3.6rem;grid-gap:.8rem;gap:.8rem;padding:2.4rem 2.4rem 1.6rem}.TitleBackCloseHeader_KsJRC,.TitleBackCloseHeader_KsJRC .title_pvjVd{-webkit-box-align:center;-ms-flex-align:center;align-items:center}.TitleBackCloseHeader_KsJRC .title_pvjVd{font-size:2.4rem;font-weight:700;line-height:3.1rem;letter-spacing:normal;color:#2f2f2f;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-align:center}@media(max-width:767.98px){.TitleBackCloseHeader_KsJRC{padding:2.4rem 1.6rem 1.6rem}.TitleBackCloseHeader_KsJRC .title_pvjVd{font-size:2rem;font-weight:700;line-height:2.6rem;letter-spacing:normal;color:#2f2f2f}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TitleBackCloseHeader": "TitleBackCloseHeader_KsJRC",
	"title": "title_pvjVd"
};
module.exports = ___CSS_LOADER_EXPORT___;
