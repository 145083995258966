// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiUniversalDropdownValue_rddyl{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:4.4rem;padding:0 2.4rem;background-color:#f3f3f3;-webkit-transition:.3s ease;transition:.3s ease;cursor:pointer}.UiUniversalDropdownValue_rddyl._opened_fF72W,.UiUniversalDropdownValue_rddyl:not(._opened_fF72W):hover{background-color:#fff;-webkit-box-shadow:0 1.2rem 2.4rem 0 rgba(0,0,0,.04);box-shadow:0 1.2rem 2.4rem 0 rgba(0,0,0,.04)}.UiUniversalDropdownValue_rddyl._opened_fF72W .arrow_4uT5\\+{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.UiUniversalDropdownValue_rddyl .placeholder_M8n9s,.UiUniversalDropdownValue_rddyl .title_KPQ3\\+{text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:1}.UiUniversalDropdownValue_rddyl .placeholder_M8n9s{color:#a0a0a0}.UiUniversalDropdownValue_rddyl .title_KPQ3\\+{text-transform:uppercase;color:#2f2f2f;-webkit-transition:inherit;transition:inherit}.UiUniversalDropdownValue_rddyl .arrow_4uT5\\+{display:-webkit-box;display:-ms-flexbox;display:flex;width:1.6rem;height:1.6rem;margin-left:.4rem;color:#2f2f2f;-webkit-transition:inherit;transition:inherit}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiUniversalDropdownValue": "UiUniversalDropdownValue_rddyl",
	"_opened": "_opened_fF72W",
	"arrow": "arrow_4uT5+",
	"placeholder": "placeholder_M8n9s",
	"title": "title_KPQ3+"
};
module.exports = ___CSS_LOADER_EXPORT___;
