<template>
    <div
        ref="GenplanTooltipWrap"
        :style="position"
        :class="$style.GenplanTooltipWrap"
        @mouseenter="$emit('mouseenter', $event)"
        @mouseleave="$emit('mouseleave', $event)"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'GenplanTooltipWrap',

    props: {
        top: {
            type: Number,
            default: 0,
        },

        left: {
            type: Number,
            default: 0,
        },

        width: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            tooltipHeight: 0,
            tooltipWidth: 0,

            cursorTop: 0,
            cursorLeft: 0,

            padding: 16,
        };
    },

    computed: {
        // ширина изображения (экрана)
        imageWidth() {
            return this.$parent.$refs.GenplanContainer.clientWidth;
        },

        // высота изображения
        imageHeight() {
            return this.$parent.$refs.GenplanContainer.clientHeight;
        },

        // ширина изображения (полностью)
        scrollWidth() {
            return this.$parent.$refs.GenplanContainer.scrollWidth;
        },

        // сколько проскроллили
        scrollLeft() {
            return this.$parent.$refs.GenplanContainer.scrollLeft;
        },

        // положение тултипа относительно курсора
        cursorOffset() {
            return {
                x: this.width / 2,
                y: -this.tooltipHeight / 2,
            };
        },

        // расчет положения тултипа на карте
        position() {
            let x;
            let y;

            // для удобства вычислений
            const containerRightEnd = this.imageWidth + this.scrollLeft - this.padding;
            const tooltipRightEnd = this.cursorLeft + this.tooltipWidth + this.cursorOffset.x;

            // Если тултип не влезает по ширине справа
            if (tooltipRightEnd > containerRightEnd) {
                x = this.cursorLeft - this.tooltipWidth - this.cursorOffset.x - this.padding;
                // Если все ок
            } else {
                x = this.cursorLeft + this.cursorOffset.x + this.padding;
            }

            // для удобства вычислений
            const tooltipTopEnd = this.cursorTop + this.cursorOffset.y - this.padding;
            const tooltipBottomEnd = this.cursorTop - this.cursorOffset.y + this.padding;

            // Если тултип не влезает по высоте снизу
            if (tooltipBottomEnd > this.imageHeight) {
                y = this.imageHeight - this.tooltipHeight - this.padding;
                // Если тултип не влезает по высоте сверху
            } else if (tooltipTopEnd < 0) {
                y = this.padding;
                // Если все ок
            } else {
                y = this.cursorTop + this.cursorOffset.y;
            }

            return {
                top: y + 'px',
                left: x + 'px',
            };
        },
    },

    mounted() {
        this.init();
    },

    updated() {
        this.init();
    },

    methods: {
        init() {
            this.tooltipHeight = this.$refs.GenplanTooltipWrap.offsetHeight;
            this.tooltipWidth = this.$refs.GenplanTooltipWrap.offsetWidth;
    
            this.cursorTop = this.top * this.imageHeight / 100;
            this.cursorLeft = this.left * this.scrollWidth / 100;
        },
    },
};
</script>

<style lang="scss" module>
    .GenplanTooltipWrap {
        position: absolute;
    }
</style>
