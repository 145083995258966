<template>
    <div :class="$style.AdvantagesGallery">
        <div :class="[$style.block, $style._video]">
            <AdvantageGalleryCard
                :card="videoCard"
                is-video
                @click="handleVideoCardClick"
            />
        </div>
        <div :class="[$style.galleryWrapper]">
            <div :class="[$style.block, $style._gallery]">
                <AdvantageGalleryCard
                    :card="leftCard"
                    @click="handleLeftCardClick"
                />
            </div>
            <div :class="[$style.block, $style._gallery]">
                <AdvantageGalleryCard
                    :card="rightCard"
                    @click="handleRightCardClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { centerCard, leftCard, rightCard } from '~/assets/js/mock/advantages';
import AdvantageGalleryCard from '~/components/common/card/advantage-cards/AdvantageGalleryCard.vue';

export default {
    name: 'AdvantagesGalleryCard',
    components: { AdvantageGalleryCard },

    props: {
        gallery: {
            type: Array,
            default: () => [],
        },

        video: {
            type: Array,
            default: () => [],
        },

        videoImage: {
            type: [Object, String],
            default: () => ({}),
        },
    },

    computed: {
        videoCard() {
            if (this.checkCardContent(this.video, this.videoImage.imageDisplay, this.videoImage.imagePreview)) {
                return this.getCardContent('Смотреть', this.videoImage.imageDisplay, this.videoImage.imagePreview, [
                    {
                        id: 1,
                        color: 'default-transparent',
                        text: 'Видео',
                    },
                ], {
                    title: 'Видео о жилом районе ÁLIA',
                    subtitle: 'Посмотрите видео-экскурсию о проекте',
                    duration: '15:13',
                });
            } else {
                return centerCard;
            }
        },

        leftCard() {
            if (this.checkCardContent(this.gallery, this.gallery[0]?.imageDisplay, this.gallery[0]?.imagePreview)) {
                return this.getCardContent('Архитектура', this.gallery[0]?.imageDisplay, this.gallery[0]?.imagePreview, [
                    {
                        id: 1,
                        color: 'default-transparent',
                        text: 'Галерея',
                    },
                ]);
            } else {
                return leftCard;
            }
        },

        rightCard() {
            if (this.checkCardContent(this.gallery, this.gallery[1]?.imageDisplay, this.gallery[1]?.imagePreview)) {
                return this.getCardContent('Окружение', this.gallery[1]?.imageDisplay, this.gallery[1]?.imagePreview, [
                    {
                        id: 1,
                        color: 'default-transparent',
                        text: 'Галерея',
                    },
                ]);
            } else {
                return rightCard;
            }
        },

        getProxyGallery() {
            const ex = this.isWebpSup ? 'webp' : 'png';
            const noRelativeArray = [];
            for (let i = 0; i < this.gallery.length; i++) {
                noRelativeArray.push(Object.assign({}, this.gallery[i]));
            }
            return noRelativeArray.map(el => {
                const noRelativeSlides = [];
                for (let i = 0; i < el.slides.length; i++) {
                    noRelativeSlides.push(Object.assign({}, el.slides[i]));
                }
                noRelativeSlides.map(slide => {
                    slide.imageDisplay = this.$image.gen(slide.imageDisplay, { q: 60, fit: false, ex: ex });
                    slide.imagePreview = this.$image.gen(slide.imagePreview, { q: 50, fit: false, ex: ex });
                    return slide;
                });
                return el;
            });
        },
    },

    methods: {
        checkCardContent(contentArray, contentImageDisplay, contentImagePreview) {
            return contentArray?.length && contentImageDisplay && contentImagePreview;
        },

        getCardContent(title, imageDisplay, imagePreview, labels, videoContent = {}) {
            const ex = this.isWebpSup ? 'webp' : 'png';
            return {
                title: title,
                image: {
                    origin: this.$image.gen(imageDisplay, { q: 60, fit: false, ex: ex }),
                    preview: this.$image.gen(imagePreview, { q: 50, fit: false, ex: ex }),
                },

                labels: labels,
                video: videoContent,
            };
        },

        handleVideoCardClick() {
            this.$fullScreenModal.open({
                component: () => import('~/components/common/modals/home/ModalHomeAdvantagesVideo'),
                componentOptions: {
                    video: this.video[0].video,
                },
            });
        },

        handleLeftCardClick() {
            this.$modalNew.open('ModalHomeAdvantagesGallery', { gallery: this.getProxyGallery, activeId: this.getProxyGallery[0]?.id });
        },

        handleRightCardClick() {
            this.$modalNew.open('ModalHomeAdvantagesGallery', { gallery: this.getProxyGallery, activeId: this.getProxyGallery[1]?.id });
        },
    },
};
</script>

<style lang="scss" module>
    .AdvantagesGallery {
        padding: 6.4rem;
        gap: 2rem;

        @include respond-to(sm) {
            padding: 8rem 0 7.6rem;
        }

        @include respond-to(xs) {
            padding: 3.2rem 0;
        }

        .galleryWrapper {
            display: flex;
            margin-top: 2rem;

            @include respond-to(sm) {
                flex-direction: column;
                margin-top: 0;
                padding: 4rem 2.4rem;
            }

            @include respond-to(xs) {
                padding: 3.2rem 1.6rem;
            }
        }

        .block {
            width: 100%;

            &._video {
                height: 58rem;
            }

            &._gallery {
                height: 32rem;

                &:first-child {
                    flex: 2 1 auto;
                }

                &:last-child {
                    flex: 1 2 auto;
                    margin: 0 0 0 1.6rem;

                    @include respond-to(sm) {
                        margin: 1.6rem 0 0;
                    }
                }
            }
        }
    }
</style>
