<template>
    <div :class="$style.YMap">
        <div ref="map" :class="$style.map"></div>
        <!--Инициализируем геообъекты только после загрузки карты-->
        <slot v-if="loaded"></slot>
        <slot
            name="control"
            :handle-zoom="handleZoom"
        />
    </div>
</template>

<script>
import ymapsLoader from 'ymaps';
import { centerCoords, behaviorsDefault, apiKey, ymapClientKey } from '~/components/common/ymap/config';
import { useGeoObjectActions, validateCoordinates } from '~/components/common/ymap/utils';

let ymaps = null;

export default {
    name: 'YMap',

    provide() {
        return {
            [ymapClientKey]: () => ymaps,
        };
    },

    props: {
        // координаты при первом открытии карты
        openedCoords: {
            type: Array,
            default: () => centerCoords,
            validate: val => validateCoordinates(val),
        },

        zoom: {
            type: Number,
            default: 15,
        },

        // отключение/включение масштабирование карты по скроллу
        scrollZoom: {
            type: Boolean,
            default: true,
        },

        // Отступы от границ видимой области карты.
        zoomMargin: {
            type: [Array, Number],
            default: 20,
        },
    },

    data() {
        return {
            map: null,
            loaded: false,

            mapState: {
                center: this.openedCoords, // Центрируем карту
                zoom: this.zoom, // Изначальный зум
                controls: [], // Убираем все дефолтные элементы управления картой
                behaviors: behaviorsDefault,
            },

            mapOptions: {
                minZoom: 3,
                maxZoom: 19,
                suppressMapOpenBlock: true, // Скрывает предложение открыть текущую карту в Яндекс.Картах
                yandexMapDisablePoiInteractivity: true, // Выключает описание при клике на чужие иконки на карте
            },
        };
    },

    created() {
        // Отключаем zoom по скроллу
        if (!this.scrollZoom) {
            this.mapState.behaviors = this.mapState.behaviors.filter(b => b !== 'scrollZoom');
        }

        // получаем обработчики для добавления/удаление гео-объектов
        const { addGeoObject, removeGeoObject } = useGeoObjectActions(this.updateGeoObjects);
        this.addGeoObject = addGeoObject;
        this.removeGeoObject = removeGeoObject;
    },

    async mounted() {
        try {
            await this.loadMap();
            this.initMap();
        } catch (e) {
            console.log('[YMap]: loadMap', e);
        }
    },

    methods: {
        async loadMap() {
            ymaps = await ymapsLoader.load(`https://api-maps.yandex.ru/2.1/?apikey=${apiKey}&lang=ru_RU`);
            this.loaded = true;
            this.$emit('loaded');
        },

        initMap() {
            this.map = new ymaps.Map(this.$refs.map, this.mapState, this.mapOptions);
        },

        /**
         * Обновления гео-объекта в зависимости от action
         * @param arr - массив гео-объектов
         * @param action - "add" или "remove"
         */
        updateGeoObjects(arr, action) {
            if (!this.map || !arr.length) {
                return;
            }

            arr.forEach(geoObject => this.map.geoObjects[action](geoObject));
            this.$emit('geo-objects-updated', action);
        },

        /**
         * увеличение/уменьшение зума карты
         * @param value - величина на которое необходимо увеличит/уменьшить zoom
         */
        handleZoom(value) {
            this.mapState.zoom = this.map.getZoom() + value;
            this.map.setZoom(this.mapState.zoom, {
                smooth: true,
                checkZoomRange: true,
                duration: 300,
            });
        },

        /**
         * Позиционирует карту так, чтобы все маркеры были в области просмотра
         */
        setBounds() {
            // ограничиваем zoom, поскольку при наличии одной точки
            // setBounds увеличивает карту на значение maxZoom
            this.map.options.set('maxZoom', 15);
            // центрируем карту чтобы все точки помещались
            // в область просмотра с указанным zoomMargin
            this.map.setBounds(this.map.geoObjects.getBounds(), {
                checkZoomRange: true,
                duration: 500,
                zoomMargin: this.zoomMargin, // отступы от границ видимой области карты
            }).then(() => {
                // возвращаем значение maxZoom
                this.map.options.set('maxZoom', this.mapOptions.maxZoom);
            });
        },
    },
};
</script>

<style lang="scss" module>
    .YMap {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
