// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenplanOverlay_w4xip{visibility:hidden;display:none;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:100%;background-color:rgba(47,47,47,.85);pointer-events:none;-webkit-transition:.3s ease;transition:.3s ease}.GenplanOverlay_w4xip._visible_6EKWy{visibility:visible}.container_MDUBD,.GenplanOverlay_w4xip._visible_6EKWy{display:-webkit-box;display:-ms-flexbox;display:flex}.container_MDUBD{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-ms-flex-align:center;align-items:center;max-width:30rem}.text_bC4MW{font-size:1.4rem;line-height:2rem;letter-spacing:normal;color:#616161;text-align:center;color:#fff}.icon_01QrJ{width:4.8rem;height:4.8rem;margin-bottom:1.2rem;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GenplanOverlay": "GenplanOverlay_w4xip",
	"_visible": "_visible_6EKWy",
	"container": "container_MDUBD",
	"text": "text_bC4MW",
	"icon": "icon_01QrJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
