<template>
    <div :class="$style.HomeFormat">
        <div class="container-home">
            <ProjectSectionTitle
                :class="$style.title"
                :first-line="title"
                :second-line="subtitle"
                :description="text"
                is-reversed
            />
            <ProjectFormatFilter
                v-if="formats !== null || scenarios !== null"
                v-model="filter"
                :formats="formats"
                :scenarios="scenarios"
                :button="button"
            />
            <SkeletonLoader
                v-else
                height="5.6rem"
                radius="0.4rem"
                :class="$style.fileterSkeleton"
            />
            <template v-if="formats !== null || scenarios !== null">
                <ProjectFormatSlider
                    :slides="slides"
                    :class="$style.slider"
                    @update-button="debouncedOnUpdateButton"
                />
                <ExpandBlock>
                    <div v-if="button && button.link">
                        <UiButton
                            color="black"
                            size="big"
                            :link="button.link"
                            blank
                            :class="$style.button"
                        >
                            <span v-html="button.text" />
                        </UiButton>
                    </div>
                </ExpandBlock>
            </template>

            <SkeletonLoader
                v-else
                radius="0.4rem"
                height="58rem"
            />
        </div>
    </div>
</template>

<script>
import mainPageNewFormat from '~/queries/new-home/mainPageNewFormat.graphql';
import projectScenarios from '~/queries/pages/project/projectScenarios.graphql';
import { debounce, getNodeArray } from '~/assets/js/utils';
import ProjectSectionTitle from '~/components/pages/project/ProjectSectionTitle.vue';
import SkeletonLoader from '~/components/common/SkeletonLoader.vue';
import ProjectFormatFilter from '~/components/pages/project/fromat/ProjectFormatFilter.vue';
import ProjectFormatSlider from '~/components/pages/project/fromat/ProjectFormatSlider.vue';
import ExpandBlock from '~/components/common/ExpandBlock.vue';
import UiButton from '~/components/ui/buttons/UiButton.vue';

export default {
    name: 'HomeFormat',
    components: { UiButton, ExpandBlock, ProjectFormatSlider, ProjectFormatFilter, SkeletonLoader, ProjectSectionTitle },

    props: {
        title: {
            type: String,
            default: '',
        },

        subtitle: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },
    },


    data() {
        return {
            formats: null,
            scenarios: null,

            filter: {
                type: 'scenarios',
                currentTag: null,
            },

            button: null,
        };
    },

    computed: {
        slides() {
            if (this.filter.type && this.filter.currentTag && this[this.filter.type]) {
                const tag = this[this.filter.type].find(tag => tag.id === this.filter.currentTag);
                if (!tag) {
                    return [];
                }
                return tag[this.filter.type] || [];
            } else {
                return [];
            }
        },

        isMainPage() {
            return this.$route.name === 'index';
        },
    },

    created() {
        this.debouncedOnUpdateButton = debounce(this.onUpdateButton, 200);
        if (this.isMainPage) {
            this.filter.type = 'formats';
        }
    },

    beforeMount() {
        this.handleLoadData();
    },

    methods: {
        async handleLoadData() {
            try {
                const [formatRes, scenariosRes] = await Promise.all([
                    this.$axios.$post('/graphql/', {
                        query: mainPageNewFormat.loc.source.body,
                    }),
                    this.$axios.$post('/graphql/', {
                        query: projectScenarios.loc.source.body,
                    }),
                ]);

                console.log('formatRes -', formatRes, 'scenariosRes -', scenariosRes);

                let formats = getNodeArray(formatRes?.data?.mainPageNew, 'allPlanCategories');
                formats = formats.filter(fm => fm.plans.length);

                this.formats = formats.map(item => ({
                    id: item.id,
                    name: item.name,
                    description: '',
                    order: item.order,
                    formats: item.plans,
                }));

                let scenarios = getNodeArray(scenariosRes?.data, 'allFlatsScenarios');
                scenarios = scenarios.filter(sc => sc.flats.length);

                this.scenarios = scenarios.map(item => {
                    const res = {
                        id: item.id,
                        name: item.title,
                        description: item.hoverDescription,
                        order: item.order,
                    };

                    res.scenarios = item.flats.map(flat => ({
                        id: flat.id,
                        plan: flat.plan ? flat.plan : '/assets/flats/plan-placeholder.svg',
                        title: flat.scenarioTitle,
                        subtitle: flat.scenarioDescription,
                        buttonText: this.getTextButton(flat.subgroupMinPrice),
                        link: `/flats/?scenario=${item.id}&subgroup=${flat.subgroupId}`,
                        pins: flat.pinsForScenario,
                    }));

                    return res;
                });
            } catch (e) {
                console.warn('[HomeFormat - handleLoadData]', e);
            }
        },

        onUpdateButton(newVal) {
            this.button = newVal;
        },

        getTextButton(price) {
            return `Смотреть от ${(price / 1000000).toFixed(1)} млн ₽ `;
        },
    },
};
</script>

<style lang="scss" module>
    .HomeFormat {
        overflow: hidden;
        width: 100%;
        height: 107rem;
        max-height: 107rem;
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;

        @include respond-to(sm) {
            height: 107rem;
            max-height: 107rem;
        }

        @include respond-to(xxs) {
            padding-top: 0;
        }

        .button {
            display: none;
            margin-top: 2.4rem;

            @include respond-to(sm) {
                display: flex;
            }
        }

        .title {
            max-width: 64rem;
            margin-bottom: 6.4rem;

            @include respond-to(sm) {
                margin-bottom: 4.8rem;
            }

            @include respond-to(xxs) {
                margin-bottom: 4rem;
            }
        }
    }

    .fileterSkeleton {
        max-width: 80rem;
        margin-right: auto;
        margin-bottom: 3.2rem;
        margin-left: auto;

        @include respond-to(xxs) {
            margin-bottom: 2rem;
        }
    }

    .slider {
        margin-top: 3.2rem;

        @include respond-to(xxs) {
            margin-top: 2rem;
        }
    }
</style>
