// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectGenplanFlatsPrices_-1NYH{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:1.2rem;gap:1.2rem}.ProjectGenplanFlatsPrices_-1NYH .item_vxOJJ{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.ProjectGenplanFlatsPrices_-1NYH .count_i2s-p,.ProjectGenplanFlatsPrices_-1NYH .label_tierv,.ProjectGenplanFlatsPrices_-1NYH .value_2o2Q9{font-size:1.4rem;font-weight:400;line-height:1}.ProjectGenplanFlatsPrices_-1NYH .count_i2s-p{width:3.2rem;text-align:left;white-space:nowrap}.ProjectGenplanFlatsPrices_-1NYH .label_tierv{min-width:7rem}.ProjectGenplanFlatsPrices_-1NYH .count_i2s-p,.ProjectGenplanFlatsPrices_-1NYH .label_tierv{color:#717171}.ProjectGenplanFlatsPrices_-1NYH .value_2o2Q9{min-width:9rem;text-align:right;color:#2f2f2f}.ProjectGenplanFlatsPrices_-1NYH .count_i2s-p+.value_2o2Q9{text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectGenplanFlatsPrices": "ProjectGenplanFlatsPrices_-1NYH",
	"item": "item_vxOJJ",
	"count": "count_i2s-p",
	"label": "label_tierv",
	"value": "value_2o2Q9"
};
module.exports = ___CSS_LOADER_EXPORT___;
