<template>
    <div
        v-if="!$device.isDesktop || isIpadPro"
        @click.stop
    >
        <UiTooltip
            top
            :nudge="nudge"
            :class="$style.FlatsTooltip"
            :disabled-action-tooltip="disabledActionTooltip"
            @click-tooltip="$emit('click-tooltip')"
        >
            <template #activator>
                <InfoIcon :class="$style.icon" />
            </template>

            <div
                :class="$style.text"
                v-html="text"
            />
        </UiTooltip>
    </div>

    <div v-else>
        <UiTooltip
            top
            :nudge="nudge"
            :class="$style.FlatsTooltip"
            :disabled-action-tooltip="disabledActionTooltip"
            @click-tooltip="$emit('click-tooltip')"
        >
            <template #activator>
                <InfoIcon :class="$style.icon" />
            </template>

            <div
                :class="$style.text"
                v-html="text"
            />
        </UiTooltip>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import UiTooltip from '~/components/ui/UiTooltip.vue';

import InfoIcon from '~/assets/icons/ui/info.svg?inline';

export default {
    name: 'FlatsTooltip',

    components: {
        UiTooltip,
        InfoIcon,
    },

    props: {
        text: {
            type: String,
            default: '',
        },

        nudge: {
            type: Number,
            default: 8,
        },

        /**
         * Отключает дефолтное поведение тултипа
         * (необходимо при кастомной логике на клик тултипа)
         */
        disabledActionTooltip: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
            totalCount: 'flats/totalCount',
        }),
    },
};
</script>

<style lang="scss" module>
    .FlatsTooltip {
        margin-left: .8rem;
        pointer-events: all;
    }

    .text {
        @include p4;

        width: 24.8rem;
        padding: .8rem 1.2rem;
        background-color: $base-900;
        text-align: center;
        color: $base-0;
    }

    .icon {
        display: flex;
        width: 1.4rem;
        height: 1.4rem;
        color: $base-300;
    }
</style>
