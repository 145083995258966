<template>
    <div
        v-lazy:background-image="'/images/pages/home/home-form-background.webp'"
        :class="[$style.HomeOfficeForm, classList]"
    >

        <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="div"
            :class="$style.form"
        >
            <div :class="$style.formHeader">
                <div :class="$style.title">
                    Офис продаж
                </div>

                <a
                    href="https://yandex.ru/maps/213/moscow/?indoorLevel=1&ll=37.432218%2C55.824237&mode=poi&poi%5Bpoint%5D=37.432152%2C55.824357&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D48831688027&z=20"
                    target="_blank"
                    rel="nofollow"
                    :class="$style.mapLink"
                >
                    <div :class="$style.text">
                        На карте
                    </div>

                    <div :class="$style.pin">
                        <MapPin />
                    </div>
                </a>
            </div>

            <div :class="$style.formBody">
                <div :class="$style.address">
                    Москва, Волоколамское шоссе, вл. 73А<br>
                    Пн–Вс с 9:00 до 21:00 <br> <br>
                    Уважаемые клиенты, встречи и консультации в офисе продаж проводятся по предварительной записи.
                </div>

                <div
                    v-show="isError || isSuccess"
                    :class="$style.response"
                >
                    <div :class="$style.responseTitle">
                        {{ isError ? 'Произошла ошибка' : 'Спасибо за обращение' }}
                    </div>

                    <div :class="$style.responseText">
                        {{ isError ? 'Повторите попытку или перезагрузите страницу' : 'Менеджер свяжется с вами в течение часа' }}
                    </div>
                </div>

                <form
                    ref="HomeOfficeForm"
                    :class="$style.formInputs"
                    @submit.prevent="handleSubmit(onSubmit)"
                >
                    <UiInput
                        v-show="!isError && !isSuccess"
                        v-model="name"
                        field="имя"
                        name="name"
                        placeholder="Имя"
                        rules="required|cyrillic|min:2|alpha_spaces"
                        :class="$style.formInput"
                    />

                    <UiInput
                        v-show="!isError && !isSuccess"
                        v-model="phone"
                        is-phone
                        field="телефон"
                        type="tel"
                        name="phone"
                        placeholder="Номер телефона"
                        rules="required|phone"
                        :class="$style.formInput"
                    />

                    <div :class="$style.buttonContainer">
                        <UiButton
                            v-show="!isError"
                            color="primary"
                            size="big"
                            type="submit"
                            :disabled="isSuccess"
                            :class="$style.submit"
                        >
                            Записаться на встречу
                        </UiButton>

                        <UiButton
                            v-show="isError"
                            color="primary"
                            size="big"
                            :class="$style.tryAgain"
                            @click="tryAgain"
                        >
                            Повторить попытку
                        </UiButton>

                        <UiButton
                            color="bordered"
                            size="big"
                            :class="$style.tourButton"
                            @click="openVideoModal"
                        >
                            <template #iconBefore>
                                <IconOffice3D />
                            </template>

                            <template #default>
                                3D-тур по офису
                            </template>
                        </UiButton>
                    </div>
                </form>

                <div :class="$style.policy">
                    <FormPolicy v-model="checkboxes" :class="$style.checkboxesGroup" />
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import UiInput from '~/components/ui/home/UiInput';
import UiButton from '~/components/ui/home/UiButton';

import MapPin from '~/assets/icons/hero-form-map-pin.svg?inline';
import IconOffice3D from '~/assets/icons/office-form-3d.svg?inline';
import FormPolicy from '~/components/common/form/FormPolicy.vue';

export default {
    name: 'HomeOfficeForm',

    components: {
        FormPolicy,
        UiButton,
        MapPin,
        UiInput,
        IconOffice3D,
    },

    data() {
        return {
            name: '',
            phone: '',
            checkboxes: {
                policy: false,
                marketing: true,
            },

            isError: false,
            isSuccess: false,
        };
    },

    computed: {
        classList() {
            return {
                [this.$style._flatPage]: this.flatPage,
            };
        },
    },

    methods: {
        async onSubmit() {
            try {
                const payload = {
                    name: this.name,
                    phone: this.phone.replace(/[^+\d]+/g, ''),
                    request_type: 'office',
                    ad: this.checkboxes.marketing,
                };

                await this.$axios.$post('/api/request/', payload);

                if (window.mindbox) {
                    window.mindbox('async', {
                        operation: 'website.mainPageOfficeModalSub',
                        data: {
                            customer: {
                                mobilePhone: this.phone,
                                firstName: this.name,
                            },
                        },
                        onSuccess: function() {
                            console.log('%c Mindbox: All good, заявка отправлена', 'background: #01630b; color: #add7ff');
                        },
                        onError: function(error) {
                            console.log('%c Mindbox: error! Ошибка в отправке заявки', 'background: #ffd400; color: #ff0000', error);
                        },
                    });
                }

                this.resetForm();
                this.isSuccess = true;
            } catch (e) {
                console.warn('[HomeOfficeForm] onSubmit:', e);
                this.isError = true;
            }
        },

        tryAgain() {
            this.isError = false;
        },

        resetForm() {
            this.$refs.HomeOfficeForm.reset();
            this.name = '';
            this.phone = '';
            this.checkboxes.policy = false;
            this.checkboxes.marketing = true;
            this.$refs.observer.reset();
        },

        openVideoModal() {
            this.$modal.open('VideoModal', {
                src: ['https://www.novostroy-m.ru/files/novos/virtual_tour/jiloy_rayon_alia_office/'],
            });
        },
    },
};
</script>

<style lang="scss" module>
    .HomeOfficeForm {
        width: 100%;
        height: 71.6rem;
        padding: 6.4rem;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;

        @include respond-to(sm) {
            padding: 6.6rem 2.4rem;
        }

        @include respond-to(xs) {
            padding: 6.6rem 1.6rem;
        }

        .formHeader,
        .formInput,
        .submit,
        .tryAgain,
        .tourButton {
            text-transform: uppercase;
        }
    }

    .form {
        width: 48rem;
        background-color: $inversed;

        @include respond-to(sm) {
            width: 100%;
        }
    }

    .formHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 2.8rem;
        padding: 2.8rem;
        border-bottom: 1px solid $base-200;
        text-transform: uppercase;

        @include respond-to(sm) {
            margin-bottom: 2.4rem;
            padding: 2.4rem;
        }

        @include respond-to(xxs) {
            margin-bottom: 2rem;
            padding: 2rem;
        }
    }

    .formBody {
        display: flex;
        flex-direction: column;
        padding: 0 2.8rem 2.8rem 2.8rem;

        @include respond-to(sm) {
            padding: 0 2.4rem 2.4rem 2.4rem;
        }

        @include respond-to(xxs) {
            padding: 0 2rem 2rem 2rem;
        }
    }

    .title {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        color: $base-900;

        @include respond-to(sm) {
            font-size: 1.8rem;
        }
    }

    .mapLink {
        display: flex;
        align-items: center;
        margin-top: .2rem;

        .text {
            margin-top: .2rem;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1;
        }

        .pin {
            width: 2rem;
            height: 2rem;
            margin-left: 1.2rem;

            path {
                fill: $home-accent-2;
            }
        }
    }

    .address {
        margin-bottom: 6.1rem;
        font-size: 16px;
        font-weight: 300;
        line-height: 112%;
        color: $base-500;

        @include respond-to(sm) {
            margin-bottom: 8rem;
        }

        @include respond-to(xs) {
            margin-bottom: 4rem;
        }
    }

    .formInputs {
        width: 100%;
        margin-bottom: 24px;

        @include respond-to(sm) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 2.4rem;
            column-gap: 1.2rem;
        }

        @include respond-to(xs) {
            grid-template-columns: 1fr;
        }

        .formInput {
            margin-bottom: 1.6rem;
            color: $base-400;

            @include respond-to(sm) {
                margin-bottom: 2.4rem;
            }

            @include respond-to(xs) {
                margin-bottom: 1.2rem;
            }
        }
    }

    .buttonContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 1.2rem;
        margin-top: 2.4rem;

        @include respond-to(sm) {
            grid-template-columns: 1fr;
            grid-column: 1/3;
            grid-gap: 1.2rem;
            margin-top: initial;
        }

        @include respond-to(xs) {
            grid-column: initial;
            margin-top: 1.2rem;
        }
    }

    .checkboxesGroup {
        font-size: 14px;
        font-weight: 300;
        line-height: 124%;
        color: $base-400;
    }

    .submit,
    .tourButton {
        width: 20.6rem;
        padding: 2rem;
        font-size: 12px;
        font-weight: 700;
        line-height: 100%;

        @include respond-to(sm) {
            width: 100%;
        }
    }

    .policy {
        @include old-p6;

        color: $home-accent-5;
    }

    .policyLink {
        color: $home-accent-2;
    }

    .response {
        width: 100%;
        padding: 1.6rem;
        background:
            linear-gradient(rgba(255, 255, 255, 0) 6.24%, rgba(255, 255, 255, .1083) 67%),
            linear-gradient(0deg, rgba(0, 0, 0, .43), rgba(0, 0, 0, .43)),
            linear-gradient(0deg, rgba(0, 0, 0, .56), rgba(0, 0, 0, .56)),
            #0d1322;
        background-blend-mode: normal, color, normal, normal;
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;

        @include respond-to(sm) {
            margin-bottom: 2.4rem;
        }

        @include respond-to(sm) {
            margin-bottom: 1.2rem;
        }
    }

    .responseTitle {
        @include old-h4;

        margin-bottom: .8rem;
        color: $inversed;

        @include respond-to(sm) {
            @include old-p2;
        }
    }

    .responseText {
        @include old-p5;

        color: $inversed;
        opacity: .66;
    }
</style>
