// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsFilterLayout_ZNiey{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-transition:opacity .2s;transition:opacity .2s}.FlatsFilterLayout_ZNiey .button_g4B-c{-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:5.6rem}.FlatsFilterLayout_ZNiey .button_g4B-c:not(:first-child){margin-left:.6rem}.FlatsFilterLayout_ZNiey._disabled_xAK8O{opacity:.7;pointer-events:none;cursor:not-allowed}.FlatsFilterLayout_ZNiey._responsive_teumN .button_g4B-c{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;width:100%;min-width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsFilterLayout": "FlatsFilterLayout_ZNiey",
	"button": "button_g4B-c",
	"_disabled": "_disabled_xAK8O",
	"_responsive": "_responsive_teumN"
};
module.exports = ___CSS_LOADER_EXPORT___;
