// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiRangeInputDot_iEZYf{position:absolute;top:50%;z-index:1;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;text-align:center;line-height:normal;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.UiRangeInputDot_iEZYf,.UiRangeInputDot_iEZYf .handle_rmfX6{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.UiRangeInputDot_iEZYf .handle_rmfX6{border-radius:50%;-webkit-transition:.3s ease;transition:.3s ease}.UiRangeInputDot_iEZYf .handle_rmfX6:hover{-webkit-transform:scale(1.2);transform:scale(1.2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiRangeInputDot": "UiRangeInputDot_iEZYf",
	"handle": "handle_rmfX6"
};
module.exports = ___CSS_LOADER_EXPORT___;
