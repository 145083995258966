// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiUniversalDropdown_62UEN{position:relative;width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}.UiUniversalDropdown_62UEN._disabled_8oi1s{pointer-events:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiUniversalDropdown": "UiUniversalDropdown_62UEN",
	"_disabled": "_disabled_8oi1s"
};
module.exports = ___CSS_LOADER_EXPORT___;
