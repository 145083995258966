<template>
    <div :class="$style.AdvantagesAccessibility">
        <div :class="$style.title">{{ accessibility[0] }}</div>
        <div :class="$style.content">
            <div :class="$style.tabs">
                <div :class="$style.tabsContainer">
                    <div
                        v-for="(tab, index) in tabs"
                        :key="tab.label"
                        :class="[$style.tab,{ [$style._active]: activeTab === index }]"
                        @click="onTabClick(index)"
                    >
                        <component
                            :is="tab.component"
                            :class="$style.image"
                        >
                        </component>
                        <div :class="$style.label">{{ tab.label }}</div>
                    </div>
                </div>
            </div>
            <div :class="$style.numbers">
                <ExpandBlock>
                    <div :key="activeTab">
                        <div :class="$style.numbersContainer">
                            <div
                                v-for="(number, index) in accessibility[activeTab + 1]"
                                :key="index"
                                :class="$style.numberItem"
                            >
                                <div :class="$style.numberTop">
                                    <span :class="$style.big">{{ number.number }}</span>
                                    <span :class="$style.small">{{ number.unit }}</span>
                                </div>
                                <div :class="$style.numberBottom" v-html="number.title"></div>
                            </div>
                        </div>
                    </div>
                </ExpandBlock>
            </div>
        </div>
    </div>
</template>

<script>
import CarIcon from '~/assets/icons/icon-car.svg?inline';
import MetroIcon from '~/assets/icons/metro.svg?inline';
import WalkIcon from '~/assets/icons/walk.svg?inline';
import ExpandBlock from '~/components/common/ExpandBlock.vue';
export default {
    name: 'AdvantagesAccessibility',
    components: { ExpandBlock },
    props: {
        accessibility: {
            type: Array,
            default: () => [],

        },
    },

    data() {
        return {
            activeTab: 0,
            tabs: [
                {
                    component: WalkIcon,
                    label: 'Пешком',
                },

                {
                    component: MetroIcon,
                    label: 'На метро',
                },

                {
                    component: CarIcon,
                    label: 'На автомобиле',
                },
            ],
        };
    },

    methods: {
        onTabClick(index) {
            if (index !== this.activeTab) {
                this.activeTab = index;
            }
        },
    },
};
</script>

<style lang="scss" module>
    .AdvantagesAccessibility {
        max-width: 144rem;
        margin: 0 auto;
        padding: 0 0 10rem;
        background-color: $inversed;

        @include respond-to(sm) {
            padding: 0 0 8rem;
        }

        @include respond-to(xs) {
            padding: 0 0 6.4rem;
        }
    }

    .title {
        margin-bottom: 6.4rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 4rem;
        font-weight: 700;
        line-height: 4.8rem;
        color: $home-accent-2;

        @include respond-to(sm) {
            margin-bottom: 7.2rem;
            font-size: 3.2rem;
            line-height: 4rem;
        }

        @include respond-to(xs) {
            margin-bottom: 2.8rem;
            font-size: 2.4rem;
            line-height: 1.2;
        }
    }

    .content {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        min-height: 26rem;

        @include respond-to(xs) {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .tabs {
        flex: 1 0 40%;

        @include respond-to(xs) {
            flex-basis: 100%;
            overflow-x: scroll;
            overflow-y: hidden;
            min-width: 100%;
            max-width: 100%;
        }
    }

    .tabsContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-to(sm) {
            margin-right: 0;
        }

        @include respond-to(xs) {
            overflow-y: hidden;
            flex-direction: row;
            width: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .tab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.6rem;
        cursor: pointer;

        &:last-child {
            margin-bottom: 0;
        }

        &._active,
        &:hover {
            .image,
            .label {
                color: $home-accent-2;
            }
        }

        @include respond-to(xs) {
            margin-right: 2.6rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .image {
        width: 4.8rem;
        height: 4.8rem;
        margin-right: 1.6rem;
        color: $base-300;
        transition: all $homeTransition;

        @include respond-to(xs) {
            width: 3.2rem;
            height: 3.2rem;
        }
    }

    .label {
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 3.2rem;
        color: $base-300;
        transition: all $homeTransition;

        @include respond-to(xs) {
            font-size: 2rem;
            line-height: 2.8rem;
        }
    }

    .numbers {
        flex: 1 0 60%;
        padding-top: 1.2rem;

        @include respond-to(xs) {
            width: 100%;
            margin-top: 4.2rem;
            padding-top: 0;
            flex-basis: 100%;
        }
    }

    .numbersContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 4rem;
        width: 100%;

        @include respond-to(sm) {
            grid-template-columns: 1fr 1fr;
        }

        @include respond-to(xs) {
            grid-column-gap: 4rem;
            grid-row-gap: 3.2rem;
        }

        @include respond-to(xxs) {
            grid-column-gap: 3.2rem;
        }
    }

    .numberItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 2.4rem;

        @include respond-to(xxs) {
            padding: 0 1rem;
        }
    }

    .numberTop {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 4rem;
    }

    .big {
        margin-right: .4rem;
        text-transform: uppercase;
        font-size: 5.6rem;
        font-weight: 400;
        line-height: .7;
        color: $base-900;
    }

    .small {
        display: inline-block;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1;
        color: $base-900;
    }

    .numberBottom {
        margin-top: 1.6rem;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.12;
        color: $base-500;
    }
</style>
