// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FilterFooter_lEjxv{display:-ms-grid;display:grid;-ms-grid-columns:1fr -webkit-min-content;-ms-grid-columns:1fr min-content;grid-template-columns:1fr -webkit-min-content;grid-template-columns:1fr min-content;grid-gap:1.2rem;gap:1.2rem;padding:1.6rem 2.4rem}.FilterFooter_lEjxv .reset_tgSnN{white-space:nowrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FilterFooter": "FilterFooter_lEjxv",
	"reset": "reset_tgSnN"
};
module.exports = ___CSS_LOADER_EXPORT___;
