/**
 * Данный миксин необходимо добавлять для всех создаваемых компонентов кластеров.
 * Компонент не должен иметь template и должен реализовывать свой метод getGeoObject
 */

import { useGeoObjectActions } from '~/components/common/ymap/utils';
import { ymapClientKey } from '~/components/common/ymap/config';

const clustererMixin = {
    inject: {
        getYmapClient: {
            from: ymapClientKey,
            default: null,
        },
    },

    data() {
        return {
            geoObject: null,
            ymaps: null,
        };
    },

    created() {
        this.ymaps = this.getYmapClient();

        // получаем обработчики для добавления/удаление гео-объектов
        const { addGeoObject, removeGeoObject } = useGeoObjectActions(this.updateGeoObjects);
        this.addGeoObject = addGeoObject;
        this.removeGeoObject = removeGeoObject;
    },

    mounted() {
        // добавление кластера на Яндекс карту
        if (this.ymaps && this.$parent?.addGeoObject) {
            this.geoObject = this.getGeoObject();
            this.$parent.addGeoObject(this.geoObject);
        }
    },

    beforeDestroy() {
        // удаление кластера с Яндекс карты
        if (this.$parent?.removeGeoObject && this.geoObject) {
            this.$parent.removeGeoObject(this.geoObject);
        }
    },

    methods: {
        updateGeoObjects(arr, action) {
            if (!this.geoObject || !arr.length) {
                return;
            }

            this.geoObject[action](arr);
            this.$emit('geo-objects-updated', action);
        },

        /**
         * Данный метод необходимо переопределить в компоненте
         */
        getGeoObject() {
            return new this.ymaps.Clusterer();
        },
    },

    render(h) {
        return h('div', {
            style: { display: 'none' },
            attrs: { 'data-name': this.$options?.name || '' },
        }, this.$slots?.default || []);
    },
};

export default clustererMixin;
