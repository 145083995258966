<template>
    <div :class="$style.FlatsAdvancedFilterHeader">
        <h3 :class="$style.title">
            {{ $device.isDesktop ? 'Расширенный фильтр' : 'Фильтр' }}
        </h3>

        <UiCircleButton
            data-gtm-html-id="close"
            :size="$device.isMobile ? 'moderate' : 'normal'"
            @click="$emit('close')"
        >
            <CloseIcon />
        </UiCircleButton>

        <transition name="component-fade">
            <div
                v-if="isResetBtnShown && $device.isMobile"
                :class="[$style.reset, {[$style._disabled]: isReloading}]"
                data-gtm-html-id="reset"
                @click="resetFilters"
            >
                Сбросить
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { DEFAULT_FILTERS } from '~/assets/js/constants';

import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';

import CloseIcon from '~/assets/icons/ui/close.svg?inline';

export default {
    name: 'FlatsAdvancedFilterHeader',

    components: {
        UiCircleButton,
        CloseIcon,
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'isReloading',
        ]),

        isResetBtnShown() {
            return JSON.stringify(this.filters) !== JSON.stringify(DEFAULT_FILTERS);
        },
    },

    methods: {
        ...mapActions({
            resetFilters: 'flats/resetFilters',
        }),
    },
};
</script>

<style lang="scss" module>
    .FlatsAdvancedFilterHeader {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .title {
            @include h4;
        }

        .reset {
            @include l4;

            color: $base-900;
            transition: $transition;

            &._disabled {
                pointer-events: none;
            }
        }

        @include respond-to(md) {
            .title {
                @include h5;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>
