// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsAdvancedFiltersMobile_zl6kj .ready_cpkvO{margin-bottom:2rem}.FlatsAdvancedFiltersMobile_zl6kj .column_Heqp1{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:2rem;gap:2rem;padding-top:2rem;padding-bottom:2rem;border-top:1px solid #dbdbdb}.FlatsAdvancedFiltersMobile_zl6kj .column_Heqp1:last-child{padding-bottom:0}.FlatsAdvancedFiltersMobile_zl6kj .row_MvWEf{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);width:100%;grid-column-gap:1.2rem;-webkit-column-gap:1.2rem;-moz-column-gap:1.2rem;column-gap:1.2rem}.FlatsAdvancedFiltersMobile_zl6kj .title_5jE02{font-size:1.6rem;font-weight:700;line-height:2rem;color:#2f2f2f}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsAdvancedFiltersMobile": "FlatsAdvancedFiltersMobile_zl6kj",
	"ready": "ready_cpkvO",
	"column": "column_Heqp1",
	"row": "row_MvWEf",
	"title": "title_5jE02"
};
module.exports = ___CSS_LOADER_EXPORT___;
