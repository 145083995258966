// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UiSimpleDropdownOption_MS1b\\+{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;width:100%;height:4.8rem;padding:0 2rem;-webkit-transition:.3s ease;transition:.3s ease;cursor:pointer}.UiSimpleDropdownOption_MS1b\\+:hover{background-color:#2f2f2f}.UiSimpleDropdownOption_MS1b\\+:hover .title_YA2bg{color:#fff}.UiSimpleDropdownOption_MS1b\\+._active_PSIdo{background-color:#2f2f2f}.UiSimpleDropdownOption_MS1b\\+._active_PSIdo .title_YA2bg{color:#fff}.UiSimpleDropdownOption_MS1b\\+._hasDescription_rlpZn{padding-right:3.6rem}.UiSimpleDropdownOption_MS1b\\+ .title_YA2bg{font-size:1.4rem;font-weight:700;line-height:1.8rem;color:#2f2f2f;-webkit-transition:inherit;transition:inherit}.UiSimpleDropdownOption_MS1b\\+ .icon_KjcnP{display:inline-block;padding:.4rem;color:#cfcfcf}.UiSimpleDropdownOption_MS1b\\+ .icon_KjcnP svg{display:block;width:14px;height:auto}.UiSimpleDropdownOption_MS1b\\+ .tooltip_k6USp{position:absolute;top:50%;right:1.2rem;display:inline-block;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.tooltipBox_xQ0Al{max-width:28rem;padding:1.6rem;background-color:#fff;font-size:1.2rem;line-height:1.6rem;-webkit-box-shadow:12px 12px 34px 0 rgba(0,0,0,.05);box-shadow:12px 12px 34px 0 rgba(0,0,0,.05)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UiSimpleDropdownOption": "UiSimpleDropdownOption_MS1b+",
	"title": "title_YA2bg",
	"_active": "_active_PSIdo",
	"_hasDescription": "_hasDescription_rlpZn",
	"icon": "icon_KjcnP",
	"tooltip": "tooltip_k6USp",
	"tooltipBox": "tooltipBox_xQ0Al"
};
module.exports = ___CSS_LOADER_EXPORT___;
