<template>
    <div :class="$style.HomePartnersCard">
        <div :class="$style.partnersDescription">
            <div :class="$style.partnersTitle">Инвесторы проекта</div>
            <div :class="$style.partnersContent">
                Инвесторы проекта — международный консорциум суверенных фондов. Проектное финансирование обеспечивают крупнейшие банки.
            </div>
        </div>

        <div
            v-if="$device.isDesktop"
            :class="$style.imageWrapper"
        >
            <div
                v-for="(logo, i) in logos"
                :key="i"
                class="swiper-slide"
                :class="$style.logoItem"
            >
                <img
                    :src="`/images/pages/home/sponsors/${logo.path}`"
                    :style="{'height': i === 1? '20px' : undefined}"
                    alt=""
                    :class="[$style.logoImage]"
                >
            </div>

        </div>

        <div
            v-else
            :class="$style.imageWrapper"
        >
            <div
                v-for="(logo, i) in getRequiredLogos"
                :key="i"
                class="swiper-slide"
                :class="$style.logoItem"
            >
                <img
                    :src="`/images/pages/home/sponsors/${logo.path}`"
                    alt=""
                    :class="$style.logoImage"
                >
            </div>

        </div>

        <div
            v-if="$device.isMobileOrTablet"
            :class="$style.controls"
        >
            <UiButton
                color="primary"
                :class="$style.sponsorsBtn"
                @click="openModal"
            >
                Смотреть всех инвесторов
            </UiButton>
        </div>
    </div>

</template>

<script>
import UiButton from '~/components/ui/home/UiButton';


export default {
    name: 'HomePartners',
    components: {
        UiButton,
    },

    data() {
        return {
            isPaused: false,
            slider: undefined,
            logos: [
                {
                    id: 2,
                    path: 'sponsor-logo-2.png',
                    required: true,
                },
                {
                    id: 3,
                    path: 'sponsor-logo-3.png',
                    required: true,
                },
                {
                    id: 5,
                    path: 'sponsor-logo-5.png',
                    required: true,
                },
                {
                    id: 6,
                    path: 'sponsor-logo-6.png',
                    required: true,
                },
                {
                    id: 7,
                    path: 'sponsor-logo-7.png',
                },
                {
                    id: 8,
                    path: 'sponsor-logo-8.png',
                },
            ],
        };
    },

    computed: {
        getRequiredLogos() {
            return this.logos.filter(el => el.required === true);
        },
    },

    methods: {
        openModal() {
            this.$modal.open('SponsorsModal', this.logos);
        },
    },
};
</script>

<style lang="scss" module>

    .partnersDescription {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 9.6rem;
        text-align: start;

        @include respond-to(sm) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin: 0 auto 6rem;
        }

        @include respond-to(xs) {
            width: 100%;
        }
    }

    .partnersTitle {
        font-size: 1.4rem;
        line-height: 1.24;
        color: $base-900;

        @include respond-to(sm) {
            text-align: start;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: 700;
            line-height: 1;
            letter-spacing: -.04rem;
            color: $base-900;
        }
    }

    .partnersContent {
        max-width: 70.5rem;
        margin-right: 8.2rem;
        font-size: 2rem;
        font-weight: 300;
        line-height: 1.32;

        @include respond-to(sm) {
            margin-top: 2.4rem;
            margin-right: 0;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 140%;
            color: $base-500;
        }

        @include respond-to(xs) {
            width: 100%;
        }
    }

    .logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;
        width: 100%;
        padding: 2.7rem 2.3rem 2.7rem 2.9rem;
    }

    .logoItem {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo_1 {
            height: 35px;
        }

        @include respond-to(sm) {
            .logoImage {
                width: auto;
                height: 42px;
                mix-blend-mode: darken;
                filter: grayscale(100%);
                cursor: pointer;

                @include respond-to(xs) {
                    height: 26px;
                }

                &:hover {
                    filter: none;
                }
            }
        }

        img {
            mix-blend-mode: darken;
            filter: grayscale(100%);
            cursor: pointer;

            &:hover {
                filter: none;
            }
        }
    }

    .imageWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include respond-to(sm) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-row-gap: 40px;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .controls {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        width: 100%;
        margin-top: 66px;
    }

    .sponsorsBtn {
        width: 100%;
        height: 40px;
    }
</style>
