// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GenplanBuildingLabel_vtQW3{overflow:hidden;min-width:3.4rem;text-align:center;-webkit-transform:translateZ(0);transform:translateZ(0);-webkit-transition:.3s ease;transition:.3s ease;cursor:pointer}.GenplanBuildingLabel_vtQW3._disabled_2Kb4j{pointer-events:none}.GenplanBuildingLabel_vtQW3 .text_fGlIl{text-transform:uppercase;font-size:1.2rem;font-weight:700;line-height:100%}.GenplanBuildingLabel_vtQW3 .icon_LHEfP,.GenplanBuildingLabel_vtQW3 .text_fGlIl{position:relative;color:#2f2f2f;-webkit-transition:.3s ease;transition:.3s ease}.GenplanBuildingLabel_vtQW3 .icon_LHEfP{width:1.2rem;height:1.2rem;margin-left:.8rem}.GenplanBuildingLabel_vtQW3 .icon_LHEfP svg{position:absolute;top:0;left:0;width:100%;height:100%}.body_L8oZH,.header_TAbll{padding:0 1rem}.header_TAbll{padding-top:.6rem;padding-bottom:.6rem;background:#fff;text-align:center;text-transform:uppercase;font-size:1rem;font-weight:700;line-height:1;color:#2f2f2f}.body_L8oZH{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;min-height:2.8rem;padding-top:.8rem;padding-bottom:.8rem;background-color:#f7ee80;line-height:1}.GenplanBuildingLabel_vtQW3._inversed_G6\\+67 .body_L8oZH{background-color:#2f2f2f}.GenplanBuildingLabel_vtQW3._inversed_G6\\+67 .icon_LHEfP,.GenplanBuildingLabel_vtQW3._inversed_G6\\+67 .text_fGlIl{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GenplanBuildingLabel": "GenplanBuildingLabel_vtQW3",
	"_disabled": "_disabled_2Kb4j",
	"text": "text_fGlIl",
	"icon": "icon_LHEfP",
	"body": "body_L8oZH",
	"header": "header_TAbll",
	"_inversed": "_inversed_G6+67"
};
module.exports = ___CSS_LOADER_EXPORT___;
