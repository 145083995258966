<script>
import clustererMixin from '~/components/common/ymap/mixins/clustererMixin';

export default {
    name: 'YMapInfrastructureClusterer',
    mixins: [clustererMixin],

    methods: {
        getGeoObject() {
            const layout = this.ymaps.templateLayoutFactory.createClass(`
                    <div class="ymap-cluster-placemark">$[properties.iconContent]</div>
                `);

            const clusterer = new this.ymaps.Clusterer({
                clusterOpenBalloonOnClick: false, // запретить открытие балуна при клике на кластере
                clusterDisableClickZoom: true, // запретить увеличение карты при клике на кластер
                clusterIconLayout: layout,
                clusterIconShape: {
                    type: 'Circle',
                    coordinates: [0, 0],
                    radius: 16,
                },
            });

            // По умолчанию при клике на кластер карта слишком сильно увеличивается
            // поэтому определяем свой обработчик события при клике на кластер
            // и увеличиваем карту на +2
            clusterer.events.add('click', e => {
                const map = clusterer.getMap();
                const target = e.get('target');

                // данный обработчик так же срабатывает при клике на PlaceMark
                // поэтому проверяем что это действительно Clusterer
                // у PlaceMark параметр getGeoObjects = undefined
                if (typeof target.getGeoObjects != 'undefined') {
                    const coords = target.geometry.getCoordinates();
                    const zoomValue = map.getZoom() + 2;
                    map.setCenter(coords, zoomValue, {
                        duration: 300,
                    });
                }
            });

            return clusterer;
        },
    },
};
</script>

<style lang="scss">
    .ymap-cluster-placemark {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        background-color: $base-900;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 3.2rem;
        color: #fff;
        transform: translate(-50%, -50%);
    }
</style>
