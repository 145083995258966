<template>
    <div :class="[$style.ModalAccordionBody, classes]">
        <UiSelectAccordion
            :data-gtm-html-id="name"
            :use-child="useChild"
            :name="name"
            :value-name="valueName"
            :label-name="labelName"
            :child-name="childName"
            :value="internalValue"
            :child-value="childValue"
            :specs="specs"
            :child-specs="childSpecs"
            :facets="facets"
            :child-facets="childFacets"
            :reset-label="resetLabel"
            :multiple="multiple"
            :child-multiple="childMultiple"
            :disabled="isReloading"
            @change="onChange"
            @close="$emit('close')"
            @show-info-modal="infoModalFn"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import UiSelectAccordion from '~/components/ui/select-accordion/UiSelectAccordion.vue';

export default {
    name: 'ModalAccordionBody',

    components: {
        UiSelectAccordion,
    },

    props: {
        /**
         * Будет вызываться при изменении опций
         */
        changeOptionFn: {
            type: Function,
            default: () => ({}),
        },

        /**
         * Будет вызываться при тригере активатора модалки тултипа в одной из опций
         */
        infoModalFn: {
            type: Function,
            default: () => ({}),
        },

        /**
         * Будут ли включены подпункты. Если true, то поля childName childValue childSpecs childFacets обязательны
         */
        useChild: {
            type: Boolean,
            default: false,
        },

        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * Имя ключа для работы с формами или запросами подпункта
         */
        childName: {
            type: String,
            default: '',
        },

        /**
         * Текущее значение для определения активного элемента
         */
        value: {
            type: [Number, String, Array],
            required: true,
        },

        /**
         * Текущее значение для определения активного элемента подпункта
         */
        childValue: {
            type: [Number, String, Array],
            default: '',
        },

        /**
         * Название поля для value
         */
        valueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label
         */
        labelName: {
            type: String,
            default: 'label',
        },

        /**
         * Название поля для value подпункта
         */
        childValueName: {
            type: String,
            default: 'value',
        },

        /**
         * Название поля для label подпункта
         */
        childLabelName: {
            type: String,
            default: 'label',
        },

        /**
         * Диапазон всех доступных значений селектора
         */
        specs: {
            type: Array,
            required: true,
        },

        /**
         * Диапазон всех доступных значений селектора подпункта + поле parentId обязательно для таких specs
         */
        childSpecs: {
            type: Array,
            default: () => [],
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            required: true,
        },

        /**
         * Фасеты подпункта
         */
        childFacets: {
            type: Array,
            default: () => [],
        },

        /**
         * Доп. элемент в селекторе, при выборе
         * которого - сбрасывается состояние value
         */
        resetLabel: {
            type: String,
            default: '',
        },

        /**
         * Доп. элемент в селекторе подпункта, при выборе
         * которого - сбрасывается состояние value
         */
        childResetLabel: {
            type: String,
            default: '',
        },

        /**
         * Включает возможноть выбора более одного элемента
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Включает возможноть выбора более одного элемента подпункта
         */
        childMultiple: {
            type: Boolean,
            default: false,
        },

        hasPadding: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            internalValue: this.value,
        };
    },

    computed: {
        ...mapState('flats', [
            'isReloading',
        ]),

        classes() {
            return {
                [this.$style._padding]: this.hasPadding,
            };
        },
    },

    watch: {
        value(newVal) {
            this.internalValue = newVal;
        },
    },

    methods: {
        onChange(value) {
            this.changeOptionFn(value);
            this.internalValue = value[this.name];
        },
    },
};
</script>

<style lang="scss" module>
    .ModalAccordionBody {
        display: flex;
        flex-direction: column;
        padding: 0 2.4rem;

        &._padding {
            padding-bottom: 2.4rem;

            @include respond-to(xs) {
                padding-bottom: 1.6rem;
            }
        }

        @include respond-to(xs) {
            padding: 0 1.6rem;
        }
    }
</style>
