<template>
    <div
        :class="[$style.UiFilterDropdownValue, classList]"
        @click="$emit('click')"
    >
        <div
            v-if="!isSelected"
            :class="$style.value"
            class="ui-filter-dropdown-value__placeholder"
        >
            {{ placeholder }}
        </div>

        <component
            :is="display"
            v-else-if="display"
            :value="value"
            :class="$style.value"
        />

        <input
            v-else
            :value="value"
            type="text"
            :readonly="true"
            :class="$style.value"
        />

        <div :class="$style.arrow">
            <ArrowIcon />
        </div>
    </div>
</template>

<script>
import ArrowIcon from '~/assets/icons/ui/arrow-down.svg?inline';

export default {
    name: 'UiFilterDropdownValue',

    components: {
        ArrowIcon,
    },

    props: {
        value: {
            type: [String, Array],
            default: '',
        },

        isOpened: {
            type: Boolean,
            default: false,
        },

        isSelected: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        display: {
            type: Object,
            default: null,
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: val => ['base'].includes(val),
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._selected]: this.isSelected,
                [this.$style._opened]: this.isOpened,
                [this.$style._disabled]: this.disabled,
            }];
        },
    },
};
</script>

<style lang="scss" module>
    .UiFilterDropdownValue {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;

        /* Sizes */
        &._medium {
            height: 5.6rem;
            padding: 0 2rem;

            .value {
                @include l4;

                font-size: 1.2rem;
            }

            .arrow {
                width: 1.6rem;
                min-width: 1.6rem;
                height: 1.6rem;
                min-height: 1.6rem;
                margin-left: 1.2rem;
            }
        }

        /* Colors */
        &._base {
            background-color: $base-50;

            &._selected {
                .value {
                    color: $base-900;
                }

                .arrow {
                    color: $base-900;
                }
            }

            &:hover {
                .value {
                    color: $base-600;
                }

                .arrow {
                    opacity: .6;
                }
            }

            &._opened {
                .arrow {
                    color: $base-900;
                }

                .value {
                    color: $base-500;
                }
            }

            &._disabled {
                pointer-events: none;

                .value {
                    color: $base-400;
                }

                .arrow {
                    opacity: .4;
                }
            }

            .value {
                color: $base-900;
            }

            .arrow {
                color: $base-900;
            }
        }

        /* Modificators */
        &._opened {
            .arrow {
                transform: rotate(180deg);
            }
        }

        .value {
            overflow: hidden;
            display: inline-block;
            width: 100%;
            padding: initial;
            background-color: transparent;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
            transition: $transition;
            cursor: pointer;

            &::selection {
                background-color: transparent;
            }
        }

        .arrow {
            display: flex;
            transition: $transition;
        }
    }
</style>
