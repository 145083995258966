<template>
    <div :class="$style.UiInputWrapper">
        <label
            v-if="label"
            :class="$style.label"
        >
            {{ label }}

            <FlatsTooltip
                v-if="tooltip"
                :text="tooltip"
            />
        </label>

        <slot />
    </div>
</template>

<script>
import FlatsTooltip from '~/components/flats/common/FlatsTooltip.vue';

export default {
    name: 'UiInputWrapper',

    components: {
        FlatsTooltip,
    },

    props: {
        label: {
            type: String,
            default: '',
        },

        tooltip: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" module>
    .UiInputWrapper {
        display: flex;
        flex-direction: column;

        .label {
            @include l5;

            position: relative;
            display: flex;
            margin-bottom: .8rem;
            color: $base-600;
        }
    }
</style>
