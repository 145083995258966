<template>
    <div :class="$style.ProjectGenplanFlatsPrices">
        <div
            v-for="(item, i) in flatsInfo"
            :key="i"
            :class="$style.item"
        >
            <div :class="$style.label">
                {{ item.label }}
            </div>

            <div v-if="item.count" :class="$style.count">
                {{ item.count }} кв.
            </div>

            <div :class="$style.value">
                {{ item.value }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectGenplanFlatsPrices',
    props: {
        flatsInfo: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanFlatsPrices {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.2rem;

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .count,
        .label,
        .value {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1;
        }

        .count {
            width: 3.2rem;
            text-align: left;
            white-space: nowrap;
        }

        .label {
            min-width: 7rem;
        }

        .label,
        .count {
            color: $base-500;
        }

        .value {
            min-width: 9rem;
            text-align: right;
            color: $base-900;
        }

        .count + .value {
            text-align: right;
        }
    }
</style>
