<template>
    <div :class="[$style.FlatsViewSwitcher, {[$style._mobile]: $device.isMobile}]">
        <UiButton
            v-if="!$device.isMobile || $device.isMobile && gridView === 'rows'"
            :color="$device.isMobile ? 'gray': gridView === 'cards' ? 'black' : 'white'"
            :size="$device.isMobile ? 'moderate' : 'normal'"
            @click="setGridView('cards')"
        >
            <template #iconBefore>
                <GridIcon />
            </template>
        </UiButton>

        <UiButton
            v-if="!$device.isMobile || $device.isMobile && gridView === 'cards'"
            :color="$device.isMobile ? 'gray': gridView === 'rows' ? 'black' : 'white'"
            :size="$device.isMobile ? 'moderate' : 'normal'"
            @click="setGridView('rows')"
        >
            <template #iconBefore>
                <ListIcon />
            </template>
        </UiButton>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UiButton from '~/components/ui/buttons/UiButton.vue';

import GridIcon from '~/assets/icons/ui/grid.svg?inline';
import ListIcon from '~/assets/icons/ui/list.svg?inline';

export default {
    name: 'FlatsViewSwitcher',

    components: {
        UiButton,
        GridIcon,
        ListIcon,
    },

    computed: {
        ...mapState('flats', [
            'gridView',
        ]),
    },

    methods: {
        ...mapActions({
            setGridView: 'flats/setGridView',
        }),
    },
};
</script>

<style lang="scss" module>
    .FlatsViewSwitcher {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: .6rem;
        width: fit-content;
        padding: .3rem;
        background-color: $base-0;

        &._mobile {
            grid-template-columns: 1fr;
            gap: initial;
            padding: initial;
            border-radius: initial;
            border: none;
            background-color: initial;
        }
    }
</style>
