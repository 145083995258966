<template>
    <UiButtonGroup
        :class="[$style.FlatsFilterLayout, classes]"
        :multiple="multiple"
        :mandatory="false"
        :value="value"
        @input="$emit('change', {[name]: $event})"
    >
        <UiButton
            v-for="btn in buttons"
            :key="btn.value"
            :value="btn.value"
            :disabled="btn.disabled"
            :class="$style.button"
            size="big"
            :data-gtm-html-id="btn.value"
        >
            {{ btn.label }}
        </UiButton>
    </UiButtonGroup>
</template>

<script>
import UiButtonGroup from '~/components/ui/buttons/UiButtonGroup.vue';
import UiButton from '~/components/ui/buttons/UiButton.vue';

export default {
    name: 'FlatsFilterLayout',
    components: { UiButton, UiButtonGroup },

    CUSTOM_LABELS: {
        Ст: 'Студии',
        '1к': '1',
        '2к': '2',
        '3к': '3',
        '4к': '4',
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: String,
            required: true,
        },

        /**
         * Текущее значение активного элемента
         */
        value: {
            type: [Number, String, Array],
            required: true,
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Array,
            required: true,
        },

        /**
         * Диапазон всех ДОСТУПНЫХ значений селектора
         */
        specs: {
            type: Array,
            required: true,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Включает возможноть выбора более одного элемента
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * Растягивает элементы выбора на всю доступную ширину
         */
        responsive: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                [this.$style._disabled]: this.disabled,
                [this.$style._responsive]: this.responsive,
            };
        },

        buttons() {
            return this.specs.map(item => ({
                label: this.$options.CUSTOM_LABELS[item.value] || item.label,
                value: item.value,
                disabled: !this.facets.includes(item.value),
            }));
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsFilterLayout {
        display: flex;
        transition: opacity .2s;

        .button {
            flex-grow: 0;
            width: fit-content;
            min-width: 5.6rem;

            &:not(:first-child) {
                margin-left: .6rem;
            }
        }

        &._disabled {
            opacity: .7;
            pointer-events: none;
            cursor: not-allowed;
        }

        &._responsive {
            .button {
                flex-grow: 1;
                width: 100%;
                min-width: auto;
            }
        }
    }
</style>
