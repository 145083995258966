// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewHomePage_slJET .hero_OJNtB{margin-bottom:3.6rem}.NewHomePage_slJET .cards_TA9K0{margin-bottom:6.4rem}.NewHomePage_slJET .advantages_2b9uT{margin-bottom:-2px}.NewHomePage_slJET .genplan_rb5AQ{position:relative;z-index:1;margin-bottom:3.6rem;padding-top:10rem}@media(max-width:991.98px){.NewHomePage_slJET .genplan_rb5AQ{margin-bottom:2.4rem;padding-top:6.4rem;padding-top:var(--headerHeightTablet,6.4rem)}}@media(max-width:767.98px){.NewHomePage_slJET .genplan_rb5AQ{margin-bottom:3.6rem;padding-top:5.4rem;padding-top:var(--headerHeightMobile,5.4rem)}}.NewHomePage_slJET .background_RNZ7d{padding:10rem 0 6.4rem;background-color:#f3f3f3}@media(max-width:991.98px){.NewHomePage_slJET .background_RNZ7d{padding:46px 0}}.NewHomePage_slJET .homeFormat_4JoXY{padding-bottom:10rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewHomePage": "NewHomePage_slJET",
	"hero": "hero_OJNtB",
	"cards": "cards_TA9K0",
	"advantages": "advantages_2b9uT",
	"genplan": "genplan_rb5AQ",
	"background": "background_RNZ7d",
	"homeFormat": "homeFormat_4JoXY"
};
module.exports = ___CSS_LOADER_EXPORT___;
