<template>
    <div
        :class="[$style.AdvantageHeightCard, {[$style._disable]: !card.text}]"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @click="onClick"
    >
        <ImageLazy
            :class="$style.image"
            :preview="card.imagePreview"
            :origin="card.imageDisplay"
            position-image="top center"
        />
        <div
            v-if="hasTitle && !isFlatsCard"
            :class="$style.overlay"
        />

        <div :class="$style.content">
            <div :class="$style.contentContainer">
                <div v-if="isFlatsCard" :class="$style.height">
                    <span :class="$style.heightValue">140</span>
                    <span :class="$style.heightMeasure">+</span>
                </div>
                <div v-if="isFlatsCard" :class="[$style.title, {[$style._flats]: isFlatsCard}]">вариантов планировок</div>
                <div
                    v-else
                    :class="[
                        $style.title,
                        { [$style._active]: active },
                    ]"
                >
                    {{ card.title }}
                </div>
            </div>
        </div>
        <UiArrowButton
            v-if="card.text"
            solid-arrow
            :class="$style.arrowButton"
            :hovered="Boolean(isArrowButtonHoverStateEnabled && card.text.length)"
        />
    </div>
</template>

<script>
import ImageLazy from '~/components/common/ImageLazy';
import UiArrowButton from '~/components/ui/home/UiArrowButton.vue';

export default {
    name: 'AdvantageHeightCard',

    components: {
        ImageLazy,
        UiArrowButton,
    },

    props: {
        active: {
            type: Boolean,
            default: false,
        },

        card: {
            type: Object,
            default: () => ({}),
        },

        cards: {
            type: Array,
            default: () => [],
        },

        index: {
            type: Number,
            default: 0,
        },

        isFlatsCard: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isArrowButtonHoverStateEnabled: false,
        };
    },

    computed: {
        hasTitle() {
            return Boolean(this.card?.title);
        },
    },

    methods: {
        handleMouseEnter() {
            this.isArrowButtonHoverStateEnabled = true;
        },

        handleMouseLeave() {
            this.isArrowButtonHoverStateEnabled = false;
        },

        onClick() {
            if (this.card.text.length) {
                this.$modalNew.open('ModalHomeAdvantages', { id: this.card.id });
            }
        },
    },
};
</script>

<style lang="scss" module>
    .AdvantageHeightCard {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: $base-50;
        cursor: pointer;

        &._disable {
            pointer-events: none;
        }

        &:hover {
            .image {
                transform: scale(1.05);
            }
        }
    }

    .image {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: all $homeTransition;
    }

    .arrowButton {
        position: absolute;
        right: 3.2rem;
        bottom: 3.2rem;
        z-index: 2;
        width: 2.4rem;
        height: 2.4rem;
        font-size: 0;

        @include respond-to(sm) {
            right: 2.8rem;
            bottom: 2.8rem;
            width: 2rem;
            max-width: 2rem;
            height: 2rem;
            max-height: 2rem;
        }

        @include respond-to(xxs) {
            right: 2rem;
            bottom: 2rem;
        }
    }

    .overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 28.75%;
        background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, .64) 100%);
    }

    .content {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 100%;
    }

    .contentContainer {
        position: relative;
        z-index: 3;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 3.2rem;
        color: $home-base-3;

        @include respond-to(sm) {
            padding: 2.8rem;
        }

        @include respond-to(xxs) {
            padding: 2rem;
        }
    }

    .height {
        display: flex;
        align-items: flex-end;
        color: $home-accent-2;
        transition: .3s ease;

        &._active {
            color: $home-accent-2;
        }
    }

    .heightValue {
        display: inline-block;
        font-size: 9.6rem;
        line-height: .75;
        letter-spacing: -1rem;
    }

    .heightMeasure {
        @include old-h2;

        margin-left: .7rem;
    }

    .title {
        padding-right: 3.2rem;
        text-align: left;
        text-transform: uppercase;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.32;
        color: $inversed;
        transition: .3s ease;

        &._active {
            color: $inversed;
        }

        &._flats {
            text-align: right;
            color: $home-accent-2;
            transition: none;

            &._active {
                color: $home-accent-2;
            }
        }
    }

    @include respond-to(sm) {
        .contentContainer {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
        }

        .heightValue {
            display: inline-block;
            width: auto;
            font-size: 58px;
            font-weight: 700;
            line-height: .86;
            letter-spacing: normal;
        }

        .heightMeasure,
        .title {
            @include old-h4;
        }

        .overlay {
            height: 31%;
        }

        .height {
            margin-bottom: 6px;
        }

        .title {
            text-align: initial;
            font-size: 1.6rem;
        }
    }

    @include respond-to(xs) {
        .heightValue {
            font-size: 56px;
        }

        .overlay {
            height: 27%;
        }
    }
</style>
