// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectGenplanCorpusNavigation_PZ8KT{position:relative;width:100%;height:100%;pointer-events:none}.ProjectGenplanCorpusNavigation_PZ8KT:before{content:\"\";position:absolute;top:0;left:0;width:100%;height:20%;background:-webkit-gradient(linear,left bottom,left top,from(rgba(47,47,47,0)),color-stop(25%,rgba(47,47,47,.2)),color-stop(60%,rgba(47,47,47,.6)),to(rgba(47,47,47,.8)));background:linear-gradient(0deg,rgba(47,47,47,0),rgba(47,47,47,.2) 25%,rgba(47,47,47,.6) 60%,rgba(47,47,47,.8));pointer-events:none;opacity:.8}.ProjectGenplanCorpusNavigation_PZ8KT:not(._disabled_wPPa4)>*{pointer-events:all}.ProjectGenplanCorpusNavigation_PZ8KT .left_ya2CD{position:absolute;top:3.2rem;left:6.4rem;display:-ms-grid;display:grid;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-grid-columns:-webkit-min-content 1fr;-ms-grid-columns:min-content 1fr;grid-template-columns:-webkit-min-content 1fr;grid-template-columns:min-content 1fr;grid-gap:3.2rem;gap:3.2rem}.ProjectGenplanCorpusNavigation_PZ8KT .right_gKJV5{position:absolute;top:3.2rem;right:6.4rem}.ProjectGenplanCorpusNavigation_PZ8KT .wrap_DVmnz{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.ProjectGenplanCorpusNavigation_PZ8KT .subtitle_WhkfZ{font-size:1.2rem;font-weight:700;line-height:1.6rem;color:#2f2f2f;margin-bottom:.4rem;color:#fff;opacity:.72}.ProjectGenplanCorpusNavigation_PZ8KT .title_hPMoC{font-size:1.6rem;font-weight:700;line-height:2rem;color:#2f2f2f;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectGenplanCorpusNavigation": "ProjectGenplanCorpusNavigation_PZ8KT",
	"_disabled": "_disabled_wPPa4",
	"left": "left_ya2CD",
	"right": "right_gKJV5",
	"wrap": "wrap_DVmnz",
	"subtitle": "subtitle_WhkfZ",
	"title": "title_hPMoC"
};
module.exports = ___CSS_LOADER_EXPORT___;
