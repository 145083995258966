<template>
    <div
        :class="[$style.AdvantageListCard, {[$style._disable]: !card.text}]"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
        @click="onClick"
    >
        <ImageLazy
            :preview="card.imagePreview"
            :origin="card.imageDisplay"
            :class="$style.image"
        />

        <div
            v-if="hasTitle"
            :class="$style.overlay"
        />

        <div :class="$style.content">
            <div :class="$style.contentContainer">
                <div :class="$style.labels">
                    <AdvantageCardLabels :card="card" />
                </div>

                <div
                    :class="$style.title"
                    v-html="card.title"
                />
            </div>

            <UiArrowButton
                v-if="card.text"
                solid-arrow
                :class="$style.arrowButton"
                :hovered="Boolean(isArrowButtonHoverStateEnabled && card.text.length)"
            />
        </div>
    </div>
</template>

<script>
import ImageLazy from '~/components/common/ImageLazy';
import AdvantageCardLabels from '~/components/common/card/advantage-cards/AdvantageCardLabels';
import UiArrowButton from '~/components/ui/home/UiArrowButton';

export default {
    name: 'AdvantageListCard',

    components: {
        ImageLazy,
        AdvantageCardLabels,
        UiArrowButton,
    },

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },

        cards: {
            type: Array,
            default: () => [],
        },

        index: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            isArrowButtonHoverStateEnabled: false,
        };
    },

    computed: {
        hasTitle() {
            return Boolean(this.card?.title);
        },
    },

    methods: {
        handleMouseEnter() {
            this.isArrowButtonHoverStateEnabled = true;
        },

        handleMouseLeave() {
            this.isArrowButtonHoverStateEnabled = false;
        },

        onClick() {
            if (this.card.text) {
                this.$modalNew.open('ModalHomeAdvantages', { id: this.card.id });
            }
        },
    },
};
</script>

<style lang="scss" module>
    .AdvantageListCard {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: $base-50;
        cursor: pointer;

        &._disable {
            pointer-events: none;
        }

        &:hover {
            .image {
                transform: scale(1.05);
            }
        }

        .image {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            transition: transform .3s ease-in-out;
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 28.75%;
            background: linear-gradient(180deg, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, .64) 100%);
        }

        .content {
            position: relative;
            z-index: 3;
            width: 100%;
            height: 100%;
        }

        .contentContainer {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 3.2rem;

            @include respond-to(sm) {
                padding: 2.8rem;
            }

            @include respond-to(xxs) {
                padding: 2rem;
            }
        }

        .title {
            padding-right: 3.2rem;
            text-transform: uppercase;
            font-size: 2.4rem;
            font-weight: 700;
            line-height: 1.32;
            color: $inversed;
        }

        .arrowButton {
            position: absolute;
            right: 3.2rem;
            bottom: 3.2rem;
            font-size: 0;

            @include respond-to(sm) {
                right: 2.8rem;
                bottom: 2.8rem;
                width: 2rem;
                height: 2rem;
            }

            @include respond-to(xxs) {
                right: 2rem;
                bottom: 2rem;
            }
        }
    }

    @include respond-to(sm) {
        .AdvantageListCard {
            &:hover {
                .image {
                    transform: none;
                }
            }

            .overlay {
                height: 31%;
            }

            .title {
                font-size: 1.6rem;
            }
        }
    }

    @include respond-to(xs) {
        .AdvantageListCard {
            .overlay {
                height: 27%;
            }
        }
    }
</style>
