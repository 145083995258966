// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapBuildRoute_JNCSr{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:40rem;padding:2.8rem;background-color:#fff}.MapBuildRoute_JNCSr,.MapBuildRoute_JNCSr .header_I5-XG{display:-webkit-box;display:-ms-flexbox;display:flex}.MapBuildRoute_JNCSr .header_I5-XG{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:4rem}.MapBuildRoute_JNCSr .title_a5rHI{font-size:2.4rem;font-weight:700;line-height:2.4rem;letter-spacing:-.08rem;margin-right:6.2rem;color:#141516}.MapBuildRoute_JNCSr .input_xcsI0{margin-bottom:2rem}.MapBuildRoute_JNCSr .name_VMq1k{font-size:1.4rem;font-weight:700;line-height:1;letter-spacing:normal;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:5.6rem;margin-bottom:2rem;padding:0 3.2rem;background-color:#f4f4f5;color:#2f2f2f}.MapBuildRoute_JNCSr .icon_1sxXD{width:2rem;height:2rem}.MapBuildRoute_JNCSr .btns_Yf\\+qQ{display:-ms-grid;display:grid;-ms-grid-columns:(1fr)[2];grid-template-columns:repeat(2,1fr);grid-gap:1.2rem;gap:1.2rem;margin-bottom:2rem}.MapBuildRoute_JNCSr .routeBtnText_z73fc{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start}[dir=ltr] .MapBuildRoute_JNCSr .routeBtnText_z73fc span{text-align:left}[dir=rtl] .MapBuildRoute_JNCSr .routeBtnText_z73fc span{text-align:right}.MapBuildRoute_JNCSr .routeBtnText_z73fc span{white-space:nowrap}.MapBuildRoute_JNCSr .gray_sRaCG{color:#bebec5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MapBuildRoute": "MapBuildRoute_JNCSr",
	"header": "header_I5-XG",
	"title": "title_a5rHI",
	"input": "input_xcsI0",
	"name": "name_VMq1k",
	"icon": "icon_1sxXD",
	"btns": "btns_Yf+qQ",
	"routeBtnText": "routeBtnText_z73fc",
	"gray": "gray_sRaCG"
};
module.exports = ___CSS_LOADER_EXPORT___;
