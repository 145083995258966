<template>
    <div :class="$style.FlatsBuildingDropdown">
        <UiScrollbox
            resizable
            skip-offset
            :class="$style.scrollbox"
            color="black"
        >
            <UiSelectAccordion
                name="building"
                :value="filters.building"
                :specs="specs.building"
                :facets="facets.building"
                reset-label="Любой"
                multiple
                :disabled="isReloading"
                @change="changeFilters"
                @mouseover="showInfo"
                @mouseleave="hideInfo"
            />
        </UiScrollbox>

        <div :class="[$style.info, {[$style._shown]: isInfoShown}]">
            <ImageLazy
                v-if="display"
                :preview="preview"
                :origin="display"
                :absolute="false"
                :class="$style.image"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import UiScrollbox from '~/components/ui/scrollbox/UiScrollbox.vue';
import UiSelectAccordion from '~/components/ui/select-accordion/UiSelectAccordion.vue';
import ImageLazy from '~/components/common/ImageLazy.vue';

export default {
    name: 'FlatsBuildingDropdown',

    components: {
        UiScrollbox,
        UiSelectAccordion,
        ImageLazy,
    },

    data() {
        return {
            isInfoShown: false,
            preview: null,
            display: null,
            timer: null,
        };
    },

    computed: {
        ...mapState('flats', [
            'filters',
            'specs',
            'facets',
            'isReloading',
        ]),
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),

        showInfo(value) {
            if (value && value.imageFlatFilterDisplay) {
                clearTimeout(this.timer);

                this.preview = value.imageFlatFilterPreview;
                this.display = value.imageFlatFilterDisplay;
                this.isInfoShown = true;
            }
        },

        hideInfo() {
            this.isInfoShown = false;

            this.timer = setTimeout(() => {
                this.preview = null;
                this.display = null;
            }, 300);
        },
    },
};
</script>

<style lang="scss" module>
    $height-items: 4.8rem;

    .FlatsBuildingDropdown {
        position: absolute;
        top: calc(100% + 1.2rem);
        left: -.6rem;
        z-index: 5;
        display: flex;
        flex-direction: column;
        width: 40rem;
        border: 1.5px solid $base-50;
        background-color: $base-0;
        box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
        transition: $transition;

        .scrollbox {
            max-height: calc($height-items * 4.5);
        }

        .info {
            position: absolute;
            top: 0;
            left: calc(100% + .8rem);
            visibility: hidden;
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            background-color: $base-0;
            opacity: 0;
            box-shadow: 1.2rem 1.2rem 3.4rem 0 rgba($base, .05);
            transition: $transition;

            &._shown {
                visibility: visible;
                opacity: 1;
            }
        }

        .image {
            width: 100%;
            height: 100%;
        }
    }
</style>
