// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsBuildingDropdown_AtG7R{position:absolute;top:calc(100% + 1.2rem);left:-.6rem;z-index:5;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:40rem;border:1.5px solid #f3f3f3;background-color:#fff;-webkit-box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);-webkit-transition:.3s ease;transition:.3s ease}.FlatsBuildingDropdown_AtG7R .scrollbox_seJG\\+{max-height:21.6rem}.FlatsBuildingDropdown_AtG7R .info_oYeWe{position:absolute;top:0;left:calc(100% + .8rem);visibility:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%;background-color:#fff;opacity:0;-webkit-box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);-webkit-transition:.3s ease;transition:.3s ease}.FlatsBuildingDropdown_AtG7R .info_oYeWe._shown_AA4HT{visibility:visible;opacity:1}.FlatsBuildingDropdown_AtG7R .image_i5Vz7{width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsBuildingDropdown": "FlatsBuildingDropdown_AtG7R",
	"scrollbox": "scrollbox_seJG+",
	"info": "info_oYeWe",
	"_shown": "_shown_AA4HT",
	"image": "image_i5Vz7"
};
module.exports = ___CSS_LOADER_EXPORT___;
