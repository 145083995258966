<template>
    <div :class="$style.FlatsSortFilter">
        <UiButton
            v-if="!$device.isDesktop"
            size="moderate"
            @click="$emit('open-modal')"
        >
            <template
                v-if="!$device.isMobile"
                #default
            >
                Сортировать
            </template>

            <template #iconAfter>
                <SortingIcon />
            </template>
        </UiButton>

        <UiFilterDropdown
            v-if="$device.isDesktop"
            :value="filters.orderBy"
            :specs="options"
            :facets="options.map(opt => opt.value)"
            :max-items="9"
            span-placeholder
            type="sorted"
            color="transparent"
            size="moderate"
            reset-label="По возрастанию цены"
            static-placeholder="Сортировать"
            @change="onChange"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { sortingData } from '~/assets/js/mock/filters';

import UiButton from '~/components/ui/buttons/UiButton.vue';
import UiFilterDropdown from '~/components/ui/dropdowns/filter-dropdown/UiFilterDropdown.vue';

import SortingIcon from '~/assets/icons/ui/sorting.svg?inline';

export default {
    name: 'FlatsSortFilter',

    components: {
        UiButton,
        UiFilterDropdown,
        SortingIcon,
    },

    data() {
        return {
            isOpened: false,
            options: sortingData,
        };
    },

    computed: {
        ...mapState('flats', [
            'filters',
        ]),
    },

    methods: {
        ...mapActions({
            changeFilters: 'flats/changeFilters',
        }),

        onChange(value) {
            this.changeFilters({ orderBy: value });
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsSortFilter {
        display: flex;
        flex-direction: column;
    }
</style>
