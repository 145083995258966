<template>
    <div :class="[$style.UiRangeInput, classList]">
        <div
            :style="inputsStyle"
            :class="$style.inputs"
        >
            <div :class="$style.wrap">
                <div
                    v-if="showLabel && !single"
                    :class="$style.label"
                >
                    {{ labels[0] }}
                </div>

                <UiRangeInputValue
                    ref="min"
                    :color="color"
                    :size="size"
                    :value="lazyValue[0]"
                    :style="getStylesMin"
                    :positive-only="positiveOnly"
                    :width="valueMinWidth"
                    :length="inputsLength"
                    :decimal-count="decimalCount"
                    :disabled="disabled"
                    @input="onInput($event, 'first')"
                    @change="onInputChange($event, 'first')"
                />

                <div
                    v-if="postfix"
                    :class="$style.postfix"
                    v-html="postfix"
                />
            </div>

            <div
                v-if="!single"
                :class="$style.wrap"
            >
                <div
                    v-if="showLabel"
                    :class="$style.label"
                >
                    {{ labels[1] }}
                </div>

                <UiRangeInputValue
                    ref="max"
                    :value="lazyValue[1]"
                    :color="color"
                    :size="size"
                    :style="getStylesMax"
                    :positive-only="positiveOnly"
                    :width="valueMaxWidth"
                    :length="inputsLength"
                    :decimal-count="decimalCount"
                    :disabled="disabled"
                    @input="onInput($event, 'second')"
                    @change="onInputChange($event, 'second')"
                />

                <div
                    v-if="postfix"
                    :class="$style.postfix"
                    v-html="postfix"
                />
            </div>
        </div>

        <UiRangeInputSlider
            v-if="single"
            v-model="lazyValue[0]"
            :color="color"
            :min="specs.min"
            :max="specs.max"
            :step="step"
            :disabled="disabled"
            :class="$style.slider"
            @change="emitChange"
        />

        <UiRangeInputSlider
            v-else
            v-model="lazyValue"
            :color="color"
            :min="specs.min"
            :max="specs.max"
            :step="step"
            range
            :disabled="disabled"
            :class="$style.slider"
            @change="emitChange"
        />

        <div
            v-if="postfix"
            ref="placeholder"
            :class="$style.placeholder"
        >
            <span
                ref="minPlaceholder"
                v-html="`${splitThousands(placeholderVals.first)}`"
            />

            <span
                v-if="!single"
                ref="maxPlaceholder"
                v-html="`${splitThousands(placeholderVals.second)}`"
            />
        </div>
    </div>
</template>

<script>
// Компонент ориентирован на ALIA-UI (KIT)
// Utils
import { splitThousands, getTextWidth } from '~/assets/js/utils';
// Components
import UiRangeInputValue from '~/components/ui/inputs/range-input/UiRangeInputValue.vue';
import UiRangeInputSlider from '~/components/ui/inputs/range-input/UiRangeInputSlider.vue';

/**
 * Позволяет пользователю ввести данные в рамках диапазона, например цены или площади.<br>
 * Включает в себя два компонента, UiRangeInputSlider и UiRangeInputValue (для разбиение цены на тычячные).
 *
 * У нас на проектах применяется принцип "фасетного фильтра", т.е.:<br><br>
 *
 * <strong>specs</strong> - диапазон всех доступных значений.<br>
 * <strong>facets</strong> - значения, которые доступны после передачи параметров из
 * <strong>value</strong>.<br><br>
 *
 * <a href="https://habr.com/ru/post/517074/" target="_blank">
 *     Подробннее про работу фасетных фильтров
 * </a>
 * @version 1.0.4
 * @displayName UiRangeInput
 */
export default {
    name: 'UiRangeInput',

    components: {
        UiRangeInputValue,
        UiRangeInputSlider,
    },

    props: {
        /**
         * Имя ключа для работы с формами или запросами
         */
        name: {
            type: [Array, String],
            required: true,
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'base',
            validator: val => ['base', 'transparent'].includes(val),
        },

        /**
         * Добавочное значение после инпута, например знак валюты
         */
        postfix: {
            type: String,
            default: '',
        },

        /**
         * Включает лейблы от/до, в рейндже
         */
        showLabel: {
            type: Boolean,
            default: false,
        },

        /**
         * Для переопределения значений лейблов
         */
        labels: {
            type: Array,
            default: () => ['от', 'до'],
        },

        /**
         * Диапазон всех доступных значений
         */
        specs: {
            type: Object,
            required: true,
            default: () => ({ min: 1, max: 100 }),
        },

        /**
         * Значения, которые доступны после передачи параметров в backend,
         * если существует определённый item в specs, но отсуствует в facets,
         * то по логике компонента, он перестаёт быть активным для выбора.
         */
        facets: {
            type: Object,
            default: () => ({}),
        },

        /**
         * Позволяет задать шаг слайдера вручную
         */
        step: {
            type: Number,
            default: 1,
        },

        /**
         * Текущее минимальное значение, при инициализации
         */
        valueMin: {
            type: String,
            default: '',
        },

        /**
         * Текущее максимальное значение, при инициализации
         */
        valueMax: {
            type: String,
            default: '',
        },

        /**
         * Отключает функционал диапазона, в этом режиме не имеет смысла использовать facets
         */
        single: {
            type: Boolean,
            default: false,
        },

        /**
         * При передаче false - позволяет выбирать отрицательные суммы
         */
        positiveOnly: {
            type: Boolean,
            default: true,
        },

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Сколько будет знаков после запятой
         */
        decimalCount: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            lazyValue: [this.valueMin ? this.valueMin : this.specs.min, this.valueMax ? this.valueMax : this.specs.max],
            oldValues: [this.valueMin ? this.valueMin : this.specs.min, this.valueMax ? this.valueMax : this.specs.max],
            placeholderWidth: { first: null, second: null },
            placeholderVals: { first: null, second: null },
            resizeObserver: null,
            valueMinWidth: 0,
            valueMaxWidth: 0,
        };
    },

    computed: {
        classList() {
            return [
                {
                    [this.$style[`_${this.color}`]]: this.color,
                    [this.$style[`_${this.size}`]]: this.size,
                    [this.$style._disabled]: this.disabled,
                },
            ];
        },

        inputsLength() {
            return {
                maxlength: splitThousands(this.specs.max).length + this.decimalCount + 1,
                minlength: splitThousands(this.specs.min).length + this.decimalCount + 1,
            };
        },

        inputsStyle() {
            if (!this.postfix || this.postfix && this.placeholderWidth.first) {
                return { visibility: 'visible', opacity: 1 };
            } else {
                return { visibility: 'hidden' };
            }
        },

        getStylesMin() {
            if (!this.postfix || !this.placeholderWidth.first) {
                return;
            }

            // return { width: `${this.placeholderWidth.first}px` };
            return { width: 'max-content' };
        },

        getStylesMax() {
            if (this.single || !this.postfix || !this.placeholderWidth.second) {
                return;
            }

            // return { width: `${this.placeholderWidth.second}px` };
            return { width: 'max-content' };
        },

        isValuesChange() {
            return `${this.valueMin}|${this.valueMax}`;
        },
    },

    watch: {
        facets() {
            if (!this.single && !this.valueMin && !this.valueMax) {
                this.applyfacets();
            }
        },

        specs() {
            if (!this.valueMin && !this.valueMax) {
                this.applySpecs();
            }
        },

        lazyValue: {
            deep: true,
            handler(val) {
                if (val[0]) {
                    this.valueMinWidth = this.calculateInputWidth(this.$refs.min.$el.querySelector('input'), val[0]);
                }

                if (val[1]) {
                    this.valueMaxWidth = this.calculateInputWidth(this.$refs.max.$el.querySelector('input'), val[1]);
                }

                if (!this.postfix) {
                    return;
                }

                if (this.single) {
                    this.placeholderVals.first = val[0];
                    return;
                }

                [this.placeholderVals.first, this.placeholderVals.second] = [...val];
            },
        },

        isValuesChange() {
            this.applyValues();
        },
    },

    created() {
        if (this.single) {
            this.lazyValue = [this.valueMin, ''];
            this.oldValues = [this.valueMin, ''];
            return;
        }

        if (this.facets?.min && this.facets?.max) {
            const min = this.valueMin ? this.valueMin : this.facets.min;
            const max = this.valueMax ? this.valueMax : this.facets.max;
            this.lazyValue = [min, max];
            this.oldValues = [min, max];
        }
    },

    mounted() {
        if (this.postfix) {
            this.resizeObserver = new ResizeObserver(this.getPlaceholderWidth);
            this.resizeObserver.observe(this.$refs.placeholder);

            this.$nextTick(() => {
                this.getPlaceholderWidth();
            });
        }
    },

    activated() {
        if (Array.isArray(this.lazyValue) && this.lazyValue.length === 2) {
            const [min, max] = this.lazyValue;

            if (min) {
                this.valueMinWidth = this.calculateInputWidth(this.$refs.min.$el.querySelector('input'), min);
            }

            if (max) {
                this.valueMaxWidth = this.calculateInputWidth(this.$refs.max.$el.querySelector('input'), max);
            }
        }
    },

    beforeDestroy() {
        if (this.postfix && this.resizeObserver) {
            this.resizeObserver.unobserve(this.$refs.placeholder);
            this.resizeObserver = null;
            this.placeholderWidth = { first: null, second: null };
        }
    },

    methods: {
        /**
         * Импорт из библиотеки функций, разбивает число на тысячные
         * @param {String} val Число для разбиения
         * @returns {String} Результат работы функции
         * @public
         */
        splitThousands,

        /**
         * Обновляет значение lazyValues компонента, при изменении передаваемых пропсов
         * @public
         */
        applyValues() {
            const min = this.valueMin ? this.valueMin : this.facets.min;
            const max = this.valueMax ? this.valueMax : this.facets.max;
            this.lazyValue = ['', ''];
            this.$nextTick(() => {
                this.lazyValue = [min, max];
                this.oldValues = [min, max];
            });
        },

        /**
         * Для корректной работы ResizeObserver, позволяет задать
         * размер плейсхолдера, для корректной работы postfix
         * @public
         */
        getPlaceholderWidth() {
            // Дополнительная проверка для минимальной ширины placeholeder т.к. postfix залезает на значение

            this.placeholderWidth.first = this.$refs.minPlaceholder?.offsetWidth;
            if (!this.single) {
                this.placeholderWidth.second = this.$refs.maxPlaceholder?.offsetWidth;
            }
        },

        /**
         * Вызывается из watch facets, чтобы изменить значение рейнджей и инпута,
         * после выполнения запроса в backend.
         * Только в случае, если не заданы valueMin и valueMax
         * @public
         */
        applyfacets() {
            if (this.facets.min && this.facets.max && (!this.valueMin && !this.valueMax)) {
                this.lazyValue = ['', ''];
                this.$nextTick(() => {
                    this.lazyValue = [this.facets.min, this.facets.max];
                    this.oldValues = [this.facets.min, this.facets.max];
                });
            } else {
                console.warn('Something wrong with range facets');
            }
        },

        /**
         * Вызывается из watch specks, чтобы изменить значение рейнджей и инпута,
         * после выполнения запроса в backend.
         * Только в случае, если не заданы valueMin и valueMax
         * @public
         */
        applySpecs() {
            this.lazyValue = [this.specs.min, this.specs.max];
            this.oldValues = [this.specs.min, this.specs.max];
        },

        /**
         * Вызывается после ручного введения данных, через инпут.
         * Позволяет задать размер плейсхолдера, для корректной работы postfix
         * @param {Number} val Значение
         * @param {String} handler Ключ объекта placeholderVals
         * @public
         */
        onInput(val, handler) {
            if (handler === 'first') {
                this.valueMinWidth = this.calculateInputWidth(this.$refs.min.$el.querySelector('input'), val);
            }

            if (handler === 'second') {
                this.valueMaxWidth = this.calculateInputWidth(this.$refs.max.$el.querySelector('input'), val);
            }

            if (this.placeholderVals[handler] !== val) {
                this.placeholderVals[handler] = val;
            }
        },

        /**
         * Вызывается после ручного введения данных, через инпут.
         * Сохраняет новые значения в lazyValue.
         * Необходимо для решения проблем реактивности.
         * @param {Number} val Значение
         * @param {String} handler Для определения min или max изменений
         * @public
         */
        onInputChange(val, handler) {
            if (handler === 'first') {
                if (val !== this.lazyValue[0]) {
                    val > this.lazyValue[1]
                        ? this.lazyValue = [this.lazyValue[1], val]
                        : this.lazyValue = [val, this.lazyValue[1]];

                    this.$nextTick(() => {
                        this.emitChange();
                    });
                }
            }

            if (handler === 'second') {
                if (val !== this.lazyValue[1]) {
                    val < this.lazyValue[0]
                        ? this.lazyValue = [val, this.lazyValue[0]]
                        : this.lazyValue = [this.lazyValue[0], val];

                    this.$nextTick(() => {
                        this.emitChange();
                    });
                }
            }
        },

        /**
         * Эмитит новые значения в родительский компонент,
         * для передачи в запрос фильтра
         * @returns {Object} values Объект с ключами из name
         * @public
         */
        emitChange() {
            let [minValue, maxValue] = this.lazyValue;

            if (!this.single) {
                if (minValue <= this.facets.min) {
                    minValue = this.facets.min;
                }

                if (maxValue >= this.facets.max) {
                    maxValue = this.facets.max;
                }
            }

            let name = [];

            if (this.single) {
                name = this.name;
            } else if (Array.isArray(this.name)) {
                name = [...this.name];
            } else {
                name = [`${this.name}Min`, `${this.name}Max`];
            }

            if (this.lazyValue !== [minValue, maxValue]) {
                this.lazyValue = [minValue, maxValue];
            }

            if (JSON.stringify(this.lazyValue) !== JSON.stringify(this.oldValues)) {
                this.oldValues = [minValue, maxValue];

                let emitData = '';

                if (this.single) {
                    emitData = {
                        [name]: minValue.toString(),
                    };
                } else {
                    emitData = {
                        [name[0]]: minValue.toString(),
                        [name[1]]: maxValue.toString(),
                    };
                }

                /**
                 * Отдаёт выбранные пользователем значения
                 * @event change
                 * @param {Object} emitData Объект с ключами из name
                 */
                this.$emit('change', emitData);
            }
        },

        /**
         * Вычиляет длину инпута, чтобы префиксы и постфиксы всегда были на одинаковом расстоянии от значения инпута
         * @returns {Number} длина строки в пикселях
         * @public
         */
        calculateInputWidth(input, value) {
            const { fontWeight, fontSize, fontFamily, letterSpacing } = getComputedStyle(input);
            const font = `${fontWeight} ${fontSize} ${fontFamily}`;
            if (this.decimalCount) {
                value = splitThousands(Number(value).toFixed(this.decimalCount), ' ', true);
            } else {
                value = splitThousands(value);
            }

            return getTextWidth(value, font, letterSpacing) + 1;
        },
    },
};
</script>

<style lang="scss" module>
    .UiRangeInput {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 2rem;

        .inputs {
            visibility: hidden;
            display: flex;
            justify-content: space-between;
            height: 100%;
            opacity: 0;
            transition: opacity $transition;
        }

        .wrap {
            display: flex;
            align-items: center;

            &:last-child {
                justify-content: right;
            }
        }

        .slider {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .postfix {
            white-space: nowrap;
        }

        .postfix,
        .label {
            transition: $transition;
            cursor: default;
            user-select: none;
        }

        .placeholder {
            position: absolute;
            z-index: -1;
            visibility: hidden;
            font-size: inherit;
            letter-spacing: initial;
            cursor: default;
        }

        /* Sizes */
        &._medium {
            height: 5.6rem;

            .label {
                @include l4;

                margin-right: .8rem;
                text-transform: uppercase;
            }

            .postfix {
                @include l4;

                margin-left: .8rem;
            }
        }

        /* Colors */
        &._base {
            background-color: $base-50;

            .wrap {
                &:hover {
                    .postfix,
                    .label {
                        color: $base-600;
                    }
                }
            }

            .postfix,
            .label {
                color: $base-400;
            }
        }

        // уникальный цвет для фильтра на странице проекта
        &._transparent {
            border: 1px solid $base-700;
            background-color: transparent;

            .wrap {
                width: 100%;

                &:last-child {
                    justify-content: initial;
                    padding-left: 2rem;
                    border-left: 1px solid $base-700;
                }
            }

            .label {
                color: $base-500;
            }

            .postfix {
                color: $base-0;
            }
        }

        /* Modificators */
        &._disabled {
            pointer-events: none;

            .wrap {
                &:hover {
                    .postfix,
                    .label {
                        color: $base-400;
                    }
                }
            }

            .postfix,
            .label {
                color: $base-400;
            }
        }
    }
</style>
