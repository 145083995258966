<template>
    <div
        :class="[$style.IconFilter, {[$style._active]:active}]"
        :style="style"
    >
        <component
            :is="headerComponent"
            v-if="$scopedSlots?.headerLabel || $scopedSlots?.headerIcon"
            v-bind="headerAttributes"
            :class="$style.header"
            @mouseenter="headerHover = true"
            @mouseleave="headerHover = false"
        >
            <div :class="$style.headerIcon">
                <slot name="headerIcon" :hover="headerHover"/>
            </div>
            <transition
                :enter-active-class="$style.labelEnterActive"
                :leave-active-class="$style.labelLeaveActive"
                :enter-class="$style.labelEnter"
                :leave-to-class="$style.labelLeaveTo"
            >
                <div
                    v-if="active && !inProgress"
                    :class="$style.headerLabel"
                >
                    <slot name="headerLabel" :hover="headerHover"/>
                </div>
            </transition>
        </component>
        <div :class="$style.body">
            <div
                v-for="item in items"
                :key="item[keyValue]"
                :class="[$style.link, {[$style._active]:internalValue === item[keyValue]}]"
                @click="handlerSelectItem(item)"
            >
                <div :class="$style.iconContainer">
                    <div
                        :style="{ backgroundImage: `url('${item[keyIcon]}')`}"
                        :class="$style.icon"
                    />
                </div>
                <transition
                    :enter-active-class="$style.labelEnterActive"
                    :leave-active-class="$style.labelLeaveActive"
                    :enter-class="$style.labelEnter"
                    :leave-to-class="$style.labelLeaveTo"
                >
                    <div
                        v-if="active && !inProgress"
                        :class="$style.label"
                        v-html="item[keyLabel]"
                    >
                    </div>
                </transition>
            </div>
        </div>
        <div :class="$style.footer">
            <UiCircleButton
                :class="$style.toggleBtn"
                @click="active = !active"
            >
                <LeftIcon />
            </UiCircleButton>

            <transition
                :enter-active-class="$style.labelEnterActive"
                :leave-active-class="$style.labelLeaveActive"
                :enter-class="$style.labelEnter"
                :leave-to-class="$style.labelLeaveTo"
            >
                <div
                    v-if="active && !inProgress"
                    :class="$style.footerLabel"
                    v-html="footerLabel"
                >
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import UiCircleButton from '~/components/ui/buttons/UiCircleButton.vue';
import LeftIcon from 'assets/icons/arrow-left-solid.svg?inline';
import { convertToUnit } from '~/components/ui/utils/helpers';

export default {
    name: 'IconFilter',
    components: { UiCircleButton, LeftIcon },
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: () => null,
        },

        items: {
            type: Array,
            default: () => [],
        },

        keyLabel: {
            type: String,
            default: 'label',
        },

        keyValue: {
            type: String,
            default: 'value',
        },

        keyIcon: {
            type: String,
            default: 'icon',
        },

        footerLabel: {
            type: String,
            default: '',
        },

        headerLink: {
            type: String,
            default: '',
        },

        blank: {
            type: Boolean,
            default: false,
        },

        width: {
            type: [Number, String],
            default: '26rem',
        },
    },

    data() {
        return {
            active: true,
            inProgress: false,
            internalValue: this.value,
            headerHover: false,
        };
    },

    computed: {
        style() {
            return {
                maxWidth: convertToUnit(this.width, 'rem'),
            };
        },

        headerAttributes() {
            return {
                to: this.blank ? null : this.headerLink,
                href: this.blank ? this.headerLink : null,
                target: this.blank ? '_blank' : null,
                rel: this.blank ? 'noreferrer noopener' : null,
            };
        },

        headerComponent() {
            return this.headerLink ? this.blank ? 'a' : 'nuxt-link' : 'div';
        },
    },

    watch: {
        active(newVal) {
            this.inProgress = true;

            setTimeout(() => {
                this.inProgress = false;
            }, 200);
        },

        value(newVal) {
            this.internalValue = newVal;
        },

        internalValue(newVal) {
            this.$emit('input', newVal);
        },
    },

    methods: {
        handlerSelectItem(item) {
            this.internalValue = item[this.keyValue];
        },
    },
};
</script>

<style lang="scss" module>
    .IconFilter {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        width: 6.4rem;
        background-color: #fff;
        transition: width .4s ease .1s;
        flex-direction: column;

        &._active {
            width: 100%;
            transition: width .4s ease;

            .toggleBtn {
                transform: rotate(0deg);
            }
        }
    }

    .body {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        flex-grow: 1;
        padding: 1.2rem;
        scrollbar-width: thin;
        scrollbar-color: rgba($base-600, .5) transparent;

        &::-webkit-scrollbar {
            width: .8rem;
        }

        &::-webkit-scrollbar-track {
            border-radius: .8rem;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: .8rem;
            border: .2rem solid transparent;
            background-clip: content-box;
            background-color: rgba($base-600, .5);

            &:hover {
                background-color: rgba($base-600, .7);
            }
        }
    }

    .footer {
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        padding: 1.2rem;
    }

    .footerLabel {
        margin-left: 2rem;
        font-size: 1.2rem;
        line-height: 1.24;
        color: $base-400;
    }

    .link {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        &:not(:first-child) {
            margin-top: 1.2rem;
        }

        &._active {
            cursor: default;
        }

        &._active,
        &:hover {
            .iconContainer {
                background-color: $base-900;
                color: $base-0;
            }

            .icon {
                filter: none;
            }

            .label {
                color: $base-900;
            }
        }
    }

    .iconContainer {
        position: relative;
        flex-shrink: 0;
        width: 4rem;
        height: 4rem;
        padding: 1rem;
        border-radius: 50%;
        background-color: $base-50;
        color: $base-900;
        transition: background-color .2s;
    }

    .icon {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        filter: invert(1);
    }

    .label {
        margin-left: 2rem;
        font-size: 1.6rem;
        line-height: 1.24;
        color: $base-500;
        transition: color .2s;
    }

    .toggleBtn {
        transform: rotate(180deg);
        transition: transform .4s ease;
    }

    .labelLeaveActive,
    .labelEnterActive {
        transition: opacity .2s;
    }

    .labelEnter,
    .labelLeaveTo {
        opacity: 0;
    }

    .header {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        padding: 1.2rem 1.2rem 2rem 1.2rem;

        &:hover {
            @media (hover: hover) {
                .headerLabel {
                    color: $base-900;
                }
            }
        }
    }

    .headerIcon {
        width: 4rem;
        height: 4rem;
    }

    .headerLabel {
        margin-left: 2rem;
        font-weight: 700;
        color: $base-600;
    }
</style>
