// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsAdvancedFilter_r8vqb{position:absolute;top:100%;left:0;z-index:-2;display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:70rem;max-height:calc(100vh - var(--header-height));background-color:#fff;-webkit-transform:translateY(-100%);transform:translateY(-100%);-webkit-transition:.8s ease;transition:.8s ease;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.FlatsAdvancedFilter_r8vqb._opened_ahHNV{-webkit-transform:translateY(0);transform:translateY(0)}.FlatsAdvancedFilter_r8vqb .header_czh4I{width:100%;padding-top:4.8rem;padding-bottom:3.2rem}.FlatsAdvancedFilter_r8vqb .scrollbox_uZu6S{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;max-height:100%}@media(max-width:991.98px){.FlatsAdvancedFilter_r8vqb .scrollbox_uZu6S{-webkit-box-flex:unset;-ms-flex-positive:unset;flex-grow:unset}}.FlatsAdvancedFilter_r8vqb .adaptiveWrapper_lW67D{display:-webkit-box;display:-ms-flexbox;display:flex;height:100%;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.FlatsAdvancedFilter_r8vqb .body_w-aif{width:100%;height:100%}.FlatsAdvancedFilter_r8vqb .footer_\\+XmiH{width:100%;padding-top:3.2rem;padding-bottom:4.8rem}@media(max-width:1279.98px){.FlatsAdvancedFilter_r8vqb{position:fixed;top:-100%;z-index:21;height:100vh;height:calc(var(--vh, 1vh)*100);max-height:none}.FlatsAdvancedFilter_r8vqb._opened_ahHNV{top:0}.FlatsAdvancedFilter_r8vqb .header_czh4I{padding-top:2.4rem;padding-bottom:2.4rem}.FlatsAdvancedFilter_r8vqb .footer_\\+XmiH{padding-top:1.6rem;padding-bottom:1.6rem}}@media(max-width:767.98px){.FlatsAdvancedFilter_r8vqb .header_czh4I{padding-bottom:2rem}.FlatsAdvancedFilter_r8vqb .footer_\\+XmiH{padding-top:1.2rem;padding-bottom:1.2rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsAdvancedFilter": "FlatsAdvancedFilter_r8vqb",
	"_opened": "_opened_ahHNV",
	"header": "header_czh4I",
	"scrollbox": "scrollbox_uZu6S",
	"adaptiveWrapper": "adaptiveWrapper_lW67D",
	"body": "body_w-aif",
	"footer": "footer_+XmiH"
};
module.exports = ___CSS_LOADER_EXPORT___;
