<template>
    <div
        :class="$style.MiniFilters"
        class="container-home"
    >
        <h3 :class="$style.title">
            {{ MINI_FILTERS_DATA.title }}
        </h3>

        <transition name="fade">
            <FlatsFilter
                :class="[$style.filter, $style._desktop]"
                is-have-redirect-button
            />
        </transition>

        <transition name="fade">
            <FlatsListHeader
                :class="[$style.filter, $style._mobile]"
                :is-have-sort="false"
                :is-have-view-switcher="false"
            />
        </transition>
    </div>
</template>

<script>

import FlatsFilter from '~/components/flats/filters/FlatsFilter';
import FlatsListHeader from '~/components/flats/list/FlatsListHeader';

const MINI_FILTERS_DATA = {
    title: 'Выбрать квартиру',
};

export default {
    name: 'MiniFilters',

    components: {
        FlatsListHeader,
        FlatsFilter,
    },

    data() {
        return {
            MINI_FILTERS_DATA,
        };
    },
};
</script>

<style lang="scss" module>
    .MiniFilters {
        min-height: 12.4rem;
        margin-bottom: 10rem;

        @include respond-to(lg) {
            min-height: 19.2rem;
        }

        @include respond-to(sm) {
            min-height: 11.6rem;
        }

        .title {
            @include h3;

            margin-bottom: 3.2rem;
            text-transform: uppercase;

            @include respond-to(xxs) {
                @include h4;
            }

            @include respond-to(xxs) {
                @include h5;

                margin-bottom: 2rem;
            }
        }

        .filter {
            //
        }

        ._desktop {
            display: flex;

            @include respond-to(xs) {
                display: none;
            }
        }

        ._mobile {
            display: none;

            @include respond-to(xs) {
                display: flex;
            }
        }
    }
</style>
