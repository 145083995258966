<template>
    <div :class="$style.HomeArchitecture">
        <div
            v-show="!($device.isDesktop && !isIpadPro) && !isRostech"
            :class="$style.btns"
        >
            <UiButton
                color="primary"
                size="big"
                @click="openMap"
            >
                <template slot="iconBefore">
                    <LocationIcon />
                </template>

                <template slot="default">
                    На карте
                </template>
            </UiButton>

            <UiButton
                size="big"
                @click="openPanorama"
            >
                <template slot="iconBefore">
                    <CubeIcon />
                </template>

                <template slot="default">
                    Панорама
                </template>
            </UiButton>
        </div>

        <div
            ref="HomeGenplanContainer"
            :class="$style.container"
        >
            <!-- Переключение между генпланом и картой -->
            <UiTabs
                v-show="$device.isDesktop && !isRostech"
                v-model="activeTab"
                :tabs="tabs"
                dark
                tab-width="109px"
                :class="$style.tabs"
            />

            <UiButton
                v-show="$device.isDesktop && !isIpadPro && activeTab === 'genplan'"
                size="big"
                color="yellow"
                :class="$style.panoramaBtn"
                @click="openPanorama"
            >
                <template slot="iconBefore">
                    <CubeIcon />
                </template>

                <template slot="default">
                    Панорама
                </template>
            </UiButton>

            <HomeGenplan
                ref="genplan"
                :class="[$style.section, {[$style._active]: activeTab === 'genplan'}]"
                @loaded="onLoad"
            />

            <transition
                name="fade"
                mode="out-in"
            >
                <InfrastructureMap v-if="activeTab === 'environment'" :class="$style.map" />
            </transition>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.min.js';
gsap.registerPlugin(ScrollTrigger);
import { mapGetters } from 'vuex';

import CubeIcon from '~/assets/icons/home/cube.svg?inline';
import LocationIcon from '~/assets/icons/home/location-pin.svg?inline';
import UiTabs from '~/components/ui/home/UiTabs.vue';
import InfrastructureMap from '~/components/common/maps/InfrastructureMap.vue';
import HomeGenplan from '~/components/home/genplan/HomeGenplan.vue';
import UiButton from '~/components/ui/home/UiButton';

export default {
    name: 'HomeArchitecture',

    components: {
        HomeGenplan,
        InfrastructureMap,
        UiTabs,
        UiButton,
        CubeIcon,
        LocationIcon,
    },

    props: {
        isHome: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            tabs: [{
                value: 'genplan',
                label: 'Генплан',
            }, {
                value: 'environment',
                label: 'Окружение',
            }],

            activeTab: 'genplan',
            showAnimation: null,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        isRostech() {
            return this.$route.path.includes('rosteh');
        },
    },

    beforeDestroy() {
        if (this.showAnimation) {
            this.showAnimation.kill();
            this.showAnimation = null;
        }
    },

    methods: {
        showAnimate() {
            const TheWidgetButtons = document.querySelector('.TheWidgetButtons');

            if (!TheWidgetButtons) {
                return false;
            }
            TheWidgetButtons.style.opacity = 1;
            this.showAnimation = gsap.timeline({
                defaults: {
                    ease: 'none',
                },

                scrollTrigger: {
                    trigger: this.$refs.genplan.$el,
                    start: 'top bottom',
                    refreshPriority: -2,
                    end: `${this.$refs.genplan.$el.clientHeight + 90} bottom`,
                    onEnter: () => {
                        TheWidgetButtons.style.opacity = 0;
                    },
                    onLeave: () => {
                        TheWidgetButtons.style.opacity = 1;
                    },
                    onEnterBack: () => {
                        TheWidgetButtons.style.opacity = 0;
                    },
                    onLeaveBack: () => {
                        TheWidgetButtons.style.opacity = 1;
                    },
                },
            });
        },

        onLoad() {
            if (this.$refs.genplan) {
                this.showAnimate();
            }
            this.$emit('loaded');
        },

        openPanorama() {
            this.$modal.open('VideoModal', {
                src: ['https://tour.virtualland.ru/svl/alia/'],
                aeroModal: true,
                lazy: false,
                withoutPadding: true,
            });
        },

        openMap() {
            this.$fullScreenModal.open({
                component: InfrastructureMap,
                componentOptions: {
                    isModal: true,
                    onCloseModal: () => {
                        this.$fullScreenModal.close();
                    },
                },
            });
        },
    },
};
</script>

<style lang="scss" module>
    .HomeArchitecture {
        width: 100%;
        height: fit-content;

        .btns {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.2rem;
            width: 100%;
            padding: 2.4rem 2.4rem 1.6rem;
        }

        .container {
            position: relative;
        }

        .tabs {
            position: absolute;
            top: 3.2rem;
            right: $homeDesktopPadding;
            z-index: 5;

            & > button {
                height: 4.4rem;
            }
        }

        .panoramaBtn {
            position: absolute;
            bottom: 3.2rem;
            left: $homeDesktopPadding;
            z-index: 5;
        }

        .section {
            opacity: 0;
            transition: opacity .3s ease;

            &._active {
                opacity: 1;
            }
        }

        .map {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }
</style>
