// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsUrbanBlockDropdown_ZQF6p{position:absolute;top:calc(100% + 1.2rem);left:0;z-index:5;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:40rem;border:1.5px solid #f3f3f3;background-color:#fff;-webkit-box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);-webkit-transition:.3s ease;transition:.3s ease}.FlatsUrbanBlockDropdown_ZQF6p .scrollbox_6B\\+15{max-height:21.6rem}.FlatsUrbanBlockDropdown_ZQF6p .info_GAB2M{position:absolute;top:0;left:calc(100% + .8rem);visibility:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%;min-height:20rem;background-color:#fff;opacity:0;-webkit-box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);box-shadow:1.2rem 1.2rem 3.4rem 0 rgba(0,0,0,.05);-webkit-transition:.3s ease;transition:.3s ease}.FlatsUrbanBlockDropdown_ZQF6p .info_GAB2M._shown_c-ZjM{visibility:visible;opacity:1}.FlatsUrbanBlockDropdown_ZQF6p .image_Hezgw{width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsUrbanBlockDropdown": "FlatsUrbanBlockDropdown_ZQF6p",
	"scrollbox": "scrollbox_6B+15",
	"info": "info_GAB2M",
	"_shown": "_shown_c-ZjM",
	"image": "image_Hezgw"
};
module.exports = ___CSS_LOADER_EXPORT___;
