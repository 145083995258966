<template>
    <div :class="cardClasses" @click="$emit('click')">
        <ImageLazy
            :class="$style.image"
            :preview="image.preview"
            :origin="image.origin"
        />

        <div v-if="visibleLabels && visibleLabels.length" :class="$style.labels">
            <UiLabel
                v-for="label in visibleLabels"
                :key="label.id"
                :color="label.color"
                :class="$style.label"
            >
                {{ label.text }}
            </UiLabel>
        </div>

        <div v-if="isVideo" :class="$style.videoContent">
            <h3 :class="$style.title">
                {{ card?.video?.title }}
            </h3>
            <button :class="$style.playButton">
                <IconPlay />
            </button>
            <p :class="$style.description">
                {{ card?.video?.subtitle }}
            </p>
        </div>

        <UiLabel
            v-if="isVideo && card.video?.duration"
            :class="$style.videoDuration"
            color="default-transparent"
            icon-before
        >
            <template #iconBefore>
                <IconClock/>
            </template>
            {{ card.video?.duration }}
        </UiLabel>

        <h3 v-if="!isVideo" :class="$style._cardTitle">
            {{ cardTitle }}
        </h3>
    </div>
</template>

<script>
import ImageLazy from '~/components/common/ImageLazy.vue';
import UiLabel from '~/components/ui/home/UiLabel.vue';
import IconPlay from '~/assets/icons/home/play.svg?inline';
import IconClock from '~/assets/icons/home/clock.svg?inline';

const MAX_LABELS_SHORT = 2;
const MAX_LABELS_LONG = 3;

export default {
    name: 'HomeAdvantageGalleryCardNew',
    components: { IconPlay, IconClock, UiLabel, ImageLazy },

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },

        isVideo: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        cardClasses() {
            return {
                [this.$style.HomeAdvantageGalleryCardNew]: true,
                [this.$style._video]: this.isVideo,
            };
        },

        image() {
            return {
                preview: this.card?.image?.preview || '',
                origin: this.card?.image?.origin || '',
            };
        },

        cardTitle() {
            return this.card?.title ? this.card?.title.toUpperCase() : '';
        },

        isFirstTwoLabelsTooLong() {
            const labels = this.card?.labels;

            if (!labels || labels.length < 2) {
                return false;
            }

            const firstLabelLength = labels[0]?.text?.length || 0;
            const secondLabelLength = labels[1]?.text?.length || 0;

            return firstLabelLength + secondLabelLength > 40;
        },

        visibleLabels() {
            const labels = this.card?.labels || [];

            if (labels.length === 0) {
                return [];
            }

            if (labels.length >= MAX_LABELS_SHORT && this.isFirstTwoLabelsTooLong) {
                return labels.slice(0, MAX_LABELS_SHORT);
            }

            if (labels.length >= MAX_LABELS_LONG) {
                return labels.slice(0, MAX_LABELS_LONG);
            }

            return labels;
        },
    },
};
</script>

<style lang="scss" module>
    .HomeAdvantageGalleryCardNew {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &._video:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background-color: $body-color;
            opacity: .56;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            background: $blackout-gradient;
            opacity: .5;
        }

        @include hover {
            &:hover {
                .image {
                    transform: scale(1.05);
                }
            }
        }

        .image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            transition: transform $transition;
        }

        .label {
            position: absolute;
            top: 3.2rem;
            left: 3.2rem;
            z-index: 3;

            @include respond-to(sm) {
                top: 2.4rem;
                left: 2.4rem;
            }

            @include respond-to(xs) {
                top: 2rem;
                left: 2rem;
            }
        }

        .videoDuration {
            position: absolute;
            right: 3.2rem;
            bottom: 3.2rem;
            z-index: 3;
            width: 7.6rem;
            height: 3.2rem;
            padding: 1.2rem;

            @include respond-to(sm) {
                bottom: 2.4rem;
                left: 2.4rem;
            }

            @include respond-to(xs) {
                bottom: 2rem;
                left: 2rem;
            }
        }

        .clockIcon {
            width: 1.2rem;
            height: 1.2rem;
        }

        .videoContent {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 3;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            transform: translate(-50%, -50%);

            @include respond-to(xs) {
                padding: 0 2rem;
            }

            .playButton {
                width: 5.6rem;
                height: 5.6rem;
                margin: 5.6rem 0;
                border-radius: 50%;
                border: none;
                background-color: $base-inverse;
                cursor: pointer;

                @include respond-to(xs) {
                    margin: 3.2rem 0;
                }

                svg {
                    width: 2rem;
                    height: 2rem;
                    margin-left: .3rem;
                }
            }
        }

        ._cardTitle {
            position: absolute;
            bottom: 3.2rem;
            left: 3.2rem;
            z-index: 3;
            font-size: 4rem;
            line-height: 4.8rem;
            color: $base-0;

            @include respond-to(md) {
                bottom: 2.4rem;
                left: 2.4rem;
                font-size: 3.2rem;
            }

            @include respond-to(xs) {
                bottom: 2rem;
                left: 2rem;
                font-size: 2.4rem;
            }
        }

        .title {
            @include h2;

            @include respond-to(sm) {
                @include old-h2;
            }

            text-transform: uppercase;
        }

        .description {
            @include p2;

            width: 29.4rem;
            font-size: 2rem;
        }

        .title,
        .description {
            text-align: center;
            color: $base-inverse;
        }
    }
</style>
