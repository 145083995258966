<template>
    <div
        ref="ProjectGenplanAdvantages"
        :class="$style.ProjectGenplanAdvantages"
    >
        <UiTag
            v-for="(item, i) in advantages.show"
            :key="i"
            :color="item?.color"
            :class="$style.tag"
        >
            {{ item.label }}
        </UiTag>

        <UiTag
            v-if="advantages.extra.length"
            ref="more"
            v-click-outside="hideExtraAdvantages"
            :class="[$style.tag, $style._extra]"
            @mouseenter="showExtraAdvantages"
            @mouseleave="hideExtraAdvantages"
            @click.native.stop.prevent="switchExtraAdvantagesVisibility"
        >
            +{{ advantages.extra.length }}

            <div
                v-show="isExtraAdvantagesVisible"
                :class="[$style.popup, {[$style._shown]: isExtraAdvantagesVisible}]"
            >
                <UiTag
                    v-for="(item, i) in advantages.extra"
                    :key="i"
                    :class="$style.tag"
                >
                    {{ item.label }}
                </UiTag>
            </div>
        </UiTag>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import UiTag from '~/components/ui/tags/UiTag.vue';
import { mapGetters } from 'vuex';
import { getTextWidth } from '~/assets/js/utils';

export default {
    name: 'ProjectGenplanAdvantages',
    components: { UiTag },

    directives: {
        ClickOutside,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            isExtraAdvantagesVisible: false,
            tagCount: 0,
        };
    },

    computed: {
        ...mapGetters({
            isIpadPro: 'device/getIsIpadPro',
        }),

        advantages() {
            return {
                show: this.items.slice(0, this.tagCount),
                extra: this.items.slice(this.tagCount),
            };
        },
    },

    mounted() {
        if (this.items.length) {
            this.tagCount = this.getTagCount(this.items);
        }
    },

    methods: {
        switchExtraAdvantagesVisibility() {
            this.isExtraAdvantagesVisible = !this.isExtraAdvantagesVisible;
        },

        hideExtraAdvantages() {
            if (this.$device.isDesktop && !this.isIpadPro) {
                this.isExtraAdvantagesVisible = false;
            }
        },

        showExtraAdvantages() {
            if (this.$device.isDesktop && !this.isIpadPro) {
                this.isExtraAdvantagesVisible = true;
            }
        },

        getTagCount(tags) { // функция ищет, сколько тегов влезет в контейнер
            const { fontWeight, fontSize, fontFamily, letterSpacing } = getComputedStyle(this.$refs.more.$el);
            const font = `${fontWeight} ${fontSize} ${fontFamily}`;
            let tagCount = tags.length; // общее количество тегов
            const containerWidth = this.$refs.ProjectGenplanAdvantages.clientWidth - this.$refs.more.$el.clientWidth - 10; // доступная ширина
            const tagPadding = 12 * 2; // паддинги у UiTag
            const tagGap = 6; // отступ между тегами
            let stringValues = ''; // сумма текстов тегов

            for (let i = 0; i < tags.length; i++) {
                stringValues += tags[i].text; // прибавляем текст следующего тега
                const sumPaddings = tagPadding * (i + 1); // сумма паддингов тегов
                const sumGaps = tagGap * (i + 1); // сумма отступов между тегами
                const filledSpace = sumPaddings + sumGaps + getTextWidth(stringValues, font, letterSpacing); // ширина тегов

                if (containerWidth < filledSpace) { // если ширина контейнера больше ширины текущий тегов
                    tagCount = i;
                    break;
                }
            }
            return tagCount;
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanAdvantages {
        position: relative;
        display: flex;
        align-items: center;
        gap: .6rem;
        width: 100%;

        .tag {
            position: relative;
            width: fit-content;
            pointer-events: none;

            &._extra {
                pointer-events: all;
            }
        }

        .popup {
            position: absolute;
            bottom: calc(100% + .85rem);
            left: 100%;
            visibility: hidden;
            display: flex;
            flex-wrap: wrap;
            gap: .6rem;
            width: max-content;
            max-width: 37rem;
            padding: 1.6rem;
            box-shadow: 1.2rem 1.2rem 3.4rem rgba($base, .05);
            background-color: $base-0;
            opacity: 0;
            transform: translateX(-50%);
            transition: opacity $transition;

            &._shown {
                visibility: visible;
                opacity: 1;
            }
        }
    }
</style>
