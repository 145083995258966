<template>
    <div :class="[$style.ProjectGenplanUrbanNavigation, {[$style._disabled]: disabled}]">
        <div :class="$style.left">
            <div
                v-if="text"
                :class="$style.text"
                v-html="text"
            />
        </div>

        <div v-if="link" :class="$style.right">
            <ProjectGenplanFilterBtn :link="link" />
        </div>
    </div>
</template>

<script>
import ProjectGenplanFilterBtn from '~/components/pages/project/architecture/attachments/ProjectGenplanFilterBtn.vue';

export default {
    name: 'ProjectGenplanUrbanNavigation',

    components: {
        ProjectGenplanFilterBtn,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        link: {
            type: [String, Function],
            default: '/flats/',
        },

        text: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanUrbanNavigation {
        position: relative;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &:not(._disabled) > * {
            pointer-events: all;
        }

        .left {
            position: absolute;
            top: 3.2rem;
            left: $homeDesktopPadding;

            @include respond-to(sm) {
                display: none;
            }
        }

        .right {
            position: absolute;
            top: 3.2rem;
            right: $homeDesktopPadding;

            @include respond-to(sm) {
                top: $homeTabletPadding;
                right: $homeTabletPadding;
            }

            @include respond-to(xxs) {
                top: initial;
                right: initial;
                bottom: 2rem;
                left: 2rem;
                width: calc(100% - 4rem);
            }
        }

        .text {
            max-width: 20.1rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.24;
            color: $base-0;
        }
    }
</style>
