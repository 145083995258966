// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectSectionTitle_20vYa{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:3.2rem;gap:3.2rem;max-width:72.8rem;margin-right:auto;margin-left:auto}@media(max-width:991.98px){.ProjectSectionTitle_20vYa{grid-gap:2.4rem;gap:2.4rem;max-width:none}}@media(max-width:519.98px){.ProjectSectionTitle_20vYa{grid-gap:2rem;gap:2rem}}.ProjectSectionTitle_20vYa._smallDescription_X23sb .description_3iHwy{font-size:1.6rem}@media(max-width:991.98px){.ProjectSectionTitle_20vYa._smallDescription_X23sb .description_3iHwy{font-size:1.4rem}}.title_k-UTs{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:.4rem;gap:.4rem}.line_y0MJx{text-transform:uppercase;font-size:4rem;font-weight:700;line-height:4.8rem;letter-spacing:normal;color:#2f2f2f;text-align:center;color:#cfcfcf}.line_y0MJx._dark_NurXO{color:#2f2f2f}@media(max-width:991.98px){.line_y0MJx{font-size:3.2rem;line-height:4rem;letter-spacing:-.04em;opacity:.9}}@media(max-width:519.98px){.line_y0MJx{text-transform:uppercase;font-size:2.4rem;font-weight:700;line-height:3.2rem;letter-spacing:normal;color:#2f2f2f;color:#cfcfcf}}@media(max-width:519.98px)and (max-width:991.98px){.line_y0MJx{font-size:2rem;line-height:2.8rem}}@media(max-width:519.98px)and (max-width:767.98px){.line_y0MJx{font-size:1.6rem;line-height:2.1rem}}.description_3iHwy{text-align:center;font-size:2rem;font-weight:300;line-height:124%;color:#717171}@media(max-width:519.98px){.description_3iHwy{font-size:1.8rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectSectionTitle": "ProjectSectionTitle_20vYa",
	"_smallDescription": "_smallDescription_X23sb",
	"description": "description_3iHwy",
	"title": "title_k-UTs",
	"line": "line_y0MJx",
	"_dark": "_dark_NurXO"
};
module.exports = ___CSS_LOADER_EXPORT___;
