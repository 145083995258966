<template>
    <div :class="$style.FlatsAdvancedFilterFooter">
        <UiButton
            :size="'big'"
            color="black"
            :class="$style.button"
            @click="closeAndRedirect"
        >
            {{ FILTER_TEXT.SHOW }} {{ countLabel }}
        </UiButton>

        <FlatsResetButton :class="$style.reset" />

        <UiButton
            v-if="$device.isTablet && isResetBtnShown"
            :size="!$device.isMobile ? 'big' : 'normal'"
            :class="$style.tabletReset"
            @click="resetFilters"
        >
            {{ FILTER_TEXT.RESET }}
        </UiButton>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { DEFAULT_FILTERS } from '~/assets/js/constants';

import UiButton from '~/components/ui/buttons/UiButton.vue';
import FlatsResetButton from '~/components/flats/common/FlatsResetButton.vue';
import { plural } from '~/assets/js/utils';
import { objectToQuery } from '~/assets/js/utils/queryUtils';

const FILTER_TEXT = {
    RESET: 'Сбросить параметры',
    SHOW: 'Показать',
};

const FLAT_TYPES = {
    210: ['ситихаус', 'ситихауса', 'ситихаусов'],
    211: ['пентхаус', 'пентхауса', 'пентхаусов'],
    212: ['сити-юнит', 'сити-юнита', 'сити-юнитов'],
    280: ['таунхаус', 'таунхауса', 'таунхаусов'],
};

export default {
    name: 'FlatsAdvancedFilterFooter',

    components: {
        UiButton,
        FlatsResetButton,
    },

    data() {
        return {
            FILTER_TEXT,
        };
    },

    computed: {
        ...mapGetters({
            totalCount: 'flats/totalCount',
        }),

        ...mapState('flats', [
            'filters',
        ]),

        isResetBtnShown() {
            return JSON.stringify(this.filters) !== JSON.stringify(DEFAULT_FILTERS);
        },

        newTypeFlat() {
            return this.filters.newTypeFlat || [];
        },

        countLabel() {
            const count = this.totalCount;

            if (this.newTypeFlat.length === 1) {
                const type = this.newTypeFlat[0];
                const pluralForms = FLAT_TYPES[type] || ['квартиру', 'квартиры', 'квартир'];

                return `${count} ${plural(count, pluralForms)}`;
            }

            return `${count} ${plural(count, ['квартиру', 'квартиры', 'квартир'])}`;
        },
    },

    methods: {
        ...mapActions({
            resetFilters: 'flats/resetFilters',
        }),

        closeAndRedirect() {
            const query = objectToQuery(this.filters);
            this.$router.push(query ? `/flats/?${query}` : '/flats/');

            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" module>
    .FlatsAdvancedFilterFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .button {
            width: fit-content;
        }

        .tabletReset {
            display: flex;
            width: fit-content;
            margin-left: 1.2rem;
            white-space: nowrap;
        }

        @include respond-to(md) {
            .button {
                width: 100%;
            }

            .reset {
                display: none;
            }
        }
    }
</style>
