<template>
    <div :class="$style.ProjectGenplanFilterBtn">
        <UiButton
            :link="typeof link === 'function' ? null : link"
            color="black"
            size="big"
            :blank="typeof link!=='function'"
            :class="$style.btn"
            @click="handleClick"
        >
            <template #iconBefore>
                <FilterIcon />
            </template>

            Выбрать квартиру
        </UiButton>

        <div :class="$style.tooltip">
            Подбор по параметрам
        </div>
    </div>
</template>

<script>
import UiButton from '~/components/ui/buttons/UiButton.vue';

import FilterIcon from '~/assets/icons/home/filter.svg?inline';

export default {
    name: 'ProjectGenplanFilterBtn',

    components: {
        UiButton,
        FilterIcon,
    },

    props: {
        link: {
            type: [String, Function],
            default: '/flats/',
        },
    },

    methods: {
        handleClick() {
            if (typeof this.link === 'function') {
                this.link();
            }
        },
    },
};
</script>

<style lang="scss" module>
    .ProjectGenplanFilterBtn {
        position: relative;

        .btn {
            width: fit-content;

            &:hover {
                + .tooltip {
                    opacity: 1;
                }
            }

            @include respond-to(sm) {
                &:hover {
                    + .tooltip {
                        opacity: 0;
                    }
                }
            }

            @include respond-to(xxs) {
                width: 100%;
            }
        }

        .tooltip {
            position: absolute;
            top: calc(100% + 1.2rem);
            left: 50%;
            padding: 1.2rem;
            background-color: $base-900;
            white-space: nowrap;
            font-size: 1.4rem;
            font-weight: 300;
            line-height: 1.24;
            color: $base-0;
            opacity: 0;
            transform: translateX(-50%);
            transition: opacity $transition;
            box-shadow: 1.2rem 1.2rem 3.4rem rgba($base, .05);
        }
    }
</style>
