// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FlatsResetButton_PXJru{padding:0;color:inherit;background-color:transparent;border:none;cursor:pointer;background-clip:padding-box;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin-left:1.6rem;text-transform:uppercase;white-space:nowrap;font-size:1.2rem;font-weight:700;line-height:1;-webkit-transition:.3s ease;transition:.3s ease}.FlatsResetButton_PXJru:focus{outline:none}.FlatsResetButton_PXJru:not(._disabled_zOeNX):hover{color:#717171}.FlatsResetButton_PXJru .icon_RKw3M{display:-webkit-box;display:-ms-flexbox;display:flex;width:1.4rem;min-width:1.4rem;height:1.4rem;min-height:1.4rem;margin-left:.8rem;color:#a0a0a0}.FlatsResetButton_PXJru._disabled_zOeNX{color:#cfcfcf;pointer-events:none}.FlatsResetButton_PXJru._disabled_zOeNX .icon_RKw3M{color:#cfcfcf}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FlatsResetButton": "FlatsResetButton_PXJru",
	"_disabled": "_disabled_zOeNX",
	"icon": "icon_RKw3M"
};
module.exports = ___CSS_LOADER_EXPORT___;
