<template>
    <div
        :class="$style.GenplanPinInfrastructure"
        :style="stylesValue"
        @click="handleTooltip"
    >
        <img
            v-if="info.icon"
            :src="info.icon"
            :alt="info.name"
        >

        <div
            v-if="info.name"
            :class="$style.tooltip"
        >
            <div
                :class="$style.title"
                v-html="info.name"
            />

            <div
                v-if="info.text"
                :class="$style.description"
                v-html="info.text"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'GenplanPinInfrastructure',

    props: {
        info: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        stylesValue: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },

    methods: {
        handleTooltip() {
            if (this.$device.isDesktop) {
                return;
            }

            this.$emit('click', this.info);
        },
    },
};
</script>

<style lang="scss" module>
    .GenplanPinInfrastructure {
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 50%;
        background-color: #f4f4f4;
        pointer-events: all;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            bottom: -.6rem;
            left: 50%;
            width: 0;
            height: 0;
            border-top: 1.2rem solid #f4f4f4;
            border-right: .6rem solid transparent;
            border-left: .6rem solid transparent;
            transform: translateX(-50%);
        }

        .icon {
            width: 1.6rem;
            height: 1.6rem;
            object-fit: contain;
        }

        .tooltip {
            position: absolute;
            top: calc(100% + 8px);
            left: 50%;
            visibility: hidden;
            width: max-content;
            max-width: 25rem;
            padding: .8rem;
            background-color: $base-0;
            opacity: 0;
            user-select: none;
            pointer-events: none;
            transform: translateX(-50%);
            transition: all .3s ease-in;
        }

        .title {
            text-transform: uppercase;
            white-space: nowrap;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 1.2;
            color: $base-900;
        }

        .description {
            margin-top: .6rem;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1;
            color: $base-500;
        }

        &:hover {
            .tooltip {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
            }
        }

        @include respond-to(sm) {
            .tooltip {
                display: none;
            }
        }
    }
</style>
