// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProjectGenplanUrbanTooltip_39D1x{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:.8rem;gap:.8rem;width:34rem;min-width:34rem;max-width:34rem;padding:.8rem;-webkit-box-shadow:0 1.6rem 2.4rem rgba(0,0,0,.06),0 .2rem .6rem rgba(0,0,0,.04),0 0 .1rem rgba(0,0,0,.04);box-shadow:0 1.6rem 2.4rem rgba(0,0,0,.06),0 .2rem .6rem rgba(0,0,0,.04),0 0 .1rem rgba(0,0,0,.04);background-color:#fff}.ProjectGenplanUrbanTooltip_39D1x .image_9f0FO{width:100%;height:16.4rem}.ProjectGenplanUrbanTooltip_39D1x .container_A2\\+8f{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:2rem;gap:2rem;padding:1.2rem}.ProjectGenplanUrbanTooltip_39D1x .info_tsBlM{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}.ProjectGenplanUrbanTooltip_39D1x .header_kn5n6{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.ProjectGenplanUrbanTooltip_39D1x .title_8K8sq{font-size:2rem;font-weight:700;line-height:2.4rem;color:#2f2f2f}.ProjectGenplanUrbanTooltip_39D1x .subtitle_oUBIs{margin-top:.8rem;font-size:1.4rem;font-weight:400;line-height:1.2;color:#2f2f2f}.ProjectGenplanUrbanTooltip_39D1x .description_oqS7\\+{font-size:1.4rem;line-height:2rem;letter-spacing:normal;color:#616161;margin-top:.8rem;font-weight:300;line-height:132%;color:#717171}.ProjectGenplanUrbanTooltip_39D1x .btns_DgIlc{display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;grid-gap:.8rem;gap:.8rem}.ProjectGenplanUrbanTooltip_39D1x .flatsInfo_QO1X9{padding-top:2rem;border-top:1px solid #e7e7e7}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ProjectGenplanUrbanTooltip": "ProjectGenplanUrbanTooltip_39D1x",
	"image": "image_9f0FO",
	"container": "container_A2+8f",
	"info": "info_tsBlM",
	"header": "header_kn5n6",
	"title": "title_8K8sq",
	"subtitle": "subtitle_oUBIs",
	"description": "description_oqS7+",
	"btns": "btns_DgIlc",
	"flatsInfo": "flatsInfo_QO1X9"
};
module.exports = ___CSS_LOADER_EXPORT___;
