<template>
    <ValidationObserver
        v-slot="{ handleSubmit }"
        ref="observer"
        tag="div"
        :class="$style.MapBuildRouteMobile"
    >
        <UiInput
            v-model="addressLazy"
            field="адрес"
            name="address"
            rules="required|map_route"
            :custom-messages="{ required: 'Введите адрес' }"
            placeholder="Ваше местоположение"
            :class="$style.input"
        />

        <div :class="$style.name">
            ÁLIA

            <LocationArrowIcon :class="$style.icon" />
        </div>

        <div :class="$style.btns">
            <UiButton
                :size="$device.isTablet ? 'big' : ''"
                :active="routeTypeLazy === 'pedestrian'"
                @click="routeTypeLazy = 'pedestrian'"
            >
                <template slot="iconBefore">
                    <WalkIcon />
                </template>

                <template slot="default">
                    <span :class="$style.routeBtnText">
                        Пешком<br/>
                        <span :class="$style.gray">{{ pedestrianTime }}</span>
                    </span>
                </template>
            </UiButton>

            <UiButton
                :size="$device.isTablet ? 'big' : ''"
                :active="routeTypeLazy === 'driving'"
                @click="routeTypeLazy = 'driving'"
            >
                <template slot="iconBefore">
                    <CarIcon />
                </template>

                <template slot="default">
                    <span :class="$style.routeBtnText">
                        Авто<br/>
                        <span :class="$style.gray">{{ drivingTime }}</span>
                    </span>
                </template>
            </UiButton>
        </div>

        <UiButton
            color="primary"
            :size="$device.isTablet ? 'big' : ''"
            @click="handleSubmit(() => onBuild())"
        >
            <template slot="iconBefore">
                <MapRoute />
            </template>

            <template slot="default">
                Построить маршрут
            </template>
        </UiButton>

        <ExpandBlock>
            <UiButton
                v-if="routeInfoLazy"
                :size="$device.isTablet ? 'big' : ''"
                :class="$style.resetButton"
                @click="onResetRoute"
            >
                <template slot="iconBefore">
                    <CloseIcon />
                </template>

                <template slot="default">
                    Сбросить маршрут
                </template>
            </UiButton>
        </ExpandBlock>
    </ValidationObserver>
</template>

<script>
import UiInput from '~/components/ui/home/UiInput';
import UiButton from '~/components/ui/home/UiButton';

import LocationArrowIcon from '~/assets/icons/home/location-arrow.svg?inline';
import WalkIcon from '~/assets/icons/home/walk.svg?inline';
import CarIcon from '~/assets/icons/home/car.svg?inline';
import MapRoute from '~/assets/icons/home/map-route.svg?inline';
import CloseIcon from '~/assets/icons/home/close.svg?inline';
import ExpandBlock from '~/components/common/ExpandBlock.vue';

export default {
    name: 'MapBuildRouteMobile',

    components: {
        ExpandBlock,
        UiInput,
        UiButton,
        LocationArrowIcon,
        WalkIcon,
        CarIcon,
        MapRoute,
        CloseIcon,
    },

    props: {
        buildRoute: {
            type: Function,
            required: true,
        },

        resetRoute: {
            type: Function,
            required: true,
        },

        routeInfo: {
            type: Object,
            default: () => null,
        },

        address: {
            type: String,
            default: '',
        },

        routeType: {
            type: String,
            default: 'driving',
        },
    },

    data() {
        return {
            addressLazy: this.address,
            routeTypeLazy: this.routeType,
            routeInfoLazy: this.routeInfo,
        };
    },

    computed: {
        drivingTime() {
            if (this.routeInfoLazy && this.routeInfoLazy.type === 'driving') {
                const time = this.routeInfoLazy.time.split(' ').map(el => {
                    el = el[el.length - 1] === '.'? el : el + '.';
                    return el;
                });
                return time.join(' ');
            }

            return '';
        },

        pedestrianTime() {
            if (this.routeInfoLazy && this.routeInfoLazy.type === 'pedestrian') {
                const time = this.routeInfoLazy.time.split(' ').map(el => {
                    el = el[el.length - 1] === '.'? el : el + '.';
                    return el;
                });
                return time.join(' ');
            }

            return '';
        },
    },

    watch: {
        routeTypeLazy() {
            if (!this.routeInfoLazy) {
                return false;
            }

            this.$refs.observer.validate().then(isValid => {
                if (isValid) {
                    this.onBuild();
                }
            });
        },
    },

    methods: {
        onBuild() {
            this.buildRoute(this.addressLazy, this.routeTypeLazy);
            this.$emit('close');
        },

        onResetRoute() {
            this.resetRoute();
            this.addressLazy = '';
            this.routeInfoLazy = null;
            this.$refs.observer.reset();
        },
    },
};
</script>

<style lang="scss" module>
    .MapBuildRouteMobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2.4rem;

        .input {
            margin-bottom: 2rem;
        }

        .name {
            @include old-p4;

            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 5.6rem;
            margin-bottom: 2rem;
            padding: 0 3.2rem;
            background-color: $home-accent-6;
            color: $home-accent-2;
        }

        .icon {
            width: 2rem;
            height: 2rem;
        }

        .btns {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.2rem;
            margin-bottom: 2rem;
        }

        .resetButton {
            margin-top: 2rem;
        }

        .routeBtnText {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            span {
                text-align: start;
                white-space: nowrap;
            }
        }

        .gray {
            color: $home-accent-5;
        }
    }

    @include respond-to(xs) {
        .MapBuildRouteMobile {
            padding: 1.6rem;
        }
    }
</style>
