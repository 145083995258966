/**
 * Получение микроразметки для сайта
 * @param {string} title
 * @param {string} description
 * @param {string} url
 * @returns {({
 *     hid: string,
 *     name: string,
 *     content: string
 * })[]}
 */
export default function getMicroMarkup({
    title = '',
    description = '',
    url = '',
}) {
    return [
        {
            hid: 'og:image',
            name: 'og:image',
            content: 'https://alia.moscow/m/Alia_BLD_10_Cam_004_01.jpg'
        },

        title ? {
            hid: 'og:title',
            name: 'og:title',
            content: title,
        } : undefined,

        description ? {
            hid: 'og:description',
            name: 'og:description',
            content: description,
        } : undefined,

        url ? {
            hid: 'og:url',
            name: 'og:url',
            content: url,
        } : undefined,
    ].filter(Boolean);
}
