var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",class:[_vm.$style.ProjectFormatSlide, {[_vm.$style._loaded]: _vm.loaded}]},[_c('div',{class:_vm.$style.container,style:(_vm.imageOrientation)},[_c('img',{ref:"image",staticClass:"swiper-lazy",class:_vm.$style.image,style:(_vm.imageOrientation),attrs:{"data-src":_vm.slide.plan},on:{"load":_vm.handlerLoadImage}}),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":_vm.$style.overlayEnterActive,"leave-active-class":_vm.$style.overlayLeaveActive,"enter-class":_vm.$style.overlayEnter,"leave-to-class":_vm.$style.overlayLeaveTo}},[(_vm.loaded && _vm.displayPins.length && _vm.isActive)?_c('div',{ref:"overlay",class:_vm.$style.overlay},[_vm._l((_vm.displayPins),function(pin){return [(pin.imageDisplay || pin.title || pin.text)?[(_vm.$device.isDesktop)?_c('UiTooltip',{key:pin.id,class:_vm.$style.position,style:({
                                top: `${pin.point[1]}%`,
                                left: `${pin.point[0]}%`,
                            }),attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('UiButton',{class:_vm.$style.button,attrs:{"color":"black"}},[_c('PlusIcon',{class:_vm.$style.buttonIcon})],1)]},proxy:true},{key:"default",fn:function(){return [_c('ProjectLocationVisualCard',{class:_vm.$style.pinCard,attrs:{"title":pin.title,"text":pin.text,"display-image":pin.imageDisplay,"preview-image":pin.imagePreview}})]},proxy:true}],null,true)}):_c('UiButton',{key:pin.id,class:[_vm.$style.button, _vm.$style.position],style:({
                                top: `${pin.point[1]}%`,
                                left: `${pin.point[0]}%`,
                            }),attrs:{"color":"black"},on:{"click":function($event){return _vm.handlePinModal(pin)}}},[_c('PlusIcon',{class:_vm.$style.buttonIcon})],1)]:_vm._e()]})],2):_vm._e()])],1),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":_vm.$style.skeletonEnterActive,"leave-active-class":_vm.$style.skeletonLeaveActive,"enter-class":_vm.$style.skeletonEnter,"leave-to-class":_vm.$style.skeletonLeaveTo}},[(!_vm.loaded)?_c('SkeletonLoader',{attrs:{"absolute":"","radius":".6rem","color":_vm.isActive ? '#f3f3f3' : !_vm.$device.isMobile ? '#dddde7' : '#f3f3f3'}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }