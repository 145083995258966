<template>
    <div
        ref="HomeCards"
        class="container-home"
        :class="$style.HomeCards"
    >
        <UiCard
            v-for="(card, i) in cards"
            :key="i"
            v-bind="card"
            :class="{ 'js-card': i !== 0 }"
            :style="`z-index: ${cards.length - i}; grid-area: card${i};`"
        />
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import UiCard from '~/components/ui/cards/UiCard';
import LocationIcon from '~/assets/icons/home/location-pin.svg?inline';
import SunIcon from '~/assets/icons/home/sun.svg?inline';
import FireIcon from '~/assets/icons/home/fire.svg?inline';
import MapIcon from '~/assets/icons/map.svg?inline';

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'HomeCards',

    components: {
        UiCard,
    },

    data() {
        return {
            cards: [{
                title: 'Лучшие условия',
                icon: FireIcon,
                text: 'Актуальные выгодные предложения для покупки квартир в жилом районе ÁLIA.',
                color: 'primary',
                link: '/flats/?newTypeFlat=209&orderBy=-sale_percent&sale=1',
            }, {
                title: 'Район-оазис',
                icon: SunIcon,
                text: 'Экологически чистый район Москвы у слияния двух рек, в окружении девяти парков.',
            }, {
                title: 'Северо-запад Москвы',
                icon: LocationIcon,
                text: 'Район Покровское-Стрешнево,<br> на берегу Москвы-реки.',
            }, {
                title: '15-минутный город',
                icon: MapIcon,
                text: 'Всё необходимое для жизни в пешей доступности.',
            }],
        };
    },

    mounted() {
        if (window.innerWidth <= 991) {
            const screens = gsap.utils.toArray('.js-card');

            screens.forEach((screen, i) => {
                gsap.from(screen, {
                    scrollTrigger: {
                        trigger: this.$refs.HomeCards,
                        start: 'top bottom',
                        end: `+=${this.$refs.HomeCards.clientHeight + 50} bottom`,
                        scrub: true,
                    },

                    ease: 'none',
                    scale: .1 * (9 - i),
                    opacity: 1 / (i + 2),
                    y: `${-130 * (i + 1)}%`,
                });
            });
        }
    },
};
</script>

<style lang="scss" module>
    .HomeCards {
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: "card2 card3 card0 card1";
        gap: 2rem;

        @include respond-to(sm) {
            grid-template-columns: 1fr;
            grid-template-areas: "card0" "card1" "card2" "card3";
            gap: .8rem;
        }
    }
</style>
